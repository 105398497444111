import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandThunk } from "../index";
import {
	setError,
	setLoading,
	setWasCreated,
	setWasDeleted,
	setWasUpdated,
} from "./FlagSlice";
import { createBrand, deleteBrand, updateBrand } from "../graphql/mutations";
import { brandByName, getBrand, listBrands } from "../graphql/queries";


//Interfaces
export interface BrandState {
	brand: BrandObject[];
	actualBrand: BrandObject;
}

export interface BrandObject {
	
	id: string;
	name: string,
}

//Initial State
const initialState: BrandState = {
	brand: [],
	actualBrand: { 
		id: "",
		name: "",
	}
};

//Reducers
const brand = createSlice({
	name: "brand",
	initialState,
	reducers: {
		setBrand(state, { payload }: PayloadAction<BrandObject[]>) {
			state.brand = payload;
		},
		setActualBrand(state, { payload }: PayloadAction<BrandObject>){
			state.actualBrand = payload;
		},
		cleanBrand(state) {
			state.brand = [];
		},
	},
});

//Exports
export const { setBrand, cleanBrand, setActualBrand } = brand.actions;

export default brand.reducer;

export const brandSelector = (state: { brandStore: BrandState }) =>
	state.brandStore;

//Middleware
export const postBrand = (input: any): BrandThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createBrand, { input }));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error.errors[0].message));
		}
	};
};

export const getBrandInfo =
  (input: any): BrandThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getBrand, input));
      if (typeof res.data.getBrand !== "undefined") {
        dispatch(setActualBrand(res.data.getBrand));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const getAllBrand = (): BrandThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(listBrands));
		dispatch(setBrand(res.data.listBrands.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const getAllBrandByName = (input: any): BrandThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(brandByName, {input}));
		dispatch(setBrand(res.data.brandByName.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const readBrand =
	(input: any): BrandThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(
				graphqlOperation(getBrand, { input })
			);
			dispatch(setBrand(res.data.listBrands));
			dispatch(setLoading(false));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editBrand = (input: any): BrandThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(updateBrand, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removeBrand = (input: any): BrandThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deleteBrand, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error:any) {
			dispatch(setLoading(false));
		}
	};
};
