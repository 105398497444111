import { getProductInfo } from './ProductSlice';
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryThunk } from "../index";
import {
	setError,
	setLoading,
	setWasCreated,
	setWasDeleted,
	setWasUpdated,
} from "./FlagSlice";
import { createCategory, deleteCategory, updateCategory } from "../graphql/mutations";
import { categoryByName, getCategory, listCategorys } from "../graphql/queries";
import { getPropertyInfo } from './PropertySlice';


//Interfaces
export interface CategoryState {
	category: CategoryObject[];
	actualCategory: CategoryObject;
}

export interface CategoryObject {
	
	id: string;
	name: string,
}

//Initial State
const initialState: CategoryState = {
	category: [],
	actualCategory: { 
		id: "",
		name: "",
	}
};

//Reducers
const category = createSlice({
	name: "category",
	initialState,
	reducers: {
		setCategory(state, { payload }: PayloadAction<CategoryObject[]>) {
			state.category = payload;
		},
		setActualCategory(state, { payload }: PayloadAction<CategoryObject>){
			state.actualCategory = payload;
		},
		cleanCategory(state) {
			state.category = [];
		},
	},
});

//Exports
export const { setCategory, cleanCategory, setActualCategory } = category.actions;

export default category.reducer;

export const categorySelector = (state: { categoryStore: CategoryState }) =>
	state.categoryStore;

//Middleware
export const postCategory = (input: any): CategoryThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createCategory, { input }));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error.errors[0].message));
		}
	};
};

export const getCategoryInfo =
  (input: any): CategoryThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getCategory, input));
      if (typeof res.data.getCategory !== "undefined") {
        dispatch(setActualCategory(res.data.getCategory));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const getAllCategory = (): CategoryThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(listCategorys));
		dispatch(setCategory(res.data.listCategorys.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const getAllCategoryByName = (input: any): CategoryThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(categoryByName, {input}));
		dispatch(setCategory(res.data.categoryByName.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const readCategory =
	(input: any): CategoryThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(
				graphqlOperation(getCategory, { input })
			);
			dispatch(setCategory(res.data.listCategorys));
			dispatch(setLoading(false));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editCategory = (input: any): CategoryThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(updateCategory, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removeCategory = (input: any): CategoryThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deleteCategory, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error:any) {
			dispatch(setLoading(false));
		}
	};
};
