import { IonButton, IonCard, IonCol, IonGrid, IonRow, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import { image } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { flagSelector } from "../../store/FlagSlice";
import { editShop, ShopObject } from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
import ImageAdminStatus from "./ImagesAdminStatus";


const ImagesAdminSliderCard: React.FC<{slider: string[]; shop: ShopObject; 
}> = (props) => {
  const [imageStatus, setImageStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined
  >()
 

return ( <>
{props.slider.map((photo: string, index: number) =>
    <IonCard>
                    <IonGrid>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <IonText color="tertiary"><b>
                          {props.shop.name}</b></IonText>
                        </IonCol>
                      </IonRow>
                        <IonRow>
                            <IonCol className="ion-text-center">
                            <img src={photo} />
                            </IonCol>
                        </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <ImageAdminStatus
                            setImageStatus={setImageStatus}
                            imageStatus={imageStatus}
                            shop={props.shop}
                            index={index}
                          />
                       
{/*                     
      
      <IonButton className="ion-text-center" expand="block" 
      // color={colorByStatus()!}
      >
          <IonSelect 
            value={imageStatus}
            onIonChange={(e) => {
              changeStatus(e.detail.value); setImageStatus(e.detail.value);
            }}
          >
            <IonSelectOption value="PENDIENTE DE APROBACIÓN">PENDIENTE DE APROBACIÓN</IonSelectOption>
            <IonSelectOption value="ACTIVO">
           APROBADO
            </IonSelectOption>
            <IonSelectOption value="NO APROBADO">
           NO APROBADO
            </IonSelectOption>
          </IonSelect>
          </IonButton>*/}
   </IonCol>
                      </IonRow> 
                    </IonGrid>
                  </IonCard>)}</>
)
}
export default ImagesAdminSliderCard;