import { useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyShopEditContent from "../../components/shop/MyShopEditContent";
import { authSelector } from "../../store/AuthSlice";
import { getShopInfo } from "../../store/ShopSlice";

const MyShopEditPage: React.FC = () => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector(authSelector);

  useIonViewWillEnter(() => {
    let userProfileEmail = {
      userProfileEmail: userProfile.email,
    };
    dispatch(getShopInfo(userProfileEmail));
  });
  return <MyShopEditContent />;
};

export default MyShopEditPage;
