import { IonCol, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { editCambaceo, postCambaceo, removeCambaceo } from "../../store/CambaceoSlice";

import { shopSelector } from "../../store/ShopSlice";


const MyCambaceoForm: React.FC<{
  myCambaceo: Dispatch<SetStateAction<string>>; previousPage: string
}> = (props) => {
  const [theCambaceo, setTheCambaceo] = useState<any>([]);
  

  const fieldArray = [
     {
          name: "sku",
          label: "SKU del producto",
          type: "text" as FieldTypes,
          value: useState(theCambaceo.sku),
          placeholder: "Ej: Novo101",
          error: useState(false),
          errorMessage: useState(""),
          isRequired: true,
          validationType: "text",
        },
        {
      name: "name",
      label: "Nombre del producto",
      type: "text" as FieldTypes,
      value: useState(theCambaceo.name),
      placeholder: "Ej: Playera amarilla",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
  
          {
            name: "price",
            label: "Precio",
            type: "number" as FieldTypes,
            value: useState(theCambaceo.price),
            placeholder: "Ej: 90",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
          {
            name: "promoPrice",
            label: "Precio en promoción",
            type: "number" as FieldTypes,
            value: useState(theCambaceo.promoPrice),
            placeholder: "Ej: 90",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
          {
            name: "promoType",
            label: "Tipo de promoción",
            type: "text" as FieldTypes,
            value: useState(theCambaceo.promoType),
            placeholder: "Ej: Promo Verano",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postCambaceo,
      pushRoute: props.previousPage,
    },
    edit: {
      inputObject: {},
      dispatchFunction: editCambaceo,
      pushRoute: "/mis-colecciones/pagina/productos",
    },
    delete: {
      inputObject: {},
      dispatchFunction: removeCambaceo,
      pushRoute: "/mis-colecciones/pagina/productos",
    },

    


  };

  return (
   
      <IonRow>
        <IonCol>
          <AmonytForm
            title={"Crear producto"}
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={true}
            hiddenFields={[
              { name: "amount", value: "1" }
            ]}
          
          />
        </IonCol>
      </IonRow>
   
  );
};

export default MyCambaceoForm;
