import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import {
  arrowBackOutline,
  closeCircleOutline,
  square,
  storefrontOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector } from "../../store/FlagSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";

import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import {
  listShopsInfo,
  setActualShop,
  shopSelector,
} from "../../store/ShopSlice";
import {
  SHOP_PLAN_ONE,
  SHOP_PLAN_THREE,
  SHOP_PLAN_TWO,
} from "../../pages/MyShop";
import ShopState from "./ShopState";

export const PRODUCTS_LANDING = "productsLanding";
export const PRODUCTS_ADD = "productsAdd";
export const PRODUCTS_VARIATIONS = "productsVariations";
export const PRODUCTS_ADD_VARIATIONS = "productsAddVariations";

const AllShopsContent: React.FC = () => {
  const [myProducts, setProducts] = useState(PRODUCTS_LANDING);
  const { theShop } = useSelector(shopSelector);
  const { loading } = useSelector(flagSelector);
  const { shops } = useSelector(shopSelector);
  const [shopStatus, setShopStatus] = useState<
    "ACTIVO" | "PENDIENTE DE APROBACIÓN" | "SUSPENDIDO" | undefined
  >();
  const [statusView, setStatusView] = useState(false);
  const tiendaPrincipal = shops.filter((shop) => shop.shopID === "novocorpo");

  const shopsActivos = shops.filter((shop) => shop.status === "ACTIVO");
  const shopsPendientes = shops.filter(
    (shop) => shop.status === "PENDIENTE DE APROBACIÓN"
  );
  const shopsSuspendidos = shops.filter((shop) => shop.status === "SUSPENDIDO");
  let color = "warning";
  const [status, setStatus] = useState("ACTIVO");

  const [toastMessage, setToastMessage] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
  }, [theShop.status, shopStatus, statusView]);

  const recharge = () => {
    setShopStatus(undefined);
    dispatch(listShopsInfo());
  };

  const goBackHandler = () => {
    if (myProducts === PRODUCTS_LANDING) {
      dispatch(setActualShop(tiendaPrincipal[0]));
      history.push("/mi-tienda");
    }
    else if (myProducts === PRODUCTS_ADD) {
      setProducts(PRODUCTS_LANDING);
    } else if (myProducts === PRODUCTS_VARIATIONS) {
      setProducts(PRODUCTS_LANDING);
    } else if (myProducts === PRODUCTS_ADD_VARIATIONS) {
      setProducts(PRODUCTS_VARIATIONS);
    }
  };
  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar iconName={storefrontOutline} title="Tiendas" />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonContent>
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
          </IonRow>

          <IonModal isOpen={statusView}>
            <IonRow>
              <IonCol className="ion-text-right">
                <IonButton onClick={() => setStatusView(false)} fill="clear">
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonCol>
            </IonRow>

            <ShopState setShopStatus={setShopStatus} shopStatus={shopStatus} />
            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  className="ion-margin"
                  fill="outline"
                  onClick={() => {
                    dispatch(listShopsInfo());
                    recharge();
                    setStatusView(false);
                  }}
                >
                  Guardar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonModal>

          <>
            <IonRow className="ion-text-center">
              <IonCol>
                <IonTitle color="tertiary">
                  <h2>Estatus</h2>
                </IonTitle>
              </IonCol>
            </IonRow>
           
            <IonRow className="ion-text-center">
              <IonCol offsetXs="3" sizeXs="12" sizeMd="3" offsetMd="1.5">
                <IonItem lines="none">
                  <IonIcon color="warning" icon={square} slot="start" />
                  <IonText>Tienda Premium</IonText>
                </IonItem>
              </IonCol>
              <IonCol offsetXs="3" sizeXs="12" sizeMd="3" offsetMd="0">
                <IonItem lines="none">
                  <IonIcon color="secondary" icon={square} slot="start" />
                  <IonText>Tienda Clásica</IonText>
                </IonItem>
              </IonCol>
              <IonCol offsetXs="3" sizeXs="12" sizeMd="3" offsetMd="0">
                <IonItem lines="none">
                  <IonIcon color="medium" icon={square} slot="start" />
                  <IonText>Tienda Gratuita</IonText>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-flex">
              <IonCol>
                <IonButton
                  onClick={() => setStatus("ACTIVO")}
                  color={status === "ACTIVO" ? "tertiary" : "primary"}
                  expand="full"
                >
                  Activos
                </IonButton>{" "}
              </IonCol>
              <IonCol>
                <IonButton
                  onClick={() => setStatus("PENDIENTE")}
                  color={status === "PENDIENTE" ? "tertiary" : "primary"}
                  expand="full"
                >
                  Pendientes
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  onClick={() => setStatus("SUSPENDIDO")}
                  color={status === "SUSPENDIDO" ? "tertiary" : "primary"}
                  expand="full"
                >
                  Suspendidos
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonCard>
                  {status === "ACTIVO" &&
                    shopsActivos.map((item) => {
                      switch (item.plan) {
                        case SHOP_PLAN_THREE:
                          color = "warning";
                          break;
                        case SHOP_PLAN_TWO:
                          color = "secondary";
                          break;
                        case SHOP_PLAN_ONE:
                          color = "medium";
                          break;
                      }
                      return (
                        <IonCard>
                          <IonItem
                            onClick={() => dispatch(setActualShop(item))}
                            className="show-click"
                            color={color}
                          >
                            <IonIcon icon={storefrontOutline} slot="start" />

                            <IonLabel>{item.name}</IonLabel>
                            <IonButton
                              fill="outline"
                              slot="end"
                              onClick={() => setStatusView(true)}
                            >
                              Editar
                            </IonButton>
                          </IonItem>
                        </IonCard>
                      );
                    })}

                  {status === "PENDIENTE" &&
                    shopsPendientes.map((item) => {
                      switch (item.plan) {
                        case SHOP_PLAN_THREE:
                          color = "warning";
                          break;
                        case SHOP_PLAN_TWO:
                          color = "secondary";
                          break;
                        case SHOP_PLAN_ONE:
                          color = "medium";
                          break;
                      }
                      return (
                        <IonCard>
                          <IonItem
                            onClick={() => dispatch(setActualShop(item))}
                            className="show-click"
                            color={color}
                          >
                            <IonIcon icon={storefrontOutline} slot="start" />
                            <IonLabel>{item.name}</IonLabel>

                            <IonButton
                              fill="outline"
                              slot="end"
                              onClick={() => setStatusView(true)}
                            >
                              Editar
                            </IonButton>
                          </IonItem>
                        </IonCard>
                      );
                    })}

                  {status === "SUSPENDIDO" &&
                    shopsSuspendidos.map((item) => {
                      switch (item.plan) {
                        case SHOP_PLAN_THREE:
                          color = "warning";
                          break;
                        case SHOP_PLAN_TWO:
                          color = "secondary";
                          break;
                        case SHOP_PLAN_ONE:
                          color = "medium";
                          break;
                      }
                      return (
                        <IonCard>
                          <IonItem
                            onClick={() => dispatch(setActualShop(item))}
                            className="show-click"
                            color={color}
                          >
                            <IonIcon icon={storefrontOutline} slot="start" />
                            <IonLabel>{item.name}</IonLabel>

                            <IonButton
                              fill="outline"
                              slot="end"
                              onClick={() => setStatusView(true)}
                            >
                              Editar
                            </IonButton>
                          </IonItem>
                        </IonCard>
                      );
                    })}
                </IonCard>
              </IonCol>
            </IonRow>
          </>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AllShopsContent;
