import {  IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import {  cambaceoSelector, editCambaceo, postCambaceo, removeCambaceo } from "../../store/CambaceoSlice";


const MyCambaceoEditForm: React.FC<{
  myCambaceo: Dispatch<SetStateAction<string>>;
}> = () => {
  const { actualCambaceo } = useSelector(cambaceoSelector)


  const fieldArray = [
     {
          name: "sku",
          label: "SKU del producto",
          type: "text" as FieldTypes,
          value: useState(actualCambaceo.sku),
          placeholder: "Ej: Novo101",
          error: useState(false),
          errorMessage: useState(""),
          isRequired: true,
          validationType: "text",
        },
        {
      name: "name",
      label: "Nombre del producto",
      type: "text" as FieldTypes,
      value: useState(actualCambaceo.name),
      placeholder: "Ej: Playera amarilla",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
  
          {
            name: "price",
            label: "Precio",
            type: "number" as FieldTypes,
            value: useState(JSON.stringify(actualCambaceo.price)),
            placeholder: "Ej: 90",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
          {
            name: "promoPrice",
            label: "Precio en promoción",
            type: "number" as FieldTypes,
            value: useState(JSON.stringify(actualCambaceo.promoPrice)),
            placeholder: "Ej: 90",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
          {
            name: "promoType",
            label: "Tipo de promoción",
            type: "text" as FieldTypes,
            value: useState(actualCambaceo.promoType),
            placeholder: "Ej: Promo Verano",
            error: useState(false),
            errorMessage: useState(""),
            isRequired: false,
            validationType: "text",
          },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postCambaceo,
      pushRoute: "/mis-colecciones/pagina/productos",
    },
    edit: {
      inputObject: { id: actualCambaceo.id },
      dispatchFunction: editCambaceo,
      pushRoute: "/mis-colecciones/cambaceo",
    },
    delete: {
      inputObject: { id: actualCambaceo.id },
      dispatchFunction: removeCambaceo,
      pushRoute: "/mis-colecciones/cambaceo",
    },
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <AmonytForm
            title="Editar producto"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={false}
            hiddenFields={[
              { name: "amount", value: "1" }
            ]}
          
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MyCambaceoEditForm;
