import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonLabel,
  IonSearchbar,
  IonItem,
  IonModal,
  IonRadio,
  IonRange,
  IonRadioGroup,
  IonChip,
} from "@ionic/react";
import {
  cashOutline,
  closeCircleOutline,
  closeOutline,
  cubeOutline,
  gridOutline,
  homeOutline,
  storefrontOutline,
} from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import FooterComponent from "../global/general/FooterComponent";
import "./ShopProducts.css";
import { useSelector } from "react-redux";
import { productSelector } from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";
import ProductView from "../products/ProductView";
import { useState } from "react";
import { categorySelector } from "../../store/CategorySlice";

const ShopProdroducts: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const { products } = useSelector(productSelector);
  const { category } = useSelector(categorySelector);
  const [searchText, setSearchText] = useState("");
  const { shops } = useSelector(shopSelector);
  const [price, setPrice] = useState(false);
  const [cat, setCat] = useState("");
  const [selected, setSelected] = useState("");
  const prices = products.map((prod: any) =>
    prod.productVariations.items[0] !== undefined
      ? prod.productVariations.items[0].price
      : 0
  );
  const maxPrice = Math.max(...prices);
  const [rangeValue, setRangeValue] = useState<{
    lower: number;
    upper: number;
  }>({ lower: 0, upper: maxPrice });
  
  let catCopy = [...category];
  let catOrdered = catCopy.sort((a, b) =>
    a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  );

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="page-content info">
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonButtons>
                <IonButton color="secondary" href="/">
                  <IonIcon slot="start" icon={homeOutline}></IonIcon>
                  <IonText className="ion-hide-sm-down">Inicio</IonText>
                </IonButton>
                <IonText color="secondary">/</IonText>
                <IonButton color="secondary" href="/productos">
                  <IonIcon slot="start" icon={cubeOutline}></IonIcon>
                  <IonText className="ion-hide-sm-down">Productos</IonText>
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonTitle color="primary">
                <h1>Productos</h1>
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonSearchbar
                value={searchText}
                onIonChange={(e) => setSearchText(e.detail.value!)}
                placeholder="¿Qué estás buscando?"
              ></IonSearchbar>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol
              sizeMd="1.5"
              offsetMd="5.5"
              sizeXs="2.9"
              offsetXs="0"
              className="ion-text-right ion-padding"
            >
              <IonLabel color="primary">
                <small>Filtrar por:</small>
              </IonLabel>
            </IonCol>
            <IonCol className="ion-text-center" sizeMd="1" sizeXs="2">
              <IonChip
                color="primary"
                onClick={(e) => {
                  setShowModal(true);
                }}
              >
                <IonIcon
                  className="ion-hide-md-down"
                  icon={storefrontOutline}
                />
                <IonText>Tienda</IonText>
              </IonChip>
            </IonCol>
            <IonCol className="ion-text-center" sizeMd="1" sizeXs="2" offsetXs="0.3" offsetMd="0">
              <IonChip
                color="primary"
                onClick={(e) => {
                  setShowModal2(true);
                }}
              >
                <IonIcon
                  className="ion-hide-md-down"
                  icon={cashOutline}
                ></IonIcon>
                <IonText>Precio</IonText>
              </IonChip>
            </IonCol>
            <IonCol className="ion-text-center" sizeMd="1" sizeXs="2" offsetXs="0.2" offsetMd="0">
              <IonChip
                color="primary"
                onClick={(e) => {
                  setShowModal3(true);
                }}
              >
                <IonIcon
                  className="ion-hide-md-down"
                  icon={gridOutline}
                ></IonIcon>
                <IonText>Categoría</IonText>
              </IonChip>
            </IonCol>
            <IonCol className="ion-text-center" sizeMd="2" sizeXs="2" offsetXs="4" offsetMd="0">
              <IonChip
                color={
                  selected !== "" || price === true || cat !== ""
                    ? "tertiary"
                    : "secondary"
                }
                onClick={(e) => {
                  setSelected("");
                  setRangeValue({ lower: 0, upper: maxPrice });
                  setPrice(false);
                  setCat("");
                }}
              >
                <IonIcon icon={closeOutline}></IonIcon>
                <IonText>Limpiar</IonText>
              </IonChip>
            </IonCol>
          </IonRow>

          <div className="content">
            {price === false &&
              selected == "" && cat == "" &&
              products
              .filter((prod: any) =>
                prod.name.toLowerCase().includes(searchText.toLowerCase()) || prod.description.toLowerCase().includes(searchText.toLowerCase())
                || prod.category.toLowerCase().includes(searchText.toLowerCase())
              )
                .filter((prod) => prod.status === "ACTIVO")
                .map((prod: any) =>
                  prod.productVariations.items[0] !== undefined ? (
                    <ProductView key={prod.id} {...prod}></ProductView>
                  ) : (
                    ""
                  )
                )}
         

            {(price === true || selected !== "" || cat !== "") &&
              products
              .filter((prod) => prod.status === "ACTIVO")
                .filter((prod: any) =>
                  selected !== ""
                    ? prod.shopID === selected
                    : prod.shopID !== selected
                )
                .filter((prod: any) =>
                  cat !== ""
                    ? prod.category.includes(cat)
                    : prod.category !== cat
                )
                .filter(
                  (prod: any) => prod.productVariations.items[0] !== undefined
                )
                .filter((prod: any) =>
                  price === true
                    ? prod.productVariations.items[0].price <= rangeValue.upper
                    : prod !== undefined
                )
                .map((prod: any) =>
                  prod.productVariations.items[0] !== undefined ? (
                    <ProductView key={prod.id} {...prod}></ProductView>
                  ) : (
                    ""
                  )
                )}
          </div>
        </IonGrid>
        <FooterComponent></FooterComponent>

        <IonModal isOpen={showModal}>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonButton
                onClick={() => {
                  setShowModal(false);
                }}
                fill="clear"
              >
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonGrid fixed>
            <IonRow className="ion-text-center ion-margin">
              <IonTitle color="primary">Filtrar por tienda</IonTitle>
            </IonRow>
            <IonGrid>
              <IonRow>
                <IonCol
                  className="ion-text-center"
                  sizeSm="12"
                  sizeMd="12"
                  offsetMd="3"
                  sizeLg="6"
                >
                  <IonRadioGroup
                    value={selected}
                    onIonChange={(e) => {
                      setSelected(e.detail.value);
                      setShowModal(false);
                    }}
                  >
                    {shops.map((shop) => (
                      <IonItem className="storeInput">
                        <IonLabel>{shop.name}</IonLabel>
                        <IonRadio slot="start" value={shop.shopID} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </IonModal>

        <IonModal isOpen={showModal3}>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonButton
                onClick={() => {
                  setShowModal3(false);
                }}
                fill="clear"
              >
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonGrid fixed>
            <IonRow className="ion-text-center ion-margin">
              <IonTitle color="primary">Filtrar por categoría</IonTitle>
            </IonRow>
            <IonGrid>
              <IonRow>
                <IonCol
                  className="ion-text-center"
                  sizeSm="12"
                  sizeMd="12"
                  offsetMd="3"
                  sizeLg="6"
                >
                  <IonRadioGroup
                    value={cat}
                    onIonChange={(e) => {
                      setCat(e.detail.value);
                      setShowModal3(false);
                    }}
                  >
                    {catOrdered.map((cat) => (
                      <IonItem> 
                        <IonLabel>{cat.name}</IonLabel>
                        <IonRadio slot="start" value={cat.name} />
                      </IonItem>
                    ))}
                  </IonRadioGroup>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </IonModal>

        <IonModal isOpen={showModal2}>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonButton onClick={() => setShowModal2(false)} fill="clear">
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonGrid fixed>
            <IonRow className="ion-text-center ion-margin">
              <IonTitle color="primary">Filtrar por precio</IonTitle>
            </IonRow>
            <IonGrid>
              <IonRow className="ion-text-center">
                <IonCol>
                  <IonLabel color="tertiary">Precio</IonLabel>
                  <IonRange
                    pin={true}
                    min={0}
                    max={maxPrice}
                    dualKnobs={true}
                    onIonChange={(e) => {
                      setRangeValue(e.detail.value as any);
                    }}
                  >
                    <IonLabel color="tertiary" slot="start">
                      $0
                    </IonLabel>
                    <IonLabel color="tertiary" slot="end">
                      ${maxPrice}
                    </IonLabel>
                  </IonRange>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="ion-text-center ion-margin ion-padding">
                  <IonButton
                    onClick={() => {
                      setShowModal2(false);
                      setPrice(true);
                    }}
                    className="ion-text-center"
                    color="primary"
                  >
                    Filtrar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default ShopProdroducts;
