import {
  IonButton,
  IonCol,
  IonIcon,
  IonImg,
  IonRow,
} from "@ionic/react";
import { createOutline, shareSocial} from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  getCatalogInfo,
  CatalogObject,
  setActualCatalog,
  catalogSelector,
} from "../../store/CatalogSlice";
import { shopSelector } from "../../store/ShopSlice";
import {
  ROUTE_SHOP_CATALOGS_EDIT,
  ROUTE_SHOP_CATALOGS_PAGES,
} from "../../utils/routes";

const MyCatalogsCard: React.FC<{
  catalog: CatalogObject;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [viewMore, setViewMore] = useState(false);

  const viewMoreHandler = () => {
    if (viewMore) {
      setViewMore(false);
    } else {
      setViewMore(true);
    }
  };

  const catalogLinkHandler = () => {
    dispatch(setActualCatalog(props.catalog));
    const catalogID = {
      id: props.catalog.id,
    };
    dispatch(getCatalogInfo(catalogID));
    history.push(ROUTE_SHOP_CATALOGS_PAGES);
  };
  return (
    <>
      <IonRow>
        <IonCol className="show-click ion-padding">
          {props.catalog.cover && (
            <IonImg
              src={props.catalog.cover}
              onClick={catalogLinkHandler}
              style={{ height: "300px", width: "200px", objectFit: "cover" }}
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            onClick={() => {
              dispatch(setActualCatalog(props.catalog));
              history.push(ROUTE_SHOP_CATALOGS_EDIT);
            }}
          >
            <IonIcon icon={createOutline} slot="icon-only" />
          </IonButton>
          <IonButton onClick={catalogLinkHandler}>
            {props.catalog.name}
          </IonButton>
          {props.catalog.coverStatus == "ACTIVO" && 
          <IonButton  onClick={() => { props.catalog.type === "catalogo" ?
													window.open(`https://wa.me/?text=Echa%20un%20vistazo%20al%20catálogo%20${props.catalog.name}:%20https://www.novocorpo.com.mx/catalogo/"+ ${props.catalog.id}`) : window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20la%20colección%20${props.catalog.name}:%20https://www.novocorpo.com.mx/catalogo/"+ ${props.catalog.id}`)
												}} >
            <IonIcon icon={shareSocial}>
            </IonIcon>
          </IonButton>}
        </IonCol>
      </IonRow>
    </>
  );
};

export default MyCatalogsCard;
