import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonederoLogThunk } from "../index";
import {
    setError,
    setLoading,
    setWasCreated,
    setWasDeleted,
    setWasUpdated,
  } from "./FlagSlice";
import { createMonederoLog, updateMonederoLog } from "../graphql/mutations";
import { getMonederoLog, listMonederoLogs, monederoLogsByUserID } from "../graphql/queries";
import { editUser, UserObject } from "./UserSlice";
import { ModelSortDirection } from "../API";
import { createDeflate } from "zlib";

//Interfaces
export interface MonederoLogState {
  monederoLog: MonederoLogObject[];
}

// export interface MonederoObject {
// 	userID: string;
//   points: number;
//   monederoLog: MonederoLog[],
// }

export interface MonederoLogObject{
    id: string;
    userID: string;
    points: number;
    type: string;
    description: string;
    createdAt: string;
}

//Initial State
const initialState: MonederoLogState = {
  monederoLog: []};

const monederoLog = createSlice({
  name: 'monederoLog',
  initialState,
  reducers: {
    setMonederoLog(state, { payload }: PayloadAction<MonederoLogObject[]>){
        state.monederoLog = payload
    },
    resetMonedero(state){
        state.monederoLog = []
    },

  },
});

export const {setMonederoLog, resetMonedero} = monederoLog.actions;

export default monederoLog.reducer;
export const monederoLogSelector = (state: { monederoLogStore: MonederoLogState }) => state.monederoLogStore;

//Middleware
export const postMonederoLog = (input: any): MonederoLogThunk => {
    return async (dispatch: any) => {
      try {
        dispatch(setLoading(true));
        const res:any = await API.graphql(graphqlOperation(createMonederoLog, { input }));
        dispatch(setLoading(false));
        dispatch(setWasCreated(true));
      } catch (error:any) {
        dispatch(setLoading(false));
        dispatch(setError(error));
      }
    };
};
export const editMonederoLogs = (input: any): MonederoLogThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(updateMonederoLog, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};
export const getMonederoLogsInfo =
  (input: any): MonederoLogThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getMonederoLog, input));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
  export const getAllMonederoLogs = (input: any): MonederoLogThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(listMonederoLogs, input));
			dispatch(setMonederoLog(res.data.listMonederoLogs.items));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
  export const getAllMonederoLogsByUserID = (input: any): MonederoLogThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(monederoLogsByUserID, input));
			dispatch(setMonederoLog(res.data.monederoLogsByUserID.items));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
