import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonText,
  IonCard,
  IonCardContent,
} from "@ionic/react";
import { alertCircle, createOutline, sync, trash } from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { fieldValidations } from "../validations/FieldValidations";
import AmonytField, { FieldFields, HiddenFields } from "./AmonytField";
import "./AmonytField.css";

const AmonytForm: React.FC<{
  title: string;
  debug?: boolean;
  fieldArray: FieldFields[];
  hiddenFields?: HiddenFields[];
  add: boolean;
  disableDelete?: boolean;
  actionObject: {
    add: {
      inputObject: {};
      dispatchFunction: any;
      pushRoute: string;
    };
    edit: {
      inputObject: {};
      dispatchFunction: any;
      pushRoute: string;
    };
    delete: {
      inputObject: {};
      dispatchFunction: any;
      pushRoute: string;
    };
  };
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false)


  const createForm = (fields: {}) => {
    const components: any = [];
    Object.entries(fields).forEach((field) => {
      const [key, value] = field;
      let val = value as FieldFields;
      components.push(<AmonytField  dataField={val as FieldFields} />);
    });
    return components;
  };

  const validateFields = (fields: {}) => {
    const fieldValidation: any = [];
    Object.entries(fields).forEach((field) => {
      const [key, value] = field;
      fieldValidation.push(fieldValidations(value as FieldFields));
    });

    if (fieldValidation.includes(true)) {
      return false;
    } else {
      return true;
    }
  };
  const formHandler = (action: string) => {
    setCheck(true)
    if (action === "delete") {
      dispatch(
        props.actionObject.delete.dispatchFunction(
          props.actionObject.delete.inputObject
        )
      );
      history.replace(props.actionObject.delete.pushRoute);
    }
    if(validateFields(props.fieldArray)){
      let input: any;
      if (action === "add") {
        input = props.actionObject.add.inputObject;
      } else if (action === "edit") {
        input = props.actionObject.edit.inputObject;
      }
      Object.entries(props.fieldArray).forEach((field) => {
        const [key, value] = field;
        switch (value.type) {
          case "images":
            const parsedValue = JSON.parse(value.value[0]);
            input = { ...input, [value.name]: parsedValue };
            break;
          case "select":
            input = { ...input, [value.name]: JSON.stringify(value.value[0]) };
            break;
          default:
            input = { ...input, [value.name]: value.value[0] };
        }
      });
      if (props.hiddenFields !== undefined) {
        Object.entries(props.hiddenFields).forEach((field) => {
          const [key, value] = field;
          input = { ...input, [value.name]: value.value?.toString() };
        });
      }

      if (action === "add") {
        dispatch(props.actionObject.add.dispatchFunction({ ...input }));
        history.replace(props.actionObject.add.pushRoute);
      } else if (action === "edit") {
        dispatch(props.actionObject.edit.dispatchFunction({ ...input }));
        history.replace(props.actionObject.edit.pushRoute);
      }
    }
  };

  return (
    <IonGrid fixed>
      <IonRow>
        <IonCol sizeXs="12" sizeMd="10" offsetMd="1" sizeLg="8" offsetLg="2">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonText className="ion-margin-bottom" color="primary">
                <h1>{props.title}</h1>
              </IonText>
            </IonCol>
          </IonRow>
          {/* {props.title === "Editar Carrusel" && 
          <IonText color="tertiary"><small>*Asegúrese de que sus imágenes tengan una medida de 740x400 píxeles aproximadamente.</small></IonText>}
          {(props.title === "Crear Colección" || props.title === "Crear Catálogo") && 
          <IonText color="tertiary"><small>*Asegúrese de que su imagen de portada tenga una medida de 345x240 píxeles aproximadamente.</small></IonText>} */}
   
          {createForm(props.fieldArray).map((field: any, index: number) => (
            <IonRow className="ion-margin-bottom" key={index}>
              <IonCol>{field}</IonCol>
            </IonRow>
          ))}
          {check && !validateFields(props.fieldArray) &&
            <IonCard className="validate">
              <IonCardContent>
            <IonIcon color="danger" icon={alertCircle} slot="start"/><IonText color="danger" className="ion-margin ion-padding">Revise los campos, por favor.</IonText></IonCardContent>
            </IonCard>}
          <IonRow>
            <IonCol>
              <IonButton
              color="tertiary"
                expand="block"
                onClick={() => {
                  props.add ? formHandler("add") : formHandler("edit");
                }}
              >
                {props.add ? (
                  <IonIcon slot="start" icon={createOutline} />
                ) : (
                  <IonIcon slot="start" icon={sync} />
                )}

                {props.add ? "Crear" : "Actualizar"}
              </IonButton>
            </IonCol>
          </IonRow>
          {!props.add && !props.disableDelete && (
            <IonRow>
              <IonCol>
                <IonButton
                  fill="clear"
                  expand="block"
                  color="danger"
                  onClick={() => {
                    formHandler("delete");
                  }}
                >
                  <IonIcon slot="start" icon={trash} />
                  <u>Eliminar</u>
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
            
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AmonytForm;
