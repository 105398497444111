/*
Users
User
users
user
*/
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonederoLogThunk, UserThunk } from "../index";
import { setError, setLoading, setWasCreated, setWasDeleted, setWasUpdated } from "./FlagSlice";
import { createUser, deleteUser, updateUser } from "../graphql/mutations";
import { getUser, listUsers, usersByUserProfileEmail } from "../graphql/queries";
import Monedero from "../components/profile/Monedero";
import { MonederoLogObject, setMonederoLog } from "./MonederoSlice";
import { customlistAllUsers } from "../graphql/customqueries";

//Interfaces
export interface UserState {
	users: UserObject[];
	actualUser: UserObject;
}

export interface UserObject {
	id: string;
	userID: string;
	phoneNumber: string;
	addressStreet: string;
	addressExterior: string;
	addressInterior: string;
	addressNeighborhood: string;
	addressZipcode: string;
	addressState: string;
	addressCity: string;
	addressNotes: string;
	invoiceName: string;
	invoiceRfc: string;
	invoiceStreet: string;
	invoiceExterior: string;
	invoiceInterior: string;
	invoiceNeighborhood: string;
	invoiceZipcode: string;
	invoiceState: string;
	invoiceCity: string;
	userProfileEmail: string;
	monederoPoints: 0;

}

//Initial State
const initialState: UserState = {
	users: [],
	actualUser: {
		id: "",
		userID: "",
		phoneNumber: "",
		addressStreet: "",
		addressExterior: "",
		addressInterior: "",
		addressNeighborhood: "",
		addressZipcode: "",
		addressState: "",
		addressCity: "",
		addressNotes: "",
		invoiceName: "",
		invoiceRfc: "",
		invoiceStreet: "",
		invoiceExterior: "",
		invoiceInterior: "",
		invoiceNeighborhood: "",
		invoiceZipcode: "",
		invoiceState: "",
		invoiceCity: "",
		userProfileEmail: "",
		monederoPoints: 0,
		
	},
};

//Reducers
const user = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser(state, { payload }: PayloadAction<UserObject[]>) {
			state.users = payload;
		},
		setActualUser(state, { payload }: PayloadAction<UserObject>) {
		
			state.actualUser = payload;
		},
		cleanUser(state) {
			state.users = [];
		},
	},
});

//Exports
export const { setUser, setActualUser, cleanUser } = user.actions;

export default user.reducer;

export const userSelector = (state: { userStore: UserState }) => state.userStore;

//Middleware
export const postUser = (input: any): UserThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createUser, {input}));
			dispatch(setActualUser(res.data.createUser));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const getAllUser =
	(input: any): UserThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(listUsers, input));
			dispatch(setUser(res.data.listUsers.items));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

	export const getAllUsers = (): UserThunk => async (dispatch: any) => {
		try {
		  dispatch(setLoading(true));
		  const res: any = await API.graphql(graphqlOperation(customlistAllUsers,{}));
		  dispatch(setUser(res.data.listUsers.items));
		  dispatch(setLoading(false));
		}
		catch(error:any){
		  dispatch(setLoading(false));
		  dispatch(setError(error));
		}
	};

export const readUser =
	(input: any): UserThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(usersByUserProfileEmail, input));
			dispatch(setActualUser(res.data.usersByUserProfileEmail.items[0]));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editUser = (input: any): UserThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(updateUser, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removeUser = (input: any): UserThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deleteUser, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error) {
			dispatch(setLoading(false));
		}
	};
};
