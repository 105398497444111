import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { catalogSelector } from "../../store/CatalogSlice";
import { catalogPageSelector, editCatalogPage, postCatalogPage, removeCatalogPage } from "../../store/CatalogPageSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";
import { ROUTE_SHOP_CATALOGS_PAGES } from "../../utils/routes";
import { arrowBackOutline, cube } from "ionicons/icons";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import Toolbar from "../global/general/Toolbar";
import { flagSelector } from "../../store/FlagSlice";
import { useHistory } from "react-router";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";

const CatalogPageForm: React.FC = () => {
	const history = useHistory();
	const { actualCatalog } = useSelector(catalogSelector);
	const { actualCatalogPage } = useSelector(catalogPageSelector);
	const { loading } = useSelector(flagSelector);
	const [toastMessage, setToastMessage] = useState("");
	const catType = useContext(CatalogTypeContext);

	const fieldArray = [
		{
			name: "pageNumber",
			label: "Número de página",
			type: "number" as FieldTypes,
			value: useState(actualCatalogPage.pageNumber + "" || ""),
			placeholder: "Ej: 1",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
		}
	];

	const image = {
			name: "pageImage",
			label: "Imagen",
			type: "images" as FieldTypes,
			value: useState(JSON.stringify(actualCatalogPage.pageImage) || ""),
			imageArray: useState([""]),
			placeholder: "",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
			showPreview: true,
			max: 1,
			unavailableImageURL: UNAVAILABLE_IMAGE,
			bucketURL: BUCKET_URL,
			requiredWidth: 450,
			requiredHeight: 600
		}
	
	useEffect(() => {
		if (catType.getCatalogType == "catalogo") {
			fieldArray.push(image)
		}
	})

	const actionObject = {
		add: {
			inputObject: {},
			dispatchFunction: postCatalogPage,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
		edit: {
			inputObject: {},
			dispatchFunction: editCatalogPage,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
		delete: {
			inputObject: { id: actualCatalogPage.id },
			dispatchFunction: removeCatalogPage,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
	};
	const goBackHandler = () => {
		history.replace(ROUTE_SHOP_CATALOGS_PAGES);
	};
	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<MyShopToolbar iconName={cube} title={"Mis " + (catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol sizeXs="1">
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
						<IonCol className="ion-text-start">
							<h3>
								Editar página {actualCatalogPage.pageNumber || ""} de {actualCatalog.name}
							</h3>
						</IonCol>
					</IonRow>
					<IonGrid>
						<IonRow>
							<IonCol>
								<AmonytForm
									title=""
									fieldArray={fieldArray}
									add={false}
									actionObject={actionObject}
									hiddenFields={[{ name: "catalogID", value: actualCatalog.id }]}
								/>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CatalogPageForm;
