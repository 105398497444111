import {
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useDispatch} from "react-redux";
import { getAllPropertyFeature } from "../../store/PropertyFeatureSlice";
import PropertyFeaturesList from "../../components/propertyFeatures/PropertyFeaturesList";

const PropertyFeaturesListPage: React.FC = () => {
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
   
    dispatch(getAllPropertyFeature());
    
  });

  return <PropertyFeaturesList />;
};

export default PropertyFeaturesListPage;
