import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSearchbar,
    IonText,
  } from "@ionic/react";
  import { addOutline,trashOutline } from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useHistory } from "react-router";
  import { flagSelector, setWasCreated } from "../../store/FlagSlice";
  import {
    getAllCategory,
    postCategory,
    categorySelector,
    removeCategory,
    setActualCategory
  } from "../../store/CategorySlice";
import { getAllProduct } from "../../store/ProductSlice";
import { PRODUCTS_LANDING } from "../products/MyProductsContent";
import { CATEGORIES } from "../products/AllProductsContent";
  
  export let categoryArray: string[] = [];

  const CategoryList: React.FC = () => {
    const { category } = useSelector(categorySelector);
    const { wasCreated } = useSelector(flagSelector);
    const [toastMessage, setToastMessage] = useState("");
    const [myProducts, setProducts] = useState(CATEGORIES);
    const history = useHistory();
    const dispatch = useDispatch();
    const [theCategorys, setTheCategorys] = useState<any>([]);
    const [newCategory, setNewCategory] = useState("");
    const [searchText, setSearchText] = useState("");
  
    const goBackHandler = () => {
        dispatch(getAllProduct({}))
        setProducts(PRODUCTS_LANDING)
      history.push('/admin-products')
    }
  
    const activateButton = () => {
      if (newCategory !== "") {
        return false;
      } else {
        return true;
      }
    };
  
    const addCategory = () => {
        categoryArray.push(newCategory)
        let input = { name: newCategory}
        dispatch(postCategory(input));
     
      setNewCategory("");
    };
  
    const deleteCategory = (CategoryId: string) => {
      if (categoryArray.includes(CategoryId)) {
        categoryArray = categoryArray.filter((feat) => feat !== CategoryId);
      }
      let input = {id: CategoryId}
      dispatch(removeCategory(input))
    }
    
    const refreshDelete = () => {
      dispatch(getAllCategory())
    }
    
    const deleteArrayCategory = (Category: string) => {
      if (categoryArray.includes(Category)) {
        categoryArray = categoryArray.filter((feat) => feat !== Category);
      }
    }
  
  
    useEffect(() => {
      if (wasCreated) {
        setToastMessage("¡Característica creada satisfactoriamente!");
        dispatch(setWasCreated(false));
  
        dispatch(getAllCategory())
      }
    }, [wasCreated, toastMessage, categoryArray, 
        category
    ]);
  
    return (
       
      <IonGrid fixed>

        <IonRow>
          <IonCol className="ion-text-center ion-margin-bottom">
            <IonText color="primary">
              <h2>
                Agrega categorías para luego aplicarlas
                a tus productos.
              </h2>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd="4" offsetMd="3.5" sizeXs="10" offsetXs="1" className="ion-text-center">
            <IonItem>
              <IonLabel position="floating">Nueva categoría:</IonLabel>
              <IonInput
                value={newCategory}
                onIonChange={(e) => setNewCategory(e.detail.value || "")}
              ></IonInput>
            </IonItem>
          </IonCol>
  
         
          <IonCol sizeXs="2" offsetXs="4" offsetMd="0">
            <IonButton
              onClick={() => {addCategory() ;dispatch(getAllCategory())}}
              disabled={activateButton()}
              fill="outline"
            >
              <IonIcon icon={addOutline} slot="start" />
              Agregar
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center ion-margin">
            <IonCard>
              <IonText color="tertiary">
                <h5>Categorías</h5>
              </IonText>
              <IonCardContent>
              <IonRow>
                  <IonCol className="ion-text-left">
                    <IonSearchbar
                      value={searchText}
                      onIonChange={(e) => setSearchText(e.detail.value!)}
                      placeholder="Nombre de la categoría"
                      class="search-bar-color"
                    />
                  </IonCol>
                </IonRow>
             
            <IonRow>
                {category.length > 0 &&
                  category.filter((cat) =>
                  cat.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                ).map((feat) => (<IonCol sizeMd="3" sizeXs="12">
                    <IonCard className="ion-margin-bottom show-click" >
                      <IonItem onClick={()=> dispatch(setActualCategory(feat))}>
                        <IonLabel>{feat.name}</IonLabel>
                        <IonButton color="danger" fill="clear" onClick={() => {deleteCategory(feat.id); dispatch(getAllCategory()); refreshDelete()}}>
                          <IonIcon icon={trashOutline} slot="icon-only" />
                        </IonButton>
                      </IonItem>
                    </IonCard></IonCol>
                  ))}</IonRow>
         </IonCardContent>
            </IonCard>
          </IonCol>
         
        </IonRow>
       
      </IonGrid>
    );
  };
  
  export default CategoryList;
  