import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { personCircle } from "ionicons/icons";

const SignInButton: React.FC = () => {
  return (
    <IonButton
      className="ion-align-self-center"
      size="small"
      fill="clear"
      color="light"
      routerLink="/signin"
    >
      <IonIcon slot="start" icon={personCircle} />
      <div className="c">INGRESA</div>
    </IonButton>
  );
};

export default SignInButton;
