

export const customlistAllProducts = `
query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      featured
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          pictures
          condition
          productID
          status
        }
      }
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const customlistAllUsers = `
query ListUsers(
  $userID: String
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    userID: $userID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      monederoLog
      {
        items {
          id
          userID
          points
          type
          description
          createdAt
          updatedAt
        }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;

export const customlistProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        featured
        productVariations {
          items {
            id
            sku
            variationName
            amount
            cost
            price
            availability
            pictures
            condition
            productID
            status
          }
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;