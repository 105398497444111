export const PRODUCTS_CATEGORY_OPTIONS = [
  { label: "Abarrotes", value: "abarrotes" },
  { label: "Aceites esenciales", value: "aceites esenciales" },
  { label: "Alimentos", value: "alimentos" },
  { label: "Audio", value: "audio" },
  { label: "Automóviles", value: "automóviles" },

  { label: "Baño", value: "baño" },
  { label: "Bebés", value: "bebés" },
  { label: "Bebidas", value: "bebidas" },
  { label: "Bicicletas", value: "bicicletas" },
  { label: "Bienestar", value: "bienestar" },
  { label: "Blancos", value: "blancos" },

  { label: "Caballeros", value: "caballeros" },
  { label: "Calzado", value: "calzado" },
  { label: "Cocina", value: "cocina" },
  { label: "Cómputo", value: "cómputo" },
  { label: "Consumibles", value: "consumibles" },
  { label: "Cosméticos", value: "cosméticos" },
  { label: "Cuidado Personal", value: "cuidado personal" },

  { label: "Damas", value: "damas" },
  { label: "Decoración", value: "decoración" },
  { label: "Deportes", value: "deportes" },

  { label: "Educativo", value: "educativo" },
  { label: "Electrodomésticos", value: "electrodomésticos" },
  { label: "Eléctricos", value: "eléctricos" },
  { label: "Electrónicos", value: "electrónicos" },
  { label: "Entretenimiento", value: "entretenimiento" },

  { label: "Fotografía", value: "fotografía" },
  { label: "Fumigación", value: "fumigación" },

  { label: "Gadgets", value: "gadgets" },

  { label: "Herramientas", value: "herramientas" },
  { label: "Higiene personal", value: "higiene personal" },
  { label: "Hogar", value: "hogar" },

  { label: "Infantiles", value: "infantiles" },
  { label: "Inmuebles renta", value: "inmuebles renta" },
  { label: "Inmuebles venta", value: "inmuebles venta" },

  { label: "Jardín", value: "jardín" },
  { label: "Joyería", value: "joyería" },
  { label: "Juegos", value: "juegos" },

  { label: "Lavandería", value: "lavandería" },
  { label: "Limpieza", value: "limpieza" },
  { label: "Línea blanca", value: "línea blanca" },

  { label: "Mascotas", value: "mascotas" },
  { label: "Moda", value: "moda" },
  { label: "Muebles", value: "muebles" },
  { label: "Nutrición", value: "nutrición" },

  { label: "Oficios", value: "oficios" },
  { label: "Oficina", value: "oficina" },
  { label: "Oportunidades", value: "oportunidades" },
  { label: "Organización", value: "organización" },

  { label: "Papelería", value: "papelería" },
  { label: "Perfumería", value: "perfumería" },
  { label: "Plantas", value: "plantas" },

  { label: "Recámara", value: "recámara" },
  { label: "Remate", value: "remate" },

  { label: "Salud", value: "salud" },
  { label: "Segunda mano", value: "Segunda mano" },
  { label: "Servicios", value: "servicios" },

  { label: "Tecnología", value: "tecnología" },
  { label: "Transporte y viajes", value: "transporte y viajes" },

  { label: "Viajes", value: "viajes" },
  { label: "Video", value: "video" },
];

export const BRAND_OPTIONS = [
  { label: "Novocorpo", value: "novocorpo" },
  { label: "Avon", value: "avon" },
  { label: "Betterware", value: "betterware" },
];

