import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catalogSelector} from "../../store/CatalogSlice";
import { catalogPageSelector, editCatalogPage } from "../../store/CatalogPageSlice";
import { ROUTE_SHOP_CATALOGS_PAGES } from "../../utils/routes";
import { arrowBackOutline, cloudUploadOutline, cube } from "ionicons/icons";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import Toolbar from "../global/general/Toolbar";
import { flagSelector } from "../../store/FlagSlice";
import { useHistory } from "react-router";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import MyCambaceoContent from "../cambaceo/MyCambaceoContent";
import MyListadoProductsContent from "./MyListadoProductsContent";


export const PRODUCTS_LANDING = "productsLanding";
export const PRODUCTS_ADD = "productsAdd";
export const PRODUCTS_EDIT = "productsEdit";

const CatalogPageFormEditProducts: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { actualCatalog } = useSelector(catalogSelector);
	const { actualCatalogPage } = useSelector(catalogPageSelector);
	const [myCambaceo, setCambaceo] = useState(PRODUCTS_LANDING);

	const { loading } = useSelector(flagSelector);
	const [toastMessage, setToastMessage] = useState("");
	const [getProductList, setProductList] = useState<any[]>([]);
	const [getCatProductList, setCatProductList] = useState<any[]>([]);
	const catType = useContext(CatalogTypeContext);

	useEffect(() => {
		if (actualCatalogPage.productsString) {
			setCatProductList(JSON.parse(actualCatalogPage.productsString));
		
		}
	}, [actualCatalogPage]);

	const saveHandler = () => {
		let productsString = "";
		if (actualCatalog.type === "catalogo") {
			productsString = JSON.stringify(getCatProductList);
		} else if (actualCatalog.type === "listado") {
			productsString = JSON.stringify(getProductList);
		}
		let inputObject = { id: actualCatalogPage.id, productsString };
		
		dispatch(editCatalogPage({ input: inputObject }));
		history.replace(ROUTE_SHOP_CATALOGS_PAGES);
	};



	const goBackHandler = () => {
		if (myCambaceo === PRODUCTS_LANDING){
		history.replace(ROUTE_SHOP_CATALOGS_PAGES);
	} else if (myCambaceo === PRODUCTS_ADD || myCambaceo === PRODUCTS_EDIT) {
		setCambaceo(PRODUCTS_LANDING)
	}

	};


	const removeProduct = (sku: string) => {
		let productList: any[] = getCatProductList
		productList = productList.filter((product) => product.sku !== sku);
		setCatProductList(productList);

		
	};
	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<MyShopToolbar iconName={cube} title={"Mis " + (catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol sizeXs="1">
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
						<IonCol className="ion-text-start">
							<h3>
								Editar productos de página {actualCatalogPage.pageNumber || ""} de {actualCatalog.name}
							</h3>
						</IonCol>
					</IonRow>
				{actualCatalog.type === "catalogo" && (
						<IonGrid>
							
							<IonRow>
          
            <MyCambaceoContent/>
           
          </IonRow>
          
							
						</IonGrid>
				)}
					{actualCatalog.type === "listado" && (
						<IonGrid>
							<IonRow>
								<MyListadoProductsContent/>
				
							</IonRow>
						</IonGrid>
					)}
					<IonRow>
						<IonCol>
							<IonButton onClick={saveHandler}>
								<IonIcon slot="start" icon={cloudUploadOutline} />
								Guardar cambios
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default CatalogPageFormEditProducts;


