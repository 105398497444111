import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { PropertyFeatureSelector } from "../../store/PropertyFeatureSlice";
import { editProperty, postProperty, removeProperty } from "../../store/PropertySlice";
import { shopSelector } from "../../store/ShopSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";



  export let publicFeaturesOptions: {label: string, value: string}[] = []
  export let privateFeaturesOptions: {label: string, value: string}[] = []
const MyPropertiesForm: React.FC<{
  myProperties: Dispatch<SetStateAction<string>>;
}> = () => {
  const {propertyFeature} = useSelector(PropertyFeatureSelector)
  let operationOptions = [
    { label: "Venderla", value: "venta" },
    { label: "Rentarla", value: "renta" },
  ];
  publicFeaturesOptions = []
  privateFeaturesOptions =[]
  
  
  if (propertyFeature.length > 0) {
    propertyFeature.filter((feat) => feat.condition === "PÚBLICO").forEach((feat) => publicFeaturesOptions.push({label: feat.name, value: feat.name}))
   
     propertyFeature.filter((feat) => feat.condition === "PRIVADO").forEach((feat) => privateFeaturesOptions.push({label: feat.name, value: feat.name}))

   }
   publicFeaturesOptions = publicFeaturesOptions.sort((a,b) => a.value.toUpperCase().localeCompare(b.value.toUpperCase()))
   privateFeaturesOptions =  privateFeaturesOptions.sort((a,b) => a.value.toUpperCase().localeCompare(b.value.toUpperCase()))

  
  const history = useHistory();
  const { theShop } = useSelector(shopSelector);
  const [theProperty, setTheProperties] = useState<any>([]);


 
  const fieldArray = [
    {
      name: "typeOfOperation",
      label: "¿Qué desea hacer con esta propiedad?",
      type: "select" as FieldTypes,
      options: operationOptions,
      value: useState(theProperty.typeOfOperation),
      placeholder: "¿Qué desea hacer con esta propiedad?",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "name",
      label: "Nombre del inmueble",
      type: "text" as FieldTypes,
      value: useState(theProperty.name),
      placeholder: "Ej: Departamento a estrenar",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "description",
      label: "Descripción",
      type: "textarea" as FieldTypes,
      value: useState(theProperty.description),
      placeholder:
        "Describe en detalle tu inmueble.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "address",
      label: "Dirección",
      type: "text" as FieldTypes,
      value: useState(theProperty.address),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "location",
      label: "Ubicación",
      type: "text" as FieldTypes,
      value: useState(theProperty.location),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "gpsPosition",
      label: "Coordenadas GPS",
      type: "text" as FieldTypes,
      value: useState(theProperty.gpsPosition),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "floors",
      label: "Cantidad de plantas",
      type: "number" as FieldTypes,
      value: useState(theProperty.floors),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "bedrooms",
      label: "Cantidad de recámaras",
      type: "number" as FieldTypes,
      value: useState(theProperty.bedrooms),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "bathrooms",
      label: "Cantidad de baños",
      type: "number" as FieldTypes,
      value: useState(theProperty.bathrooms),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "publicFeatures",
      label: "Características públicas",
      type: "select" as FieldTypes,
      options: publicFeaturesOptions,
      multiple: true,
      value: useState(theProperty.publicFeatures),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "privateFeatures",
      label: "Características privadas",
      type: "select" as FieldTypes,
      options: privateFeaturesOptions,
      multiple: true,
      value: useState(theProperty.privateFeatures),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
        name: "propertySize",
        label: "Superficie construida",
        type: "text" as FieldTypes,
        value: useState(theProperty.propertySize),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
      },
      {
        name: "landSize",
        label: "Superficie del terreno",
        type: "text" as FieldTypes,
        value: useState(theProperty.propertySize),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
      },
      {
        name: "price",
        label: "Precio",
        type: "text" as FieldTypes,
        value: useState(theProperty.price),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
      },
      {
        name: "videoUrl",
        label: "Liga de video",
        type: "url" as FieldTypes,
        value: useState(theProperty.videoUrl),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
      },
      {
        name: "pictures",
        label: "Imágenes",
        type: "images" as FieldTypes,
        value: useState(""),
        imageArray: useState([""]),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
        showPreview: true,
        max: 5,
        unavailableImageURL: UNAVAILABLE_IMAGE,
        bucketURL: BUCKET_URL,
        requiredWidth: 450,
				requiredHeight: 600
      },
      {
        name: "pdf",
        label: "Documento PDF",
        type: "pdf" as FieldTypes,
        value: useState(""),
        pdfArray: useState([""]),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
        showPreview: true,
        max: 1,
        unavailableImageURL: UNAVAILABLE_IMAGE,
        bucketURL: BUCKET_URL,
      },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProperty,
      pushRoute: "/mis-inmuebles",
    },
    edit: {
      inputObject: {},
      dispatchFunction: editProperty,
      pushRoute: "/mis-inmuebles",
    },
    delete: {
      inputObject: {},
      dispatchFunction: removeProperty,
      pushRoute: "/mis-inmuebles",
    },
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <AmonytForm
            title="Crear inmueble"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={true}
            hiddenFields={[
              {
                name: "shopID",
                value: theShop.shopID,
              },
            ]}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MyPropertiesForm;
