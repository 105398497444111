import React from "react";
import { IonAlert } from "@ionic/react";
import { useHistory } from "react-router";

const CreateOrderAlert: React.FC<{
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  link: string;
}> = (props) => {
  const history = useHistory();
  return (
    <IonAlert
      mode="ios"
      isOpen={props.showAlert}
      header={"¡Felicidades!"}
      message={
        "Tu orden fue recibida. Para una atención más veloz compártela por WhatsApp a nuestra cuenta empresarial."
      }
      backdropDismiss={false}
      onDidDismiss={()=>{history.push("/")}}
      buttons={[
        {
          text: "Enviar por WhatsApp",
          handler: ()=>{
            window.open(props.link, )
            props.setShowAlert(false);
          },
        },
        {
          text: "Cerrar",
          handler: () => {
            props.setShowAlert(false);
            history.push("/");
          },
        },
      ]}
    />
  );
};

export default CreateOrderAlert;
