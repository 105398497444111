import React from "react";
import Orders from "../../components/profile/Orders";
import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import { getOrdersInfo} from "../../store/OrderSlice";

const ProfileOrders: React.FC<{}> = () => {
	const dispatch = useDispatch();

	useIonViewWillEnter(() => {
		dispatch(getOrdersInfo({}));
	});
	
	return (<Orders/>);
};

export default ProfileOrders;