
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  PropertyThunk } from "../index";
import {
  setError,
  setLoading,
  setWasCreated,
  setWasDeleted,
  setWasUpdated,
} from "./FlagSlice";
import {
  createProperty,
  deleteProperty,
  updateProperty,
  sendEmailToAdmin
} from "../graphql/mutations";
import { getProperty, listPropertys } from "../graphql/queries";
import { setActualShop } from "./ShopSlice";

//Interfaces
export interface PropertyState {
  properties: PropertyObject[];
  actualProperty: PropertyObject;
}

export interface PropertyObject {
  id: string;
  name: string;
  description: string;
  address: string;
  location: string;
  typeOfOperation: string;
  gpsPosition: string;
  floors: number;
  bedrooms: number;
  bathrooms: number;
  publicFeatures: string;
  privateFeatures: string;
  landSize: string;
  propertySize: string;
  price: number;
  videoUrl: string;
  pdf: [string];
  comments: string;
  pictures: [string]
  shopID: string;
  status: string;
  };


//Initial State
const initialState: PropertyState = {
  properties: [],
  actualProperty: {
  id: "",
  name: "",
  description: "",
  address: "",
  location: "",
  typeOfOperation: "",
  gpsPosition: "",
  floors: 0,
  bedrooms: 0,
  bathrooms: 0,
  publicFeatures: "",
  privateFeatures: "",
  landSize: "",
  propertySize: "",
  price: 0,
  videoUrl: "",
  pdf: [""],
  comments: "",
  pictures: [""],
  shopID: "",
    status: "ALTA",
},
}
;

//Reducers
const property = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperty(state, { payload }: PayloadAction<PropertyObject[]>) {
      state.properties = payload;
    },
    setActualProperty(state, { payload }: PayloadAction<PropertyObject>) {
      const propertiesArray = {
        id: payload.id,
        name: payload.name,
        description: payload.description,
        address: payload.address,
        location: payload.location,
        typeOfOperation: JSON.parse(payload.typeOfOperation),
        gpsPosition: payload.gpsPosition,
        floors: payload.floors,
        bedrooms: payload.bedrooms,
        bathrooms: payload.bathrooms,
        publicFeatures: JSON.parse(payload.publicFeatures),
        privateFeatures: JSON.parse(payload.privateFeatures),
        landSize: payload.landSize,
        propertySize: payload.propertySize,
        price: payload.price,
        videoUrl: payload.videoUrl,
        pdf: JSON.parse(payload.pdf[0]),
        comments: payload.comments,
        pictures: payload.pictures,
        shopID: payload.shopID,
        status: payload.status
        }

      state.actualProperty = propertiesArray;
    },
    cleanProperty(state) {
      state.properties = [];
    },
  },
});

//Exports
export const { setProperty, setActualProperty, cleanProperty } = property.actions;

export default property.reducer;

export const propertySelector = (state: { propertyStore: PropertyState }) =>
  state.propertyStore;

//Middleware
export const postProperty = (input: any): PropertyThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res:any = await API.graphql(graphqlOperation(createProperty, { input }));
      dispatch(setActualProperty(res.data.createProperty))
      dispatch(setLoading(false));
      dispatch(setWasCreated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const getAllProperty =
  (input: any): PropertyThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(listPropertys, input));

      dispatch(setProperty(res.data.listPropertys.items));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
export const getPropertyInfo =
  (input: any): PropertyThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getProperty, input));
      if (typeof res.data.getProperty !== "undefined") {
        dispatch(setActualProperty(res.data.getProperty));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };





export const readProperty =
  (input: any): PropertyThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(getProperty, { input })
      );
      dispatch(setProperty(res.data.listPropertys));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const editProperty = (input: any): PropertyThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(updateProperty, { input }));
      dispatch(setLoading(false));
      dispatch(setWasUpdated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const removeProperty = (input: any): PropertyThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
       await API.graphql(graphqlOperation(deleteProperty, { input }));
      dispatch(setLoading(false));
      dispatch(setWasDeleted(true));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const sendContactEmail = (input: any): PropertyThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(sendEmailToAdmin, input));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};
