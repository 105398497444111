import { useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../../components/profile/Profile";
import { authSelector } from "../../store/AuthSlice";
import { readUser } from "../../store/UserSlice";

const ProfileClient: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector(authSelector);
  useIonViewWillEnter(() => {
    let userProfileEmail = {
      userProfileEmail: userProfile.email,
    };
    dispatch(readUser(userProfileEmail));
  });
	return <Profile />;
};

export default ProfileClient;
