import { API, graphqlOperation } from "aws-amplify";
import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyProductsContent from "../../components/products/MyProductsContent";
import { getAllProduct, productSelector } from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";
import { Observable } from "zen-observable-ts";
import {
  onCreateProduct,
  onDeleteProduct,
  onUpdateProduct,
  onUpdateProductVariation,
} from "../../graphql/subscriptions";
import { setErrorMessage } from "../../store/FlagSlice";
import { useHistory } from "react-router";
import { getAllCategory } from "../../store/CategorySlice";
import { getAllProductVariation, readProductVariation } from "../../store/ProductVariationSlice";
import { setActualCambaceo } from "../../store/CambaceoSlice";
import { getAllBrand } from "../../store/BrandSlice";

const MyProductsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { theShop } = useSelector(shopSelector);
  const history = useHistory();
  const {actualProduct} = useSelector(productSelector)

  let createProductListener: any;
  let updateProductListener: any;
  let deleteProductListener: any;


  let createProductVariationListener: any;
  let updateProductVariationListener: any;
  let deleteProductVariationListener: any;

  useIonViewWillEnter(() => {
    if(!theShop.shopID){
      history.replace("/mi-tienda")
    }
    let filterShopID = {
      filter: { shopID: { eq: theShop.shopID } },
    };
    dispatch(getAllProduct(filterShopID));
    dispatch(getAllCategory())
    dispatch(getAllBrand())
  });
  useIonViewDidEnter(() => {
    const onCreateSubClient = API.graphql(
      graphqlOperation(onCreateProduct)
    ) as Observable<object>;
    

    createProductListener = onCreateSubClient.subscribe({
      next: () => {
        let filterProfileID = {
          filter: { shopID: { eq: theShop.shopID } },
        };
        dispatch(getAllProduct(filterProfileID));
      },
      error: (error) => console.warn(error),
    });

    const onUpdateSubClient = API.graphql(
      graphqlOperation(onUpdateProduct)
    ) as Observable<object>;


    updateProductListener = onUpdateSubClient.subscribe({
      next: () => {
        let filterProfileID = {
          filter: { shopID: { eq: theShop.shopID } },
        };
        dispatch(getAllProduct(filterProfileID));

      },
    });


    const onDeleteSubClient = API.graphql(
      graphqlOperation(onDeleteProduct)
    ) as Observable<object>;

    deleteProductListener = onDeleteSubClient.subscribe({
      next: () => {
        let filterProfileID = {
          filter: { shopID: { eq: theShop.shopID } },
        };
        dispatch(getAllProduct(filterProfileID));
      },
    });
  });

  useIonViewDidLeave(() => {
    createProductListener.unsubscribe();
    updateProductListener.unsubscribe();
    deleteProductListener.unsubscribe();
  });
  return <MyProductsContent />;
};

export default MyProductsPage;
