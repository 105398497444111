import React, { useState } from "react";

export const CatalogTypeContext = React.createContext({
	getCatalogType: 'listado',
	setCatalogType: (type: string) => {},
});

const CatalogTypeContextProvider = (props: any) => {
	const [getCatalogType, setCatType] = useState<string>('listado');
	const setCatalogType = (type: string) => {
		setCatType(type);
	};
	return (
		<CatalogTypeContext.Provider
			value={{
				getCatalogType,
				setCatalogType,
			}}
		>
			{props.children}
		</CatalogTypeContext.Provider>
	);
};

export default CatalogTypeContextProvider;
