import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* * Interfaces * */
export interface MenuState {
	menuOption: string;
}

// Initial state
const initialState: MenuState = {
	menuOption: "home",
};

/* * FLAG SLICE * */
const menu = createSlice({
	name: "menu",
	initialState,
	reducers: {
		setMenuOption(state, { payload }: PayloadAction<string>) {
			state.menuOption = payload;
		},
	},
});

/* * EXPORTS * */
export const {
	setMenuOption
} = menu.actions;

export default menu.reducer;
export const menuSelector = (state: {  menuStore: MenuState }) =>
	state.menuStore;
