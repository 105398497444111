import {
  IonContent,
  IonSlides,
  IonSlide,
  IonHeader,
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonChip,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
  IonButtons,
  IonModal,
  IonBadge,
  IonCard,
  IonLabel,
  IonInput,
  IonTextarea,
} from "@ionic/react";
import {
  checkboxOutline,
  closeCircleOutline,
  homeOutline,
  shareSocial,
  storefrontOutline,
} from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import { propertySelector, sendContactEmail} from "../../store/PropertySlice";
import FooterComponent from "../global/general/FooterComponent";
import Toolbar from "../global/general/Toolbar";
import "../products/ProductDetailContent.css";
import { capitalizeFirstLetter } from "./MyPropertiesDetailsCard";
import UserFeedback from "../global/general/UserFeedback";

const PropertyDetailContent: React.FC = () => {
  const [singleSwiper, setSingleSwiper] = useState<any>({});
  const [thumbsSwiper, setThumbsSwiper] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [showPicModal, setShowPicModal] = useState(false)
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const { actualProperty } = useSelector(propertySelector);
  const [contactPhoneNumber, setContactPhoneNumber] = useState("");
  const { wasCreated, loading } = useSelector(flagSelector);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const dispatch = useDispatch()
  const [toastMessage, setToastMessage] = useState("");
  const [getSelectedVariation, setSelectedVariation] = useState(0);
  const [descType, setDescType] = useState("general");
  const generalDescHandler = () => {
    setDescType("general");
  };
  const technicalDescHandler = () => {
    setDescType("technical");
  };

  const singleSlider = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: singleSwiper,
    },
  };

  const thumbSlider = {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: "vertical",
  };

  const initSingle = async function (this: any) {
    setSingleSwiper(await this.getSwiper());
  };

  const initThumbs = async function (this: any) {
    setThumbsSwiper(await this.getSwiper());
  };

  const swipe = async function (this: any) {
    thumbsSwiper.slideTo(await this.getActiveIndex());
  };

  const tap = function (index: number) {
    singleSwiper.slideTo(index);
  };

  const activateButton = () => {
    if(contactName !== "" && contactEmail !== "" && contactPhoneNumber !== "" && contactMessage !== "") {
      return false
    }
    return true
  }

  useEffect(() => {
    if (wasCreated) {
      setToastMessage("¡Mensaje enviado!");
      dispatch(setWasCreated(false));
    }
  }, [wasCreated, toastMessage]);

  const sendEmail = () => {
    const emailData = {
      toemail: "operaciones@novocorpo.com.mx",
      subject: "Nueva consulta por " + actualProperty.name,
      message: 
      `${contactName},

      (Teléfono: ${contactPhoneNumber},\n

      Email: ${contactEmail})\n

      dejó la siguiente consulta: ${contactMessage}`
      
    };
    dispatch(sendContactEmail(emailData));
    dispatch(setWasCreated(true))
    setShowModal(false)
  }

  const transformVideoUrl = () => {
     let str = actualProperty.videoUrl;
     let res = str.split("=");
     let embeddedUrl = "https://www.youtube.com/embed/"+ res[1]
  
     return embeddedUrl
   }

  return (
    <IonPage className="productDetail">
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        />
      <IonContent>
        <IonModal isOpen={showPicModal} >
        <IonGrid >
          <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowPicModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        
         <IonRow>
           <IonCol className="ion-text-center" offset="2" size="8">
             <img key={getSelectedVariation} src={actualProperty.pictures[getSelectedVariation]}></img>
         </IonCol>
         {/* <IonCol className="ion-text-center">
             <IonButton fill="clear"><IonIcon icon={arrowForwardCircleOutline} slot="icon-only" /></IonButton>
         </IonCol> */}
         </IonRow>
         </IonGrid>
       


        </IonModal>
        <IonModal isOpen={showModal}>
                           
                              <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonGrid fixed>   
        <IonRow>
          <IonCol>
          <IonText color="primary">
                      <h1 className="ion-no-margin ion-padding-bottom ion-text-center">Envíanos tu consulta</h1></IonText>
        </IonCol></IonRow>
                   
      <IonRow> 
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Nombre:</IonLabel>
              <IonInput value={contactName} onIonChange={(e) => setContactName(e.detail.value || "")} color="primary"/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Teléfono:</IonLabel>
              <IonInput value={contactPhoneNumber}  onIonChange={(e) => setContactPhoneNumber(e.detail.value || "")} color="primary"/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Email:</IonLabel>
              <IonInput type="email" color="primary" value={contactEmail} onIonChange={(e) => setContactEmail(e.detail.value || "")}/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Mensaje:</IonLabel>
              <IonTextarea rows={3} color="primary" value={contactMessage} onIonChange={(e) => setContactMessage(e.detail.value || "")} />
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
                    <IonCol
                      offsetMd="2"
                      sizeMd="8"
                      sizeXs="12"
                      className="ion-text-center"
                    >
                      <IonButton color="tertiary" fill="solid" disabled={activateButton()}
                      onClick={sendEmail}>
                        Enviar
                      </IonButton>
                    </IonCol>
                  </IonRow>

        </IonGrid>
</IonModal>

        {actualProperty && (
          <IonGrid fixed className="ion-margin-top ion-padding-top">
            <IonRow>
              <IonCol>
                <IonButtons>
                  <IonButton color="secondary" href="/home">
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">Inicio</IonText>
                  </IonButton>
                  <IonText color="secondary">/</IonText>
                  <IonButton
                    color="secondary"
                    href={`/shops/${actualProperty.shopID}`}
                  >
                    <IonIcon slot="start" icon={storefrontOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">
                      {actualProperty.shopID}
                    </IonText>
                  </IonButton>
                </IonButtons>
              </IonCol>
             
            </IonRow>
            <IonRow>
              <IonCol
                sizeSm="
							12"
                sizeMd="6"
                className="carouselContainer"
              >
                {/* Carrousel */}
                <IonGrid fixed>
                  <IonRow>
                    <IonCol size="2">
                      <IonSlides
                        options={thumbSlider}
                        className="thumbs-slider"
                        onIonSlidesDidLoad={initThumbs}
                      >
                        {actualProperty.pictures &&
                          actualProperty.pictures.map(
                            (picture: any, pindex) => (
                              <IonSlide onClick={() => tap(pindex)}>
                                <img src={picture}></img>
                              </IonSlide>
                            )
                          )}
                      </IonSlides>
                    </IonCol>
                    <IonCol size="10">
                      <IonSlides
                        onIonSlidesDidLoad={initSingle}
                        scrollbar={true}
                        options={singleSlider}
                        className="single-slider"
                        onIonSlideDidChange={swipe}
                      >
                        {actualProperty.pictures &&
                          actualProperty.pictures.map((picture, pindex) => (
                            <>
                              <IonSlide key={pindex}>
                                <img
                                  onClick={() => {
                                    setShowPicModal(true);
                                    setSelectedVariation(pindex);
                                  }}
                                  src={picture}
                                ></img>
                              </IonSlide>
                            </>
                          ))}
                      </IonSlides>
                      {actualProperty.videoUrl !== null && <>
                     <IonItem></IonItem>
<IonRow>
<iframe width="640" height="360" src={transformVideoUrl()} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                 </IonRow> </>} </IonCol>
                  </IonRow>
                </IonGrid>
                
                 
               
              </IonCol>


              <IonCol sizeSm="12" sizeMd="6" className="dataContainer">
                <IonRow>
                  <IonCol>
                    <IonBadge color="success">
                      <small className="ion-no-margin">
                        {actualProperty.typeOfOperation
                          .toUpperCase()}
                      </small>
                    </IonBadge>
                    <IonText color="primary">
                      <h1 className="ion-no-margin ion-padding-bottom">
                        {actualProperty.name}
                      </h1>
                    </IonText>

                 
                    <IonRow>
                      <small>
                        <IonText color="primary">
                          {"Ubicación: " + actualProperty.location}
                        </IonText>
                      </small>{" "}
                    </IonRow>
                    <IonRow>
                      <small>
                        <IonText color="primary">
                          {actualProperty.address}
                        </IonText>
                      </small>
                     
                    </IonRow>
               
                  </IonCol>      
                  <IonCol className="ion-text-right">
                <IonButton color="tertiary" fill="clear" onClick={() => {
																										window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20${actualProperty.name}:%20https://www.novocorpo.com.mx/inmueble/+ ${actualProperty.id}`);
                                                  }}>
                  <IonIcon icon={shareSocial} slot="icon-only" />
                </IonButton>
              </IonCol>
                </IonRow>

                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText color="tertiary" className="priceProduct">
                        <p>
                          <strong>
                            {numberFormat.format(actualProperty.price)}
                          </strong>
                        </p>
                      </IonText>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol
                      offsetMd="2"
                      sizeMd="8"
                      sizeXs="12"
                      className="ion-text-center"
                    >
                      <IonButton expand="block" color="tertiary" fill="solid" onClick={() => setShowModal(true)}>
                        Contactar
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonRow>
                  <IonCol className="ion-text-center" sizeXs="4" sizeMd="4">
                    <IonCard>
                      <IonRow>
                        <IonCol>
                          <h1>
                            <strong>
                              <IonText color="primary">
                                {actualProperty.floors}
                              </IonText>
                            </strong>
                          </h1>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <h6>
                            <IonText color="primary">
                              {actualProperty.floors > 1 ? "plantas" : "planta"}
                            </IonText>
                          </h6>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>

                  <IonCol className="ion-text-center" sizeXs="4" sizeMd="4">
                    <IonCard>
                      <IonRow>
                        <IonCol>
                          <h1>
                            <strong>
                              <IonText color="primary">
                                {actualProperty.bedrooms}
                              </IonText>
                            </strong>
                          </h1>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <h6>
                            <IonText color="primary">
                              {actualProperty.bedrooms > 1
                                ? "recámaras"
                                : "recámara"}
                            </IonText>
                          </h6>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>

                  <IonCol className="ion-text-center" sizeXs="4" sizeMd="4">
                    <IonCard>
                      <IonRow>
                        <IonCol>
                          <h1>
                            <strong>
                              <IonText color="primary">
                                {actualProperty.bathrooms}
                              </IonText>
                            </strong>
                          </h1>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <h6>
                            <IonText color="primary">
                              {actualProperty.bathrooms > 1 ? "baños" : "baño"}
                            </IonText>
                          </h6>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </IonCol>
                </IonRow>

                {/* <IonRow>
                  {actualProperty.pictures &&
                    actualProperty.pictures.map((picture, pindex) => (
                      <IonCol key={pindex} size="2">
                        <IonImg
                          src={picture}
                          className={
                            "variationImageSelector " +
                            (getSelectedVariation === pindex ? "selected" : "")
                          }
                          onClick={() => {
                            setSelectedVariation(pindex);
                            tap(pindex);
                          }}
                        ></IonImg>
                      </IonCol>
                    ))}
                </IonRow> */}
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonCol offsetMd="2" offsetXs="1">
                        <IonChip
                          onClick={generalDescHandler}
                          color={
                            descType === "general" ? "primary" : "secondary"
                          }
                        >
                          Descripción
                        </IonChip>
                      </IonCol>
                      <IonCol>
                        <IonChip
                          onClick={technicalDescHandler}
                          color={
                            descType === "technical" ? "primary" : "secondary"
                          }
                        >
                          Características
                        </IonChip>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonText color="primary">
                          {descType === "general" ? (
                            <>
                              <p>{actualProperty.description}</p>
                              <p>
                                {"Tamaño del terreno: " +
                                  actualProperty.landSize}
                              </p>
                              <p>
                                {"Tamaño de la construcción: " +
                                  actualProperty.propertySize}
                              </p>

                            
                            </>
                          ) : (
                            <>
                              <IonRow>
                              {
                              Object.entries(actualProperty.publicFeatures).map((val: any) => (
        <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
        
            <IonItem lines="none">
             <IonIcon color="tertiary" icon={checkboxOutline} slot="start"/> <IonLabel color="primary">{capitalizeFirstLetter(val[1].toString())}</IonLabel>
              
            </IonItem></IonCol>))
          }
                              </IonRow>
                            </>
                          )}
                        </IonText>
                      </IonCol>
                    </IonRow>
                      {actualProperty.pdf[0] !== undefined ? <><IonItem lines="none" className="ion-margin"><p><IonText color="tertiary">¿Quieres ver más información sobre este inmueble?</IonText></p>
                              <IonRow>
                                <IonCol className="ion-text-center">
                              <IonButton onClick={() => window.open(actualProperty.pdf[0], '_blank')}>
                                Descargar PDF
                              </IonButton>
                                </IonCol>
                              </IonRow></IonItem></> : ""}

                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>

          </IonGrid>
        )}

        <FooterComponent />
      </IonContent>
    </IonPage>
  );
};

export default PropertyDetailContent;
