import { IonButton, IonContent, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonTitle, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";
import { arrowBackOutline} from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import './Info.css';

const PrivacyAndPolicy: React.FC<{}> = (props) => {
	const history = useHistory();
	const goBackHandler = () => {
		history.replace("/home");
	};

  	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="page-content info">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="ion-text-center"><IonTitle color="primary"><h1>AVISO DE PRIVACIDAD INTEGRAL</h1></IonTitle></IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>Actualizado en enero de 2021</strong></p></IonText>
							<IonLabel color="primary">
								<p>Novocorpo, parte de Grupo Empresarial Riodel, SA de CV, hace de su conocimiento el presente Aviso de Privacidad, en el cual le informamos como nos lo pide la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), cuáles son los datos personales que podemos obtener, sus finalidades, así como los términos en que los estaremos utilizando, buscando en todo momento garantizar su confidencialidad y protección.</p>
							</IonLabel>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>1.RESPONSABLE DE DATOS PERSONALES</strong></p></IonText>
							<IonLabel color="primary">
								<p><b>Grupo Empresarial Riodel, SA de CV y/o Novocorpo</b>, en adelante <b>“Novocorpo”</b> es el responsable del uso y manejo de los datos personales que capta y administra, teniendo como domicilio para oír y recibir notificaciones en XXXXXXXXXX # XXX, Col. XXXXX, Alcaldía XXXXX, C.P. XXXXXX, Ciudad de México, México.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>2.FINALIDADES PRIMARIAS</strong></p></IonText>
							<IonLabel color="primary">
								<p>
									o cambio o adición al tratamiento de sus datos personales como se describe en esta Declaración de Privacidad se le comunicará de antemano a través de una notificación individual mediante nuestros canales de comunicación habituales (p. ej. por correo electrónico), así como a través de nuestras páginas web o aplicaciones (a través de banners, mensajes emergentes u otros mecanismos de notificación). 
								</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><h1>Aviso Legal</h1></IonText>
							<IonLabel color="primary">
								<p>Los datos personales que recabamos los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:</p>
								<ul>
									<li><p>Ofrecimiento de productos y servicios.</p></li>
									<li><p>Promoción de productos y servicios.</p></li>
									<li><p>Difusión de productos y servicios.</p></li>
									<li><p>Comercialización de productos y servicios.</p></li>
									<li><p>Distribución de productos y servicios.</p></li>
									<li><p>Asesoría comercial.</p></li>
									<li><p>Administración del acceso electrónico a la página web e infraestructura tecnológica de <b>Novocorpo</b></p></li>
									<li><p>Servicio de “Pago en Línea”.</p></li>
									<li><p>Servicio de “Pago referenciado”.</p></li>
									<li><p>Servicio de “Pago por terceros”.</p></li>
									<li><p>Gestión administrativa durante su estancia en nuestro sitio y plataformas digitales.</p></li>
									<li><p>Gestiones de facturación y procesos de cobranza correspondientes.</p></li>
									<li><p>Generación de bases de datos de prospectos y/o clientes.</p></li>
									<li><p>Registro de visitantes a nuestras instalaciones.</p></li>
								</ul>
							</IonLabel>
							<IonLabel color="primary"><p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, clasificaremos los datos personales en las siguientes categorías:</p></IonLabel>
							<IonLabel color="primary"><p><b>Datos de identificación:</b> Nombre completo, identificación oficial, CURP, RFC, Comprobante de domicilio, firma autógrafa, fecha de nacimiento, género y nacionalidad.</p></IonLabel>
							<IonLabel color="primary"><p><b>Datos de contacto:</b> Correo electrónico, teléfono particular y celular. </p></IonLabel>
							<IonLabel color="primary"><p><b>Datos Fiscales:</b> RFC (PFAE, PM y PF con régimen de incorporación fiscal), Dirección y cédula fiscal.  </p></IonLabel>
							<IonLabel color="primary"><p><b>Datos Patrimoniales:</b>Copias de transferencias bancarias, fichas de depósito y/o cuentas bancarias y voucher de pago. </p></IonLabel>
							<IonLabel color="primary"><p><b>Datos Laborales:</b> Domicilio laboral, correo electrónico, teléfonos de oficina, puesto o cargo desempeñado y CV. </p></IonLabel>
							<IonLabel color="primary"><p><b>Datos Académicos:</b> Grado académico, títulos, certificados y/o reconocimientos. </p></IonLabel>
							<IonLabel color="primary"><p><b>Otros Datos (redes sociales):</b> Cuando nos siguen por redes sociales, los datos obtenidos a través de este medio quedan bajo la propiedad y políticas de privacidad de cada uno de ellas, mismas que fueron autorizadas por usted al momento de registrarse por lo que <b>“Novocorpo”</b> no tiene derechos ni responsabilidad alguna sobre los datos personales de quienes nos siguen o se registran en nuestras redes sociales. </p></IonLabel>
							<br></br>
							<IonLabel color="primary"><p>Lo anterior será obtenido a través de las siguientes formas, mismas que detallamos a continuación: </p></IonLabel>
							<IonLabel color="primary"><p><b>Personalmente:</b> Cuando usted acude directamente con nosotros para consultar nuestros servicios y/o cuando acudimos con usted para ofertar los mismos.</p></IonLabel>
							<IonLabel color="primary"><p><b>Directa:</b> Cuando nos proporciona sus datos personales para solicitar nuestros servicios vía telefónica, correo electrónico, página web o cualquier otro medio digital.</p></IonLabel>
							<IonLabel color="primary"><p><b>Indirecta:</b> De cualquier otra fuente de información comercialmente disponible o que sean permitidas por la Ley.</p></IonLabel>
							
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>3.FINALIDADES SECUNDARIAS</strong></p></IonText>
							<IonLabel color="primary">
								<p>Novocorpo hace de su conocimiento que los datos podrán ser usados para las siguientes finalidades secundarias:</p>
							</IonLabel>
							<IonLabel color="primary">
								<ul>
									<li><p>Elaboración de material informativo y promocional con testimoniales. (Fotografía y video).</p></li>
									<li><p>Promover nuestros servicios a través del envío de correos electrónicos.</p></li>
									<li><p>Envío de comunicados electrónicos y notas de interés relacionada con los servicios brindados.</p></li>
									<li><p>Aplicar encuestas para medir la satisfacción del cliente y evaluar la calidad en el servicio brindado por nuestro equipo de colaboradores y de los proveedores.</p></li>
									<li><p>Análisis estadísticos y comerciales para identificación de necesidades y clasificación de clientes.</p></li>
									<li><p>Envío de invitación a eventos y conferencias.</p></li>
									<li><p>Envío de invitación de registro e inscripción de nuestro Newsletter.</p></li>
								</ul>
							</IonLabel>
							<IonLabel color="primary"><p>Para las finalidades establecidas en el párrafo anterior, el titular de los datos cuenta con 5 días hábiles para manifestar su negativa, independientemente de estos días en cualquier momento del tratamiento el titular puede manifestar su negativa a través del siguiente correo <a href="privacidad@novocorpo.com.mx">privacidad@novocorpo.com.mx</a> </p></IonLabel>
							<IonLabel color="primary"><p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p></IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>4. TRANSFERENCIAS DE DATOS</strong></p></IonText>
							<IonLabel color="primary">
								<p>Los datos personales que han sido solicitados podrán ser transferidos y compartidos dentro del país y en el extranjero con las siguientes personas, empresas y organizaciones distintas a nosotros para los siguientes fines. </p>
							</IonLabel>
							<IonLabel color="primary">
								<table >
									<tr>
										<th>Destinatario de los datos personales</th>
										<th>Finalidad</th>
									</tr>
									<tr>
										<td>Proveedores de pagos Online</td>
										<td>Para la realización de pagos de servicios.</td>
									</tr>
									<tr>
										<td>Proveedores de Seguridad</td>
										<td>Seguridad y circuito cerrado.</td>
									</tr>
									<tr>
										<td>Consultores y Socios Especializados</td>
										<td>Promoción de servicios especializados.</td>
									</tr>
									<tr>
										<td>Proveedores Diversos</td>
										<td>Elaboración de estudios de mercado, aplicación de encuestas y realización de eventos.</td>
									</tr>
									<tr>
										<td>Proveedores servicios de Marketing digital</td>
										<td>Realización de estrategias comerciales y envíos de e-mailing</td>
									</tr>
									<tr>
										<td>Proveedor de Hosting y Web mail</td>
										<td>Almacenamiento de información de carácter personal registrada y compartida por usted en nuestra plataforma.</td>
									</tr>
									<tr>
										<td>Proveedor de Servicios de Tecnología de Información</td>
										<td>Brindar servicios de “anonimización” de usuarios para evitar que la identidad y actividad especifica de un usuario pueda ser descifrada por un tercero no autorizado.</td>
									</tr>
									<tr>
										<td>Despacho Contable</td>
										<td>Gestión contable y fiscal</td>
									</tr>
								</table>
								
							</IonLabel>
							<IonLabel color="primary"><p>Está previsto por la Ley la transferencia de datos sin autorización del titular; en caso de que esté previsto en una Ley o tratado en el que México sea parte, tenga por objeto cumplir las obligaciones y responsabilidades adquiridas al momento de su contratación, tal como se indica en el Art. 10 y 37 de la LFPDPPP y su Reglamento.</p></IonLabel>
							<IonLabel color="primary"><p>Los receptores de los datos personales encargados y/o terceros, asumen las mismas responsabilidades del responsable de los datos personales de conformidad con los términos definidos en el presente aviso de privacidad debiendo abstenerse de transferir los datos personales salvo que derive de una subcontratación autorizada por el responsable tal como se indica en el artículo 50 fracción 6 del reglamento de la LFPDPPP.</p></IonLabel>
							<IonLabel color="primary"><p>Si usted no manifiesta su negativa a las transferencias de sus datos, se entenderá que ha otorgado su consentimiento.</p></IonLabel>
							
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>5.LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS.</strong></p></IonText>
							<IonLabel color="primary">
								<p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios: La inscripción de su teléfono fijo y móvil en el Registro Público para Evitar Publicidad <b>(REPEP)</b>, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para más información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta. </p>
							</IonLabel>
							<IonLabel color="primary">
								<p>Su registro en el listado de exclusión en la cuenta de correo electrónico: <a href="mailto:privacidad@novocorpo.com.mx">privacidad@novocorpo.com.mx</a> a fin de que sus datos personales no sean tratados para alguna o todas las finalidades secundarias definidas en el presente Aviso de Privacidad.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>6.PÁGINA DE INTERNET</strong></p></IonText>
							<IonLabel color="primary">
								<p>Es importante mencionar que contamos con página <a href="https://www.novocorpo.com.mx">https://www.novocorpo.com.mx</a>  y utilizamos diferentes tipos de cookies con las siguientes finalidades: </p>
							</IonLabel>
							<IonLabel color="primary">
								<ul>
									<li><p>Brindar una óptima funcionalidad del sitio web de acuerdo con el dispositivo utilizado para ingresar a ella.</p></li>
									<li><p>Permitir contabilizar y controlar las visitas al sitio, identificar al usuario en nuestra página web.</p></li>
									<li><p>Guardar sus preferencias y ofrecerle contenido personalizado.</p></li>
								</ul>
							</IonLabel>
							<IonLabel color="primary">
								<p>En caso de requerir desactivarlas, podrá realizarlo en cualquier momento ingresando al consultar las instrucciones de configuración de su navegador web para que pueda configurar si acepta, o no, las cookies que recibe o para que el navegador le avise cuando un servidor quiera guardar una cookie o borrarlas de su dispositivo.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>7.DERECHOS ARCO – REVOCACIÓN DEL CONSENTIMIENTO</strong></p></IonText>
							<IonLabel color="primary">
								<p><b>Novocorpo</b>, hace de su conocimiento que en todo momento usted tiene derecho al acceso, rectificación, cancelación u oposición sobre el tratamiento de sus datos personales, así como a la revocación del consentimiento que nos ha brindado, para esto es necesario el envío de un correo electrónico a la clave: <a href="mailto:privacidad@novocorpo.com.mx">privacidad@novocorpo.com.mx</a> indicando como Asunto: <b>“Ejercicio de Derechos ARCO y/o Revocación del Consentimiento”</b>, adjuntando la solicitud, identificación oficial con fotografía vigente, datos de contacto, documentación que sustente alguna modificación y/o actualización a sus datos personales y en caso de apoyarse de un representante legal, la documentación que acredite su identidad y representación.</p>
							</IonLabel>
							<IonLabel color="primary">
								<p>Al recibir su solicitud y encontrarse completa, realizaremos un análisis de procedencia, teniendo 20 días hábiles para ello y 15 días hábiles más para en caso de proceder realizar las actividades operativo – administrativas correspondientes para atender su solicitud, lo anterior en cumplimiento a lo dispuesto por la LFPDPPP y su Reglamento.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>8.CAMBIOS EN EL AVISO DE PRIVACIDAD</strong></p></IonText>
							<IonLabel color="primary">
								<p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, mediante su publicación en nuestra página web https://www.novocorpo.com.mx</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>9.MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN DE LOS DATOS PERSONALES</strong></p></IonText>
							<IonLabel color="primary">
								<p>Los datos personales serán mantenidos en estricta confidencialidad, de conformidad con las medidas de seguridad, administrativas, técnicas y físicas que al efecto <b>“Novocorpo”</b>, implemente en sus políticas y procedimientos de seguridad, quedando prohibida su divulgación y limitando su uso a terceros, conforme a lo previsto en el presente Aviso de Privacidad.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>10.CONSENTIMIENTO AVISO DE PRIVACIDAD</strong></p></IonText>
							<IonLabel color="primary">
								<p>Acepto, consiento y autorizo expresamente que los datos personales que proporcione sean tratados conforme a los términos definidos en el presente Aviso de Privacidad.</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="ion-text-right">
							<IonLabel color="tertiary"><p><strong>Fecha de elaboración: 17 marzo 2021</strong></p></IonLabel>
							<IonLabel color="tertiary"><p><strong>Fecha de actualización: 17 marzo 2021</strong></p></IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
      	</IonPage>
  	);
};

export default PrivacyAndPolicy;
