import { calendar, checkmark, warning } from "ionicons/icons";

export const BUCKET_URL = "https://novoprodstoragebucket113706-prod.s3.amazonaws.com/public/";
export const UNAVAILABLE_IMAGE = "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";

export const CURRENCY = "MXN";
export const TEXT_CANCEL = "Cancelar";
export const TEXT_APPLY = "Aplicar";
export const SPACE_TYPES = [
	{ id: "1", label: "Bodega" },
	{ id: "2", label: "Cochera" },
	{ id: "3", label: "Habitación" },
	{ id: "4", label: "Patio techado" },
	{ id: "5", label: "Clóset" },
	{ id: "6", label: "Sótano" },
	{ id: "7", label: "Contenedor" },
];
export const CHARACTERISTICS = [
	{ id: "1", label: "Acceso privado", image: "" },
	{ id: "2", label: "Cerradura electrónica", image: "" },
	{ id: "3", label: "Cámaras de seguridad", image: "" },
	{ id: "4", label: "Control vehicular", image: "" },
	{ id: "5", label: "Entrada vehículos de carga", image: "" },
	{ id: "6", label: "Protección contra plagas", image: "" },
	{ id: "7", label: "Control de temperatura", image: "" },
];
export const SCHEDULE = [
	{ id: "1", label: "Las 24 horas" },
	{ id: "2", label: "A cualquier hora del día" },
];
export const ALL_WEEK = "1";
export const MONDAY_TO_FRIDAY = "2";
export const FREQUENCY = [
	{ id: ALL_WEEK, label: "Los 7 días de la semana" },
	{ id: MONDAY_TO_FRIDAY, label: "De lunes a viernes" },
];
export const TIMESPAN = [
	{id: "1", label: "Por la mañana (7:00am a 10:00am)"},
	{id: "2", label: "A medio día (10:00am a 2:00pm)"},
	{id: "3", label: "Por la tarde (2:00pm a 7:00pm)"},
	{id: "4", label: "Por la noche (7:00pm a 10:00pm)"},
	{id: "5", label: "A media noche (10:00pm a 2:00am)"},
	{id: "6", label: "Por la madrugada (2:00am a 7:00am)"},
]
export const TIME_LENGTH = [
	{id:"1", label:"Indefinidamente"}
];
export const FREQUENCY_PRICES = [
	{ id: "1", label: "Hasta 1 vez al mes", price: "+ $0" },
	{ id: "2", label: "Hasta 2 veces al mes", price: "+ $40" },
	{ id: "3", label: "Hasta 2 veces por semana", price: "+ $80" },
	{ id: "4", label: "Hasta 5 veces por semana", price: "+ $120" },
	{ id: "5", label: "Hasta 2 veces al día", price: "+ $180" },
];
export const STRINGS = {
	button_ok: "OK",
	button_cancel: TEXT_CANCEL,
	button_accept: "Aprobar",
	button_reject: "Rechazar",
	text_reservation_ready: "Tu solicitud ha sido enviada al dueño de la unidad de almacenamiento. Espera noticias durante las próximas 24 horas."
}
export const STATUS_APPROVED = "approved";
export const STATUS_DENIED = "denied";
export const STATUS_PENDING = "pending";
export const STATUS_CURRENT = "current";
export const STATUS_COMPLETED = "completed";
export const STATUS_SCHEDULED = "scheduled";
export const STATUS_NEXT = "next";
export const STATUS_ACTIVE = "active";
export const STATUS_PREV = "prev";
export const STATUS_TEMPORARY = "temporary";

export const STATUSES = [
	{id: STATUS_APPROVED, label: "Aprobado"},
	{id: STATUS_DENIED, label: "Rechazado"},
	{id: STATUS_PENDING, label: "Pendiente de aprobación", icon: warning, color:"warning"},
	{id: STATUS_CURRENT, label: "En curso", icon: calendar, color:"info"},
	{id: STATUS_COMPLETED, label: "Completado", icon: checkmark, color:"success"},
	{id: STATUS_SCHEDULED, label: "Agendado", icon: calendar, color:"info"},
	{id: STATUS_NEXT, label: "Próximo"},
	{id: STATUS_ACTIVE, label: "Activo"},
	{id: STATUS_PREV, label: "Finalizada"},
]

export const VARIATION_FIELDS = ["color", "condition", "gender", "material", "pattern", "size", "pictures"];