import {
    IonGrid,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonText,
  } from "@ionic/react";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
import { editProperty, getAllProperty, propertySelector } from "../../store/PropertySlice";
  
  
  const PropertyState: React.FC<{
    setPropertyStatus: React.Dispatch<
      React.SetStateAction<
      "ALTA" | "BAJA" | "RENTADO" | "COMPRADO" | "PROMOCIÓN" | "SUSPENSIÓN" | undefined
      >
    >;
    propertyStatus: "ALTA" | "BAJA" | "RENTADO" | "COMPRADO" | "PROMOCIÓN" | "SUSPENSIÓN" | undefined;
}> = () => {
  
    const dispatch = useDispatch();
    const { actualProperty } = useSelector(propertySelector);
    const [propertyStatus, setPropertyStatus] = useState<
    "ALTA" | "BAJA" | "RENTADO" | "COMPRADO" | "PROMOCIÓN" | "SUSPENSIÓN" | string
  >(actualProperty.status)
  

  const changeStatus = (statusChanged: any) => {
  
    let inputObject = {
      id: actualProperty.id,
      status: statusChanged,
    };
    dispatch(editProperty(inputObject))
    setPropertyStatus(statusChanged)
    dispatch(getAllProperty({}))
  }


    return (
       
        <IonGrid>
       
   
       <IonItem className="ion-text-center ion-margin">
                <IonLabel position="floating" className="ion-text-left">
                <IonText className="ion-padding" color="primary">
                  Status
                  </IonText>
                </IonLabel>
                <IonSelect
                  value={propertyStatus}
                  onIonChange={(e) => {changeStatus(e.detail.value); setPropertyStatus(e.detail.value); }}
                >
                  <IonSelectOption value="ALTA">ALTA</IonSelectOption>
                  <IonSelectOption value="BAJA">
                  BAJA
                  </IonSelectOption>
                  <IonSelectOption value="RENTADO">
              RENTADO
                  </IonSelectOption>
                  <IonSelectOption value="COMPRADO">
                  COMPRADO
                  </IonSelectOption>
                  <IonSelectOption value="PROMOCIÓN">
PROMOCIÓN                  </IonSelectOption>
                  <IonSelectOption value="SUSPENSIÓN">
                  SUSPENSIÓN
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              
   </IonGrid>

    );
  };
  
  export default PropertyState;