import { useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyShopSliderContent from "../../components/shop/MyShopSliderContent";
import { authSelector } from "../../store/AuthSlice";
import { getShopInfo } from "../../store/ShopSlice";

const MyShopSliderPage: React.FC = () => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector(authSelector);

  useIonViewWillEnter(() => {
    let userProfileEmail = {
      userProfileEmail: userProfile.email,
    };
    dispatch(getShopInfo(userProfileEmail));
  });
  return <MyShopSliderContent />;
};

export default MyShopSliderPage;
