import { API, graphqlOperation } from "aws-amplify";
import { useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { shopSelector } from "../../store/ShopSlice";
import { Observable } from "zen-observable-ts";
import { onCreateCatalog, onDeleteProduct, onUpdateCatalog } from "../../graphql/subscriptions";
import { useHistory } from "react-router";
import { getAllCatalog } from "../../store/CatalogSlice";
import { ROUTE_SHOP } from "../../utils/routes";
import MyCatalogsFormEdit from "../../components/catalogs/MyCatalogsFormEdit";

const MyCatalogsNewPage: React.FC = () => {
	const dispatch = useDispatch();
	const { theShop } = useSelector(shopSelector);
	const history = useHistory();

	let createCatalogListener: any;
	let updateCatalogListener: any;
	let deleteCatalogListener: any;

	useIonViewWillEnter(() => {
		if (!theShop.shopID) {
			history.replace(ROUTE_SHOP);
		}
		let filterShopID = {
			filter: { shopID: { eq: theShop.shopID } },
		};
		let filterCambaceo = {
			filter: { }
		}
		
		dispatch(getAllCatalog(filterShopID));
	});
	useIonViewDidEnter(() => {
		const onCreateSubClient = API.graphql(graphqlOperation(onCreateCatalog)) as Observable<object>;

		createCatalogListener = onCreateSubClient.subscribe({
			next: () => {
				let filterProfileID = {
					filter: { shopID: { eq: theShop.shopID } },
				};
				dispatch(getAllCatalog(filterProfileID));
			},
			error: (error) => console.warn(error),
		});

		const onUpdateSubClient = API.graphql(graphqlOperation(onUpdateCatalog)) as Observable<object>;

		updateCatalogListener = onUpdateSubClient.subscribe({
			next: () => {
				let filterCatalogID = {
					filter: { shopID: { eq: theShop.shopID } },
				};
				dispatch(getAllCatalog(filterCatalogID));
			},
		});

		const onDeleteSubClient = API.graphql(graphqlOperation(onDeleteProduct)) as Observable<object>;

		deleteCatalogListener = onDeleteSubClient.subscribe({
			next: () => {
				let filterProfileID = {
					filter: { shopID: { eq: theShop.shopID } },
				};
				dispatch(getAllCatalog(filterProfileID));
			},
		});
	});

	useIonViewDidLeave(() => {
		createCatalogListener.unsubscribe();
		updateCatalogListener.unsubscribe();
		deleteCatalogListener.unsubscribe();
	});
	return <MyCatalogsFormEdit />;
};

export default MyCatalogsNewPage;
