import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { addOutline, arrowBackOutline, pricetags, trashOutline } from "ionicons/icons";
import React, {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import {
  getAllPropertyFeature,
  postPropertyFeature,
  PropertyFeatureSelector,
  removePropertyFeature,
  setActualPropertyFeature
} from "../../store/PropertyFeatureSlice";
import Toolbar from "../global/general/Toolbar";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";

export let publicArray: string[] = [];
export let privateArray: string[] = [];

const PropertyFeaturesList: React.FC = (props) => {
  const { propertyFeature } = useSelector(PropertyFeatureSelector);
  const { wasCreated, loading } = useSelector(flagSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [condition, setCondition] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const [newFeature, setNewFeature] = useState("");

  const goBackHandler = () => {
    history.push('/mis-inmuebles')
  }

  const activateButton = () => {
    if (condition !== "" && newFeature !== "") {
      return false;
    } else {
      return true;
    }
  };

  const addFeature = () => {
    if (condition === "PÚBLICO") {
      publicArray.push(newFeature)
      let input = { name: newFeature, condition: "PÚBLICO" }
      dispatch(postPropertyFeature(input));
    } else if (condition === "PRIVADO") {
      privateArray.push(newFeature);
      let input = { name: newFeature, condition: "PRIVADO" }
      dispatch(postPropertyFeature(input));
    }
    setNewFeature("");
    setCondition("");
  };

  const deleteFeature = (featureId: string) => {
    if (publicArray.includes(featureId)) {
      publicArray = publicArray.filter((feat) => feat !== featureId);
    }
    else if (privateArray.includes(featureId)) {
      privateArray = privateArray.filter((feat) => feat !== featureId);
    }
    let input = {id: featureId}
    dispatch(removePropertyFeature(input))
  }
  
  const refreshDelete = () => {
    dispatch(getAllPropertyFeature())
  }
  


  useEffect(() => {
    if (wasCreated) {
      setToastMessage("¡Característica creada satisfactoriamente!");
      dispatch(setWasCreated(false));
   

      dispatch(getAllPropertyFeature())
    }
  }, [wasCreated, toastMessage, publicArray, privateArray, propertyFeature]);

  return (
    <IonPage>
    <IonHeader>
      <Toolbar />
    </IonHeader>
    <MyShopToolbar iconName={pricetags} title="Mis Inmuebles" />
    <UserFeedback
      loading={loading}
      toastMessage={[toastMessage, setToastMessage]}
    />
    <IonContent>
     
    <IonGrid fixed>
    <IonRow>
              <IonCol>
                <IonButton fill="clear" onClick={goBackHandler}>
                  <IonIcon slot="start" icon={arrowBackOutline} />
                  Volver
                </IonButton>
              </IonCol>
              </IonRow>
      <IonRow>
   
        <IonCol className="ion-text-center ion-margin-bottom">
          <IonText color="primary">
            <h2>
              Agrega características públicas y/o privadas para luego aplicarlas
              a tus inmuebles.
            </h2>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="4" offsetMd="2" sizeXs="10" offsetXs="1" className="ion-text-center">
          <IonItem>
            <IonLabel position="floating">Nueva característica:</IonLabel>
            <IonInput
              value={newFeature}
              onIonChange={(e) => setNewFeature(e.detail.value || "")}
            ></IonInput>
          </IonItem>
        </IonCol>

        <IonCol sizeMd="2" sizeXs="10" offsetMd="0" offsetXs="1">
          <IonItem>
            <IonLabel position="floating">Carácter</IonLabel>
            <IonSelect
              value={condition}
              onIonChange={(e) => setCondition(e.detail.value || "")}
            >
              <IonSelectOption value="PÚBLICO">Público</IonSelectOption>
              <IonSelectOption value="PRIVADO">Privado</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonCol>
        <IonCol offsetXs="4" offsetMd="0">
          <IonButton
            onClick={() => {addFeature() ;dispatch(getAllPropertyFeature())}}
            disabled={activateButton()}
            fill="outline"
          >
            <IonIcon icon={addOutline} slot="start" />
            Agregar
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center ion-margin">
          <IonCard>
            <IonText color="tertiary">
              <h5>Características públicas</h5>
            </IonText>
            <IonCardContent>
           
          
              {propertyFeature.length > 0 &&
                propertyFeature.filter((feat) => feat.condition === "PÚBLICO").map((feat) => (
                  <IonCard className="ion-margin-bottom show-click" >
                    <IonItem onClick={()=> dispatch(setActualPropertyFeature(feat))}>
                      <IonLabel>{feat.name}</IonLabel>
                      <IonButton color="danger" fill="clear" onClick={() => {deleteFeature(feat.id); dispatch(getAllPropertyFeature()); refreshDelete()}}>
                        <IonIcon icon={trashOutline} slot="icon-only" />
                      </IonButton>
                    </IonItem>
                  </IonCard>
                ))}
       </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol className="ion-margin ion-text-center">
          <IonCard>
          <IonText color="tertiary">
            <h5>Características privadas</h5>
          </IonText>
       <IonCardContent>
    
       {propertyFeature.length > 0 &&
                propertyFeature.filter((feat:any) => feat.condition === "PRIVADO").map((feat:any) => 
                  <IonCard className="ion-margin-bottom show-click" >
                    <IonItem onClick={()=> dispatch(setActualPropertyFeature(feat))}>
                      <IonLabel>{feat.name}</IonLabel>
                      <IonButton color="danger" fill="clear" onClick={() => {deleteFeature(feat.id); dispatch(getAllPropertyFeature()); refreshDelete()}}>
                        <IonIcon icon={trashOutline} slot="icon-only" />
                      </IonButton>
                    </IonItem>
                  </IonCard> 
                )}
          </IonCardContent></IonCard></IonCol>
      </IonRow>
     
    </IonGrid>
    </IonContent>
      </IonPage>
  );
};

export default PropertyFeaturesList;
