import React, { useContext } from "react";
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/react";
import {
  home,
} from "ionicons/icons";
import {
  ROUTE_FAQ,
  ROUTE_ABOUT_US,
  ROUTE_CONTACT,
  ROUTE_SHOP_PRODUCTS,
  ROUTE_HOME,
  ROUTE_PROMOTE,
} from "../../../utils/routes";
import "./Menu.css";
import { MenuContext } from "../../../context/MenuContext";

const Menu: React.FC<{}> = () => {
  const menuContext = useContext(MenuContext);


  return (
    <>
      <div id="themenu" className={menuContext.getOpenState ? "active" : ""}>
        <div className="menuContainer">
          <div className="header">
            <IonItem
              no-lines
              color="transparent"
              button
              routerLink={ROUTE_HOME}
            >
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem lines="none" color="transparent">
                      <IonIcon slot="start" color="secondary" icon={home} />
                      <IonLabel no-lines color="light">
                        Inicio
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          </div>
          <IonGrid className="ion-margin-bottom">
            <IonRow>
              <IonCol>
                <div className="links">
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink={ROUTE_ABOUT_US}
                  >
                    <IonText color="light">Acerca de Novocorpo</IonText>
                  </IonItem>
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink={ROUTE_PROMOTE}
                  >
                    <IonText color="light">
                      ¿Cómo promocionarte con nosotros?
                    </IonText>
                  </IonItem>
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink={ROUTE_FAQ}
                  >
                    <IonText color="light">Preguntas Frecuentes</IonText>
                  </IonItem>
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink={ROUTE_CONTACT}
                  >
                    <IonText color="light">Contacto</IonText>
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid className="ion-margin-bottom">
            <IonRow>
              <IonCol>
                <div className="links">
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink={ROUTE_SHOP_PRODUCTS}
                  >
                    <IonText color="light">Productos</IonText>
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <div className="links">
                  <IonItem
                    color="transparent"
                    lines="none"
                    button
                    routerLink='/inmuebles'
                  >
                    <IonText color="light">Inmuebles</IonText>
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );
};

export default Menu;
