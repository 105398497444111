import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import MyPurchaseOrders from "../../components/Orders/MyPurchaseOrders";
import {getOrdersByDate, orderSelector} from "../../store/OrderSlice";

const MyPurchaseOrdersPage: React.FC = (props) => {
	const {pastOrder} = useSelector(orderSelector)
	const dispatch = useDispatch();

	useIonViewWillEnter(() => {
	dispatch(getOrdersByDate({typeName: "Order", sortDirection: "DESC"}));
	})
	

	return (<MyPurchaseOrders></MyPurchaseOrders>);
	}

export default MyPurchaseOrdersPage;
