import React from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import AllUsers from "../../components/user/AllUsers";
import {getAllUser} from "../../store/UserSlice";
import { getAllMonederoLogs } from "../../store/MonederoSlice";

const AllUsersPage: React.FC = () => {
  const dispatch = useDispatch();
  useIonViewWillEnter(() => {
    dispatch(getAllUser({}))
    dispatch(getAllMonederoLogs({}))
  });

  return <AllUsers />;
};

export default AllUsersPage;
