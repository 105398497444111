import {
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shopSelector, listShopsInfo } from "../../store/ShopSlice";
import { useHistory } from "react-router";
import AllShopsContent from "../../components/shop/AllShopsContent";
import { getAllUsers } from "../../store/UserSlice";

const AllShopsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { theShop } = useSelector(shopSelector);
  const history = useHistory();
  const [status, setStatus ] = useState("ACTIVO");



  useIonViewWillEnter(() => {
    if(!theShop.shopID){
      history.replace("/mi-tienda")
    }
    setStatus("ACTIVO")
    dispatch(listShopsInfo());
    dispatch(getAllUsers())
  });
 
  return <AllShopsContent />;
};

export default AllShopsPage;
