import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonRow,
  IonSlides,
  IonText,
} from "@ionic/react";
import { closeCircleOutline, imageOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import AddToCart from "../cart/AddToCart";
import "./ProductView.css";
import { ProductObject } from "../../store/ProductSlice";

const MyShopsProductView: React.FC<ProductObject> = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const currencyFormat = { style: 'currency', currency: 'MXN' };
  const numberFormat = new Intl.NumberFormat('es-MX', currencyFormat);
  const slideOpts = {
    autoplay: {
      delay: Math.random() * 2000 + 2000,
    },
    pauseOnMouseEnter: true,
  };
  const [descType, setDescType] = useState("general");
  const generalDescHandler = () => {
    setDescType("general");
  };
  const technicalDescHandler = () => {
    setDescType("technical");
  };

  return (
    <React.Fragment>
      <IonModal isOpen={showModal}>
          <IonRow>
              <IonCol className="ion-text-right">
                <IonButton onClick={() => setShowModal(false)} fill="clear">
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonGrid fixed className="modalProduct">
              <IonRow>
                <IonCol size="6" offset-xs="3" offset-sm="3" offset-md="0" className="ion-margin-top ion-text-center">
                  <div className="">
                    <IonImg className="product-img" src={props.productVariations.items[0].pictures[0]}></IonImg>
                  </div>
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-md="6">
                  <IonRow>
                    <IonCol>
                      <IonRow><IonCol><IonText color="primary"><h1 className="ion-no-margin">{props.name}</h1></IonText></IonCol></IonRow>
                    <IonRow><IonCol>
                      <IonText color="primary"><small>Categorías: {
                        JSON.parse(props.category.toString()).map(
                          (cat: string, index: number) => {
                            const cal = JSON.parse(props.category.toString()).length;
                            if (index === cal - 1) {
                              return cat;
                            } else {
                              return cat + " , ";
                            }
                          }
                        )}</small></IonText></IonCol></IonRow>
                      <IonRow><IonCol className="ion-text-right"><IonLabel color="tertiary"><b>{numberFormat.format(props.productVariations.items[0].cost)}</b></IonLabel></IonCol></IonRow>
                      <IonRow className="ion-text-center">
                        <IonCol>
                          <IonButton
                                  expand="block"
                                  fill="outline"
                                  color="primary"
                                  size="default"
                                  onClick={() => {
                                    setShowModal(false);
                                    history.push("/producto/" + props.id);
                                  }}
                                >
                                  Ver más
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-text-center">
                        <IonCol>
                        <AddToCart
                          product={props}
                          variationID={0}
                          amount={1}
                          subtotal={props.productVariations.items[0].cost}
                          shop={props.shopID}
                        />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonRow>
                            <IonCol>
                              <IonChip
                                onClick={generalDescHandler}
                                color={descType === "general" ? "primary" : "secondary"}
                              >
                                Descripción
                              </IonChip>
                            </IonCol>
                            <IonCol>
                              <IonChip
                                onClick={technicalDescHandler}
                                color={descType === "technical" ? "primary" : "secondary"}
                              >
                                Especificaciones
                              </IonChip>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <IonText className="modalText"><small>
                              {descType === "general" ? `${props.description.slice(0,100)}...` : props.specifications}
                              </small></IonText>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
      </IonModal>
      <IonRow className="productContainer">
        <IonCol>
          <div className="product-view">
            <div className="product-view-wrapper" >
              <div className="image">
                {props.productVariations.items[0].pictures.length > 0 ? (
                  <IonSlides
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    <IonImg className="product-image"
                      src={props.productVariations.items[0].pictures[0]}
                    ></IonImg>
                  </IonSlides>
                ) : (
                  <IonIcon icon={imageOutline} />
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="name">{props.name}</div>
            <div className="price"><span>{numberFormat.format(props.productVariations.items[0].cost)}</span></div>
          </div>
          <div className="info">
              <AddToCart 
                product={props}
                variationID={0}
                amount={1}
                subtotal={props.productVariations.items[0].cost}
                shop={props.shopID}
              />
          </div>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default MyShopsProductView;
