import {
    IonButton,
    IonChip,
    IonCol,
    IonGrid,
    IonIcon,
    IonImg,
    IonLabel,
    IonModal,
    IonRow,
    IonText,
  } from "@ionic/react";
  import { closeCircleOutline, imageOutline } from "ionicons/icons";
  import React, { useState } from "react";
  import { useHistory } from "react-router";
  import AddToCart from "../cart/AddToCart";
  import "./ProductView.css";
  import { ProductObject } from "../../store/ProductSlice";
  
  const CollectionProductModal: React.FC<{product: ProductObject; showDetail: boolean ;setShowDetail: React.Dispatch<React.SetStateAction<boolean>>}> = (props) => {
    const history = useHistory();
    const [showDetail, setShowDetail] = useState(false);
    const currencyFormat = { style: 'currency', currency: 'MXN' };
    const numberFormat = new Intl.NumberFormat('es-MX', currencyFormat);
    const slideOpts = {
      autoplay: {
        delay: Math.random() * 2000 + 2000,
      },
      pauseOnMouseEnter: true,
    };
    const [descType, setDescType] = useState("general");
    const generalDescHandler = () => {
      setDescType("general");
    };
    const technicalDescHandler = () => {
      setDescType("technical");
    };
    return (
      <React.Fragment>
        <IonModal isOpen={props.showDetail}>
            <IonRow>
                <IonCol className="ion-text-right">
                  <IonButton onClick={() => props.setShowDetail(false)} fill="clear">
                    <IonIcon icon={closeCircleOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonGrid fixed className="modalProduct">
                <IonRow>
                  <IonCol size="6" offset-xs="3" offset-sm="3" offset-md="0" className="ion-margin-top ion-text-center">
                    <div className="">
                      <IonImg className="product-img" src={props.product.productVariations.items[0].pictures[0]}></IonImg>
                    </div>
                  </IonCol>
                  <IonCol size-xs="12" size-sm="12" size-md="6">
                    <IonRow>
                      <IonCol>
                        <IonRow><IonCol><IonText color="primary"><h1 className="ion-no-margin">{props.product.name}</h1></IonText></IonCol></IonRow>
                        {/* <IonRow><IonCol><IonText color="dark"><small>SKU: {props.productVariations.items[0].sku}</small></IonText></IonCol></IonRow> */}
                        <IonRow><IonCol>
                        {/* <IonText color="primary"><small>Categorías: {
                          JSON.parse(props.product.category.toString()).map(
                            (cat: string, index: number) => {
                              const cal = JSON.parse(props.product.category.toString()).length;
                              if (index === cal - 1) {
                                return cat;
                              } else {
                                return cat + " , ";
                              }
                            }
                          )}</small></IonText> */}
                          </IonCol></IonRow>
                        <IonRow><IonCol className="ion-text-right"><IonLabel color="tertiary"><b>{numberFormat.format(props.product.productVariations.items[0].price)}</b></IonLabel></IonCol></IonRow>
                        <IonRow className="ion-text-center">
                          <IonCol>
                            <IonButton
                                    expand="block"
                                    fill="outline"
                                    color="primary"
                                    size="default"
                                    onClick={() => {
                                      props.setShowDetail(false);
                                      history.push("/producto/" + props.product.id);
                                    }}
                                  >
                                    Ver más
                            </IonButton>
                          </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center">
                          <IonCol>
                          <AddToCart
                            product={props.product}
                            variationID={0}
                            amount={1}
                            subtotal={props.product.productVariations.items[0].cost}
                            shop= {props.product.brandName}
                          />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonRow>
                              <IonCol>
                                <IonChip
                                  onClick={generalDescHandler}
                                  color={descType === "general" ? "primary" : "secondary"}
                                >
                                  Descripción
                                </IonChip>
                              </IonCol>
                              <IonCol>
                                <IonChip
                                  onClick={technicalDescHandler}
                                  color={descType === "technical" ? "primary" : "secondary"}
                                >
                                  Especificaciones
                                </IonChip>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol>
                                <IonText className="modalText"><small>
                                {descType === "general" ? `${props.product.description.slice(0,100)}...` : props.product.specifications}
                                </small></IonText>
                              </IonCol>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
        </IonModal>
      </React.Fragment>
    );
  };
  
  export default CollectionProductModal;
  