import React from "react";
import "./ProductsView.css";
import { useSelector } from "react-redux";
import { productSelector } from "../../store/ProductSlice";
import ProductView from "./ProductView";

const ProductsView: React.FC = () => {
  const { products } = useSelector(productSelector);

  return (
    <div className="content">
      {products.map((prod: any, index) => (
        (prod.productVariations.items[0] !== undefined && prod.productVariations.items[0].status === "ACTIVO" && index <= 9 && prod.featured == "DESTACADO") ? (<ProductView key={prod.id} {...prod}></ProductView>) : ''
      ))}
    </div>
  );
};

export default ProductsView;
