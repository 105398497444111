import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatalogThunk } from "../index";
import {
  setError,
  setLoading,
  setWasCreated,
  setWasDeleted,
  setWasUpdated,
} from "./FlagSlice";
import {
  createCatalog,
  deleteCatalog,
  updateCatalog,
} from "../graphql/mutations";
import { getCatalog, listCatalogs } from "../graphql/queries";

//Interfaces
export interface CatalogState {
  catalogs: CatalogObject[];
  actualCatalog: CatalogObject;
}

export interface CatalogObject {
  id: string;
  name: string;
  shopID: string;
  type: string;
  cover: string;
  coverStatus: string;
  url: string;
}

//Initial State
const initialState: CatalogState = {
  catalogs: [],
  actualCatalog: {
    id: "",
    name: "",
    shopID: "",
    type: "",
    cover: "",
    coverStatus: "PENDIENTE DE APROBACIÓN",
    url: "",
  },
};

//Reducers
const catalog = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalogs(state, { payload }: PayloadAction<CatalogObject[]>) {
      state.catalogs = payload;
    },
    setActualCatalog(state, { payload }: PayloadAction<CatalogObject>) {
      state.actualCatalog = payload;
    },
    cleanCatalog(state) {
      state.catalogs = [];
    },
  },
});

//Exports
export const { setCatalogs, setActualCatalog, cleanCatalog } = catalog.actions;

export default catalog.reducer;

export const catalogSelector = (state: { catalogStore: CatalogState }) =>
  state.catalogStore;

//Middleware
export const postCatalog = (input: any): CatalogThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res:any = await API.graphql(graphqlOperation(createCatalog, { input }));
      dispatch(setActualCatalog(res.data.createCatalog))
      dispatch(setLoading(false));
      dispatch(setWasCreated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const getAllCatalog =
  (input: any): CatalogThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(listCatalogs, input));
      dispatch(setCatalogs(res.data.listCatalogs.items));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
export const getCatalogInfo =
  (input: any): CatalogThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getCatalog, input));
      if (typeof res.data.getCatalog !== "undefined") {
        dispatch(setActualCatalog(res.data.getCatalog));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const readCatalog =
  (input: any): CatalogThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(getCatalog, { input })
      );
      dispatch(setCatalogs(res.data.listCatalogs.items));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const editCatalog = (input: any): CatalogThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(updateCatalog, { input }));
      dispatch(setLoading(false));
      dispatch(setWasUpdated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const removeCatalog = (input: any): CatalogThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(deleteCatalog, { input }));
      dispatch(setLoading(false));
      dispatch(setWasDeleted(true));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};
