import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import { arrowBackOutline, cube } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import MyCatalogsCard from "./MyCatalogsCard";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import { catalogSelector } from "../../store/CatalogSlice";
import { ROUTE_SHOP, ROUTE_SHOP_CATALOGS_NEW } from "../../utils/routes";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";

export const CATALOG_LANDING = "catalogLanding";
export const CATALOG_ADD = "catalogAdd";
export const CATALOG_PAGE = "catalogPage";
export const CATALOG_ADD_PAGE = "catalogAddPage";

const MyCatalogsContent: React.FC = () => {
  const { wasCreated, loading } = useSelector(flagSelector);
  const { catalogs } = useSelector(catalogSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const catType = useContext(CatalogTypeContext);

  useEffect(() => {
    if (wasCreated) {
      setToastMessage("¡Catálogo creado satisfactoriamente!");
      dispatch(setWasCreated(false));
    }
  }, [wasCreated, toastMessage]);

  const goBackHandler = () => {
    history.replace(ROUTE_SHOP);
  };
  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar
        iconName={cube}
        title={
          "Mis " +
          (catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")
        }
      />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
            {catType.getCatalogType === "catalogo" && (
				<IonCol className="ion-padding" sizeMd="3.3" sizeXs="12" offsetXs="1.3">
              <IonButtons>
                <IonButton
                  slot="end"
                  color="primary"
                  fill="solid"
                  onClick={() => history.push("/mis-colecciones/cambaceo")}
                >
                  Agregar productos
                </IonButton>
                <IonButton
                  slot="end"
                  color="primary"
                  fill="solid"
                  onClick={() => history.push(ROUTE_SHOP_CATALOGS_NEW)}
                >
                  Agregar catálogo
                </IonButton>
              </IonButtons>
            </IonCol>)}

            {catType.getCatalogType === "listado" && (
              <IonCol className="ion-text-end">
                <IonButton
                  color="primary"
                  fill="solid"
                  onClick={() => history.push(ROUTE_SHOP_CATALOGS_NEW)}
                >
                  Agregar colección
                </IonButton>
              </IonCol>
            )}
          </IonRow>
          <>
            <IonRow>
              <IonCol>
                <IonSearchbar
                  value={searchText}
                  onIonChange={(e) => setSearchText(e.detail.value!)}
                  placeholder={"Nombre " + (catType.getCatalogType === "catalogo" ? "del catálogo" : "de la colección")}
                  class="search-bar-color"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {catalogs
                  .filter((catalog) => catalog.type === catType.getCatalogType)
                  .filter((catalog) =>
                    catalog.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map((catalog) => (
                    <MyCatalogsCard key={catalog.id} catalog={catalog} />
                  ))}
              </IonCol>
            </IonRow>
          </>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyCatalogsContent;
