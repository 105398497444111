import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  editShop,
  listShopsInfo,
  postShop,
  shopSelector,
} from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
import { flagSelector } from "../../store/FlagSlice";
import ShopAlert from "../alerts/ShopAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Toolbar from "../global/general/Toolbar";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";
import { editNovocorpo } from "../../store/NovocorpoSlice";

export const SHOP_LANDING = "shopLanding";
export const SHOP_PLAN = "shopPlan";
export const SHOP_FORM = "shopForm";
export const SHOP_PLAN_ONE = "planOne";
export const SHOP_PLAN_TWO = "planTwo";
export const SHOP_PLAN_THREE = "planThree";

const MyShopSliderContent: React.FC = () => {
  const history = useHistory();
  const { theShop } = useSelector(shopSelector);
  const { loading } = useSelector(flagSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(true);

  if (theShop.name === "novocorpo") {
    let novocorpo = { novocorpoID: "novocorpo", slider: theShop.slider };
    dispatch(editNovocorpo(novocorpo));
  }

  const goBackHandler = () => {
    history.replace("/mi-tienda");
  };
  const fieldArray = [
    {
      name: "slider",
      label: "Carrusel",
      type: "images" as FieldTypes,
      value: useState(theShop.slider ? JSON.stringify(theShop.slider) : ""),
      imageArray: useState([""]),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
      showPreview: true,
      max: 10,
      unavailableImageURL: UNAVAILABLE_IMAGE,
      bucketURL: BUCKET_URL,
      requiredWidth: 1500,
      requiredHeight: 700
    },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postShop,
      pushRoute: "/mi-tienda",
    },
    edit: {
      inputObject: {},
      dispatchFunction: editShop,
      pushRoute: "/mi-tienda",
    },
    delete: {
      inputObject: {},
      dispatchFunction: listShopsInfo,
      pushRoute: "/mi-tienda",
    },
  };

  let initialArray: { url: string; status: string }[] = [];
  let statArray: { url: string; status: string }[] = [];
  let updatedStatus: string[] = [];
  let urlArray: string[] = [];

  const defineSliderStatus = () => {
    if (fieldArray[0].value[0]) {
      if (theShop.slider !== null) {
        theShop.slider!.map((photo: string, index: number) => {
          let definedStatus =
            theShop.sliderStatus![index] && theShop.sliderStatus![index] !== ""
              ? JSON.parse(theShop.sliderStatus!)[index]
              : "PENDIENTE DE APROBACIÓN";
          initialArray.push({ url: photo, status: definedStatus });
        });
      }

      if (JSON.parse(fieldArray[0].value[0]) !== theShop.slider) {
        JSON.parse(fieldArray[0].value[0]).map(
          (photo: string, index: number) => {
            let definedStatus =
              theShop.sliderStatus![index] &&
              theShop.sliderStatus![index] !== ""
                ? JSON.parse(theShop.sliderStatus!)[index]
                : "PENDIENTE DE APROBACIÓN";
            if (!definedStatus) {
              definedStatus = "PENDIENTE DE APROBACIÓN";
            }
            statArray.push({ url: photo, status: definedStatus });
          }
        );

        if (statArray !== initialArray) {
          statArray
            .filter((pic) => pic.url)
            .map((pic, index) =>
              theShop.slider?.includes(pic.url)
                ? (statArray[index].status = JSON.parse(theShop.sliderStatus!)[
                    theShop.slider.indexOf(pic.url)
                  ])
                : null
            );
        }

        statArray.map((photo) => {
          updatedStatus.push(photo.status);
          urlArray.push(photo.url);
        });

        return JSON.stringify(updatedStatus);
      }
    }
  };

  useEffect(() => {
    if (!theShop.sliderStatus && flag) {
      dispatch(editShop({ shopID: theShop.shopID, sliderStatus: "" }));
      setFlag(false);
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <ShopAlert showAlert={showAlert} setShowAlert={setShowAlert} />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <ErrorAlert />

      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                <p>Volver</p>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size-xs="12"
              size-sm="10"
              offset-sm="1"
              size-md="8"
              offset-md="2"
              size-lg="8"
              offset-lg="2"
              size-xl="8"
              offset-xl="2"
            >
              <AmonytForm
                title="Editar Carrusel"
                fieldArray={fieldArray}
                actionObject={actionObject}
                add={false}
                disableDelete={true}
                hiddenFields={[
                  { name: "shopID", value: theShop.shopID },
                  { name: "sliderStatus", value: defineSliderStatus() },
                ]}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyShopSliderContent;
