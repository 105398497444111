import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSearchbar,
    IonText,
  } from "@ionic/react";
  import { addOutline,trashOutline } from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useHistory } from "react-router";
  import { flagSelector, setWasCreated } from "../../store/FlagSlice";
  import {
    getAllBrand,
    postBrand,
    brandSelector,
    removeBrand,
    setActualBrand
  } from "../../store/BrandSlice";
import { getAllProduct } from "../../store/ProductSlice";
import { PRODUCTS_LANDING } from "../products/MyProductsContent";
import { CATEGORIES } from "../products/AllProductsContent";
  
  export let brandArray: string[] = [];

  const BrandList: React.FC = () => {
    const { brand } = useSelector(brandSelector);
    const { wasCreated } = useSelector(flagSelector);
    const [toastMessage, setToastMessage] = useState("");
    const [myProducts, setProducts] = useState(CATEGORIES);
    const history = useHistory();
    const dispatch = useDispatch();
    const [theBrands, setTheBrands] = useState<any>([]);
    const [newBrand, setNewBrand] = useState("");
    const [searchText, setSearchText] = useState("");
  
    const goBackHandler = () => {
        dispatch(getAllProduct({}))
        setProducts(PRODUCTS_LANDING)
      history.push('/admin-products')
    }
  
    const activateButton = () => {
      if (newBrand !== "") {
        return false;
      } else {
        return true;
      }
    };
  
    const addBrand = () => {
        brandArray.push(newBrand)
        let input = { name: newBrand}
        dispatch(postBrand(input));
     
      setNewBrand("");
    };
  
    const deleteBrand = (BrandId: string) => {
      if (brandArray.includes(BrandId)) {
        brandArray = brandArray.filter((feat) => feat !== BrandId);
      }
      let input = {id: BrandId}
      dispatch(removeBrand(input))
    }
    
    const refreshDelete = () => {
      dispatch(getAllBrand())
    }
    
    const deleteArrayBrand = (Brand: string) => {
      if (brandArray.includes(Brand)) {
        brandArray = brandArray.filter((feat) => feat !== Brand);
      }
    }
  
  
    useEffect(() => {
     
      if (wasCreated) {
        setToastMessage("¡Marca creada satisfactoriamente!");
        dispatch(setWasCreated(false));
  
        dispatch(getAllBrand())
      }
    }, [wasCreated, toastMessage, brandArray, 
        brand
    ]);
  
    return (
       
      <IonGrid fixed>

        <IonRow>
          <IonCol className="ion-text-center ion-margin-bottom">
            <IonText color="primary">
              <h2>
                Agrega las marcas de tus productos para que estas estén disponibles al momento de cargarlos.
              </h2>
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeMd="4" offsetMd="3.5" sizeXs="10" offsetXs="1" className="ion-text-center">
            <IonItem>
              <IonLabel position="floating">Nueva marca:</IonLabel>
              <IonInput
                value={newBrand}
                onIonChange={(e) => setNewBrand(e.detail.value || "")}
              ></IonInput>
            </IonItem>
          </IonCol>
  
         
          <IonCol sizeXs="2" offsetXs="4" offsetMd="0">
            <IonButton
              onClick={() => {addBrand() ;dispatch(getAllBrand())}}
              disabled={activateButton()}
              fill="outline"
            >
              <IonIcon icon={addOutline} slot="start" />
              Agregar
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-text-center ion-margin">
            <IonCard>
              <IonText color="tertiary">
                <h5>Marcas</h5>
              </IonText>
              <IonCardContent>
              <IonRow>
                  <IonCol className="ion-text-left">
                    <IonSearchbar
                      value={searchText}
                      onIonChange={(e) => setSearchText(e.detail.value!)}
                      placeholder="Nombre de la marca"
                      class="search-bar-color"
                    />
                  </IonCol>
                </IonRow>
             
            <IonRow>
                {brand.length > 0 &&
                  brand.filter((cat) =>
                  cat.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                ).map((feat) => (<IonCol sizeMd="3" sizeXs="12">
                    <IonCard className="ion-margin-bottom show-click" >
                      <IonItem onClick={()=> dispatch(setActualBrand(feat))}>
                        <IonLabel>{feat.name}</IonLabel>
                        <IonButton color="danger" fill="clear" onClick={() => {deleteBrand(feat.id); dispatch(getAllBrand()); refreshDelete()}}>
                          <IonIcon icon={trashOutline} slot="icon-only" />
                        </IonButton>
                      </IonItem>
                    </IonCard></IonCol>
                  ))}</IonRow>
         </IonCardContent>
            </IonCard>
          </IonCol>
         
        </IonRow>
       
      </IonGrid>
    );
  };
  
  export default BrandList;
  