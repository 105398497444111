import {
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { flagSelector } from "../../store/FlagSlice";
import { productSelector } from "../../store/ProductSlice";
import {
  editProductVariation,
  postProductVariation,
  ProductVariationObject,
  removeProductVariation,
} from "../../store/ProductVariationSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";
import UserFeedback from "../global/general/UserFeedback";

const ProductVariationEditForm: React.FC<{
  productVariation: ProductVariationObject;
  myProducts: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const [toastMessage, setToastMessage] = useState("");
  const { loading } = useSelector(flagSelector);
  const { actualProduct } = useSelector(productSelector);



  
  let availabilityOptions = [
    { label: "Disponible", value: "in stock" },
    { label: "Agotado", value: "out of stock" },
    { label: "Descontinuado", value: "discontinued" },
  ];
  let conditionOptions = [
    { label: "Nuevo", value: "new" },
    { label: "Segunda Mano", value: "used" },
    { label: "Reacondicionado", value: "refurbished" },
  ];
  
  let parsedSelectAv = JSON.parse(props.productVariation.availability)
  let parsedSelectCon = JSON.parse(props.productVariation.condition as string)
  const fieldArray = [
    {
      name: "sku",
      label: "SKU del producto",
      type: "text" as FieldTypes,
      value: useState(props.productVariation.sku),
      placeholder: "Ej: Novo101",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "variationName",
      label: "Nombre de la variación",
      type: "text" as FieldTypes,
      value: useState(props.productVariation.variationName),
      placeholder: "Ej: Talla XL color Rojo",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "amount",
      label: "Cantidad en inventario",
      type: "text" as FieldTypes,
      value: useState(props.productVariation.amount as unknown as string),
      placeholder: "Ej: 100",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "cost",
      label: "Precio",
      type: "text" as FieldTypes,
      value: useState(props.productVariation.cost as unknown as string),
      placeholder: "Ej: 100",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "price",
      label: "Precio en promoción",
      type: "text" as FieldTypes,
      value: useState(props.productVariation.price as unknown as string),
      placeholder: "Ej: 90",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "availability",
      label: "Disponibilidad",
      type: "select" as FieldTypes,
      options: availabilityOptions,
      multiple: false,
      value: useState(parsedSelectAv),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "condition",
      label: "Condición",
      type: "select" as FieldTypes,
      options: conditionOptions,
      multiple: false,
      value: useState(parsedSelectCon),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "pictures",
      label: "Imágenes",
      type: "images" as FieldTypes,
      value: useState(JSON.stringify(props.productVariation.pictures) || ""),
      imageArray: useState([""]),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
      showPreview: true,
      max: 5,
      unavailableImageURL: UNAVAILABLE_IMAGE,
      bucketURL: BUCKET_URL,
      requiredWidth: 450,
      requiredHeight: 700
    },
  ];


  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProductVariation,
      pushRoute: "/mis-productos",
    },
    edit: {
      inputObject: {id: props.productVariation.id},
      dispatchFunction: editProductVariation,
      pushRoute: "/mis-productos",
    },
    delete: {
      inputObject: {id: props.productVariation.id},
      dispatchFunction: removeProductVariation,
      pushRoute: "/mis-productos",
    },
  };

  return (
    <IonGrid fixed>
      <IonRow>
        <IonCol>
        <UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
          <AmonytForm
            title="Editar variación de producto"
            fieldArray={fieldArray}
            add={false}
            actionObject={actionObject}
            hiddenFields={[
              { name: "status", value: "PENDIENTE DE APROBACIÓN" },
              { name: "productID", value: actualProduct.id },
            ]}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProductVariationEditForm;
