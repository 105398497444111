import { IonButton, IonCard, IonCol, IonContent, IonItem, IonGrid, IonHeader, IonIcon, IonImg, IonPage, IonRow, IonText, IonList, IonLabel} from "@ionic/react";
import { arrowBackOutline, cube, pencil, pricetag } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import { catalogSelector } from "../../store/CatalogSlice";
import { ROUTE_SHOP_CATALOGS, ROUTE_SHOP_CATALOGS_PAGES_EDIT, ROUTE_SHOP_CATALOGS_PAGES_NEW, ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS } from "../../utils/routes";
import { catalogPageSelector, getAllCatalogPages, setActualCatalogPage } from "../../store/CatalogPageSlice";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import "./MyCatalogsPagesContent.css";
import { ProductObject } from "../../store/ProductSlice";

export const CATALOG_LANDING = "catalogLanding";
export const CATALOG_ADD = "catalogAdd";
export const CATALOG_PAGE = "catalogPage";
export const CATALOG_ADD_PAGE = "catalogAddPage";

const MyCatalogsContent: React.FC = () => {
	const { wasCreated, loading } = useSelector(flagSelector);
	const [toastMessage, setToastMessage] = useState("");
	const dispatch = useDispatch();
	const { actualCatalog } = useSelector(catalogSelector);
	const { catalogPages } = useSelector(catalogPageSelector);
	const history = useHistory();
	const catType = useContext(CatalogTypeContext);

	useEffect(() => {
		let filterCatalogID = {
			filter: { catalogID: { eq: actualCatalog.id } },
		};
		dispatch(getAllCatalogPages(filterCatalogID));
		if (wasCreated) {
			setToastMessage("¡Página creada satisfactoriamente!");
			dispatch(setWasCreated(false));
		}
	}, [wasCreated, toastMessage]);
	
	
	

	const goBackHandler = () => {
		history.replace(ROUTE_SHOP_CATALOGS);
		dispatch(getAllCatalogPages({}))
	};
	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<MyShopToolbar iconName={cube} title={"Mis " + (catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol sizeXs="1" sizeMd="1">
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
						<IonCol offsetMd="2.5" offsetXs="0" sizeXs="12" sizeMd="5" className="ion-text-center ion-text-wrap">
							<IonText color="tertiary">
								<h3>
									Páginas de: <i>{actualCatalog.name}</i>
								</h3>
							</IonText>
						</IonCol>
						<IonCol sizeXs="12" sizeMd="2" offsetMd="1.5" offsetXs="3">
							<IonButton className="ion-margin" color="primary" fill="solid" routerLink={ROUTE_SHOP_CATALOGS_PAGES_NEW}>
								Agregar página
							</IonButton>
						</IonCol>
						
					</IonRow>
				<div>
						{catalogPages && catType.getCatalogType === "listado" &&
							catalogPages.map((page) => (
								<div key={page.id} >
										
									<IonCard >
										<IonGrid>
											<IonRow> 
												<IonCol sizeXs="4" sizeSm="4" sizeMd="2" className="pageOrderContainer">
													{JSON.parse(page.productsString!) ? JSON.parse(page.productsString!).map((prod: ProductObject) =>
													<>
													{JSON.parse(page.productsString!).length > 2 && 
														
														<IonImg className="resize_fit_center" src={prod.productVariations.items[0].pictures[0]} />
													}
													{JSON.parse(page.productsString!).length <= 2 &&
													<IonImg src={prod.productVariations.items[0].pictures[0]} />}</>) : null}
												
													<div className="pageNumber">
														<p>{page.pageNumber}</p>
													</div>
												</IonCol>
												<IonCol sizeXs="8" sizeSm="8" sizeMd="3">
													<IonGrid className="columna">
														<IonRow class="ion-align-items-center">
															<IonCol>
																<IonButton
																	expand="block"
																	color="tertiary"
																	fill="outline"
																	onClick={() => {
																		dispatch(setActualCatalogPage(page));
																		history.push(ROUTE_SHOP_CATALOGS_PAGES_EDIT);
																	}}
																>
																	<IonIcon slot="start" icon={pencil} />
																	Editar página
																</IonButton>
															</IonCol>
														</IonRow>
														<IonRow>
															<IonCol>
																<IonButton
																	expand="block"
																	color="tertiary"
																	fill="outline"
																	onClick={() => {
																		dispatch(setActualCatalogPage(page));
																		history.push(ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS);
																	}}
																>
																	<IonIcon slot="start" icon={pricetag} />
																	Agregar productos
																</IonButton>
															</IonCol>
														</IonRow>
													</IonGrid>
												</IonCol>
												<IonCol sizeXs="12" sizeSm="12" sizeMd="7">
													{page.productsString && (
														<IonGrid>
															<IonRow>
																<IonCol>
																	<IonText color="primary">Lista de Productos:</IonText>
																	<IonList>
                                    {catType.getCatalogType === "catalogo" && JSON.parse(page.productsString).map((product:any) => (
                                      <IonItem key={product.id}>
                                        <IonLabel>
                                          {product.sku} | {product.name} | {product.price} | {product.promoPrice} | {product.promoType}
                                        </IonLabel>
                                      </IonItem>
                                    ))}
									    {catType.getCatalogType === "listado" && JSON.parse(page.productsString).map((product:any) => (
                                      <IonItem key={product.id}>
                                        <IonLabel>
                                           {product.name}
                                        </IonLabel>
                                      </IonItem>
                                    ))}
																	</IonList>
																</IonCol>
															</IonRow>
														</IonGrid>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCard>
								</div>
							))}
			</div>

					<div>
						{catalogPages && catType.getCatalogType === "catalogo" &&
							catalogPages.map((page) => (
								<div key={page.id}>
										
									<IonCard>
										<IonGrid>
											<IonRow>
												<IonCol sizeXs="4" sizeSm="4" sizeMd="2" className="pageOrderContainer">
													<IonImg src={page.pageImage[0]} />
													<div className="pageNumber">
														<p>{page.pageNumber}</p>
													</div>
												</IonCol>
												<IonCol sizeXs="8" sizeSm="8" sizeMd="3">
													<IonGrid className="columna">
														<IonRow class="ion-align-items-center">
															<IonCol>
																<IonButton
																	expand="block"
																	color="tertiary"
																	fill="outline"
																	onClick={() => {
																		dispatch(setActualCatalogPage(page));
																		history.push(ROUTE_SHOP_CATALOGS_PAGES_EDIT);
																	}}
																>
																	<IonIcon slot="start" icon={pencil} />
																	Editar página
																</IonButton>
															</IonCol>
														</IonRow>
														<IonRow>
															<IonCol>
																<IonButton
																	expand="block"
																	color="tertiary"
																	fill="outline"
																	onClick={() => {
																		dispatch(setActualCatalogPage(page));
																		history.push(ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS);
																	}}
																>
																	<IonIcon slot="start" icon={pricetag} />
																	Agregar productos
																</IonButton>
															</IonCol>
														</IonRow>
													</IonGrid>
												</IonCol>
												<IonCol sizeXs="12" sizeSm="12" sizeMd="7">
													{page.productsString && (
														<IonGrid>
															<IonRow>
																<IonCol>
																	<IonText color="primary">Lista de Productos:</IonText>
																	<IonList>
                                    {catType.getCatalogType === "catalogo" && JSON.parse(page.productsString).map((product:any) => (
                                      <IonItem key={product.id}>
                                        <IonLabel>
                                          {product.sku} | {product.name} | {product.price} | {product.promoPrice} | {product.promoType}
                                        </IonLabel>
                                      </IonItem>
                                    ))}
									    {catType.getCatalogType === "listado" && JSON.parse(page.productsString).map((product:any) => (
                                      <IonItem key={product.id}>
                                        <IonLabel>
                                           {product.name}
                                        </IonLabel>
                                      </IonItem>
                                    ))}
																	</IonList>
																</IonCol>
															</IonRow>
														</IonGrid>
													)}
												</IonCol>
											</IonRow>
										</IonGrid>
									</IonCard>
								</div>
							))}
						{catalogPages.length === 0 && (
							<IonGrid>
								<IonRow>
									<IonCol className="ion-text-center">
										<IonText color="primary">
											<b>Sin Páginas</b>
										</IonText>
									</IonCol>
								</IonRow>
							</IonGrid>
						)}
					</div>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default MyCatalogsContent;

