import {
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useDispatch} from "react-redux";
import MyPropertiesContent from "../../components/properties/MyPropertiesContent";
import { getAllProperty } from "../../store/PropertySlice";



const MyPropertiesPage: React.FC = () => {
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
   
    dispatch(getAllProperty({}));
   
  });


  return <MyPropertiesContent />;
};

export default MyPropertiesPage;
