import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import { closeCircleOutline, imageOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import "../products/ProductView.css";
import {PropertyObject } from "../../store/PropertySlice";

const PropertyView: React.FC<PropertyObject> = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const slideOpts = {
    autoplay: {
      delay: Math.random() * 2000 + 2000,
    },
    pauseOnMouseEnter: true,
  };

 
  const [descType, setDescType] = useState("general");
  const generalDescHandler = () => {
    setDescType("general");
  };
  const technicalDescHandler = () => {
    setDescType("technical");
  };
  return (
    <React.Fragment>
      <IonModal isOpen={showModal}>
        <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonGrid fixed className="modalProduct">
          <IonRow>
            <IonCol
              size="6"
              offset-xs="3"
              offset-sm="3"
              offset-md="0"
              className="ion-margin-top ion-text-center"
            >
              <div className="">
                <IonImg
                  className="product-img"
                  src={props.pictures[0]}
                ></IonImg>
              </div>
            </IonCol>
            <IonCol size-xs="12" size-sm="12" size-md="6">
              <IonRow>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonText color="primary">
                        <h1 className="ion-no-margin">{props.name}</h1>
                      </IonText>
                    </IonCol>
                  </IonRow>
<IonGrid>
                  <IonBadge color="success">
                    <small>
                      {JSON.parse(props.typeOfOperation).toUpperCase()}
                    </small>
                  </IonBadge>

</IonGrid>
                
                  <IonRow>
                    <IonCol className="ion-text-right">
                      <IonLabel color="tertiary">
                        <b>{numberFormat.format(props.price)}</b>
                      </IonLabel>
                    </IonCol>
                  </IonRow>
<IonGrid>
                 <IonRow>
                   <IonCol>
                            <small>
                            <span className="category"><IonText color="primary">{props.floors > 1 ? props.floors + " plantas" : props.floors + " planta"}</IonText></span>
                            <span className="category"><IonText color="primary">{props.bedrooms > 1 ? props.bedrooms + " recámaras" : props.bedrooms + " recámara"}</IonText></span>
                            <span className="category"><IonText color="primary">{props.bathrooms > 1 ? props.bathrooms + " baños" : props.bathrooms + " baño"}</IonText></span>
                              </small>
                            </IonCol></IonRow>



                              <IonRow>
                        <IonCol>
                          <IonText className="modalText">
                            <small>
<>
                                <p>Ubicación: {props.location}</p>
                                {`${props.description.slice(0, 100)}`}
                             </> </small></IonText></IonCol></IonRow>
</IonGrid>

                  <IonRow className="ion-text-center">
                    <IonCol>
                      <IonButton
                        expand="block"
                        fill="outline"
                        color="primary"
                        size="default"
                        onClick={() => {
                          setShowModal(false);
                          history.push("/inmueble/" + props.id);
                        }}
                      >
                        Ver más
                      </IonButton>
                    </IonCol>
                  </IonRow>
                
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <IonRow className="productContainer">
        <IonCol>
          <div className="product-view">
            <div className="product-view-wrapper">
              <div className="image">
                {props.pictures.length > 0 ? (
                  <IonCol
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    <IonImg
                      className="product-image"
                      src={props.pictures[0]}
                    ></IonImg>
                  </IonCol>
                ) : (
                  <IonIcon icon={imageOutline} />
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="name">{props.name}</div>
            <div className="price">
              <span>{numberFormat.format(props.price)}</span>
            </div>
          </div>
        
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default PropertyView;
