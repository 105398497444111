import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonCol,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonSearchbar,
  } from "@ionic/react";
  import {
    cubeOutline,
    homeOutline,
  } from "ionicons/icons";
  import Toolbar from "../global/general/Toolbar";
  import FooterComponent from "../global/general/FooterComponent";
  import "../ShopProducts/ShopProducts.css";
  import { useSelector } from "react-redux";
  import { useState } from "react";
import { propertySelector } from "../../store/PropertySlice";
import PropertyView from "./PropertyView";
  
  const PropertiesView: React.FC = () => {
    const { properties } = useSelector(propertySelector);
    const [searchText, setSearchText] = useState("");
  
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <IonContent className="page-content info">
          <IonGrid fixed>
            <IonRow>
              <IonCol>
                <IonButtons>
                  <IonButton color="secondary" href="/">
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">Inicio</IonText>
                  </IonButton>
                  <IonText color="secondary">/</IonText>
                  <IonButton color="secondary" href="/inmuebles">
                    <IonIcon slot="start" icon={cubeOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">Inmuebles</IonText>
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonTitle color="primary">
                  <h1>Inmuebles</h1>
                </IonTitle>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonSearchbar
                  value={searchText}
                  onIonChange={(e) => setSearchText(e.detail.value!)}
                  placeholder="¿Qué estás buscando?"
                ></IonSearchbar>
              </IonCol>
             
              {/* <IonCol className="ion-align-self-center" size="2">
                <IonButton
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                  expand="block"
                >
                  <IonIcon slot="start" icon={filter}></IonIcon>
                  <IonText className="ion-hide-md-down">Filtros</IonText>
                </IonButton>
              </IonCol> */}
            </IonRow>
            <div className="content">
              {properties.filter((prod: any) =>
                              prod.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            ).map((prod: any) =>
                prod !== undefined ? (
                  <PropertyView key={prod.id} {...prod}></PropertyView>
                ) : (
                  ""
                )
              )}
            </div>
          </IonGrid>
          <FooterComponent></FooterComponent>
  
          {/* <IonModal isOpen={showModal} cssClass="filtersModal">
            <IonRow>
              <IonCol className="ion-text-right">
                <IonButton onClick={() => setShowModal(false)} fill="clear">
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonGrid fixed>
              <IonRow className="ion-text-center">
                <IonTitle color="primary">Filtros</IonTitle>
              </IonRow>
              <IonGrid>
                <IonRow className="ion-text-center">
                  <IonCol>
                    <IonLabel color="tertiary">Precio</IonLabel>
                    <IonRange
                      pin={true}
                      min={0}
                      max={maxPrice}
                      dualKnobs={true}
                      onIonChange={(e) => {
                        setRangeValue(e.detail.value as any);
                      }}
                    >
                      <IonLabel color="tertiary" slot="start">
                        $0
                      </IonLabel>
                      <IonLabel color="tertiary" slot="end">
                        ${maxPrice}
                      </IonLabel>
                    </IonRange>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-text-center">
                  <IonCol sizeSm="12" sizeMd="12" sizeLg="6">
                    <IonLabel color="tertiary">Tiendas</IonLabel>
                    <IonRadioGroup
                      value={selected}
                      onIonChange={(e) => setSelected(e.detail.value)}
                    >
                      {shops.map((shop) => (
                        <IonItem className="storeInput">
                          <IonLabel>{shop.name}</IonLabel>
                          <IonRadio slot="start" value={shop.shopID} />
                        </IonItem>
                      ))}
                    </IonRadioGroup>
                  </IonCol>
                  <IonCol sizeSm="12" sizeMd="12" sizeLg="6">
                    <IonLabel color="tertiary">Categoría</IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonButton className="ion-text-center" color="primary">
                      Filtrar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonGrid>
          </IonModal> */}
        </IonContent>
      </IonPage>
    );
  };
  
  export default PropertiesView;
  