import {
  IonGrid,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editOrder,
  orderSelector,
} from "../../store/OrderSlice";
import "./MyPurchaseOrders.css";


const OrderState: React.FC<{
  setStatus: React.Dispatch<
    React.SetStateAction<
    "PENDIENTE DE SURTIR" | "SURTIDO" | "PENDIENTE DE ENTREGA" | "ENTREGADO"  | undefined
    >
  >;
  status: "PENDIENTE DE SURTIR" | "SURTIDO" | "PENDIENTE DE ENTREGA" | "ENTREGADO"  | undefined;
  setPaymentStatus: React.Dispatch<
    React.SetStateAction<
    "PENDIENTE" | "RECIBIDO" | undefined
    >
  >;
  paymentStatus: "PENDIENTE" | "RECIBIDO"  | undefined;
}> = () => {

  const dispatch = useDispatch();
  const { pastOrder } = useSelector(orderSelector);
  const [paymentStatus, setPaymentStatus] = useState<"PENDIENTE" | "RECIBIDO" | string
>(pastOrder.payment)
  const [status, setStatus] = useState<
  "PENDIENTE DE SURTIR" | "SURTIDO" | "PENDIENTE DE ENTREGA" | "ENTREGADO" | string
>(pastOrder.status)


const changeStatus = (statusChanged: any) => {

  let inputObject = {
    id: pastOrder.id,
    status: statusChanged,
  };
  dispatch(editOrder(inputObject))
  setStatus(statusChanged)
}
const changePaymentStatus = (paymentStatusChanged: any) => {

  let inputObject = {
    id: pastOrder.id,
    payment: paymentStatusChanged,
  };
  dispatch(editOrder(inputObject))
  setPaymentStatus(paymentStatusChanged)
}


  return (
    <IonGrid>
 <IonItem className="ion-text-center ">
      <IonLabel position="floating" className="ion-text-left select-full-width">
      <IonText color="primary">
        Pago
        </IonText>
      </IonLabel>
      <IonSelect className="select-full-width"
        value={paymentStatus}
        onIonChange={(e) => {
          changePaymentStatus(e.detail.value); setPaymentStatus(e.detail.value);
        }}
      >
        <IonSelectOption value="PENDIENTE">PENDIENTE</IonSelectOption>
        <IonSelectOption value="RECIBIDO">
       RECIBIDO
        </IonSelectOption>
      </IonSelect>
    </IonItem>
    
      <IonItem className="ion-text-center ">
      <IonLabel position="floating" className="ion-text-left select-full-width">
      <IonText color="primary">
        Status
        </IonText>
      </IonLabel>
      <IonSelect className="select-full-width"
        value={status}
        onIonChange={(e) => {
          changeStatus(e.detail.value); setStatus(e.detail.value);
        }}
      >
        <IonSelectOption value="PENDIENTE DE SURTIR">PENDIENTE DE SURTIR</IonSelectOption>
        <IonSelectOption value="SURTIDO">
        SURTIDO
        </IonSelectOption>
        <IonSelectOption value="PENDIENTE DE ENTREGA">
        PENDIENTE DE ENTREGA
        </IonSelectOption>
        <IonSelectOption value="ENTREGADO">
        ENTREGADO
        </IonSelectOption>
      </IonSelect>
    </IonItem>
  </IonGrid>
  );
};

export default OrderState;