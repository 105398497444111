export const ROUTE_SHOP = '/mi-tienda';
export const ROUTE_SHOP_EDIT = '/mi-tienda/editar';
export const ROUTE_SHOP_SLIDER = '/mi-tienda/carrusel';

export const ROUTE_SHOP_CATALOGS = '/mis-colecciones';
export const ROUTE_SHOP_CATALOGS_NEW = '/mis-colecciones/nuevo';
export const ROUTE_SHOP_CATALOGS_EDIT = '/mis-colecciones/editar';
export const ROUTE_SHOP_CATALOGS_PAGES = '/mis-colecciones/paginas';
export const ROUTE_SHOP_CATALOGS_PAGES_NEW = '/mis-colecciones/pagina/nueva';
export const ROUTE_SHOP_CATALOGS_PAGES_EDIT = '/mis-colecciones/pagina/editar';
export const ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS = '/mis-colecciones/pagina/productos';
export const ROUTE_SHOP_CATALOGS_PAGES_NUMBER = '/mis-colecciones/pagina/:num';
export const ROUTE_SHOP_CAMBACEO = '/mis-colecciones/cambaceo';
export const ROUTE_SHOP_CAMBACEO_NEW = '/mis-colecciones/cambaceo/nuevo';
export const ROUTE_SHOP_CAMBACEO_EDIT = '/mis-colecciones/cambaceo/editar';
export const ROUTE_SHOP_SELLED_PRODUCTS = '/mis-ordenes/productos-vendidos';
export const ROUTE_SHOP_PROPERTIES = '/mis-inmuebles';
export const ROUTE_SHOP_PROPERTIES_ADD = '/mis-inmuebles/nuevo';
export const ROUTE_SHOP_PROPERTIES_EDIT = '/mis-inmuebles/editar';
export const ROUTE_SHOP_PROPERTYFEATURE_ADD = '/mis-inmuebles/nueva-caracteristica';
export const ROUTE_ADMIN_USER_MONEDERO = '/admin-users/monedero';




export const ROUTE_ABOUT_US = '/nosotros';
export const ROUTE_PROMOTE = '/promocionate';
export const ROUTE_FAQ = '/faq';
export const ROUTE_CONTACT = '/contacto';
export const ROUTE_PRIVACITY = '/aviso-privacidad';
export const ROUTE_TERMS = '/terminos-y-condiciones';

export const ROUTE_CATALOGS = "/catalogos";
export const ROUTE_CATALOG = "/catalogo";

export const ROUTE_HOME = "/";
export const ROUTE_SHOP_PRODUCTS = "/productos";


export const ROUTE_PROFILE_CLIENT = "/profile";
export const ROUTE_PROFILE_ORDERS = "/orders";
export const ROUTE_PROFILE_MONEDERO = "/monedero";


export const ROUTE_ADMIN_SHOPS = "/admin-tiendas";
export const ROUTE_ADMIN_PRODUCTS = "/admin-productos";
export const ROUTE_ADMIN_CATEGORIES = "/admin-productos/categorias";
export const ROUTE_ADMIN_USERS = "/admin-users";

export const ROUTE_ADMIN_IMAGES = "/admin-images"