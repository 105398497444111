import React, { RefObject, useContext, useEffect, useRef } from "react";
import { IonToolbar, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react";
import { useSelector } from "react-redux";
import SignOutButton from "../buttons/SignOutButton";
import SignInButton from "../buttons/SignInButton";
import { authSelector } from "../../../store/AuthSlice";
import CartButton from "../buttons/CartButton";
import "./Toolbar.css";
import HomeButton from "../buttons/HomeButton";
import { MenuContext } from "../../../context/MenuContext";
import { menu } from "ionicons/icons";

const Toolbar: React.FC = () => {
  const { isAuthed } = useSelector(authSelector);
  const menuContext = useContext(MenuContext);

  const ref = useRef<HTMLIonButtonElement>(null);
  type Event = MouseEvent | TouchEvent;

  const useOnClickOutside = <T extends HTMLIonButtonElement = HTMLIonButtonElement>(
    ref: RefObject<T>
  ) => {
    useEffect(() => {
      const listener = (event: Event) => {
        const el = ref?.current;
        if (!el || el.contains((event?.target as Node) || null)) {
          return;
        }
  
        menuContext.setOpenStateTo(false); 
      };
  
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
  
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref]); 
  };

 

  useOnClickOutside(ref)


  return (
    <React.Fragment>
      <IonToolbar className="header-toolbar-style">
        <IonRow>
          <IonCol className="ion-align-self-center" sizeMd="3" sizeXs="5.5">
            <HomeButton />
          </IonCol>
          <IonCol className="ion-align-self-center" sizeMd="6" sizeXs="3" />
          <IonCol size="3" className=" ion-padding">
            <div className="main-toolbar-buttons ion-text-right">
              <CartButton />
              {isAuthed ? <SignOutButton /> : <SignInButton />}{" "}
              <IonButton ref={ref}
                color=""
                onClick={() => {
                  menuContext.toggleOpenState();
                }}
              >
                <IonIcon icon={menu}></IonIcon>
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </React.Fragment>
  );
};

export default Toolbar;
