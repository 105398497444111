import { } from "aws-amplify";
import { useIonViewWillEnter } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PROPERTIES_ADD } from "../../components/properties/MyPropertiesContent";
import MyPropertiesForm from "../../components/properties/MyPropertiesForm";
import { getAllPropertyFeature } from "../../store/PropertyFeatureSlice";


const MyPropertiesFormPage: React.FC = () => {
	const dispatch = useDispatch();
    const [myProperty, setProperties] = useState(PROPERTIES_ADD);

	useIonViewWillEnter(() => {
   
		dispatch(getAllPropertyFeature());
		
	  });
	

	return <MyPropertiesForm myProperties={setProperties} />;
};

export default MyPropertiesFormPage;
