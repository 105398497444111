import {
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonText,
    IonTitle,
  } from "@ionic/react";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useHistory } from "react-router";
  import {
    orderSelector,
  } from "../../store/OrderSlice";
  import { editShop,  shopSelector } from "../../store/ShopSlice"
import { userSelector } from "../../store/UserSlice";
  
  
  const ShopState: React.FC<{
    setShopStatus: React.Dispatch<
      React.SetStateAction<
      "ACTIVO" | "PENDIENTE DE APROBACIÓN" | "SUSPENDIDO" | undefined
      >
    >;
    shopStatus: "ACTIVO" | "PENDIENTE DE APROBACIÓN" | "SUSPENDIDO" | undefined;
}> = () => {
  
    const history = useHistory();
    const dispatch = useDispatch();
    const o = useSelector(orderSelector);
    const orders = o.orders;
    const { theOrder } = useSelector(orderSelector);
    const {actualUser, users} = useSelector(userSelector)
    const { theShop } = useSelector(shopSelector);
    const [statusView, setStatusView] = useState(true)
    const [shopStatus, setShopStatus] = useState<
    "ACTIVO" | "PENDIENTE DE APROBACIÓN" | "SUSPENDIDO" | string
  >(theShop.status!)
  

  const changeStatus = (statusChanged: any) => {
  
    let inputObject = {
      shopID: theShop.shopID,
      status: statusChanged,
    };
    dispatch(editShop(inputObject))
    setShopStatus(statusChanged)
  }
let userName: string = ""
for (let i =0; users.length > i; i++){
  if (users[i].userProfileEmail.includes(theShop.userProfileEmail)){
    let userName = users[i].invoiceName
  }
}


    return (
       
        <IonGrid>
        <IonRow ><IonCol className="ion-text-center ion-margin"><IonTitle color="tertiary">Tienda {theShop.name}</IonTitle></IonCol></IonRow>
       {users.filter((user) => theShop.userProfileEmail === user.userID).map((user) => <IonRow><IonCol><IonItem lines="none"><IonLabel color="primary">
          Nombre del usuario: {user.invoiceName !== null ? <strong>{user.invoiceName}</strong> : "El usuario aún no ha ingresado su nombre."}
         </IonLabel></IonItem> </IonCol></IonRow>)}
        <IonRow><IonCol><IonItem lines="none"><IonLabel color="primary">
          E-mail del usuario: <strong>{theShop.userProfileEmail}</strong>
          </IonLabel> </IonItem> </IonCol></IonRow>
          {users.filter((user) => theShop.userProfileEmail === user.userID).map((user) => <IonRow><IonCol><IonItem lines="none"><IonLabel color="primary">
          Teléfono del usuario: {user.phoneNumber !== null ? <strong>{user.phoneNumber}</strong> : "El usuario aún no ha ingresado su teléfono."}
          </IonLabel> </IonItem> </IonCol></IonRow> )}
       <IonItem className="ion-text-center ion-margin">
                <IonLabel position="floating" className="ion-text-left">
                <IonText className="ion-padding" color="primary">
                  Status
                  </IonText>
                </IonLabel>
                <IonSelect
                  value={shopStatus}
                  onIonChange={(e) => {changeStatus(e.detail.value); setShopStatus(e.detail.value); }}
                >
                  <IonSelectOption value="ACTIVO">ACTIVO</IonSelectOption>
                  <IonSelectOption value="PENDIENTE DE APROBACIÓN">
                  PENDIENTE
                  </IonSelectOption>
                  <IonSelectOption value="SUSPENDIDO">
                  SUSPENDIDO
                  </IonSelectOption>
                </IonSelect>
              </IonItem>
              
   </IonGrid>

    );
  };
  
  export default ShopState;