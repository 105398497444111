import React, { Dispatch, SetStateAction } from "react";
import Loading from "./Loading";
import Toast from "./CustomToast";

const UserFeedback: React.FC<{
  loading: boolean;
  toastMessage: [string, Dispatch<SetStateAction<string>>];
}> = ({ loading, toastMessage }) => {
  return (
    <React.Fragment>
      <Loading show={loading} />
      <Toast toastMessage={[toastMessage[0], toastMessage[1]]} />
    </React.Fragment>
  );
};

export default UserFeedback;
