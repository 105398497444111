import { IonCol, IonGrid, IonRow,IonText } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { authSelector } from "../../store/AuthSlice";
import {
  editShop,
  listShopsInfo,
  postShop,
  shopSelector,
} from "../../store/ShopSlice";

const MyShopForm: React.FC<{
  myShop: Dispatch<SetStateAction<string>>;
  myShopPlan: string;
}> = (props) => {
  const { userProfile } = useSelector(authSelector);
  const { theShop } = useSelector(shopSelector);

  const fieldArray = [
    {
      name: "name",
      label: "Nombre de tu tienda",
      type: "text" as FieldTypes,
      value: useState(""),
      placeholder: "Ej: Venta de Cochera Mary",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "description",
      label: "Descripción de tu tienda",
      type: "textarea" as FieldTypes,
      value: useState(""),
      placeholder: "Ej: Muebles antiguos ...",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
  ];
  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postShop,
      pushRoute: "/mi-tienda",
    },
    edit: {
      inputObject: {},
      dispatchFunction: editShop,
      pushRoute: "/mi-tienda/edit",
    },
    delete: {
      inputObject: {},
      dispatchFunction: listShopsInfo,
      pushRoute: "/mi-tienda",
    },
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol
          size-xs="12"
          size-sm="10"
          offset-sm="1"
          size-md="8"
          offset-md="2"
          size-lg="6"
          offset-lg="3"
          size-xl="6"
          offset-xl="3"
        >
          <IonRow>
            <IonCol className="ion-text-center">
              <IonText color="primary"><p>Paso 3</p></IonText>
            </IonCol>
          </IonRow>
          <AmonytForm
            title="Crear mi Tienda"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={true}
            hiddenFields={[
              { name: "status", value: "PENDIENTE DE APROBACIÓN" },
              {
                name: "shopID",
                value: fieldArray[0].value[0]
                  .toString()
                  .replace(/\s+/g, "-")
                  .toLowerCase(),
              },
              { name: "plan", value: props.myShopPlan },
              { name: "userProfileEmail", value: userProfile.email },
            ]}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MyShopForm;
