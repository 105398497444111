import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct, getAllProducts } from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";
import { useHistory } from "react-router";
import AllProductsContent from "../../components/products/AllProductsContent";
import { getAllCategory } from "../../store/CategorySlice";
import { Observable } from "zen-observable-ts";
import { API, graphqlOperation } from "aws-amplify";
import { onCreateProduct, onUpdateProduct, onDeleteProduct } from "../../graphql/subscriptions";

const AllProductsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { theShop } = useSelector(shopSelector);
  const history = useHistory();

  let createProductListener: any;
  let updateProductListener: any;
  let deleteProductListener: any;

  // useIonViewWillEnter(() => {
  //   if(!theShop.shopID){
  //     history.replace("/mi-tienda")
  //   }
  //   dispatch(getAllProducts());
  //   dispatch(getAllCategory());
  // });

  useIonViewWillEnter(() => {
    if(!theShop.shopID){
      history.replace("/mi-tienda")
    }
     dispatch(getAllProducts());
  });
  useIonViewDidEnter(() => {
    const onCreateSubClient = API.graphql(
      graphqlOperation(onCreateProduct)
    ) as Observable<object>;
    

    createProductListener = onCreateSubClient.subscribe({
      next: () => {
        dispatch(getAllProducts());
      },
      error: (error) => console.warn(error),
    });

    const onUpdateSubClient = API.graphql(
      graphqlOperation(onUpdateProduct)
    ) as Observable<object>;


    updateProductListener = onUpdateSubClient.subscribe({
      next: () => {     dispatch(getAllProducts());

      },
    });


    const onDeleteSubClient = API.graphql(
      graphqlOperation(onDeleteProduct)
    ) as Observable<object>;

    deleteProductListener = onDeleteSubClient.subscribe({
      next: () => {     dispatch(getAllProducts());
      },
    });
  });

  useIonViewDidLeave(() => {
    createProductListener.unsubscribe();
    updateProductListener.unsubscribe();
    deleteProductListener.unsubscribe();
  });




  return <AllProductsContent />;
};

export default AllProductsPage;
