import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSearchbar,
  } from "@ionic/react";
  import {
    arrowBackOutline,
    createOutline,
    cube,
    pricetagOutline,
    trashOutline,
  } from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { flagSelector, setWasCreated, setWasDeleted, setWasUpdated } from "../../store/FlagSlice";
  import UserFeedback from "../global/general/UserFeedback";
  import MyShopToolbar from "../shop/MyShopToolbar";
  import MyCambaceoForm from "./MyCambaceoForm";
  import { useHistory } from "react-router";
  import Toolbar from "../global/general/Toolbar";
  import {
    cambaceoSelector,
    getAllCambaceo,
    getCambaceoInfo,
    removeCambaceo,
    setActualCambaceo,
  } from "../../store/CambaceoSlice";
  import MyCambaceoEditForm from "./MyCambaceoEditForm";
  import { ROUTE_SHOP_CAMBACEO, ROUTE_SHOP_CAMBACEO_EDIT, ROUTE_SHOP_CAMBACEO_NEW, ROUTE_SHOP_CATALOGS, ROUTE_SHOP_CATALOGS_PAGES, ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS } from "../../utils/routes";


  
  export const PRODUCTS_LANDING = "productsLanding";
  export const PRODUCTS_ADD = "productsAdd";
  export const PRODUCTS_EDIT = "productsEdit";

  
  const MyCambaceoProducts: React.FC = () => {
    const [myCambaceo, setCambaceo] = useState(PRODUCTS_LANDING);
    const { wasCreated, loading, wasDeleted, wasUpdated } = useSelector(flagSelector);
    const { cambaceo } = useSelector(cambaceoSelector);
    const [toastMessage, setToastMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
 
    const { actualCambaceo } = useSelector(cambaceoSelector);

    const history = useHistory();
  

  
    const goBackHandler = () => {
      if (myCambaceo === PRODUCTS_LANDING) {
        history.replace(ROUTE_SHOP_CATALOGS);
      } else if (myCambaceo === PRODUCTS_ADD || myCambaceo === PRODUCTS_EDIT) {
        setCambaceo(PRODUCTS_LANDING);
        history.replace(ROUTE_SHOP_CAMBACEO)
      }
    };
  

    const editCambaceo = () => {
      setCambaceo(PRODUCTS_EDIT);
      history.push(ROUTE_SHOP_CAMBACEO_EDIT)
    };
  
    useEffect(() => {
      if (wasCreated) {
        setToastMessage("¡Producto creado satisfactoriamente!");
        dispatch(setWasCreated(false));
        dispatch(getCambaceoInfo({actualCambaceo}))
        dispatch(getAllCambaceo({}))
      } if (wasDeleted) {
        setToastMessage("¡Producto eliminado!");
        dispatch(getAllCambaceo({}))
        dispatch(setWasDeleted(false));
      }else if (wasUpdated) {
        setToastMessage("¡Producto actualizado!");
        dispatch(getAllCambaceo({}))
        dispatch(setWasUpdated(false));
      }
      
    }, [wasCreated, toastMessage, wasDeleted, wasUpdated]);
  
    return (
      <IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<MyShopToolbar iconName={cube} title={"Mis productos de catálogo"} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
        <IonContent>
          <IonCol sizeXs="1">
            <IonButton fill="clear" onClick={goBackHandler}>
              <IonIcon slot="start" icon={arrowBackOutline} />
              Volver
            </IonButton>
          </IonCol>
  
          <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
            {myCambaceo === PRODUCTS_LANDING && (
              <>
                <IonRow>
                <IonCol className="ion-text-right">
                     <IonButton
                      color="primary"
                      fill="solid"
                      onClick={() => {
                        setCambaceo(PRODUCTS_ADD);
                        history.push(ROUTE_SHOP_CAMBACEO_NEW)
                      }}
                    >
                      Agregar nuevo producto
                    </IonButton>
</IonCol>
                  <IonCol sizeMd="12" sizeXs="12" >
                  
                    
                   
  
                    <IonRow>
                      <IonSearchbar
                        value={searchText}
                        onIonChange={(e) => setSearchText(e.detail.value!)}
                        placeholder="Nombre del producto"
                        class="search-bar-color"
                      />
  
                      <IonCol>
                        {cambaceo
                          .filter((product) =>
                            product.name
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          .map((product) => (
                            <IonCard className="ion-margin-bottom">
                              <IonItem
                                className="show-click"
                                onClick={() => {
                                  dispatch(setActualCambaceo(product));
                                  dispatch(getCambaceoInfo(product));
                                }}
                              >
                               
                                <IonIcon
                                slot="start"
                                  color="primary"
                                  icon={pricetagOutline}
                                  
                                />
                                <IonLabel color="primary">
                                  {product.name}
                                </IonLabel>
                                <IonButtons>
                                  <IonButton
                                    color="primary"
                                    slot="end"
                                    fill="clear"
                                    onClick={() => editCambaceo()}
                                  >
                                    <IonIcon
                                      icon={createOutline}
                                      slot="icon-only"
                                    />
                                  </IonButton>
                                  <IonButton
                                    color="danger"
                                    slot="end"
                                    fill="clear"
                                    onClick={() => {dispatch(removeCambaceo({id: actualCambaceo.id}))}}
                                  >
                                    <IonIcon icon={trashOutline} slot="icon-only" />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            </IonCard>
                          ))}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </>
            )}
  
           {myCambaceo === PRODUCTS_ADD && (
              <MyCambaceoForm previousPage={ROUTE_SHOP_CAMBACEO}
                myCambaceo={setCambaceo}
              />
              
            )}
  
            {myCambaceo === PRODUCTS_EDIT && (
              <MyCambaceoEditForm myCambaceo={setCambaceo} />
            )} 
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default MyCambaceoProducts;
  
