import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { BrandObject, brandSelector } from "../../store/BrandSlice";
import { CategoryObject, categorySelector } from "../../store/CategorySlice";
import {
  editProduct,
  postProduct,
  productSelector,
  removeProduct,
} from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";

let categoryOptions: { label: string; value: string }[] = [];
let brandOptions: { label: string; value: string }[] = [];


const MyProductsForm: React.FC<{
  myProducts: Dispatch<SetStateAction<string>>;
}> = () => {
  const { theShop } = useSelector(shopSelector);
  const { category } = useSelector(categorySelector);
  const {brand} = useSelector(brandSelector)
  const [theProduct, setTheProducts] = useState<any>([]);
  const {actualCategories} = useSelector(productSelector)
brandOptions = []
  // if (theShop.shopID === "novocorpo") {
  //   brandOptions = []

    if (brand.length > 0) {
      brand.forEach((cat: BrandObject) =>
          brandOptions.push({ label: cat.name, value: cat.name })
        );
    }
  // } else {
  //   brandOptions = [{label: theShop.shopID, value: theShop.shopID}]
  // }
brandOptions = brandOptions.sort((a,b) => a.value.toUpperCase().localeCompare(b.value.toUpperCase()));


  categoryOptions = []

  if (category.length > 0) {
    category.forEach((cat: CategoryObject) =>
        categoryOptions.push({ label: cat.name, value: cat.name })
      );
   
  }
 categoryOptions = categoryOptions.sort((a,b) => a.value.toUpperCase().localeCompare(b.value.toUpperCase()));


  const fieldArray = [
    {
      name: "name",
      label: "Nombre del producto",
      type: "text" as FieldTypes,
      value: useState(theProduct.name),
      placeholder: "Ej: Playera amarilla",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "description",
      label: "Descripción",
      type: "textarea" as FieldTypes,
      value: useState(theProduct.description),
      placeholder:
        "Ej: Elige palabras clave que describan claramente tu producto.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "specifications",
      label: "Especificaciones",
      type: "textarea" as FieldTypes,
      value: useState(theProduct.specifications),
      placeholder: "Ej: Dimensiones, material, garantía, etc.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "category",
      label: "Categorías",
      type: "searchbarcheckbox" as FieldTypes,
      options: categoryOptions,
      multiple: true,
      value: useState(theProduct.category),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
{
      name: "brandName",
      label: "Marca",
      type: "searchbarradio" as FieldTypes,
      options: brandOptions,
      multiple: false,
      value: useState(theProduct.brandName),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    }
  ];



  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProduct,
      pushRoute: "/mis-productos",
    },
    edit: {
      inputObject: {},
      dispatchFunction: editProduct,
      pushRoute: "/mis-productos",
    },
    delete: {
      inputObject: {},
      dispatchFunction: removeProduct,
      pushRoute: "/mis-productos",
    },
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <AmonytForm
            title="Crear producto"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={true}
            hiddenFields={[
              { name: "status", value: "PENDIENTE DE APROBACIÓN" },
              {
                name: "shopID",
                value: theShop.shopID,
              },
            ]}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MyProductsForm;

