import { IonButton, IonButtons,IonContent, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonTitle, IonLabel, IonItem, IonInput, IonTextarea } from "@ionic/react";
import { useHistory } from "react-router";
import { homeOutline, chatboxOutline } from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import FooterComponent from "../global/general/FooterComponent";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import { useEffect, useState } from "react";
import { sendContactEmail } from "../../store/PropertySlice";
import UserFeedback from "../global/general/UserFeedback";

const Contact: React.FC<{}> = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { wasCreated, loading } = useSelector(flagSelector);
	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMessage, setContactMessage] = useState("");
	const [contactPhoneNumber, setContactPhoneNumber] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	
	
	const activateButton = () => {
		if(contactName !== "" && contactEmail !== "" && contactPhoneNumber !== "" && contactMessage !== "") {
		  return false
		}
		return true
	  }
	  useEffect(() => {
		if (wasCreated) {
		  setToastMessage("¡Mensaje enviado!");
		  dispatch(setWasCreated(false));
		}
	  }, [wasCreated, toastMessage]);

	const sendEmail = () => {
		const emailData = {
		  toemail: "operaciones@novocorpo.com.mx",
		  subject: `Nueva consulta de ${contactName}`,
		  message: 
		  `${contactName},
	
		  (Teléfono: ${contactPhoneNumber},\n
	
		  Email: ${contactEmail})\n
	
		  dejó la siguiente consulta: ${contactMessage}`
		  
		};
		dispatch(sendContactEmail(emailData));
		dispatch(setWasCreated(true))
	  }

    return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        />
			<IonContent className="page-content info">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButtons>
								<IonButton color="secondary" href="/"><IonIcon slot="start" icon={homeOutline}></IonIcon><IonText className="ion-hide-sm-down">Inicio</IonText></IonButton>
								<IonText color="secondary">/</IonText>
								<IonButton color="secondary" href="/contact"><IonIcon slot="start" icon={chatboxOutline}></IonIcon><IonText className="ion-hide-sm-down">Contacto</IonText></IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>

					
					<IonRow>
						<IonCol className="ion-text-center"><IonTitle color="tertiary"><h1>Contacto</h1></IonTitle></IonCol>
					</IonRow>
					<IonRow>
					<IonCol className="ion-text-center"><IonTitle  color="primary"><h5 className="ion-text-wrap">Envíanos tu consulta y estaremos en contacto a la brevedad.</h5></IonTitle></IonCol>
					</IonRow>
                   <IonGrid>
      <IonRow> 
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Nombre:</IonLabel>
              <IonInput value={contactName} onIonChange={(e) => setContactName(e.detail.value || "")} color="primary"/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Teléfono:</IonLabel>
              <IonInput value={contactPhoneNumber}  onIonChange={(e) => setContactPhoneNumber(e.detail.value || "")} color="primary"/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Email:</IonLabel>
              <IonInput type="email" color="primary" value={contactEmail} onIonChange={(e) => setContactEmail(e.detail.value || "")}/>
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
      <IonCol size="8" offset="2">
            <IonItem>
              <IonLabel position="floating" color="primary">Mensaje:</IonLabel>
              <IonTextarea rows={3} color="primary" value={contactMessage} onIonChange={(e) => setContactMessage(e.detail.value || "")} />
            </IonItem>
          </IonCol>
      </IonRow>
      <IonRow>
                    <IonCol
                      offsetMd="2"
                      sizeMd="8"
                      sizeXs="12"
                      className="ion-text-center"
                    >
                      <IonButton color="tertiary" fill="solid" disabled={activateButton()}
                      onClick={() => sendEmail}>
                        Enviar
                      </IonButton>
                    </IonCol>
                  </IonRow>

				</IonGrid></IonGrid>
				<FooterComponent></FooterComponent>
            </IonContent>
        </IonPage>
    );
}

export default Contact;