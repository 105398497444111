import React, { useContext, useState } from "react";
import {
	IonCard,
	IonCardHeader,
	IonItem,
	IonIcon,
	IonLabel,
	IonGrid,
	IonButton,
	IonImg,
	IonText,
	IonHeader,
	IonToolbar,
	IonCol,
	IonRow,
	IonTitle,
	IonPopover,
	IonContent,
	IonModal,
} from "@ionic/react";
import {
	albumsOutline,
	cash,
	copyOutline,
	ellipsisVertical,
	home,
	imagesOutline,
	layers,
	logoWhatsapp,
	pencil,
	pricetags,
	pricetagsOutline,
	shareSocial,
	storefront,
	storefrontOutline,
	walletOutline,
} from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { editShop, shopSelector } from "../../store/ShopSlice";
import MyShopStatus from "./MyShopStatus";
import UserFeedback from "../global/general/UserFeedback";
import { flagSelector } from "../../store/FlagSlice";
import { ROUTE_SHOP_EDIT, ROUTE_SHOP_CATALOGS, ROUTE_SHOP_SLIDER, ROUTE_ADMIN_USERS, ROUTE_ADMIN_PRODUCTS, ROUTE_ADMIN_SHOPS, ROUTE_SHOP_PROPERTIES, ROUTE_ADMIN_IMAGES } from "../../utils/routes";
import { useHistory } from "react-router";
import { UNAVAILABLE_IMAGE } from "../../utils/constants";
import "./MyShopContent.css";
import { SHOP_PLAN_ONE, SHOP_PLAN_THREE, SHOP_PLAN_TWO } from "./MyShopContent";
import MyPlan from "./MyPlan";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import { authSelector } from "../../store/AuthSlice";
import { userSelector } from "../../store/UserSlice";
import MyPlanCard from "./MyPlanCard";

const MyShopContentCards: React.FC = () => {
	const history = useHistory();
	const { theShop } = useSelector(shopSelector);
	const {actualUser} = useSelector(userSelector)
	const { loading } = useSelector(flagSelector);
	const [toastMessage, setToastMessage] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [showModal2, setShowModal2] = useState(false);
	const dispatch = useDispatch();

	const catType = useContext(CatalogTypeContext);

	const shopLogo = () => {
		if (theShop.logo) {
			return <IonImg className="logoShop" src={theShop.logo[0]} />;
		} else {
			return <IonImg className="logoShop" src={UNAVAILABLE_IMAGE} />;
		}
	};
	const [popoverState, setShowPopover] = useState({
		showPopover: false,
		event: undefined,
	});

	<IonPopover
		cssClass="my-custom-class"
		event={popoverState.event}
		isOpen={popoverState.showPopover}
		onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
	>
		<p>This is popover content</p>
	</IonPopover>;

	return (
		<>
			<IonModal isOpen={showModal} cssClass="my-custom-class">
				<IonContent>
					<IonGrid fixed>
						<IonRow>
							<IonCol>
								<MyPlan  />
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>

				<IonButton onClick={() => setShowModal(false)}>Cancelar</IonButton>
			</IonModal>

			<IonModal isOpen={showModal2} cssClass="my-custom-class">
				<IonContent>
					<IonGrid fixed>
						<IonRow>
							<IonCol>
			<MyPlanCard  />
			</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>

				<IonButton onClick={() => setShowModal2(false)}>Cerrar</IonButton>
			</IonModal>
			<IonHeader mode="md">
				<IonToolbar className={theShop.plan}>
					<IonGrid fixed>
						<IonRow>
							<IonCol>
								<IonButton fill="clear" onClick={()=> setShowModal2(true)} >
									<IonIcon color={theShop.plan === SHOP_PLAN_THREE ? "primary" : "light"} slot="start" icon={storefront} />
									<IonTitle color={theShop.plan === SHOP_PLAN_THREE ? "primary" : "light"}>
										Cuenta
										{theShop.plan === SHOP_PLAN_THREE ? " Premium" : ""}
										{theShop.plan === SHOP_PLAN_TWO ? " Clásica" : ""}
										{theShop.plan === SHOP_PLAN_ONE ? " Gratuita" : ""}
									</IonTitle>
								</IonButton>
							</IonCol>
							<IonCol className="ion-text-end">
								<IonButton
									fill="clear"
									onClick={(e: any) => {
										e.persist();
										// setShowPopover({ showPopover: true, event: e });
										setShowModal(true)
									}}
								><IonText color={theShop.plan === SHOP_PLAN_THREE ? "primary" : "light"}>Cambiar plan</IonText>
									{/* <IonIcon  slot="start" icon={ellipsisVertical} /> */}
								</IonButton>
								<IonPopover
									event={popoverState.event}
									isOpen={popoverState.showPopover}
									onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
								>
									<IonRow>
										<IonCol>
											<IonItem button mode="ios" onClick={() => setShowModal(true)}>
											Cambiar Plan
											</IonItem>
										</IonCol>
									</IonRow>
								</IonPopover>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonToolbar>
			</IonHeader>
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<IonGrid fixed>
				<IonRow className="ion-margin-top">
					<IonCol>
						<IonTitle className="sign" color="primary">
							MI TIENDA
						</IonTitle>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
						<IonCard>
							<IonGrid>
								<IonRow>
									<IonCol sizeXs="12" sizeSm="12" sizeMd="2">
										{shopLogo()}
									</IonCol>
									<IonCol sizeXs="12" sizeSm="12" sizeMd="10">
										<IonItem lines="none">
											<IonGrid>
												<IonRow>
													<IonCol>
														<IonTitle color="primary">
															<h1>{theShop.name}</h1>
														</IonTitle>
													</IonCol>
													<IonCol className="ion-text-end">
														<IonButton color="tertiary" fill="outline" routerLink={ROUTE_SHOP_SLIDER} 
														  >
															<IonIcon icon={albumsOutline} slot="start" />
															<IonText className="ion-hide-sm-down"> Mi carrusel</IonText>
														</IonButton>
														<IonButton color="tertiary" fill="outline" routerLink={ROUTE_SHOP_EDIT}>
															<IonIcon icon={pencil} slot="start" />
															<IonText className="ion-hide-sm-down"> Editar</IonText>
														</IonButton>
													</IonCol>
												</IonRow>
												<IonRow>
													<IonCol sizeXs="12">
														<IonText color="primary" className="description">
															{theShop.description}
														</IonText>
													</IonCol>
												</IonRow>
											</IonGrid>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>
							<IonGrid>
								<IonRow>
									<IonCol sizeXl="2" sizeLg="2" sizeMd="2" sizeSm="12">
										<MyShopStatus />
									</IonCol>
									<IonCol>
										<IonItem lines="none">
											<IonButton fill="clear" onClick={() => {
													window.open("https://wa.me/?text=Esta%20es%20mi%20tienda%20en%20Novocorpo:%20https://www.novocorpo.com.mx/shops/"+ theShop.shopID);
												}}>
											<IonIcon className="ion-margin-end" color="tertiary" icon={shareSocial} /></IonButton>
											<IonLabel>{theShop.shopID}</IonLabel>
											
											<IonButton
												fill="clear"
												onClick={() => {
													navigator.clipboard.writeText("https://www.novocorpo.com.mx/shops/" + theShop.shopID);
													setToastMessage("URL copiada");
												}}
											>
												<IonIcon color="tertiary" slot="icon-only" icon={copyOutline} />
											</IonButton>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonCard>
					</IonCol>
				</IonRow>
				<IonRow>
					
					{(theShop.status === "ACTIVO" || theShop.status === "PAGO PENDIENTE") && (
						<IonGrid>
							<IonRow>
							{(theShop.shopID && theShop.shopID === "novocorpo") && (
							<IonCol>
									<IonCard routerLink={ROUTE_SHOP_PROPERTIES} className="ion-padding ion-activated">
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={home} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Mis Inmuebles</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>)}
								<IonCol>
									<IonCard routerLink="/mis-productos" className="ion-padding ion-activated">
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={pricetags} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Mis Productos</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard
										className="ion-text-center ion-padding hoverLink"
										onClick={() => {
											catType.setCatalogType("listado");
											history.push(ROUTE_SHOP_CATALOGS);
										}}
									>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={layers} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Mis Colecciones</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
								{( (theShop.shopID && theShop.shopID === "novocorpo")) && (
									<IonCol>
										<IonCard
											className="ion-text-center ion-padding hoverLink"
											onClick={() => {
												catType.setCatalogType("catalogo");
												history.push(ROUTE_SHOP_CATALOGS);
											}}
										>
											<IonRow>
												<IonCol className="ion-text-center">
													<IonIcon size="large" color="tertiary" icon={layers} />
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol className="ion-text-center">
													<IonLabel>Mis Catálogos</IonLabel>
												</IonCol>
											</IonRow>
										</IonCard>
									</IonCol>
								)}
								<IonCol>
									<IonCard className="ion-text-center ion-padding" routerLink="/mis-ordenes">
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={cash} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Órdenes de Compra</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
							</IonRow>
						</IonGrid>
					)}
				</IonRow>
				{theShop.shopID === "novocorpo" && (
					<IonCard>
						<IonCardHeader ><IonText color='primary'><h3>Sección administrativa de clientes </h3></IonText></IonCardHeader>
						<IonGrid>
							<IonRow>
								<IonCol>
									<IonCard className="ion-text-center ion-padding" routerLink={ROUTE_ADMIN_SHOPS}>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={storefrontOutline} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Tiendas</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard className="ion-text-center ion-padding" routerLink={ROUTE_ADMIN_PRODUCTS}>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={pricetagsOutline} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Productos</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard className="ion-text-center ion-padding" routerLink={ROUTE_ADMIN_IMAGES}>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={imagesOutline} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Imágenes</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
								<IonCol>
									<IonCard className="ion-text-center ion-padding" routerLink={ROUTE_ADMIN_USERS}>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonIcon size="large" color="tertiary" icon={walletOutline} />
											</IonCol>
										</IonRow>
										<IonRow>
											<IonCol className="ion-text-center">
												<IonLabel>Monederos</IonLabel>
											</IonCol>
										</IonRow>
									</IonCard>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonCard>
				)}
			</IonGrid>
		</>
	);
};

export default MyShopContentCards;
