import {
  IonHeader,
  IonContent,
  IonPage,
  IonImg,
  IonIcon,
  IonCol,
  IonRow,
  IonGrid,
  IonButton,
  IonText,
  IonSlides,
  IonSlide,
  IonTitle,
  IonModal,
  IonBadge,
  IonLabel,
  IonChip,
  IonItem,
  IonCard,
  IonFabButton,
  IonFab,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { catalogSelector, setActualCatalog } from "../../store/CatalogSlice";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import Toolbar from "../global/general/Toolbar";
import ProductsView from "../products/ProductsView";
import { UNAVAILABLE_IMAGE } from "../../utils/constants";
import { shopSelector } from "../../store/ShopSlice";
import FooterComponent from "../global/general/FooterComponent";
import "./HomeContent.css";
import { ROUTE_CATALOG, ROUTE_CATALOGS } from "../../utils/routes";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { propertySelector, setActualProperty } from "../../store/PropertySlice";
import { closeCircleOutline } from "ionicons/icons";
import {
  CategoryObject,
  categorySelector,
  setActualCategory,
} from "../../store/CategorySlice";
import { novocorpoSelector } from "../../store/NovocorpoSlice";
import { catalogPageSelector } from "../../store/CatalogPageSlice";

const HomeContent: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { category } = useSelector(categorySelector);
  const { shopsPremium } = useSelector(shopSelector);
  const { theNovocorpo } = useSelector(novocorpoSelector);
  const [showModal, setShowModal] = useState(false);
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);

  const slideOpts = { spaceBetween: 40, autoplay: { delay: 3000 } };
  const numberSlidesPremium = shopsPremium.length > 5 ? 5 : shopsPremium.length;
  const slidePremium = {
    slidesPerView: numberSlidesPremium,
    autoplay: { delay: 3000 },
    breakpoints: {
      // when window width is >= 320px
      320: { slidesPerView: 2, spaceBetween: 20 },
      // when window width is >= 480px
      480: { slidesPerView: 3, spaceBetween: 30 },
      // when window width is >= 640px
      640: { slidesPerView: 4, spaceBetween: 40 },
    },
  };

  const { catalogs } = useSelector(catalogSelector);
  const { actualCatalogPage } = useSelector(catalogPageSelector);
  const { properties, actualProperty } = useSelector(propertySelector);
  const catType = useContext(CatalogTypeContext);
  const coleccionesArray = catalogs.filter((l) => l.type === "listado");
  const catalogosArray = catalogs.filter((l) => l.type === "catalogo");

  const numberSlidesProperties = properties.length > 5 ? 5 : properties.length;
  const slideProperty = {
    slidesPerView: numberSlidesProperties,
    autoplay: { delay: 3000 },
    breakpoints: {
      320: { slidesPerView: 2, spaceBetween: 20 },
      480: { slidesPerView: 3, spaceBetween: 30 },
      640: { slidesPerView: 4, spaceBetween: 40 },
    },
  };
  const numberSlidesCatalog =
    catalogosArray.length > 5 ? 5 : catalogosArray.length;
  const slideCatalog = {
    slidesPerView: numberSlidesCatalog,
    autoplay: { delay: 3000 },
    breakpoints: {
      320: { slidesPerView: 2, spaceBetween: 20 },
      480: { slidesPerView: 3, spaceBetween: 30 },
      640: { slidesPerView: 4, spaceBetween: 40 },
    },
  };
  const numberSlidesColecciones =
    coleccionesArray.length > 5 ? 5 : coleccionesArray.length;
  const slideColecciones = {
    slidesPerView: numberSlidesColecciones,
    autoplay: { delay: 3000 },
    breakpoints: {
      320: { slidesPerView: 2, spaceBetween: 20 },
      480: { slidesPerView: 3, spaceBetween: 30 },
      640: { slidesPerView: 4, spaceBetween: 40 },
    },
  };

  let catCopy = [...category];
  let catOrdered = catCopy.sort((a, b) =>
    a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  );

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent className="page-content">
        <IonFab vertical="center" horizontal="end" slot="fixed">
          <IonButton
            onClick={() => {
              history.push("/signup");
            }}
            color="tertiary"
          >
            <b>
              {/* <small className="ion-text-center"> */}
                ¡Crea tu cuenta!
                {/* </small> */}
            </b>
          </IonButton>
        </IonFab>
        <IonRow className="sliderContainer ion-margin-bottom">
          <IonCol className="ion-no-padding ion-no-margin">
            <IonSlides
              className="slide-style ion-no-margin ion-no-padding"
              id="slides"
              pager={true}
              options={slideOpts}
            >
              <IonSlide
                key="1"
                className="slideItem"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <IonImg src={theNovocorpo.slider![0]} />
              </IonSlide>
              <IonSlide
                key="2"
                className="slideItem"
                onClick={(e) => {
                  e.preventDefault();
                  // window.open("https://suarezgamboaalma.mitiendanikken.com/");
                }}
              >
                <IonImg src={theNovocorpo.slider![1]} />
              </IonSlide>
              <IonSlide
                key="3"
                className="slideItem"
                onClick={(e) => {
                  e.preventDefault();
                  // window.open("https://www.just.com.mx/SUGARALMASUAREZ");
                }}
              >
                <IonImg src="https://novocorpodiseno.s3.amazonaws.com/slides/swissjust.jpg" />
              </IonSlide>
            </IonSlides>
          </IonCol>
        </IonRow>

        <IonGrid fixed>
          <IonRow className="ion-text-center ion-margin-top" id="promote">
            <IonCol className="ion-margin">
              <IonTitle className="title ion-text-center" color="primary">
                ¿Quieres promocionarte con nosotros?
              </IonTitle>
              <IonButton
                size="large"
                color="tertiary"
                className="ion-margin-top"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                ¡Crea tu cuenta!
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid fixed className="renglon-end">
          <IonRow className="renglon-start  ion-padding">
            <IonCol>
              <IonTitle className="title ion-text-center" color="primary">
                Nuestros catálogos
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding ion-no-margin">
              <IonSlides
                className="slide-style ion-no-margin ion-no-padding"
                id="slideCatalog"
                pager={true}
                options={slideCatalog}
              >
                {catalogosArray
                  .filter((cat) => cat.coverStatus == "ACTIVO")
                  .map((catalog: any, index) => (
                    <IonSlide
                      max-height="240"
                      max-width="345"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setActualCatalog(catalog));
                        history.push("/catalogo/" + catalog.id);
                      }}
                    >
                      <IonImg className="catalogImg" src={catalog.cover} />
                    </IonSlide>
                  ))}
              </IonSlides>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                className="ion-text-center ion-padding"
                onClick={() => {
                  catType.setCatalogType("catalogo");
                  history.push(ROUTE_CATALOGS);
                }}
              >
                VER MÁS
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed>
          <IonRow className="renglon-start ion-padding">
            <IonCol>
              <IonTitle className="title ion-text-center" color="primary">
                Productos Destacados
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <ProductsView />
            </IonCol>
          </IonRow>

          <IonGrid fixed className="renglon-end">
            <IonRow className="renglon-start  ion-padding">
              <IonCol>
                <IonTitle className="title ion-text-center" color="primary">
                  Venta y renta de inmuebles
                </IonTitle>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-no-padding ion-no-margin">
                <IonSlides
                  className="slide-style ion-no-margin ion-no-padding"
                  id="slideCatalog"
                  pager={true}
                  options={slideCatalog}
                >
                  {properties.map((property: any, index) => (
                    <IonSlide
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setActualProperty(property));
                        setShowModal(true);
                      }}
                    >
                      <IonImg
                        className="catalogImg"
                        src={property.pictures[0]}
                      />
                    </IonSlide>
                  ))}
                </IonSlides>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonModal isOpen={showModal}>
            <IonRow>
              <IonCol className="ion-text-right">
                <IonButton onClick={() => setShowModal(false)} fill="clear">
                  <IonIcon icon={closeCircleOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonGrid fixed className="modalProduct">
              <IonRow>
                <IonCol
                  size="6"
                  offset-xs="3"
                  offset-sm="3"
                  offset-md="0"
                  className="ion-margin-top ion-text-center"
                >
                  <div className="">
                    <IonImg
                      className="product-img"
                      src={actualProperty.pictures[0]}
                    ></IonImg>
                  </div>
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-md="6">
                  <IonRow>
                    <IonCol>
                      <IonRow>
                        <IonCol>
                          <IonText color="primary">
                            <h1 className="ion-no-margin">
                              {actualProperty.name}
                            </h1>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonGrid>
                        <IonBadge color="success">
                          <small>
                            {actualProperty.typeOfOperation.toUpperCase()}
                          </small>
                        </IonBadge>
                      </IonGrid>
                      <IonRow>
                        <IonCol className="ion-text-right">
                          <IonLabel color="tertiary">
                            <b>{numberFormat.format(actualProperty.price)}</b>
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                      <IonGrid>
                        <IonRow>
                          <IonText className="modalText ion-margin">
                            <small>
                              <span className="category">
                                <IonText color="primary">
                                  {actualProperty.floors > 1
                                    ? actualProperty.floors + " plantas"
                                    : actualProperty.floors + " planta"}
                                </IonText>
                              </span>
                              <span className="category">
                                <IonText color="primary">
                                  {actualProperty.bedrooms > 1
                                    ? actualProperty.bedrooms + " recámaras"
                                    : actualProperty.bedrooms + " recámara"}
                                </IonText>
                              </span>
                              <span className="category">
                                <IonText color="primary">
                                  {actualProperty.bathrooms > 1
                                    ? actualProperty.bathrooms + " baños"
                                    : actualProperty.bathrooms + " baño"}
                                </IonText>
                              </span>
                            </small>
                          </IonText>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <IonText className="modalText">
                              <small>
                                <>
                                  <p>Ubicación: {actualProperty.location}</p>
                                  {`${actualProperty.description.slice(
                                    0,
                                    100
                                  )}`}
                                </>
                              </small>
                            </IonText>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonRow className="ion-text-center">
                        <IonCol>
                          <IonButton
                            expand="block"
                            fill="outline"
                            color="primary"
                            size="default"
                            onClick={() => {
                              setShowModal(false);
                              history.push("/inmueble/" + actualProperty.id);
                            }}
                          >
                            Ver más
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonModal>

          <IonRow className="renglon-start ion-text-center">
            <IonCol>
              <IonText className="title ion-text-center" color="primary">
                ¿Aún no encuentras lo que buscas?
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-center ion-margin-bottom">
            <IonCol>
              <IonText>Revisa nuestras diferentes categorías</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              {category &&
                catOrdered.map((cat: CategoryObject, index) => (
                  <IonChip
                    key={index}
                    onClick={(e) => {
                      e.preventDefault();
                      setActualCategory(cat);
                      history.push("/productos/" + cat.id);
                    }}
                    color="tertiary"
                  >
                    {cat.name}
                  </IonChip>
                ))}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed className="renglon-end">
          <IonRow className="renglon-start  ion-padding">
            <IonCol>
              <IonTitle className="title ion-text-center" color="primary">
                Nuestras Tiendas Premium
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding ion-no-margin">
              <IonSlides
                className="slide-style ion-no-margin ion-no-padding"
                id="slidesPremium"
                pager={true}
                options={slidePremium}
              >
                {shopsPremium.map((shop: any) => (
                  <IonSlide
                    className="slidePremium"
                    key={shop.shopID}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/shops/${shop.shopID}`);
                    }}
                  >
                    {shop.logo === null ? (
                      <IonImg src={UNAVAILABLE_IMAGE} />
                    ) : (
                      <IonImg src={shop.logo}></IonImg>
                    )}
                    <p>{shop.name}</p>
                  </IonSlide>
                ))}
              </IonSlides>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid fixed className="renglon-end">
          <IonRow className="renglon-start  ion-padding">
            <IonCol>
              <IonTitle className="title ion-text-center" color="primary">
                Nuestras colecciones
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-no-padding ion-no-margin">
              <IonSlides
                className="slide-style ion-no-margin ion-no-padding"
                id="slideCatalog"
                pager={true}
                options={slideColecciones}
              >
                {coleccionesArray
                  .filter((cat) => cat.coverStatus == "ACTIVO")
                  .map((catalog: any, index) => (
                    <IonSlide
                      max-height="240"
                      max-width="345"
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setActualCatalog(catalog));
                        history.push("/catalogo/" + catalog.id);
                      }}
                    >
                      <IonImg className="catalogImg" src={catalog.cover} />
                    </IonSlide>
                  ))}
              </IonSlides>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton
                className="ion-text-center ion-padding"
                color="tertiary"
                onClick={() => {
                  catType.setCatalogType("listado");
                  history.push(ROUTE_CATALOGS);
                }}
              >
                VER MÁS
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterComponent />
      </IonContent>
    </IonPage>
  );
};

export default HomeContent;
