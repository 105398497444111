import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { Dispatch, SetStateAction } from "react";
import { SHOP_PLAN } from "../../pages/MyShop";

const MyShopIntro: React.FC<{ myShop: Dispatch<SetStateAction<string>> }> = (
  props
) => { 
  return (
    <IonGrid fixed>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonText color="primary"><p>Paso 1</p></IonText>
          <IonText color="primary"><h1>Crear mi Tienda</h1></IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText className="ion-text-center" color="secondary">
            <h2>Reglamento de publicación</h2>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonText color="tertiary"> 
            <h6><strong>Se denomina usuario al usuario vendedor o
            publicante que utilizará el servicio de Novocorpo®</strong></h6>
            <h6><strong>Se denomina artículo al bien, servicio, ó producto ofrecido por el usuario</strong></h6>
            </IonText>
            <IonText>
            <p >Está prohibido publicar cualquier artículo incluido en catálogos de
            cambaceo, pirámide, red, árbol, desendencia ; es decir, catálogos
            físicos y electrónicos y sus productos comerciales conocidos, tales
            como: Avon®, Betterware®, Just®, Melaleuca®, Nikken®, Stanhome®,
            Tupperware®, Vianney®, etc</p>
            <p>Están prohibidas las comparaciones con
            otros usuarios.</p>
            <p>Las descripciones pueden incluir gráficos, textos,
            descripciones y fotos de los bienes ofrecidos, entendiéndose que el
            usuario cuenta con la autorización del fabricante del artículo que
            ofrece, para el uso de la marca, imágenes, videos, texto, y
            cualquier otro derecho del fabricante ó dueño de la marca.<br/>
            </p>
            <p>El
            artículo ofrecido por el usuario debe ser exactamente descrito en
            cuanto al estado, condición, tamaño, marca, modelo, color, material
            y demás características relevantes. Toda palabra utilizada en las
            descripciones y títulos debe estar relacionada en forma específica
            con el artículo ofrecido. Así como las fotografías, deben de ser
            específicamente del artículo ofrecido, no algo similar ni parecido,
            si no, el mísmo exactamente. Se prohíbe la utilización de palabras
            que no tengan relación con el artículo para manipular los buscadores
            de Novocorpo® y/o para generar tráfico hacia dicha publicación. Las
            publicaciones que demuestren una intención de evadir o eludir
            impuestos de alguna forma serán terminadas; cualquier reporte o
            intento de evasión fiscal será sancionada con la baja permanente de
            nuestro sitio, y la recisión automática de cualquier contrato o
            derecho adquirido con Novocorpo®, los pagos no serán reembolsable en
            estas situaciones y las publicaciones serán dadas de baja, ya que
            implica violaciones a la ley por lo que no habrá reembolso en caso
            de faltas al reglamento y a las leyes vigentes. Novocorpo® solamente
            pone a disposición del usuario un espacio virtual que les permite
            comunicarse mediante Internet para encontrar una forma de vender o
            comprar algún artículo, además no tiene participación alguna en el
            proceso de negociación y perfeccionamiento del contrato definitivo
            entre el usuario y el comprador. Por ello, son las partes las
            obligadas a cumplir con las obligaciones fiscales e impositivas
            impuestas por la ley. Se establece que el precio del artículo
            publicado deberá ser expresado con comprobante fiscal incluido
            cuando corresponda la aplicación del mismo, y en moneda de curso
            legal. Novocorpo® podrá remover cualquier publicación cuyo precio no
            sea expresado de esta forma, para evitar confusiones o malos
            entendidos en cuanto al precio final del artículo. Inclusión de
            datos personales Se establece que está prohibida la inserción de
            información personal o cualquier forma de contacto (salvo lo
            estipulado específicamente para las categorías: Otros, Inmuebles y
            Propiedades, Servicios y Portal de su tienda) tanto en la
            descripción, imágenes, fotografías, preguntas y respuestas sobre el
            artículo, así como en el seudónimo. Este tipo de actividades serán
            investigadas por Novocorpo® y el infractor podrá ser sancionado con
            la suspensión o cancelación de la operación e incluso de su
            inscripción como usuario de Novocorpo®. Se considera información de
            contacto la que, entre otros, incluye: dirección de e-mail,
            dirección geográfica, código postal, número(s) de teléfono, y/o fax,
            redes sociales, datos bancarios, así como URL o sitios web. Precio
            distinto al encabezado: Está prohibida la publicación de algún
            artículo con un precio distinto al expresado en la sección de
            precios del encabezado. Si hubiera cantidades mínimas de venta del
            artículo, el precio del encabezado deberá indicar ese costo (por
            ejemplo: "vendo botella de vino a $50, compra mínima 6 botellas", en
            ese caso, la publicación deberá tener un precio de $300). Precio
            Mínimo: No está permitida y será finalizada aquella publicación
            donde se establezca en la descripción un precio mínimo de venta.
            Venta por unidades de medida: El artículo publicado con un precio
            fijado según una unidad de medida como peso, volumen o longitud
            serán finalizados cuando pueda inferirse que existe en ello
            intencionalidad de evitar pagar las tarifas de envío. 
            </p>
          </IonText>
          <IonText color="tertiary">
            <h6><strong>Lenguaje vulgar</strong></h6>
          </IonText>
          <IonText>
            <p>No está permitido el uso de lenguaje blasfemo o vulgar. Éste
            incluye también el lenguaje de naturaleza racista, detestable,
            sexual u obsceno en un área pública. La aplicación de esta política
            se extiende a las publicaciones, preguntas y/o respuestas. Algunos
            artículos publicados en Novocorpo® pueden contener palabras
            irreverentes si las mismas forman parte de su título. Por ejemplo,
            hay canciones en algunos CD de música cuyos títulos contienen ese
            tipo de lenguaje. </p>
          </IonText>
          <IonText color="tertiary">
            <h6><strong>Links o enlaces en la publicación</strong></h6>
          </IonText>
          <IonText>
            <p>La descripción del artículo publicado solamente puede ser utilizada para explicar,
            promover, ofrecer y facilitar la publicación del artículo. Se
            prohíbe incluir vínculos a sitios web destinados al comercio, la
            publicación o la adquisición de bienes o servicios, o vínculos cuyo
            propósito no sea uno de los descritos a continuación. Entre los
            vínculos permitidos en la descripción del artículo, se incluyen:
            -Enlace a una página que provee información adicional o de forma más
            detallada del artículo en Novocorpo®, siempre que tal descripción no
            facilite ninguna clase de información de contacto. -Enlaces con
            información adicional sobre políticas de Novocorpo® y entrega del
            artículo ofrecido. -Enlaces a fotografías del artículo en
            Novocorpo®. Entre los vínculos prohibidos se incluyen los siguientes
            (aunque sin limitarse a ellos). -Enlaces a páginas Web destinadas al
            comercio, o la adquisición de bienes o servicios fuera de
            Novocorpo®. -Enlaces a sitios o páginas Web que ofrecen mercancía
            que no está permitida en Novocorpo® o por la ley vigente. -Enlaces a
            comunidades virtuales, como foros o blogs, que puedan proveer vías
            de contacto entre los usuarios fuera de Novocorpo®. -Enlaces a
            sitios que solicitan el seudónimo o la clave de los compradores.
            -Enlaces que puedan infringir las leyes de propiedad intelectual.
            -Enlaces a sitios que compitan de cualquier forma con la actividad o
            los servicios de Novocorpo®. -Enlaces entre artículos publicados en
            las categorías otros, Inmuebles y Propiedades y Servicios entre sí,
            con las demás categorías y a la inversa. -Ofertas con opción
            selectiva Las ofertas con opción selectiva son aquellas en la que el
            usuario enumera algún artículo a diferentes precios (esté este
            publicado o no) para que los compradores escojan entre esa selección
            del artículo. Este tipo de publicaciones puede ser problemática
            debido a su potencial de elusión de pagos, operaciones fuera de
            Novocorpo® y otros problemas. En general, las ofertas con opción
            selectiva no se permiten, y serán canceladas con la excepción (a
            criterio exclusivo de Novocorpo®) de los casos donde: -Los artículos
            enumerados estén todos publicados en Novocorpo® y enlazados entre
            si. -Los artículos enumerados tengan el mismo precio, y la cantidad
            disponible sea igual o mayor que la cantidad ofertada en la
            publicación. -Publicación en la categoría apropiada. -El usuario
            deberá publicar solamente el artículo que desea vender en la
            categoría y subcategoría apropiadas según la clase y tipo de
            artículo. -Se deja expresamente aclarado que el artículo que
            pertenece a la categoría Adultos y que se encuentre publicados en
            otra categoría será removido. -La publicación de cualquier tipo de
            artículo debe efectuarse exclusivamente en la categoría servicios.
            De lo contrario, serán removidos. </p>
          </IonText>
          <IonText color="tertiary">
            <h6><strong>Fotos en Página Principal</strong></h6>
          </IonText>
          <IonText>
            <p>No se permitirá en la página principal, aquellas publicaciones que
            contengan imágenes (que a criterio de Novocorpo® ) sean provocativas
            o sensuales o que puedan ser consideradas contrarias a la moral o
            buenas costumbres. Las fotos o imágenes que aparezcan en la página
            principal de Novocorpo® deberán: -Corresponder exactamente al
            artículo que se ofrece en la publicación y no colocar fotografías
            meramente referenciales o que puedan inducir a error o confusión al
            destinatario de la oferta. -Mostrar solamente un artículo. -Ser de
            alta resolución. -Poseer fondo de color blanco. -No tener datos
            personales o de contacto. -Cumplir con cualquier otra disposición
            prevista en los Términos y Condiciones del sitio, así como cualquier
            otra política. -Sin perjuicio de ello, Novocorpo® se reserva el
            derecho de baja de cualquier publicación o imagen en la que
            considere que hay alguna infracción a las reglas establecidas, a la
            ley o que considere inapropiada por alguna razón a su entera
            discreción. Las infracciones de estas normas podrán resultar en la
            suspensión o inhabilitación de la cuenta del usuario o en la
            adopción de cualquiera de las medidas previstas en los Términos y
            Condiciones del sitio, así como las demás políticas.</p> 
          </IonText>
          <IonText color="tertiary">
            <h6><strong>Publicaciones en página principal</strong></h6>
          </IonText>
          <IonText>
            <p>No se permitirá en la página principal, la
            publicación de productos que correspondan a la categoría Adultos,
            Manuales, dietas, complejos vitamínicos o programas alimenticios o
            de crecimiento o relacionados con el esoterismo. Sin perjuicio de
            ello, Novocorpo® se reserva el derecho de baja cualquier publicación
            en la que considere que hay alguna infracción a las reglas
            establecidas, a la ley o que considere inapropiada por alguna razón
            a su entera discreción. Las infracciones de estas normas podrán
            resultar en la suspensión o inhabilitación de la cuenta del usuario
            o en la adopción de cualquiera de las medidas previstas en los
            Términos y Condiciones del sitio, así como las demás políticas.
            Información personal en categorías especiales Dentro de las
            categorías Inmuebles y Propiedades y Servicios se permite la
            inclusión del teléfono del usuario en la publicación. En la
            categoría Servicios se deberá indicar la dirección de correo
            electrónico y el teléfono del usuario y se podrá también incluir el
            domicilio y la URL de su Sitio Web. El usuario sólo podrá ingresar
            los datos antes indicados, según corresponda, dentro del campo
            específico al momento de publicar el artículo. Sin embargo, en
            ningún caso deberá incluirlo en la descripción o en el título de su
            publicación, ni en ningún otro lugar fuera del campo específico.
            Tampoco podrá el usuario incluir ningún otro dato personal dentro o
            fuera del campo indicado. Novocorpo® no se hace responsable del uso
            que el usuario, el comprador u otra persona pudieran hacer de la
            información publicada. Está prohibido el uso de cualquier mecanismo
            o técnica destinado a evitar el pago de las tarifas o a disminuir el
            monto de las mismas, como por ejemplo (y sin limitarse a estos
            supuestos): -Ofrecer en la descripción o preguntas y respuestas
            concluir una operación sin realizar la oferta a través de Novocorpo®
            o por fuera de la plataforma. -Ofrecer un artículo a un precio
            distinto al expresado en la descripción o en preguntas y respuestas.
            -Artículos publicados a precios bajos pero con un alto costo de
            envío. -Incluir datos personales en las descripciones, títulos o
            respuestas, salvo en las excepciones previstas. -Ventas de
            catálogos, colecciones o manuales, mediante el cual el vendedor ofrece
            el artículo a un precio bajo o gratuito para obtener los datos del
            comprador, salvo en aquellos que Novocorpo® expresamente lo
            autorice. -Ofrecer y/o informar en una misma publicación, otro
            artículo a un precio inferior o diferente, con excepción de aquel
            artículo que esté efectivamente publicado en Novocorpo® y enlazados
            entre sí. -Ofrecer un artículo en forma gratuita o a un valor
            simbólico o precio significativamente menor al valor de mercado,
            cuando de ello se pueda inferir que hay intención de vender otra
            cantidad, o a otro precio al establecido o bien para evitar pagar
            comisiones. -Publicación de bienes o servicios destinada a recibir
            ofertas intencionadas para otro artículo de precio superior que
            también es ofrecido por el mismo usuario. -Poner a la venta un
            artículo que requiera u ofrezca una compra adicional. -Dar a
            entender expresa o implícitamente en la descripción o título de un
            artículo publicado, que el usuario tiene un artículo listado u
            ofrecido en las categorías: Inmuebles y propiedades. -Las
            actividades que eludan o intenten eludir las tarifas de Novocorpo®
            no están permitidas y se finalizarán y el infractor podrá ser
            sancionado con la suspensión o cancelación de la operación e incluso
            de su inscripción como usuario de Novocorpo®. -Novocorpo®, en los
            casos que la conducta de los usuario así lo requiera, adoptará las
            medidas o sanciones establecidas en los Términos y Condiciones
            Generales.</p>
          </IonText>
        </IonCol>
      </IonRow>
      <IonRow className="ion-text-center ion-margin-top">
        <IonCol>
          <IonButton
            color="tertiary"
            onClick={() => {
              props.myShop(SHOP_PLAN);
            }}
          >
            Acepto el Reglamento de publicación
          </IonButton>
        </IonCol>
      </IonRow>
      <br/><br/>
    </IonGrid>
  );
};

export default MyShopIntro;
