import {
  IonBadge,
  IonButton,
  IonIcon,
  IonItem,
  useIonPopover,
} from "@ionic/react";
import { helpCircleOutline } from "ionicons/icons";
import React from "react";
import { useSelector } from "react-redux";
import { shopSelector } from "../../store/ShopSlice";
import HelpPopover from "../global/general/HelpPopover";

const MyShopStatus: React.FC = () => {
  const { theShop } = useSelector(shopSelector);
  const statusText =
    "Novocorpo está revisando los datos que ingresaste. Pronto se actualizará el estatus de tu tienda.";
  const [present] = useIonPopover(HelpPopover, {
    text: statusText,
  });
  return (
    <IonItem lines="none">
      {theShop.status === "PENDIENTE DE APROBACIÓN" && (
        <>
          <IonBadge color="tertiary">
            <small className="ion-text-wrap">{theShop.status}</small>
          </IonBadge>
          <IonButton
            fill="clear"
            onClick={(e) =>
              present({
                event: e.nativeEvent,
              })
            }
          >
            <IonIcon slot="icon-only" icon={helpCircleOutline} />
          </IonButton>
        </>
      )}
      {theShop.status === "ACTIVO" && (
        <IonBadge color="success">
          <small>{theShop.status}</small>
        </IonBadge>
      )}
      {theShop.status === "PAGO PENDIENTE" && (
        <IonBadge color="warning">
          <small className="ion-text-wrap">{theShop.status}</small>
        </IonBadge>
      )}
      {theShop.status === "TIENDA BLOQUEADA" && (
        <IonBadge color="danger">
          <small className="ion-text-wrap">{theShop.status}</small>
        </IonBadge>
      )}
    </IonItem>
  );
};

export default MyShopStatus;
