import { BrowserPluginWeb } from "@capacitor/core";
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import {
  eyeOffOutline,
  eyeOutline,
  informationCircleOutline,
} from "ionicons/icons";
import React, { Dispatch, SetStateAction,  useState } from "react";
import "./AmonytField.css";
import AmonytPdfPicker from "./AmonytPdfPicker";
import AmonytPhotoField from "./AmonytPhotoField";
export interface FieldFields {
  name: string;
  label: string;
  type: FieldTypes;
  options?: { label: string; value: string }[];
  multiple?: boolean;
  placeholder: string;
  value: [string, Dispatch<SetStateAction<string>>];
  error: [boolean, Dispatch<SetStateAction<boolean>>];
  errorMessage: [string, Dispatch<SetStateAction<string>>];
  isRequired: boolean;
  validationType: string;
  showPreview?: boolean;
  max?: number;
  unavailableImageURL?: string;
  bucketURL?: string;
  imageArray?: [string[], Dispatch<SetStateAction<string[]>>];
  pdfArray?: [string[], Dispatch<SetStateAction<string[]>>];
  requiredWidth?: number | undefined;
  requiredHeight?: number | undefined;
}
export interface HiddenFields {
  name: string;
  value: string | string[] | undefined;
}
export type TextFieldTypes =
  | "date"
  | "email"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "url"
  | "time"
  | "week"
  | "month"
  | "datetime-local";
export type FieldTypes =
  | "date"
  | "email"
  | "number"
  | "password"
  | "search"
  | "tel"
  | "text"
  | "url"
  | "time"
  | "week"
  | "month"
  | "datetime-local"
  | "select"
  | "searchbarradio"
  | "searchbarcheckbox"
  | "textarea"
  | "images"
  | "pdf";

 let checkList: { name: string; checked: boolean }[] = [];

const AmonytField: React.FC<{
  dataField: FieldFields;
}> = (props) => {
  const [searchText, setSearchText] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [searchText2, setSearchText2] = useState("");
  const [checkedCatArray, setCheckedCatArray] = useState("");
  let categoryArray: { name: string; checked: boolean }[] = [];
  const [checked, setChecked] = useState(false);
 
  const fieldType = () => {
    switch (props.dataField.type) {
      case "select":
        return renderSelect();
      case "searchbarradio":
        return renderSearchBarRadio();
      case "searchbarcheckbox":
        return renderSearchBarCheckbox();
      case "textarea":
        return renderTextArea();
      case "images":
        return renderImagePicker();
      case "pdf":
        return renderPdfPicker();
      case "date":
      case "email":
      case "number":
      case "password":
      case "search":
      case "tel":
      case "url":
      case "time":
      case "week":
      case "month":
      case "datetime-local":
      case "text":
      default:
        return renderText();
    }
  };
  const label = () => (
    <IonLabel color={props.dataField.error[0] ? "danger" : "secondary"}>
      {props.dataField.label}
      {props.dataField.isRequired ? "*" : ""}
    </IonLabel>
  );
  const renderText = () => (
    <IonItem
      className={props.dataField.error[0] ? "error-field-style" : "field-style"}
      lines="full"
      mode="md"
    >
      {props.dataField.type !== "select" && (
        <IonInput
          type={
            props.dataField.type === "password" && showPass
              ? "text"
              : (props.dataField.type as TextFieldTypes)
          }
          value={props.dataField.value[0]}
          onIonChange={(e) =>
            props.dataField.value[1](e.detail.value as string)
          }
          placeholder={props.dataField.placeholder}
        />
      )}

      {props.dataField.type === "password" && !showPass && (
        <IonButton
          fill="clear"
          onClick={() => {
            setShowPass(true);
          }}
        >
          <IonIcon slot="icon-only" icon={eyeOutline} />
        </IonButton>
      )}
      {props.dataField.type === "password" && showPass && (
        <IonButton
          fill="clear"
          onClick={() => {
            setShowPass(false);
          }}
        >
          <IonIcon slot="icon-only" icon={eyeOffOutline} />
        </IonButton>
      )}
    </IonItem>
  );
  const renderSearchBarCheckbox = () => {

    let cOptions: any = [];
    const checkHandler = (opc: string, isChecked: boolean, index: number) => {
    checkList[index] = { name: opc, checked: isChecked };
	
    };
    const updateCatArray = () => {
      let checkedCategories = checkList
        .filter((cat: any) => cat.checked === true)
        .map((cat: any) => cat.name);
      setCheckedCatArray(JSON.stringify(checkedCategories));
    };

    if (props.dataField.options && props.dataField.options.length > 0) {
      props.dataField.options.forEach((option) => {
        cOptions.push(option.value);
        categoryArray.push({ name: option.value, checked: false });
      });

      if (props.dataField.name === "category") {
        if (checkedCatArray) {
          props.dataField.value[1](checkedCatArray);
        } 
      }

      return (
        <>
 <IonRow>
            <IonCol>
            <IonText color="tertiary"><small >{`*Puedes utilizar Ctrl+F para buscar categorías rápidamente`}</small></IonText>
            </IonCol></IonRow>
{checkedCatArray && JSON.parse(checkedCatArray).map((opc: string) => <IonChip color="tertiary">{opc}</IonChip> )}
       
          {props.dataField.name === "category" && (
            <IonCard className="selectCard">
              <IonContent>
                <IonList>
                  {cOptions
                    .map((opc: string, index: number) => (
                      <>
                        <IonItem>
                          <IonCheckbox
                            key={opc}
                            value={props.dataField.value[0]}
                            onIonChange={(e) => {
                              checkHandler(opc, e.detail.checked, index);
                              setChecked(e.detail.checked);
                              updateCatArray();
                            }}
                            slot="start"
                          />
                          <IonLabel>{opc}</IonLabel>
                        </IonItem>
                      </>
                   ))}
                </IonList>
              </IonContent>
            </IonCard>
          )}
        </>
      );
    }
  };
  const renderSearchBarRadio = () => {
    if (
      props.dataField.name === "category" ||
      props.dataField.name === "brandName"
    ) {
      let cOptions: any = [];

      if (props.dataField.options && props.dataField.options.length > 0) {
        props.dataField.options.forEach((option) => {
          cOptions.push(option.value);
        });
      }

      return (
        <>
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => setSearchText(e.detail.value!)}
            placeholder={
              "Nombre de la " +
              (props.dataField.name === "category" ? "categoría" : "marca")
            }
            class="search-bar-color"
          />
          <IonCard className="selectCard">
            <IonContent>
              <IonList>
                <IonRadioGroup
                  value={props.dataField.value[0]}
                  onIonChange={(e) =>  props.dataField.value[1](e.detail.value)}
                >
                  {cOptions
                    .filter((o: string) =>
                      o.toLowerCase().includes(searchText.toLowerCase())
                    )
                    .map((opc: string, index: number) => (
                      <>
                        <IonItem className="storeInput">
                          <IonLabel>{opc}</IonLabel>
                          <IonRadio slot="start" key={opc} value={opc} />
                        </IonItem>
                      </>
                    ))}
                </IonRadioGroup>
              </IonList>
            </IonContent>
          </IonCard>
        </>
      );
    }
  };
  const renderSelect = () => {
    let cOptions: any = [];

    if (props.dataField.options && props.dataField.options.length > 0) {
      props.dataField.options.forEach((option) => {
        cOptions.push(
          <IonSelectOption key={option.value} value={option.value}>
            {option.label}
          </IonSelectOption>
        );
      });
    }
    return (
      <IonItem
        className={
          props.dataField.error[0] ? "error-field-style" : "field-style"
        }
        lines="full"
        mode="md"
      >
        <IonSelect
          value={props.dataField.value[0]}
          okText="OK"
          cancelText="Cancelar"
          multiple={props.dataField.multiple || false}
          onIonChange={(e) => props.dataField.value[1](e.detail.value)}
        >
          {cOptions}
        </IonSelect>
      </IonItem>
    );
  };
  const renderTextArea = () => (
    <IonItem
      className={props.dataField.error[0] ? "error-field-style" : "field-style"}
      lines="full"
      mode="md"
    >
      {props.dataField.type !== "select" && (
        <IonTextarea
          value={props.dataField.value[0]}
          onIonChange={(e) =>
            props.dataField.value[1](e.detail.value as string)
          }
          placeholder={props.dataField.placeholder}
          auto-grow={true}
        />
      )}
    </IonItem>
  );
  const renderImagePicker = () => {
    return (
      <AmonytPhotoField
        showPreview={props.dataField.showPreview}
        max={props.dataField.max}
        unavailableImageURL={props.dataField.unavailableImageURL}
        bucketURL={props.dataField.bucketURL}
        imageArray={props.dataField.imageArray![0]}
        value={props.dataField.value}
        requiredWidth={props.dataField.requiredWidth!}
        requiredHeight={props.dataField.requiredHeight!}
      />
    );
  };
  const renderPdfPicker = () => {
    return (
      <AmonytPdfPicker
        showPreview={props.dataField.showPreview}
        max={props.dataField.max}
        bucketURL={props.dataField.bucketURL}
        pdfArray={props.dataField.pdfArray![0]}
        value={props.dataField.value}
      />
    );
  };
  const error = () => {
    if (props.dataField.error[0])
      return (
        <IonRow>
          <IonCol>
            <IonIcon
              className="error-labels"
              icon={informationCircleOutline}
              color="danger"
            />
            <IonLabel color="danger">
              <small>{props.dataField.errorMessage[0]}</small>
            </IonLabel>
          </IonCol>
        </IonRow>
      );
  };

  return (
    <React.Fragment>
      {label()}
      {fieldType()}
      {error()}
    </React.Fragment>
  );
};

export default AmonytField;
