import {
	IonContent,
	IonPage,
	IonHeader,
	IonTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonIcon,
	IonLabel,
	IonText,
	IonInput,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../store/AuthSlice";
import {  userSelector } from "../../store/UserSlice";
import Toolbar from "../global/general/Toolbar";
import { arrowDown, arrowUp, bagHandleOutline, logoUsd, person, storefrontOutline } from "ionicons/icons";
import "./Profile.css";
import { ROUTE_PROFILE_CLIENT, ROUTE_PROFILE_MONEDERO, ROUTE_PROFILE_ORDERS } from "../../utils/routes";
import { MonederoLogObject, monederoLogSelector } from "../../store/MonederoSlice";

const Monedero: React.FC<{}> = () => {
	const { userProfile } = useSelector(authSelector);
	const { actualUser } = useSelector(userSelector);
	const {monederoLog} = useSelector(monederoLogSelector)
	
	let myMonederoLogs: MonederoLogObject[] = [];

	for (let i = 0; i < monederoLog.length; i++) {
		if (monederoLog[i].userID === userProfile.email) {
			myMonederoLogs.push(monederoLog[i]);
		}
	}


	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent 
      // className="container"
      >
				<IonGrid fixed>
					<IonRow>
						<IonCol className="ion-margin">
							<IonTitle color="primary" className="sign">Mi Monedero</IonTitle>
						</IonCol>
					</IonRow>
					<IonRow className="mainProfile">
						<IonCol>
							<IonList className="ionList no-padding">
								<IonItem no-lines className="listItem" href={ROUTE_PROFILE_CLIENT}>
									<IonIcon color="primary" icon={person} slot="start" />
									<IonLabel color="primary">Perfil</IonLabel>
								</IonItem>
								<IonItem no-lines className="listItem" href={ROUTE_PROFILE_ORDERS}>
									<IonIcon color="primary" icon={bagHandleOutline} slot="start"></IonIcon>
									<IonLabel color="primary">Mis Compras</IonLabel>
								</IonItem>
								<IonItem color="tertiary" no-lines className="listItem" href={ROUTE_PROFILE_MONEDERO}>
									<IonIcon icon={logoUsd} slot="start"></IonIcon>
									<IonLabel>Mi Monedero</IonLabel>
								</IonItem>
								<IonItem no-lines className="listItem" href="/mi-tienda">
									<IonIcon color="primary" icon={storefrontOutline} slot="start"></IonIcon>
									<IonLabel color="primary">Mi Tienda</IonLabel>
								</IonItem>
							</IonList>
						</IonCol>
						<IonRow>
                    <IonCol>
                      <IonGrid class="area ion-padding">
                        {(myMonederoLogs.length === 0) && (
                          <IonRow>
                            <IonCol className="ion-text-center ion-padding">
                              <IonLabel color="primary">
                                Monedero Vacío
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        )}
                        {(myMonederoLogs.length !== 0) && (
                          <>
                            <IonRow>
                              <IonCol>
                                <IonTitle color="primary">
                                  <strong>HISTORIAL</strong>
                                </IonTitle>
                                <IonText
                                  className="ion-margin ion-padding-top"
                                  color="tertiary"
                                >
                                  <small>
                                    <i>Información de movimientos</i>
                                  </small>
                                </IonText>
                              </IonCol>
                              <IonCol className="ion-text-right">
                                <IonTitle color="primary">
                                  <small>Total: </small>{" "}
                                  <IonText color="tertiary">
                                    <b>{actualUser.monederoPoints}</b>
                                  </IonText>{" "}
                                  <small>puntos</small>
                                </IonTitle>
                              </IonCol>
                              <div className="lineaTertiary"></div>
                            </IonRow>
                            {monederoLog && monederoLog.map((log) =>
                            <IonRow>
                              <IonCol>
                                <IonItem>
                                  <IonLabel
                                    color="tertiary"
                                    position="floating"
                                  >
                                    {log.points} <small>puntos</small>
                                  </IonLabel>
                                  <IonInput
                                    color={log.type == "sumar" ? "primary":"secondary"}
                                    value={new Date(
                                      log.createdAt
                                    ).toLocaleDateString("es-MX")}
                                  ></IonInput>
                                  <IonIcon
                                    color={log.type == "sumar" ? "primary":"secondary"}
                                    icon={log.type === "sumar" ? arrowUp : arrowDown}
                                    slot="start"
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol className="ion-hide-sm-down">
                                <IonItem>
                                  <IonLabel position="floating">Razón</IonLabel>
                                  <IonInput
                                    color="primary"
                                    value={log.description}
                                  ></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>
                            
                            )}
                          </>
                        )}
                      </IonGrid>
                    </IonCol>
                  </IonRow>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Monedero;
