import {
    IonButton,
    IonButtons,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonSearchbar,
    IonText,
    IonTitle,
  } from "@ionic/react";
  import {
    addOutline,
    arrowBackOutline,
    cloudUploadOutline,
    pricetagOutline,
    trashOutline,
  } from "ionicons/icons";
  import React, { useContext, useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { flagSelector, setWasCreated } from "../../store/FlagSlice";
  import { getProductInfo, ProductObject, productSelector, setActualProduct } from "../../store/ProductSlice";
  import UserFeedback from "../global/general/UserFeedback";
  import { useHistory } from "react-router";
import "./Catalogs.css"
  import {ROUTE_SHOP_CATALOGS_PAGES, ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS } from "../../utils/routes";
  import {
    catalogPageSelector,
    editCatalogPage,
  } from "../../store/CatalogPageSlice";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
  
  export const PRODUCTS_LANDING = "productsLanding";
  export const PRODUCTS_ADD = "productsAdd";
  export const PRODUCTS_EDIT = "productsEdit";
  export let productsArray: ProductObject[] = [];
  
  const MyListadoProductsContent: React.FC = () => {
    const [myProduct, setProduct] = useState(PRODUCTS_LANDING);
    const { wasCreated, loading, wasUpdated } = useSelector(flagSelector);
    const { products } = useSelector(productSelector);
    const [toastMessage, setToastMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const [once, setOnce] = useState(true);
    const { actualProduct } = useSelector(productSelector);
    const { actualCatalogPage } = useSelector(catalogPageSelector);
    const history = useHistory();
    const catType = useContext(CatalogTypeContext);

  
    let check = actualCatalogPage.productsString
      ? JSON.parse(actualCatalogPage.productsString)
      : false;
  
    if (check !== false && once == true) {
      productsArray = check;
      setOnce(false);
      {
      }
    }
  
    const goBackHandler = () => {
      if (myProduct === PRODUCTS_LANDING) {
        history.replace(ROUTE_SHOP_CATALOGS_PAGES);
      } else if (myProduct === PRODUCTS_ADD || myProduct === PRODUCTS_EDIT) {
        setProduct(PRODUCTS_LANDING);
        history.replace(ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS);
      }
    };
  
    const deleteFromList = (selectedProduct: ProductObject) => {
      if (productsArray.includes(selectedProduct)) {
        productsArray = productsArray.filter((prod) => prod !== selectedProduct);
      }
    };
  
   
  
    const addToPage = (selectedProduct: ProductObject) => {
     
      if (!(productsArray.includes(selectedProduct)) && !(actualCatalogPage.productsString?.includes(JSON.stringify(selectedProduct)))) {
         productsArray.push(selectedProduct);
      }
      {
      }
    };
  
    const saveChanges = () => {
      let inputObject = {
        id: actualCatalogPage.id,
        productsString: JSON.stringify(productsArray),
      };
      dispatch(editCatalogPage({ input: inputObject }));
      productsArray = []
    };
  
  
    useEffect(() => {
      if (wasCreated) {
        setToastMessage("¡Producto creado satisfactoriamente!");
        dispatch(setWasCreated(false));
        productsArray.push(actualProduct)
      } 
 
    
    }, [wasCreated, toastMessage, productsArray]);
  
    return (
      <IonPage>
        <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        />
        <IonContent>
          <IonCol sizeXs="1">
            <IonButton fill="clear" onClick={goBackHandler}>
              <IonIcon slot="start" icon={arrowBackOutline} />
              Volver
            </IonButton>
          </IonCol>
  
          <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
            {myProduct === PRODUCTS_LANDING && (
              <>
                <IonRow>
                  <IonCol
                    sizeMd="5"
                    sizeXs="12"
                    offsetMd="0.5"
                    className="ion-margin-top ion-text-center"
                  >
                    <IonCard>
                      {catType.getCatalogType == "catalogo" ? 
                      <img max-height="240" max-width="345"  src={actualCatalogPage.pageImage[0]} /> : 
                      productsArray.filter((prod, index) => index < 4).map((prod, index) => <>
                      {productsArray.length <= 2 && <>
                      <IonCard max-height="240" max-width="345"><img src={prod.productVariations.items[0].pictures[0]} /></IonCard></>}
                      {productsArray.length == 3 && 
                      <IonCol>
                      <IonCard className="container"><img className="resize_fit_center" src={prod.productVariations.items[0].pictures[0]} /></IonCard>
      </IonCol>}
      
      {productsArray.length == 4 && 
                    <IonCol>
                      <IonCol>
                      <IonCard className="container"><img className="resize_fit_center" src={prod.productVariations.items[0].pictures[0]} /></IonCard>
     </IonCol>  </IonCol>  }
                    
                    </>)}
                      <IonRow className="ion-margin">
                        <IonCol className="ion-margin-top ion-text-center">
                          <IonText color="primary">
                            Lista de productos agregados a la página:
                          </IonText>
    
                          {productsArray &&
                            productsArray.map((prod) => (
                              <IonCard className="ion-margin-bottom">
                                <IonItem color="primary">
                                  <IonIcon
                                    color="light"
                                    icon={pricetagOutline}
                                    slot="start"
                                  />
                                  <IonLabel color="light">{prod.name}</IonLabel>
                                  <IonButtons>
                                    <IonButton
                                      slot="end"
                                      onClick={() => {
                                        dispatch(setActualProduct(prod));
                                        deleteFromList(prod);
                                      }}
                                    >
                                      <IonIcon
                                        icon={trashOutline}
                                        slot="icon-only"
                                      />
                                    </IonButton>
                                  </IonButtons>
                                </IonItem>
                              </IonCard>
                            ))}
                        </IonCol>
                      </IonRow>
                    </IonCard>
                    <IonButton onClick={saveChanges}>
                      <IonIcon slot="start" icon={cloudUploadOutline} />
                      Guardar cambios
                    </IonButton>
                  </IonCol>
  
                  <IonCol sizeMd="5" sizeXs="12" offsetMd="1.5" className="ion-text-center">
          
                    
                    <IonTitle
                      className="ion-text-center ion-margin"
                      color="primary"
                    ><p className="ion-text-wrap">
                      Productos de tu tienda</p>
                    </IonTitle>
                      {productsArray.length > 3 && 
                      <IonText color="tertiary"><small>*Has alcanzado la cantidad máxima de productos para esta página.</small></IonText>}
                    <IonRow>
                      <IonSearchbar
                        value={searchText}
                        onIonChange={(e) => setSearchText(e.detail.value!)}
                        placeholder="Nombre del producto"
                        class="search-bar-color"
                      />
  
                      <IonCol>
                        {products
                          .filter((product) =>
                            product.name
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          )
                          .filter((product) =>
                            product.productVariations.items.length >0 
                          )
                          .map((product) => (
                            <IonCard className="ion-margin-bottom">
                              <IonItem
                                className="show-click"
                                onClick={() => {
                                  dispatch(setActualProduct(product));
                                  dispatch(getProductInfo(product));
                                }}
                              >
                                <IonIcon
                                  color="primary"
                                  icon={pricetagOutline}
                                  slot="start"
                                />
                                <IonLabel color="primary">
                                  {product.name}
                                </IonLabel>
                                <IonButtons>
                                
                                  <IonButton
                                    color="primary"
                                    slot="end"
                                    fill="clear"
                                    onClick={() => {
                                      dispatch(setActualProduct(product));
                                      addToPage(product);
                                    }}
                                  >
                                    <IonIcon icon={addOutline} slot="icon-only" />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            </IonCard>
                          ))}
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>{" "}
              </>
            )}
  
  
         
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default MyListadoProductsContent;
  
  