
import {
  IonButton,
      IonGrid,
      IonSelect,
      IonSelectOption,
    } from "@ionic/react";
    import React, { useEffect, useState } from "react";
    import { useDispatch, useSelector} from "react-redux";
import { flagSelector } from "../../store/FlagSlice";
import { editShop, ShopObject } from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
    
    
    const ImagesAdminStatus: React.FC<{
      setImageStatus: React.Dispatch<
        React.SetStateAction<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined
        >
      >;
      imageStatus: "PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined;
      index: number;
      shop: ShopObject;
    }> = (props) => {
    
     
      const [imageStatus, setImageStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | string
    >(JSON.parse(props.shop.sliderStatus!)[props.index])
     const dispatch = useDispatch(); 
     const {loading} = useSelector(flagSelector)
     const [toastMessage, setToastMessage] = useState("")
    
    const changeStatus = (statusChanged: any) => {
    const copiedArray = JSON.parse(props.shop.sliderStatus!)
    copiedArray[props.index] = statusChanged
      let inputObject = {
        shopID: props.shop.shopID,
        sliderStatus: JSON.stringify(copiedArray),
      };
      dispatch(editShop(inputObject))
      setImageStatus(statusChanged)

    }

    const colorByStatus = (imageStatus: any) => {
      switch (imageStatus) {
        case "ACTIVO":
          return "success";
        case "PENDIENTE DE APROBACIÓN":
          return "warning";
          case "NO APROBADO":
          return "tertiary";
      }
    };


    useEffect(() => {
    }, [
      colorByStatus
      , props.shop.sliderStatus, setImageStatus, props.imageStatus])
    
      return (<>
        <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
        <IonGrid>
      <IonButton className="ion-text-center" expand="block" 
      color={colorByStatus(imageStatus)!}
      >
          <IonSelect 
            value={imageStatus}
            onIonChange={(e) => {
              changeStatus(e.detail.value); setImageStatus(e.detail.value);
            }}
          >
            <IonSelectOption value="PENDIENTE DE APROBACIÓN">PENDIENTE DE APROBACIÓN</IonSelectOption>
            <IonSelectOption value="ACTIVO">
           APROBADO
            </IonSelectOption>
            <IonSelectOption value="NO APROBADO">
           NO APROBADO
            </IonSelectOption>
          </IonSelect>
          </IonButton>
         
      </IonGrid>
      </>);
    };
    
    export default ImagesAdminStatus;