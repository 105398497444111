import React, { useState } from "react";

export const MenuContext = React.createContext({
	getOpenState: false,
	setOpenStateTo: (state: boolean) => {},
	toggleOpenState: () => {},
});

const MenuContextProvider = (props: any) => {
	const [getOpenState, setOpenState] = useState<boolean>(false);
	const setOpenStateTo = (state: boolean) => {setOpenState(state);};
	const toggleOpenState = () => {setOpenState(!getOpenState);};
	return (
		<MenuContext.Provider
			value={{
				getOpenState,
				setOpenStateTo,
				toggleOpenState,
			}}
		>
			{props.children}
		</MenuContext.Provider>
	);
};

export default MenuContextProvider;