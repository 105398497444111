import { IonButton } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductToOrder2, orderSelector } from "../../store/OrderSlice";


const AddToCart2: React.FC<{
  product: string;
  amount: number;
  subtotal: number;
  catalog: string;
  catalogName: string;
  catalogCover: string;
}> = (props) => {
 
  const { theOrder } = useSelector(orderSelector)
 
  const dispatch = useDispatch();


  const cambaceoProdArray : {}[] = []

  const addToCartHandler = () => {


    cambaceoProdArray.push(props)

    for (let i = 0; i < cambaceoProdArray.length; i++) {
       if (cambaceoProdArray[i] === (theOrder.cambaceoProducts[i])) {
         }
         else if (theOrder.cambaceoProducts[i] === props){
         }
         else if (!(theOrder.cambaceoProducts.includes(props))) {
           dispatch(addProductToOrder2(props));
         }
    }
     
  };
  return (
    <IonButton
      expand="block"
      fill="outline"
      color="tertiary"
      size="default"
      onClick={addToCartHandler}
    >
      Agregar a carrito
    </IonButton>
  );
};

export default AddToCart2;
    