import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { catalogSelector } from "../../store/CatalogSlice";
import HTMLFlipBook from "react-pageflip";
import Toolbar from "../global/general/Toolbar";
import {
  arrowBackOutline,
  closeCircleOutline,
  layers,
  shareSocial,
} from "ionicons/icons";
import { catalogPageSelector } from "../../store/CatalogPageSlice";
import { ROUTE_CATALOGS } from "../../utils/routes";
import { useHistory } from "react-router";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import "./Catalogs.css";
import AddToCart from "../cart/AddToCart";
import Loading from "../global/general/Loading";
import AddToCart2 from "../cart/AddToCart2";
import {
  ProductObject,
  productSelector,
  setActualProduct,
} from "../../store/ProductSlice";
import ProductView from "../products/ProductView";
import CollectionProductModal from "../products/CollectionProductModal";

const Catalog: React.FC = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { actualCatalog } = useSelector(catalogSelector);
  const { actualProduct } = useSelector(productSelector);
  const { catalogPages } = useSelector(catalogPageSelector);
  const dispatch = useDispatch();
  const catType = useContext(CatalogTypeContext);
  const history = useHistory();
  const [showProducts, setShowProducts] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const catalogPagesList = catalogPages.filter(
    (catalog) => catalog.catalogID === actualCatalog.id
  );
  const numPages = catalogPagesList.length;
  const slidePremium = { slidesPerView: 1, autoplay: false };

  const [swiper, setSwiper] = useState<any>({});
  const init = async function (this: any) {
    setSwiper(await this.getSwiper());
  };
  const handleSlidesControlNext = () => {
    swiper.slideNext(300);
  };
  const handleSlidesControlPrev = () => {
    swiper.slidePrev(300);
  };

  useEffect(() => {}, [catalogPages]);
  const [descType, setDescType] = useState("general");
  const generalDescHandler = () => {
    setDescType("general");
  };
  const technicalDescHandler = () => {
    setDescType("technical");
  };

  return (
    <IonPage>
      <Loading show={!catalogPages} />
      {showDetail && (
        <CollectionProductModal
          showDetail={showDetail}
          setShowDetail={setShowDetail}
          product={actualProduct}
        />
      )}
      <IonModal isOpen={showModal}>
        <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonGrid fixed className="modalProductos">
          <IonTitle className="ion-text-wrap" color="primary">
            Productos{" "}
            {actualCatalog.type == "catálogo"
              ? "del catálogo"
              : "de la colección"}
          </IonTitle>
          <br></br>

          {showProducts !== "" && actualCatalog.type === "listado" && (
            <>
              {JSON.parse(showProducts).map((item: any, index: any) => (
                <IonRow>
                  <IonCol>
                    <IonLabel key={index}>{item.name}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <AddToCart
                      product={item}
                      variationID={0}
                      amount={1}
                      subtotal={item.productVariations.items[0].cost}
                      shop={item.shopID}
                    />
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
          {showProducts !== "" && actualCatalog.type === "catalogo" && (
            <>
              {JSON.parse(showProducts).map((item: any, index: any) => (
                <IonRow>
                  <IonCol>
                    <IonLabel key={index}>{item.name}</IonLabel>
                  </IonCol>
                  <IonCol>
                    <AddToCart2
                      product={item}
                      catalog={actualCatalog.id}
                      amount={1}
                      subtotal={item.price}
                      catalogName={actualCatalog.name}
                      catalogCover={actualCatalog.cover}
                    />
                  </IonCol>
                </IonRow>
              ))}
            </>
          )}
        </IonGrid>
      </IonModal>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonButton
                fill="clear"
                onClick={() => {
                  catType.setCatalogType(actualCatalog.type);
                  history.replace(ROUTE_CATALOGS);
                }}
              >
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                {actualCatalog.coverStatus == "ACTIVO" && 
                <IonIcon
                  color="tertiary"
                  onClick={() => {
                    actualCatalog.type === "catalogo"
                      ? window.open(
                          `https://wa.me/?text=Echa%20un%20vistazo%20al%20catálogo%20${actualCatalog.name}:%20https://www.novocorpo.com.mx/catalogo/"+ ${actualCatalog.id}`
                        )
                      : window.open(
                          `https://wa.me/?text=Echa%20un%20vistazo%20a%20la%20colección%20${actualCatalog.name}:%20https://www.novocorpo.com.mx/catalogo/"+ ${actualCatalog.id}`
                        );
                  }}
                  slot="end"
                  icon={shareSocial}
                />}
                <IonTitle color="tertiary">
                  <h1>
                    {actualCatalog.type === "catalogo"
                      ? "CATÁLOGO"
                      : "COLECCIÓN"}
                  </h1>
                </IonTitle>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonLabel color="primary">
                <strong>{actualCatalog.name}</strong>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {catalogPagesList && (
                <IonGrid className="arrowContainer">
                  <IonButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleSlidesControlPrev();
                    }}
                  >{`<`}</IonButton>
                  <IonButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleSlidesControlNext();
                    }}
                  >{`>`}</IonButton>
                </IonGrid>
              )}
              <IonSlides
                onIonSlidesDidLoad={init}
                className="slide-style ion-no-margin ion-no-padding"
                id="slidesPremium"
                pager={true}
                options={slidePremium}
              >
                {catalogPagesList &&
                  catType.getCatalogType == "listado" &&
                  catalogPagesList.map((page) => (
                    <>
                      <IonSlide className="slidePremium">
                        <div key={page.id}>
                          <IonText className="titulo" color="secondary">
                            <span>Página</span>{" "}
                            <strong>{page.pageNumber}</strong> de {numPages}
                          </IonText>
                          <br></br>
                          <IonButton
                            color="tertiary"
                            className="ion-margin"
                            onClick={async (e) => {
                              e.preventDefault();
                              setShowProducts(`${page.productsString}`);
                              // setShowProducts(page.productsString);
                              setShowModal(true);
                            }}
                          >
                            Agregar Productos Carrito
                          </IonButton>
                          <IonRow>
                            {window.innerWidth > 576 &&
                              JSON.parse(page.productsString!).map(
                                (prod: ProductObject) => (
                                  <div className="containerSlide">
                                    {JSON.parse(page.productsString!).length >
                                      2 && (
                                      <>
                                        <IonImg
                                          onClick={() => {
                                            setShowDetail(true);
                                            dispatch(setActualProduct(prod));
                                          }}
                                          className="resize_fit_center"
                                          src={
                                            prod.productVariations.items[0]
                                              .pictures[0]
                                          }
                                        />
                                      </>
                                    )}
                                    {JSON.parse(page.productsString!).length <=
                                      2 && (
                                      <IonImg
                                        src={
                                          prod.productVariations.items[0]
                                            .pictures[0]
                                        }
                                      />
                                    )}
                                  </div>
                                )
                              )}
                          </IonRow>{" "}
                        </div>
                      </IonSlide>

                      {window.innerWidth < 576 &&
                        JSON.parse(page.productsString!).map(
                          (prod: ProductObject) => (
                            <IonSlide className="containerResponsive">
                              {/* <div className="containerResponsive"> */}

                              {JSON.parse(page.productsString!).length > 2 && (
                                <IonRow>
                                  <IonCol>
                                    <IonImg
                                      className="resize_fit_center_responsive"
                                      src={
                                        prod.productVariations.items[0]
                                          .pictures[0]
                                      }
                                    />
                                  </IonCol>
                                </IonRow>
                              )}

                              {JSON.parse(page.productsString!).length <= 2 && (
                                <IonCol>
                                  <IonImg
                                    src={
                                      prod.productVariations.items[0]
                                        .pictures[0]
                                    }
                                  />
                                </IonCol>
                              )}
                              {/* </div> */}
                            </IonSlide>
                          )
                        )}
                    </>
                  ))}
                {catalogPagesList &&
                  catType.getCatalogType == "catalogo" &&
                  catalogPagesList.map((page) => (
                    <IonSlide className="slidePremium">
                      <div key={page.id}>
                        <IonText className="titulo" color="secondary">
                          <span>Página</span> <strong>{page.pageNumber}</strong>{" "}
                          de {numPages}
                        </IonText>
                        <br></br>
                        <IonButton
                          color="tertiary"
                          className="ion-margin"
                          onClick={async (e) => {
                            e.preventDefault();
                            setShowProducts(`${page.productsString}`);
                            // setShowProducts(page.productsString);
                            setShowModal(true);
                          }}
                        >
                          Agregar Productos Carrito
                        </IonButton>
                        <IonImg
                          className="pagina"
                          src={`${page.pageImage}`}
                        ></IonImg>
                      </div>
                    </IonSlide>
                  ))}
              </IonSlides>

              {catalogPagesList.length <= 0 && (
                <IonGrid className="empty ion-text-center">
                  <IonText>Sin páginas</IonText>
                </IonGrid>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Catalog;
