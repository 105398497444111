import { getProductInfo } from './ProductSlice';
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductVariationThunk } from "../index";
import {
	setError,
	setLoading,
	setWasCreated,
	setWasDeleted,
	setWasUpdated,
} from "./FlagSlice";
import { createProductVariation, deleteProductVariation, updateProductVariation } from "../graphql/mutations";
import { getProductVariation, listProductVariations } from "../graphql/queries";


//Interfaces
export interface ProductVariationState {
	productVariation: ProductVariationObject[];
}

export interface ProductVariationObject {
	id: string;
	sku: string;
	variationName: string;
	amount: number;
	cost: number;
	price?: number;
	availability: string;
	pictures: [string]; 
	condition: String;
	productID: string;
	status: string;
	statusReason?: string;
}

//Initial State
const initialState: ProductVariationState = {
	productVariation: [],
};

//Reducers
const productVariation = createSlice({
	name: "productVariation",
	initialState,
	reducers: {
		setProductVariation(state, { payload }: PayloadAction<ProductVariationObject[]>) {
			state.productVariation = payload;
		},
		cleanProductVariation(state) {
			state.productVariation = [];
		},
	},
});

//Exports
export const { setProductVariation, cleanProductVariation } = productVariation.actions;

export default productVariation.reducer;

export const productVariationSelector = (state: { productVariationStore: ProductVariationState }) =>
	state.productVariationStore;

//Middleware
export const postProductVariation = (input: any): ProductVariationThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createProductVariation, { input }));
			const productID = {
				id: res.data.createProductVariation.productID,
			  };
			  dispatch(getProductInfo(productID));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error.errors[0].message));
		}
	};
};

export const getAllProductVariation = (input: any): ProductVariationThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(listProductVariations, input));
		dispatch(setProductVariation(res.data.listProductVariations.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const readProductVariation =
	(input: any): ProductVariationThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(
				graphqlOperation(getProductVariation, { input })
			);
			dispatch(setProductVariation(res.data.listProductVariations.items));
			dispatch(setLoading(false));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editProductVariation = (input: any): ProductVariationThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(updateProductVariation, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removeProductVariation = (input: any): ProductVariationThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deleteProductVariation, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error:any) {
			dispatch(setLoading(false));
		}
	};
};
