import { IonButton, IonButtons, IonCard, IonIcon, IonItem, IonLabel } from "@ionic/react";
import {
  homeOutline,
} from "ionicons/icons";
import React, { Dispatch, SetStateAction,} from "react";
import { useDispatch } from "react-redux";
import { getPropertyInfo, PropertyObject, setActualProperty } from "../../store/PropertySlice";
import { PROPERTIES_DETAILS, PROPERTIES_EDIT } from "./MyPropertiesContent";

const MyPropertiesCard: React.FC<{
  property: PropertyObject;
  setProperties: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const dispatch = useDispatch();

  const propertyDetailsHandler = () => {
    const propertyID = {
      id: props.property.id,
    };
    dispatch(getPropertyInfo(propertyID));
  };
  return (
    <IonCard className="ion-margin-bottom" >
      <IonItem  color="primary"
          onClick={()=>{
            dispatch(setActualProperty(props.property));
            propertyDetailsHandler();
          }}
          >
        <IonIcon className="ion-hide-sm-down"  icon={homeOutline} slot="start" />
        <IonLabel>{props.property.name}</IonLabel>
        <IonButtons>
        <IonButton slot="end"  fill="clear" onClick={() => {props.setProperties(PROPERTIES_DETAILS)}}>
          Ver detalles
        </IonButton>
        <IonButton slot="end"  fill="clear" onClick={() => {dispatch(setActualProperty(props.property)); props.setProperties(PROPERTIES_EDIT)}}>
          Editar
        </IonButton>
        </IonButtons>
      </IonItem>
    </IonCard>
  );
};

export default MyPropertiesCard;
