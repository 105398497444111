import { createOrder, updateOrder } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { OrderThunk } from "../index";
import { setError, setLoading, setWasCreated } from "./FlagSlice";
import { getOrder, listOrders, orderByDate } from "../graphql/queries";
import { productInterface } from "../interfaces/Product";
import { ProductObject } from "./ProductSlice";
import { shopSelector } from "./ShopSlice";



//Interfaces
export interface OrderState {
  theOrder: OrderObject;
  pastOrder: OrderObject;
  orders: OrderObject[];
}

export interface OrderObject {
  id: string;
  products: {
    product: ProductObject;
    variationID: number;
    amount: number;
    subtotal: number;
    shop: string;
  }[];
  catalogs: string[];
  shops: string[];
  cambaceoProducts: {
    product: string;
    amount: number;
    subtotal: number;
    catalog: string;
    catalogName: string;
    catalogCover: string;
  }[];
  contactInfo: string;
  total: number;
  payment: string;
  status: string;
  comments: string;
  userProfileEmail: string;
  billingData: string;
  addressData: string;
  subTotal: number;
  iva: number;
  shipping: number;
  createdAt: string;
  typeName: string;
}

//Initial State
const initialState: OrderState = {
  orders: [],
  pastOrder: {
    id: "",
    products: [],
    catalogs: [],
    cambaceoProducts: [],
    shops: ['novocorpo'],
    contactInfo: "",
    total: 0,
    payment: "",
    status: "",
    comments: "",
    userProfileEmail: "",
    billingData: "",
    addressData: "",
    subTotal: 0,
    iva: 0,
    shipping: 0,
    createdAt: "",
    typeName: "",
  },
  theOrder: {
    id: "",
    products: [],
    catalogs: [],
    cambaceoProducts: [],
    shops: ['novocorpo'],
    contactInfo: "",
    total: 0,
    payment: "",
    status: "",
    comments: "",
    userProfileEmail: "",
    billingData: "",
    addressData: "",
    subTotal: 0,
    iva: 0,
    shipping: 0,
    createdAt: "",
    typeName: "Order",
  },
};

//Reducers
const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders(state, { payload }: PayloadAction<OrderObject[]>) {
      state.orders = payload;
    },
    setactualOrder(state, { payload }: PayloadAction<OrderObject>) {
      state.theOrder = payload;
    },
    setactualPastOrder(state, { payload }: PayloadAction<OrderObject>) {
      state.pastOrder = payload;

      const pastOrder = {
        id: payload.id,
        products: {
          product: payload.products[0].product,
          variationID: payload.products[0].variationID,
          amount: payload.products[0].amount,
          subtotal: payload.products[0].subtotal,
          shop: payload.products[0].shop,
        },
        catalogs: payload.catalogs,
        shops: payload.shops,
        cambaceoProducts: {
          product: payload.cambaceoProducts[0].product,
          amount: payload.cambaceoProducts[0].amount as number,
          subtotal: payload.cambaceoProducts[0].subtotal as number,
          catalog: payload.cambaceoProducts[0].catalog,
          catalogName: payload.cambaceoProducts[0].catalogName,
          catalogCover: payload.cambaceoProducts[0].catalogCover,
        },
        contactInfo: payload.contactInfo,
        total: payload.total,
        payment: payload.payment,
        status: payload.status,
        userProfileEmail: payload.userProfileEmail,
        billingData: payload.billingData,
        addressData: payload.addressData,
        subTotal: payload.subTotal,
        iva: payload.iva,
        shipping: payload.shipping,
        createdAt: payload.createdAt,
        typeName: payload.typeName     };

    },
    setCheckOutInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        contactInfo: string;
        addressData: string;
        billingData: string;
        total: number;
        payment: string;
        status: string;
        userProfileEmail: string;
        iva: number;
        shipping: number;
      }>
    ) {
      state.theOrder.contactInfo = payload.contactInfo;
      state.theOrder.addressData = payload.addressData;
      state.theOrder.billingData = payload.billingData;
      state.theOrder.total = payload.total;
      state.theOrder.payment = payload.payment;
      state.theOrder.status = payload.status;
      state.theOrder.userProfileEmail = payload.userProfileEmail;
      state.theOrder.iva = payload.iva;
      state.theOrder.shipping = payload.shipping;
    },
    addProductToOrder(
      state,
      {
        payload,
      }: PayloadAction<{
        product: ProductObject;
        variationID: number;
        amount: number;
        subtotal: number;
        shop: string;
      }>
    ) {

 
    
  
  const productsArray = state.theOrder.products; 
  state.theOrder.products = productsArray
  
  productsArray.push(payload)
      

     for (let i = 0; i < state.theOrder.products.length; i++) {
        if (!(state.theOrder.shops.includes(state.theOrder.products[i].shop))) {
          state.theOrder.shops.push(state.theOrder.products[i].shop);
        }
      }
    

      //Subtotal account
      const productsArray3 = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray3.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));

    },
    addProductToOrder2(
      state,
      {
        payload,
      }: PayloadAction<{
        product: string;
        amount: number;
        subtotal: number;
        catalog: string;
        catalogName: string;
        catalogCover: string;
      }>
    ) {

      const productsArray = state.theOrder.cambaceoProducts;

      productsArray.push(payload);
      state.theOrder.cambaceoProducts = productsArray;

      for (let i = 0; i < state.theOrder.cambaceoProducts.length; i++) {
        if (state.theOrder.catalogs.includes(state.theOrder.cambaceoProducts[i].catalogName) === false)
         {
          state.theOrder.catalogs.push(
            state.theOrder.cambaceoProducts[i].catalogName
          );
        }
      }

      //Subtotal account
      const productsArray3 = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray3.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));

    },
    deleteProductOfOrder2(state, { payload }: PayloadAction<{ id: number }>) {
      const productsArray = state.theOrder.cambaceoProducts;

      productsArray.forEach((product, index) => {
        if (index === payload.id) {
          productsArray.splice(index, 1);
        }
      });
      state.theOrder.cambaceoProducts = productsArray;
      //Subtotal account
      const productsArray3 = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray3.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));
    },
    deleteProductOfOrder(state, { payload }: PayloadAction<{ id: number }>) {
      const productsArray = state.theOrder.products;

      productsArray.forEach((product, index) => {
        if (index === payload.id) {
          productsArray.splice(index, 1);
        }
      });
      state.theOrder.products = productsArray;
      //Subtotal account
      const productsArray3 = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray3.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));
    },
    updateProductAmount2(
      state,
      { payload }: PayloadAction<{ id: number; amount: number }>
    ) {
      state.theOrder.cambaceoProducts[payload.id].amount = payload.amount;
      var sku = JSON.stringify(
        state.theOrder.cambaceoProducts[payload.id].product
      );
      var sku2 = JSON.parse(sku);
      var price = JSON.stringify(
        state.theOrder.cambaceoProducts[payload.id].product
      );
      var price2 = JSON.parse(price);
      state.theOrder.cambaceoProducts[payload.id].subtotal =
        payload.amount * price2.price;
      const productsArray = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));
    },
    updateProductAmount(
      state,
      { payload }: PayloadAction<{ id: number; amount: number }>
    ) {
      state.theOrder.products[payload.id].amount = payload.amount;
      const variationID = state.theOrder.products[payload.id].variationID;
      state.theOrder.products[payload.id].subtotal =
        payload.amount *
        state.theOrder.products[payload.id].product.productVariations.items[
          variationID
        ].cost;
      const productsArray = state.theOrder.cambaceoProducts;
      let subTotal = 0;
      productsArray.forEach((product) => {
        subTotal = subTotal + product.subtotal;
      });
      const productsArray2 = state.theOrder.products;
      let subTotal2 = 0;
      productsArray2.forEach((product) => {
        subTotal2 = subTotal2 + product.subtotal;
      });
      
      state.theOrder.subTotal =
        Number.parseFloat(subTotal.toFixed(2)) +
        Number.parseFloat(subTotal2.toFixed(2));
    },
    cleanOrder(state) {
      state.theOrder = {
        id: "",
        products: [],
        catalogs: [],
        cambaceoProducts: [],
        shops: [],
        contactInfo: "",
        total: 0,
        payment: "",
        status: "",
        comments: "",
        userProfileEmail: "",
        billingData: "",
        addressData: "",
        subTotal: 0,
        iva: 0,
        shipping: 0,
        createdAt: "",
        typeName: "Order",
      };
    },
  },
});

//Exports
export const {
  setOrders,
  setactualOrder,
  addProductToOrder,
  addProductToOrder2,
  cleanOrder,
  updateProductAmount,
  updateProductAmount2,
  deleteProductOfOrder,
  deleteProductOfOrder2,
  setCheckOutInfo,
  setactualPastOrder,
} = order.actions;

export default order.reducer;

export const orderSelector = (state: { orderStore: OrderState }) =>
  state.orderStore;

//Middleware
export const getOrderInfo =
  (input: any): OrderThunk =>
  async (dispatch: any) => {
    try {
      const res: any = await API.graphql(graphqlOperation(getOrder, { input }));
      dispatch(setactualOrder(res));
    } catch (error: any) {
      dispatch(setError(error));
    }
  };

export const getOrdersInfo =
  (input: any): OrderThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(listOrders, input));
      dispatch(setOrders(res.data.listOrders.items));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

  export const getOrdersByDate =
  (input: any): OrderThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(orderByDate, input));
      dispatch(setOrders(res.data.orderByDate.items));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const listOrdersInfo =
  (input: any): OrderThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(listOrders, { input })
      );
      if (typeof res.data.listOrders.items[0] !== "undefined") {
        dispatch(setactualOrder(res.data.listOrders.items[0]));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const postOrder = (input: any): OrderThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const res = await API.graphql(graphqlOperation(createOrder, input));
      dispatch(setLoading(false));
      dispatch(setWasCreated(true));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error.errors));
    }
  };
};

export const editOrder = (input: any): OrderThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(updateOrder, { input }));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};
