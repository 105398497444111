import { IonButton, IonButtons,IonContent, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonTitle, IonLabel, IonRouterLink } from "@ionic/react";

import { helpCircleOutline, homeOutline } from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import { ROUTE_SHOP } from "../../utils/routes";
import FooterComponent from "../global/general/FooterComponent";
import './Info.css';

const Faq: React.FC<{}> = () => {


    return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="page-content info">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButtons>
								<IonButton color="secondary" href="/"><IonIcon slot="start" icon={homeOutline}></IonIcon><IonText className="ion-hide-sm-down">Inicio</IonText></IonButton>
								<IonText color="secondary">/</IonText>
								<IonButton color="secondary" href="/faq"><IonIcon slot="start" icon={helpCircleOutline}></IonIcon><IonText className="ion-hide-sm-down">FAQ</IonText></IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="ion-text-center"><IonTitle color="primary"><h1>Preguntas Frecuentes</h1></IonTitle></IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="1">
							<IonText className="title" color="tertiary"><h2>¿CÓMO SE HACE UNA DEVOLUCIÓN?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>GARANTÍA DE 15 DÍAS POR DEFECTO DE FABRICACIÓN, NOSOTROS RECOGEMOS EL PRODUCTO O ENVIAMOS LA GUÍA Y AGREGAMOS NOVOCORPO COINS O CAMBIO DE PRODUCTO, POR EL MISMO O UNO DE IGUAL O MAYOR PRECIO, PAGANDO LA DIFERENCIA.</p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="0.5">
							<IonText className="title" color="tertiary"><h2>¿CÓMO COMPRO?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>-EN UNA MISMA ORDEN DE COMPRA SUBES AL CARRITO PRODUCTOS DE DISTINTOS CATÁLOGOS Y PROVEEDORES</p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeMd="5" offsetMd="1">
							<IonText className="title" color="tertiary"><h2>¿CÓMO VENDO?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>-PROVEEDOR: SUBE TUS PRODUCTOS A NUESTRO PORTAL.<br></br>-DISTRIBUIDOR: OFRECE LOS PRODUCTOS DE NUESTRO CATÁLOGO Y GANA CON NOSOTOS CON UN MÍNIMO DE COMPRA AL PERIODO.</p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="0.5">
							<IonText className="title" color="tertiary"><h2>¿CÓMO SOLICITO MI FACTURA?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>CADA VENDEDOR FACTURA DIRECTAMENTE AL CLIENTE, AL MOMENTO DE REALIZAR TU PAGO, SE TE PREGUNTA SI DESEAS FACTURAR.</p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="1">
							<IonText className="title" color="tertiary"><h2>¿CÓMO ME ENTREGAN MI PRODUCTO?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>-SE ENVÍA A DOMICILIO, AL MOMENTO DE REALIZAR TU PAGO, TE PREGUNTAN TU DOMICILIO DE ENTREGA </p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="0.5">
							<IonText className="title" color="tertiary"><h2>¿QUÉ DIFERENCIA HAY ENTRE FOLLETO Y CATÁLOGO?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<p>-EL NÚMERO DE PRODUCTOS: FOLLETO DE 16 A 25 PRODUCTOS, CATÁLOGO DE 26 A 50 PRODUCTOS. (CON UN MÁXIMO DE 5 FOTOS CADA PRODUCTO)</p>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="1">
							<IonText className="title" color="tertiary"><h2>¿CUÁLES SON LOS PAQUETES DE VENDEDOR?</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<IonRouterLink routerLink={ROUTE_SHOP}>Aqui puedes ver nuestros <IonText color="tertiary">paquetes</IonText></IonRouterLink>
							</IonLabel>
						</IonCol>
					</IonRow>
                </IonGrid>
				<FooterComponent></FooterComponent>
            </IonContent>
        </IonPage>
    );
}

export default Faq;