import { customlistProducts,customlistAllProducts } from "./../graphql/customqueries";
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductThunk } from "../index";
import {
  setError,
  setLoading,
  setWasCreated,
  setWasDeleted,
  setWasUpdated,
} from "./FlagSlice";
import {
  createProduct,
  deleteProduct,
  updateProduct,
} from "../graphql/mutations";
import { getProduct, listProducts } from "../graphql/queries";
import { setActualShop } from "./ShopSlice";

//Interfaces
export interface ProductState {
  products: ProductObject[];
  actualProduct: ProductObject;
  actualCategories: {name: string, checked: boolean}[];
}

export interface ProductObject {
  id: string;
  name: string;
  description: string;
  specifications: string;
  category: string;
  brandName: string;
  shopID: string;
  status: string;
  featured: string;
  productVariations: {
    items: [
      {
        id: string;
        sku: string;
        variationName: string;
        amount: number;
        cost: number;
        price: number;
        availability: string;
        pictures: [string];
        condition: String;
        productID: string;
        status: string;
      }
    ];
  };
}

//Initial State
const initialState: ProductState = {
  products: [],
  actualCategories: [{name: "", checked: false}],
  actualProduct: {
    id: "",
    name: "",
    description: "",
    specifications: "",
    category: "",
    brandName: "",
    shopID: "",
    status: "",
    featured: "",
    productVariations: {
      items: [
        {
          id: "",
          sku: "",
          variationName: "",
          amount: 0,
          cost: 0,
          price: 0,
          availability: "",
          pictures: [""],
          condition: "",
          productID: "",
          status: "",
        },
      ],
    },
  },
};

//Reducers
const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProduct(state, { payload }: PayloadAction<ProductObject[]>) {
      state.products = payload;
    },
    setActualCategories(state, { payload }: PayloadAction<{name: string, checked: boolean}[]>) {
      state.actualCategories = payload;
    },
    setActualProduct(state, { payload }: PayloadAction<ProductObject>) {

      const productArray = {
        id: payload.id,
        name: payload.name,
    description: payload.description,
    specifications: payload.specifications,
    category: JSON.parse(payload.category),
    brandName: payload.brandName,
    shopID: payload.shopID,
    status: payload.status,
    featured: payload.featured,
    productVariations: payload.productVariations
      }
      
state.actualProduct = productArray
      // state.actualProduct = payload;
    },
    cleanProduct(state) {
      state.products = [];
    },
  },
});

//Exports
export const { setProduct, setActualProduct, cleanProduct, setActualCategories } = product.actions;

export default product.reducer;

export const productSelector = (state: { productStore: ProductState }) =>
  state.productStore;

//Middleware
export const postProduct = (input: any): ProductThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      console.log("POST INPUT", input)
      const res:any = await API.graphql(graphqlOperation(createProduct, { input }));
      dispatch(setActualProduct(res.data.createProduct))
      dispatch(setLoading(false));
      dispatch(setWasCreated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const getAllProduct =
  (input: any): ProductThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(customlistProducts, input));
      dispatch(setProduct(res.data.listProducts.items));
      dispatch(setLoading(false));
     
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
export const getProductInfo =
  (input: any): ProductThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getProduct, input));
      if (typeof res.data.getProduct !== "undefined") {
        dispatch(setActualProduct(res.data.getProduct));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const getAllProducts = (): ProductThunk => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(customlistAllProducts,{}));
      dispatch(setProduct(res.data.listProducts.items)); 
      dispatch(setLoading(false));
    }
    catch(error:any){
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
};

export const customListProd = (): ProductThunk => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    dispatch(setLoading(false));
  } catch (error:any) {
    dispatch(setLoading(false));
    dispatch(setError(error));
  }
};

export const readProduct =
  (input: any): ProductThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(getProduct, { input })
      );
      dispatch(setProduct(res.data.listProducts.items));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const editProduct = (input: any): ProductThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(updateProduct, { input }));
      dispatch(setLoading(false));
      dispatch(setWasUpdated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const removeProduct = (input: any): ProductThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(deleteProduct, { input }));
      dispatch(setLoading(false));
      dispatch(setWasDeleted(true));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};
