import React from "react";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

const MyShopToolbar: React.FC<{ iconName: string; title: string}> = ({
  iconName,
  title
}) => {
  return (
    <IonHeader mode="md">
      <IonToolbar color="tertiary">
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonButton fill="clear">
                <IonIcon color="light" slot="start" icon={iconName} />
                <IonTitle color="light">{title}</IonTitle>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonHeader>
  );
};

export default MyShopToolbar;
