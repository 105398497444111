import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import { ThunkAction } from "redux-thunk";
import { Action, configureStore } from "@reduxjs/toolkit";
import flagSliceReducer from "./store/FlagSlice";
import menuSliceReducer from "./store/MenuSlice";
import authSliceReducer, { AuthState } from "./store/AuthSlice";
import shopSliceReducer, { ShopState } from "./store/ShopSlice";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import productSliceReducer, { ProductState } from "./store/ProductSlice";
import catalogSliceReducer, { CatalogState } from "./store/CatalogSlice";
import catalogPageSliceReducer, { CatalogPageState } from "./store/CatalogPageSlice";
import productVariationSliceReducer, { ProductVariationState } from "./store/ProductVariationSlice";
import categorySliceReducer, { CategoryState } from "./store/CategorySlice";
import brandSliceReducer, { BrandState } from "./store/BrandSlice";
import orderSliceReducer, { OrderState } from "./store/OrderSlice";
import userSliceReducer, { UserState } from "./store/UserSlice";
import cambaceoSliceReducer, { CambaceoState } from "./store/CambaceoSlice";
import monederoLogSliceReducer, { MonederoLogState } from "./store/MonederoSlice";
import CatalogTypeContextProvider from "./context/CatalogTypeContext";
import MenuContextProvider from "./context/MenuContext";
import propertySliceReducer, { PropertyState } from "./store/PropertySlice";
import propertyFeatureSliceReducer, { PropertyFeatureState } from "./store/PropertyFeatureSlice";
import novocorpoSliceReducer, { NovocorpoState } from "./store/NovocorpoSlice";
import AuthContextProvider from "./context/AuthContext";

Amplify.configure(aws_exports);

export type AuthThunk = ThunkAction<void, AuthState, unknown, Action<string>>;
export type ShopThunk = ThunkAction<void, ShopState, unknown, Action<string>>;
export type ProductThunk = ThunkAction<void, ProductState, unknown, Action<string>>;
export type PropertyThunk = ThunkAction<void, PropertyState, unknown, Action<string>>;
export type PropertyFeatureThunk = ThunkAction<void, PropertyFeatureState, unknown, Action<string>>;
export type CambaceoThunk = ThunkAction<void, CambaceoState, unknown, Action<string>>;
export type CatalogThunk = ThunkAction<void, CatalogState, unknown, Action<string>>;
export type CatalogPageThunk = ThunkAction<void, CatalogPageState, unknown, Action<string>>;
export type CategoryThunk = ThunkAction<void, CategoryState, unknown, Action<string>>;
export type BrandThunk = ThunkAction<void, BrandState, unknown, Action<string>>;
export type ProductVariationThunk = ThunkAction<void, ProductVariationState, unknown, Action<string>>;
export type OrderThunk = ThunkAction<void, OrderState, unknown, Action<string>>;
export type UserThunk = ThunkAction<void, UserState, unknown, Action<string>>;
export type MonederoLogThunk = ThunkAction<void, MonederoLogState, unknown, Action<string>>;
export type NovocorpoThunk = ThunkAction<void, NovocorpoState, unknown, Action<string>>;

const store = configureStore({
	reducer: {
		flagStore: flagSliceReducer,
		menuStore: menuSliceReducer,
		authStore: authSliceReducer,
		shopStore: shopSliceReducer,
		productStore: productSliceReducer,
		propertyStore: propertySliceReducer,
		propertyFeatureStore: propertyFeatureSliceReducer,
		cambaceoStore: cambaceoSliceReducer,
		catalogStore: catalogSliceReducer,
		catalogPageStore: catalogPageSliceReducer,
		categoryStore: categorySliceReducer,
		brandStore: brandSliceReducer,
		productVariationStore: productVariationSliceReducer,
		orderStore: orderSliceReducer,
		userStore: userSliceReducer,
		monederoLogStore: monederoLogSliceReducer,
		novocorpoStore: novocorpoSliceReducer,

	},
});

ReactDOM.render(
	
	<Provider store={store}>
		<AuthContextProvider>
		<CatalogTypeContextProvider>
			<MenuContextProvider>
				<App />
			</MenuContextProvider>
		</CatalogTypeContextProvider>
		</AuthContextProvider>

	</Provider>,
	document.getElementById("root")
);

defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
