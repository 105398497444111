import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import PropertiesView from "../../components/properties/PropertiesView";
import { getAllProperty } from "../../store/PropertySlice";

const PropertiesViewPage: React.FC = () => {
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    dispatch(getAllProperty({}));
  });



  return <PropertiesView/>;
};

export default PropertiesViewPage;
