import {
  IonContent,
  IonSlides,
  IonSlide,
  IonHeader,
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonImg,
  IonChip,
  IonText,
  IonTitle,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonButtons,
  IonFooter,
} from "@ionic/react";
import {
  backspaceOutline,
  backspaceSharp,
  homeOutline,
  logoWhatsapp,
  returnUpBack,
  shareSocial,
  storefrontOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import HomePage from "../../pages/home/HomePage";
import { flagSelector } from "../../store/FlagSlice";
import { productSelector } from "../../store/ProductSlice";
import AddToCart from "../cart/AddToCart";
import Loading from "../general/Loading";
import FooterComponent from "../global/general/FooterComponent";
import Toolbar from "../global/general/Toolbar";
import "./ProductDetailContent.css";

const ProductDetailContent: React.FC = () => {
  const [singleSwiper, setSingleSwiper] = useState<any>({});
  const [thumbsSwiper, setThumbsSwiper] = useState<any>({});
  const currencyFormat = { style: 'currency', currency: 'MXN' };
  const numberFormat = new Intl.NumberFormat('es-MX', currencyFormat);

  const { loading } = useSelector(flagSelector);
  const { actualProduct } = useSelector(productSelector);

  const [getSelectedVariation, setSelectedVariation] = useState(0);
  const [descType, setDescType] = useState("general");
  const generalDescHandler = () => {
    setDescType("general");
  };
  const technicalDescHandler = () => {
    setDescType("technical");
  };

  const singleSlider = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: singleSwiper,
    },
  };

  const thumbSlider = {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    direction: "vertical",
  };

  const initSingle = async function (this: any) {
    setSingleSwiper(await this.getSwiper());
  };

  const initThumbs = async function (this: any) {
    setThumbsSwiper(await this.getSwiper());
  };

  const swipe = async function (this: any) {
    thumbsSwiper.slideTo(await this.getActiveIndex());
  };

  const tap = function (index: number) {
    singleSwiper.slideTo(index);
  };

  return (
    <IonPage className="productDetail">
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent>
        {actualProduct && (
          <IonGrid fixed className="ion-margin-top ion-padding-top">
            <IonRow>
              <IonCol>
                <IonButtons>
                  <IonButton color="secondary" href="/home">
                    <IonIcon slot="start" icon={homeOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">Inicio</IonText>
                  </IonButton>
                  <IonText color="secondary">/</IonText>
                  <IonButton
                    color="secondary"
                    href={`/shops/${actualProduct.shopID}`}
                  >
                    <IonIcon slot="start" icon={storefrontOutline}></IonIcon>
                    <IonText className="ion-hide-sm-down">
                      {actualProduct.brandName}
                    </IonText>
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                sizeSm="
							12"
                sizeMd="6"
                className="carouselContainer"
              >
                {/* Carrousel */}
                <IonGrid fixed>
                  <IonRow>
                    <IonCol size="2">
                      <IonSlides
                        options={thumbSlider}
                        className="thumbs-slider"
                        onIonSlidesDidLoad={initThumbs}
                      >
                        {actualProduct.productVariations.items[
                          getSelectedVariation
                        ].pictures &&
                          actualProduct.productVariations.items[
                            getSelectedVariation
                          ].pictures.map((picture, pindex) => (
                            <IonSlide onClick={() => tap(pindex)}>
                              <img src={picture}></img>
                            </IonSlide>
                          ))}
                      </IonSlides>
                    </IonCol>
                    <IonCol size="10">
                      <IonSlides
                        onIonSlidesDidLoad={initSingle}
                        scrollbar={true}
                        options={singleSlider}
                        className="single-slider"
                        onIonSlideDidChange={swipe}
                      >
                        {actualProduct.productVariations.items[
                          getSelectedVariation
                        ].pictures &&
                          actualProduct.productVariations.items[
                            getSelectedVariation
                          ].pictures.map((picture, pindex) => (
                            <IonSlide key={pindex}>
                              <img src={picture}></img>
                            </IonSlide>
                          ))}
                      </IonSlides>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol sizeSm="12" sizeMd="6" className="dataContainer">
                <IonRow>
                  <IonCol>
                    <IonText color="primary">
                      <h1 className="ion-no-margin ion-padding-bottom">
                        {actualProduct.name}
                      </h1>
                    </IonText>
                    <IonText color="primary">
                      <small>
                        Categorías:{" "}
                        {actualProduct.category &&
                          JSON.parse(JSON.stringify(actualProduct.category)).map(
                            (cat: string) => (
                              <span className="category">{cat}</span>
                            )
                          )}
                      </small>
                    </IonText>
                  </IonCol>
                 
                </IonRow>
                <IonRow>
                  <IonCol>
                    {actualProduct.productVariations.items[
                      getSelectedVariation
                    ] && (
                      <div
                        key={
                          actualProduct.productVariations.items[
                            getSelectedVariation
                          ].sku
                        }
                      >
                   
                       
                      <IonRow>
                        <IonCol className="ion-margin">
                        <IonText color="secondary">
                          <span>
                            <small>
                              SKU:{" "}
                              {
                                actualProduct.productVariations.items[
                                  getSelectedVariation
                                ].sku
                              }
                            </small>
                          </span>
                        </IonText> 
</IonCol>
                        <IonCol className="ion-text-right">
                          {actualProduct.productVariations.items.filter((pv) => pv.status == "ACTIVO").length > 0 && 
                  <IonButton
												fill="clear"
												onClick={() => {
													window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20${actualProduct.name}:%20https://www.novocorpo.com.mx/producto/${actualProduct.id}`);
												}}
											> 
												<IonIcon color="tertiary" slot="icon-only" icon={shareSocial} />
											</IonButton>}
                  </IonCol></IonRow>
                        <IonText color="tertiary" className="priceProduct">
                          <p>
                            <strong>
                              
                              {numberFormat.format(
                                actualProduct.productVariations.items[
                                  getSelectedVariation
                                ].cost)
                              }
                            </strong>
                          </p>
                        </IonText>
                        
                        <AddToCart
                          product={actualProduct}
                          variationID={getSelectedVariation}
                          amount={1}
                          subtotal={
                            actualProduct.productVariations.items[
                              getSelectedVariation
                            ].cost
                          }
                          shop={actualProduct.shopID}
                        />
                      </div>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {actualProduct.productVariations.items &&
                      actualProduct.productVariations.items.filter((variation) => variation.status == "APROBADO").map(
                        (variation, vindex) => (
                          <div
                            className="variationSelector"
                            key={variation.sku}
                          >
                            {variation.pictures &&
                              variation.pictures.map((picture, pindex) => (
                                <IonImg
                                  key={picture}
                                  src={picture}
                                  className={
                                    "variationImageSelector " +
                                    (getSelectedVariation === vindex
                                      ? "selected"
                                      : "")
                                  }
                                  onClick={() => {
                                    setSelectedVariation(vindex);
                                  }}
                                ></IonImg>
                              ))}
                          </div>
                        )
                      )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonRow>
                      <IonCol>
                        <IonChip
                          onClick={generalDescHandler}
                          color={
                            descType === "general" ? "primary" : "secondary"
                          }
                        >
                          Descripción
                        </IonChip>
                      </IonCol>
                      <IonCol>
                        <IonChip
                          onClick={technicalDescHandler}
                          color={
                            descType === "technical" ? "primary" : "secondary"
                          }
                        >
                          Detalles
                        </IonChip>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonText color="primary">
                          {descType === "general"
                            ? actualProduct.description
                            : actualProduct.specifications}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
       
        <FooterComponent />
      </IonContent>
    </IonPage>
  );
};

export default ProductDetailContent;
