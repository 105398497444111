import { shopsByPlan, shopsByStatus, shopsByUserProfileEmail } from './../graphql/queries';
import { createShop, updateNovocorpo, updateShop } from "./../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShopThunk } from "../index";
import { setError, setErrorMessage, setLoading, setWasCreated } from "../store/FlagSlice";
import { getShop, listShops } from "../graphql/queries";
import { editNovocorpo, postNovocorpo, setActualNovocorpo } from './NovocorpoSlice';

export interface ShopState {
  theShop: ShopObject;
  shops: ShopObject[];
  shopsPremium:ShopObject[];
}
export interface ShopObject {
  plan: string;
  name: string;
  shopID: string;
  userProfileEmail: string;
  logo?: string;
  status?: string;
  description?: string;
  slider?: string[];
  sliderStatus?: string;
  productsVarCounter?: number;
}

const initialState: ShopState = {
  theShop: {
    name: "",
    shopID: "",
    description: "",
    plan: "",
    status: "",
    userProfileEmail: "",
    slider: [],
    sliderStatus: "PENDIENTE DE APROBACIÓN",
    productsVarCounter: 0,
  },
  shops: [],
  shopsPremium:[]
};

const shop = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setActualShop(state, { payload }: PayloadAction<ShopObject>) {
      state.theShop = payload;
    },
    setPremiumShops(state, { payload }:PayloadAction<ShopObject[]>){
      state.shopsPremium = payload;
    },
    setAllShops(state, { payload }: PayloadAction<ShopObject[]>) {
      state.shops = payload;
    },
    cleanShop(state) {
      state.theShop = {
        name: "",
        shopID: "",
        logo: "",
        description: "",
        plan: "",
        status: "",
        userProfileEmail: "",
        slider: [],
        sliderStatus: "",
        productsVarCounter: 0,
      };
      state.shops = [];
    },
  },
});

//Exports
export const { setActualShop, setAllShops, cleanShop, setPremiumShops } = shop.actions;

export default shop.reducer;

export const shopSelector = (state: { shopStore: ShopState }) =>
  state.shopStore;

//Middleware
export const getShopInfo =
  (input: any): ShopThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(shopsByUserProfileEmail, input ));
      if(res.data.shopsByUserProfileEmail.items.length > 0){
        dispatch(setActualShop(res.data.shopsByUserProfileEmail.items[0]));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };

export const listShopsInfo = (): ShopThunk => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res: any = await API.graphql(graphqlOperation(listShops, {}));
    dispatch(setAllShops(res.data.listShops.items));
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(error.errors[0].message.toString()));
  }
};
export const getShopInfoFilter = (input:any): ShopThunk => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res: any = await API.graphql(graphqlOperation(listShops, input));
    dispatch(setAllShops(res.data.listShops.items));
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(error.errors[0].message.toString()));
  }
};

export const postShop = (input: any): ShopThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(createShop, { input })
      );
      const theShop = {
        name: res.data.createShop.name,
        shopID: res.data.createShop.shopID,
        description: res.data.createShop.description,
        plan: res.data.createShop.plan,
        status: res.data.createShop.status,
        userProfileEmail: res.data.createShop.userProfileEmail,
      };
      dispatch(setActualShop(theShop));
      dispatch(setWasCreated(true));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };
};

export const editShop = (input: any): ShopThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(updateShop, { input }));
      if (res.data.updateShop.shopID === "novocorpo") {
        let novo = {novocorpoID: res.data.updateShop.shopID, logo: res.data.updateShop.logo, description: res.data.updateShop.description, slider: res.data.updateShop.slider}
        dispatch(editNovocorpo(novo))
        setActualNovocorpo(res.data.updateNovocorpo)
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };
};

export const getShopsByPlan =
  (input: any): ShopThunk =>
  async (dispatch: any) => {
    try {
      const filterPlan = {
        plan:input
      };
      const res: any = await API.graphql(graphqlOperation(shopsByPlan, filterPlan ));
      if(res.data.shopsByPlan.items.length > 0){
        dispatch(setPremiumShops(res.data.shopsByPlan.items));
      }
    } catch (error: any) {
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };

  export const getShopsByStatus =
  (input: any): ShopThunk =>
  async (dispatch: any) => {
    try {
      const filterPlan = {
        status:input
      };
      const res: any = await API.graphql(graphqlOperation(shopsByStatus, filterPlan ));
  
      if(res.data.shopsByStatus.items.length > 0){
        dispatch(setAllShops(res.data.shopsByStatus.items));
      }
    } catch (error: any) {
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };