import {
    IonButton,
    IonCard,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonItem,
    IonText,
    IonPage,
    IonRow,
    IonTitle,
    IonCardHeader,
    IonCardContent,
    IonButtons,
  } from "@ionic/react";
  import React, { useState } from "react";
  import {useSelector } from "react-redux";
  import {
    arrowBackOutline,
    printSharp,
  } from "ionicons/icons";
  import { useHistory } from "react-router";
  import {
    orderSelector,
  } from "../../store/OrderSlice";
  import "./PdfOrderView.css";

  export const ORDERS = "orders";
  export const ORDER = "order";
  
  const PdfOrderView: React.FC = (props) => {
    const history = useHistory();
    const [myOrder, setMyOrder] = useState(ORDER);
    const { pastOrder } = useSelector(orderSelector);
   
  

    const goBackHandler = () => {
      if (myOrder === ORDER) {
        history.push("/mis-ordenes");
    };

    };
  
    return (
      <IonPage>
       
        <IonContent>
          
    

             <IonRow>
               <IonCol sizeMd="10" sizeXs="11" offsetMd="1" offsetXs="0.5">

          <IonCard className="card-content">
            <IonCardHeader className="card-header">
            <IonRow>
              
       
          <IonCol className="ion-align-self-center" sizeMd="3" sizeXs="6"><IonImg src="https://novocorpodiseno.s3.amazonaws.com/logo/Novo%2Blogo%2Betiqueta.png" /></IonCol>
          <IonCol className="ion-align-self-center" sizeMd="7" sizeXs="0" />
          <IonCol sizeMd="2" sizeXs="4" className=" ion-padding">
            <IonButtons>
                <IonButton fill="clear" onClick={goBackHandler}>
                  <IonIcon slot="start" icon={arrowBackOutline} color="light" />
                </IonButton>
              <IonButton fill="clear" onClick={() => {window.print()}}>
                  <IonIcon slot="icon-only" icon={printSharp} color="light" />
                </IonButton></IonButtons>
              </IonCol>
      
        
   
            </IonRow>
            </IonCardHeader>
            <IonCardContent>
            {myOrder === ORDER && (
                <>
                  <IonTitle color="primary ion-margin-top">
                    <strong>DATOS DEL DESTINATARIO</strong>
                  </IonTitle>

                  <IonGrid>
                 
                          <IonRow>
                              <IonCol>
                              
                              <IonRow className="ion-margin">
                              <IonText color="tertiary">
                                Nombre:&nbsp;
                              </IonText>
                              <IonText color="primary"><strong>{`${ 
                                  JSON.parse(pastOrder.contactInfo).name
                                } ${
                                  JSON.parse(pastOrder.contactInfo).lastName
                                }`}</strong></IonText> 
                                
                           
                          </IonRow>
                                <IonRow className="ion-margin">
                              <IonText color="tertiary">
                                Teléfono:&nbsp;
                              </IonText>
                              <IonText color="primary"><strong>{` ${
                                  JSON.parse(pastOrder.contactInfo).phoneNumber
                                }`}</strong>
                              </IonText>
                            </IonRow>
                        
                          <IonRow className="ion-margin">
                            
                              <IonText color="tertiary">
                                Correo Electrónico:&nbsp;
                              </IonText>
                              <IonText color="primary"><strong>{`${
                                  JSON.parse(pastOrder.contactInfo).email
                                }`}</strong>
                              </IonText>
                         </IonRow> </IonCol>  
<IonCol className="ion-margin">
                              <IonText color="tertiary">
                                Dirección de envio:
                              </IonText>
                              <IonText color="primary">
                                <IonRow><strong>
                               {`Calle: ${
                                  JSON.parse(pastOrder.addressData).street
                                } ${JSON.parse(pastOrder.addressData).extNum} ${
                                  JSON.parse(pastOrder.addressData).intNum
                                }. Colonia: ${
                                  JSON.parse(pastOrder.addressData).neighbour
                                }. CP: ${
                                  JSON.parse(pastOrder.addressData).cp
                                }. Municipio: ${
                                  JSON.parse(pastOrder.addressData).municipio
                                }. Estado: ${
                                  JSON.parse(pastOrder.addressData).state
                                }. Fachada: ${
                                  JSON.parse(pastOrder.addressData).extInfo
                                }`}
                              </strong></IonRow></IonText>
                          
                          </IonCol>
</IonRow>

                    
                  </IonGrid>
</>)}

<IonRow>
                  
                    <IonTitle color="primary ion-margin-top">
                    <strong>
                        PRODUCTOS
                        </strong>
                      </IonTitle>
                
                  <IonCol className="ion-text-right ion-margin-top">
                      <IonText color="primary">
                       Cantidad
                      </IonText></IonCol>
                </IonRow>   
                    {pastOrder.products !== undefined && (
                      <>
                        {JSON.parse(pastOrder.products as any).map((productsList: any, index: number) => (
                                <>
                                  <IonItem key={index}>
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol size="6">
                                          <IonText color="primary">
                                            {productsList.product.name}
                                          </IonText>
                                          <br></br>
                                          <IonText color="medium">
                                            <i>
                                              <small>
                                                {
                                                  productsList.product
                                                    .productVariations.items[0]
                                                    .sku
                                                }
                                              </small>
                                            </i>
                                          </IonText>
                                        </IonCol>
                                        <IonCol
                                          className="ion-hide-sm-down ion-text-right"
                                          size="6"
                                        >
                                          <IonText color="primary">
                                            {productsList.amount}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonItem>
                                </>
                            
                        
                        ))}
                      </>
                    )}
                 

                  {pastOrder.cambaceoProducts !== undefined &&
                   
                      <>
                        {JSON.parse(pastOrder.cambaceoProducts as any).map(
                          (cambaceoProductsList: any, index: number) => (
                            <IonItem key={index}>
                              <IonGrid>
                                <IonRow>
                                  <IonCol size="6">
                                    <IonText color="primary">
                                      {cambaceoProductsList.product.name}
                                    </IonText>
                                    <br></br>
                                    <IonText color="medium">
                                      <i>
                                        <small>
                                          {cambaceoProductsList.product.sku}
                                        </small>
                                      </i>
                                    </IonText>
                                  </IonCol>
                                  <IonCol
                                    className="ion-hide-sm-down ion-text-right"
                                    size="6"
                                  >
                                    <IonText color="primary">
                                      {cambaceoProductsList.amount}
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                          )
                        )}
                      </>
                    }
          </IonCardContent> </IonCard> </IonCol></IonRow>
        </IonContent>
      </IonPage>
    );
  };
  
 // ReactDOM.render(<PdfOrder />, document.getElementById('root'));
  export default PdfOrderView;
  