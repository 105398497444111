import {
  IonButton,
      IonCol,
      IonGrid,
      IonItem,
      IonLabel,
      IonRow,
      IonSelect,
      IonSelectOption,
      IonText,
      IonTextarea,
    } from "@ionic/react";
import { text } from "ionicons/icons";
    import React, { useEffect, useState } from "react";
    import { useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router";
import { flagSelector } from "../../store/FlagSlice";
  import { editProductVariation, ProductVariationObject} from "../../store/ProductVariationSlice";
import UserFeedback from "../global/general/UserFeedback";
  import "./ProductVariationStatus.css"
    
    
    const ProductVariationState: React.FC<{
      setProductVarStatus: React.Dispatch<
        React.SetStateAction<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | undefined
        >
      >;
      status: "PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | undefined;
      prodVar: ProductVariationObject
    }> = (props) => {
    
     
      const [productVarStatus, setProductVarStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | string
    >(props.prodVar.status)
     const dispatch = useDispatch(); 
     const [text, setText] = useState("")  
     const location = useLocation()
     const {loading} = useSelector(flagSelector)
     const [toastMessage, setToastMessage] = useState("")
    
    const changeStatus = (statusChanged: any) => {
    
      let inputObject = {
        id: props.prodVar.id,
        status: statusChanged,
      };
      dispatch(editProductVariation(inputObject))
      setProductVarStatus(statusChanged)
     
    }

    const colorByStatus = () => {
      let color = [];
      switch (productVarStatus) {
        case "ACTIVO":
          return "success";
        case "INACTIVO":
          return "medium";
        case "PENDIENTE DE APROBACIÓN":
          return "warning";
          case "NO APROBADO":
          return "tertiary";
      }
    };
    const sendStatusReason = () => {
      if (text !== "") {
        dispatch(editProductVariation({id: props.prodVar.id, statusReason: text}))
        setToastMessage("¡Enviado!")
      }
    }

    useEffect(() => {}, [colorByStatus(), props.prodVar.status, setProductVarStatus, productVarStatus])
    
      return (<>
        <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
        <IonGrid>
      <IonButton className="ion-text-center" expand="block" color={colorByStatus()!}>
          <IonSelect 
            value={productVarStatus}
            onIonChange={(e) => {
              changeStatus(e.detail.value); setProductVarStatus(e.detail.value);
            }}
          >
            <IonSelectOption value="PENDIENTE DE APROBACIÓN">PENDIENTE DE APROBACIÓN</IonSelectOption>
            <IonSelectOption value="ACTIVO">
           APROBADO
            </IonSelectOption>
            <IonSelectOption value="NO APROBADO">
           NO APROBADO
            </IonSelectOption>
            <IonSelectOption value="INACTIVO">
           INACTIVO
            </IonSelectOption>
          </IonSelect>
          </IonButton>
        {/* </IonItem> */}
        {productVarStatus == "NO APROBADO" &&  <>
                    <IonItem>
                     <IonTextarea
                     rows={2}
                     cols={20}
                     placeholder="Escriba aquí la razón por la que el producto no fue aprobado..."
                     value={text}
                     onIonChange={(e) => setText(e.detail.value!)}>

                     </IonTextarea>
                     </IonItem>
                     <IonRow>
                       <IonCol className="ion-text-center">
                       <IonButton onClick={() => sendStatusReason()}>
                         Enviar comentarios
                       </IonButton>
                     </IonCol></IonRow>                    
                    </>}
         
      </IonGrid>
      </>);
    };
    
    export default ProductVariationState;