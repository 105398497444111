import React from "react";
import { IonButton, IonIcon, useIonPopover } from "@ionic/react";
import { personCircle } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, signOutAuth } from "../../../store/AuthSlice";
import ToolbarPopover from "../general/ToolbarPopover";

const SignOutButton: React.FC = () => {
  const dispatch = useDispatch();
  const {userProfile} = useSelector(authSelector)
  const [present, dismiss] = useIonPopover(ToolbarPopover, {
    signOut: () => {
      dispatch(signOutAuth());
      dismiss();
    },
  });
  return (
    <IonButton
      className="ion-align-self-center"
      size="small"
      fill="clear"
      color="light"
      onClick={(e) =>
        present({
          event: e.nativeEvent,
        })
      }
    >
      <IonIcon slot="start" icon={personCircle} />
      {window.innerWidth > 300 &&
      <div className="c">{userProfile.name}</div>}
    </IonButton>
  );
};

export default SignOutButton;
