import {  useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch } from "react-redux";
import { getOrdersByDate } from "../../store/OrderSlice";
import SelledProducts from "../../components/products/SelledProducts";


const SelledProductsPage: React.FC = () => {
	const dispatch = useDispatch();


	
	useIonViewWillEnter(() => {
		dispatch(getOrdersByDate({typeName: "Order", sortDirection: "DESC"}));
		});
	
	return <SelledProducts />;
};

export default SelledProductsPage;
