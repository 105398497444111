import { IonButton,IonButtons, IonContent, IonCard, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonTitle, IonLabel, IonList } from "@ionic/react";
import { useHistory } from "react-router";
import {homeOutline, storefrontOutline } from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import FooterComponent from "../global/general/FooterComponent";

const AboutUs: React.FC<{}> = (props) => {
	const history = useHistory();
	
    return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="page-content info">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButtons>
								<IonButton color="secondary" href="/"><IonIcon slot="start" icon={homeOutline}></IonIcon><IonText className="ion-hide-sm-down">Inicio</IonText></IonButton>
								<IonText color="secondary">/</IonText>
								<IonButton color="secondary" href="/nosotros"><IonIcon slot="start" icon={storefrontOutline}></IonIcon><IonText className="ion-hide-sm-down">Nosotros</IonText></IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="ion-text-center"><IonTitle color="primary"><h1>Nosotros</h1></IonTitle></IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="1">
							<IonText className="title ion-text-center" color="tertiary"><h2>¿Qué es Grupo Novocorpo?</h2></IonText>
							<IonLabel className="subtitle" color="primary"><p>ES UNA EMPRESA COMERCIALIZADORA QUE HA IDEADO UNA NUEVA FORMA DE OFRECER TUS PRODUCTOS Y/0 SERVICIOS, MEDIANTE UNA PLATAFORMA DIGITAL.<br></br>NUESTRO SERVICIO ESTÁ DIRIGIDO A EMPRENDEDORES QUE TIENEN NECESIDAD DE MAYOR DIFUSIÓN A BAJO COSTO.</p></IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="0.5">
							<IonText className="title ion-text-center" color="tertiary"><h2>Nos avalan años de experiencia</h2></IonText>
							<IonLabel className="subtitle" color="primary"><p>LOS INTEGRANTES DE NOVOCORPO TIENEN UN POCO MÁS DE 20 AÑOS DE EXPERIENCIA COMERCIALIZANDO PRODUCTOS Y SERVICIOS, DESDE 2001.<br></br>ESPECIALIZADOS EN LA CALIDAD EN EL SERVICIO AL CLIENTE.</p></IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="1">
							<IonText className="title ion-text-center" color="tertiary"><h2>Áreas de Expertise</h2></IonText>
							<IonLabel className="subtitle ion-text-center" color="primary">
								<IonList>
									<IonCard className="card"><IonText color="primary"><p><b>PROMOCIÓN</b></p></IonText></IonCard>
									<IonCard className="card"><IonText color="primary"><p><b>DIFUSIÓN</b></p></IonText></IonCard>
									<IonCard className="card"><IonText color="primary"><p><b>DISTRIBUCIÓN</b></p></IonText></IonCard>
								</IonList>
							</IonLabel>
						</IonCol>
						<IonCol sizeXs="12" sizeSm="12" sizeMd="5" offsetMd="0.5">
							<IonText className="title ion-text-center" color="tertiary"><h2>Servicios</h2></IonText>
							<IonLabel className="subtitle" color="primary">
								<ul>
									<li><p>SUBIR FOLLETO PROPIO (DE 16 A 25 PRODUCTOS)</p></li>
									<li><p>SUBIR CATÁLOGO PROPIO (DE 26 A 50 PRODUCTOS)</p></li>
									<li><p>LOGO DE PATROCINADOR NUESTRA PAGINA PRINCIPAL</p></li>
									<li><p>DESARROLLAMOS TU CATÁLOGO</p></li>
									<li><p>DISEÑAMOS TU PUBLICIDAD</p></li>
									<li><p>ENTREGAMOS A DOMICILIO TUS VENTAS DE LA PAGINA</p></li>
								</ul>
							</IonLabel>
						</IonCol>
					</IonRow>
                </IonGrid>
				<FooterComponent></FooterComponent>
            </IonContent>
        </IonPage>
    );
}

export default AboutUs;