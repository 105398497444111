/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShop = /* GraphQL */ `
  query GetShop($shopID: String!) {
    getShop(shopID: $shopID) {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $shopID: String
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShops(
      shopID: $shopID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        shopID
        name
        logo
        description
        plan
        status
        slider
        sliderStatus
        userProfileEmail
        productsVarCounter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNovocorpo = /* GraphQL */ `
  query GetNovocorpo($novocorpoID: String!) {
    getNovocorpo(novocorpoID: $novocorpoID) {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const listNovocorpos = /* GraphQL */ `
  query ListNovocorpos(
    $novocorpoID: String
    $filter: ModelNovocorpoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNovocorpos(
      novocorpoID: $novocorpoID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        novocorpoID
        logo
        description
        slider
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        address
        location
        typeOfOperation
        gpsPosition
        floors
        bedrooms
        bathrooms
        publicFeatures
        privateFeatures
        landSize
        propertySize
        price
        videoUrl
        pdf
        comments
        pictures
        shopID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPropertyFeature = /* GraphQL */ `
  query GetPropertyFeature($id: ID!) {
    getPropertyFeature(id: $id) {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const listPropertyFeatures = /* GraphQL */ `
  query ListPropertyFeatures(
    $filter: ModelPropertyFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        condition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCambaceo = /* GraphQL */ `
  query GetCambaceo($id: ID!) {
    getCambaceo(id: $id) {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listCambaceos = /* GraphQL */ `
  query ListCambaceos(
    $filter: ModelCambaceoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCambaceos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductVariation = /* GraphQL */ `
  query GetProductVariation($id: ID!) {
    getProductVariation(id: $id) {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const listProductVariations = /* GraphQL */ `
  query ListProductVariations(
    $filter: ModelProductVariationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductVariations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        variationName
        amount
        cost
        price
        availability
        condition
        pictures
        productID
        status
        statusReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCatalog = /* GraphQL */ `
  query GetCatalog($id: ID!) {
    getCatalog(id: $id) {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const listCatalogs = /* GraphQL */ `
  query ListCatalogs(
    $filter: ModelCatalogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCatalogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shopID
        type
        cover
        coverStatus
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCatalogPages = /* GraphQL */ `
  query GetCatalogPages($id: ID!) {
    getCatalogPages(id: $id) {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCatalogPagess = /* GraphQL */ `
  query ListCatalogPagess(
    $filter: ModelCatalogPagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCatalogPagess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        catalogID
        pageNumber
        pageImage
        productsString
        cambaceoProductsArray {
          id
          sku
          name
          price
          promoPrice
          promoType
          amount
          createdAt
          updatedAt
        }
        productsArray {
          id
          name
          description
          specifications
          category
          brandName
          shopID
          status
          featured
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userID: String!) {
    getUser(userID: $userID) {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userID: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        phoneNumber
        addressStreet
        addressExterior
        addressInterior
        addressNeighborhood
        addressZipcode
        addressState
        addressCity
        addressNotes
        invoiceName
        invoiceRfc
        invoiceStreet
        invoiceExterior
        invoiceInterior
        invoiceNeighborhood
        invoiceZipcode
        invoiceState
        invoiceCity
        userProfileEmail
        monederoPoints
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMonederoLog = /* GraphQL */ `
  query GetMonederoLog($id: ID!) {
    getMonederoLog(id: $id) {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const listMonederoLogs = /* GraphQL */ `
  query ListMonederoLogs(
    $filter: ModelMonederoLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonederoLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        points
        type
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const shopsByPlan = /* GraphQL */ `
  query ShopsByPlan(
    $plan: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopsByPlan(
      plan: $plan
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopID
        name
        logo
        description
        plan
        status
        slider
        sliderStatus
        userProfileEmail
        productsVarCounter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const shopsByStatus = /* GraphQL */ `
  query ShopsByStatus(
    $status: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopsByStatus(
      status: $status
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopID
        name
        logo
        description
        plan
        status
        slider
        sliderStatus
        userProfileEmail
        productsVarCounter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const shopsByUserProfileEmail = /* GraphQL */ `
  query ShopsByUserProfileEmail(
    $userProfileEmail: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopsByUserProfileEmail(
      userProfileEmail: $userProfileEmail
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        shopID
        name
        logo
        description
        plan
        status
        slider
        sliderStatus
        userProfileEmail
        productsVarCounter
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByName = /* GraphQL */ `
  query ProductsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByCategory = /* GraphQL */ `
  query ProductsByCategory(
    $category: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByCategory(
      category: $category
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByBrandName = /* GraphQL */ `
  query ProductsByBrandName(
    $brandName: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByBrandName(
      brandName: $brandName
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByShopID = /* GraphQL */ `
  query ProductsByShopID(
    $shopID: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByShopID(
      shopID: $shopID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const propertyByName = /* GraphQL */ `
  query PropertyByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        address
        location
        typeOfOperation
        gpsPosition
        floors
        bedrooms
        bathrooms
        publicFeatures
        privateFeatures
        landSize
        propertySize
        price
        videoUrl
        pdf
        comments
        pictures
        shopID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const propertyByShopID = /* GraphQL */ `
  query PropertyByShopID(
    $shopID: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyByShopID(
      shopID: $shopID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        address
        location
        typeOfOperation
        gpsPosition
        floors
        bedrooms
        bathrooms
        publicFeatures
        privateFeatures
        landSize
        propertySize
        price
        videoUrl
        pdf
        comments
        pictures
        shopID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const propertyByLocation = /* GraphQL */ `
  query PropertyByLocation(
    $location: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyByLocation(
      location: $location
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        address
        location
        typeOfOperation
        gpsPosition
        floors
        bedrooms
        bathrooms
        publicFeatures
        privateFeatures
        landSize
        propertySize
        price
        videoUrl
        pdf
        comments
        pictures
        shopID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const propertyFeatureByCondition = /* GraphQL */ `
  query PropertyFeatureByCondition(
    $condition: String
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertyFeatureByCondition(
      condition: $condition
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        condition
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const categoryByName = /* GraphQL */ `
  query CategoryByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoryByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const brandByName = /* GraphQL */ `
  query BrandByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cambaceoByName = /* GraphQL */ `
  query CambaceoByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCambaceoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cambaceoByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsVariationBySKU = /* GraphQL */ `
  query ProductsVariationBySKU(
    $sku: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductVariationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsVariationBySKU(
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        variationName
        amount
        cost
        price
        availability
        condition
        pictures
        productID
        status
        statusReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsVariationByAvailability = /* GraphQL */ `
  query ProductsVariationByAvailability(
    $availability: String
    $sku: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductVariationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsVariationByAvailability(
      availability: $availability
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        variationName
        amount
        cost
        price
        availability
        condition
        pictures
        productID
        status
        statusReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsVariationByCondition = /* GraphQL */ `
  query ProductsVariationByCondition(
    $condition: String
    $sku: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductVariationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsVariationByCondition(
      condition: $condition
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        variationName
        amount
        cost
        price
        availability
        condition
        pictures
        productID
        status
        statusReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsVariationByStatus = /* GraphQL */ `
  query ProductsVariationByStatus(
    $status: String
    $sku: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductVariationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsVariationByStatus(
      status: $status
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sku
        variationName
        amount
        cost
        price
        availability
        condition
        pictures
        productID
        status
        statusReason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByDate = /* GraphQL */ `
  query OrderByDate(
    $typeName: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByDate(
      typeName: $typeName
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByContactInfo = /* GraphQL */ `
  query OrderByContactInfo(
    $contactInfo: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByContactInfo(
      contactInfo: $contactInfo
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByStatus = /* GraphQL */ `
  query OrderByStatus(
    $status: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByUserProfileEmail = /* GraphQL */ `
  query OrderByUserProfileEmail(
    $userProfileEmail: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByUserProfileEmail(
      userProfileEmail: $userProfileEmail
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByShops = /* GraphQL */ `
  query OrderByShops(
    $shops: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByShops(
      shops: $shops
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderByCatalogs = /* GraphQL */ `
  query OrderByCatalogs(
    $catalogs: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByCatalogs(
      catalogs: $catalogs
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        products
        shops
        cambaceoProducts
        catalogs
        contactInfo
        billingData
        addressData
        subTotal
        iva
        shipping
        total
        payment
        status
        comments
        userProfileEmail
        typeName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const catalogsByName = /* GraphQL */ `
  query CatalogsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCatalogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    catalogsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopID
        type
        cover
        coverStatus
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const catalogsByShopID = /* GraphQL */ `
  query CatalogsByShopID(
    $shopID: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatalogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    catalogsByShopID(
      shopID: $shopID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopID
        type
        cover
        coverStatus
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const catalogsByType = /* GraphQL */ `
  query CatalogsByType(
    $type: String
    $shopID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatalogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    catalogsByType(
      type: $type
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        shopID
        type
        cover
        coverStatus
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const catalogPagesByCatalogID = /* GraphQL */ `
  query CatalogPagesByCatalogID(
    $catalogID: String
    $pageNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCatalogPagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    catalogPagesByCatalogID(
      catalogID: $catalogID
      pageNumber: $pageNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        catalogID
        pageNumber
        pageImage
        productsString
        cambaceoProductsArray {
          id
          sku
          name
          price
          promoPrice
          promoType
          amount
          createdAt
          updatedAt
        }
        productsArray {
          id
          name
          description
          specifications
          category
          brandName
          shopID
          status
          featured
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByUserProfileEmail = /* GraphQL */ `
  query UsersByUserProfileEmail(
    $userProfileEmail: String
    $userID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUserProfileEmail(
      userProfileEmail: $userProfileEmail
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userID
        phoneNumber
        addressStreet
        addressExterior
        addressInterior
        addressNeighborhood
        addressZipcode
        addressState
        addressCity
        addressNotes
        invoiceName
        invoiceRfc
        invoiceStreet
        invoiceExterior
        invoiceInterior
        invoiceNeighborhood
        invoiceZipcode
        invoiceState
        invoiceCity
        userProfileEmail
        monederoPoints
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const monederoLogsByUserID = /* GraphQL */ `
  query MonederoLogsByUserID(
    $userID: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMonederoLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    monederoLogsByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        points
        type
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
