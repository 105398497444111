import { IonButton } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductToOrder } from "../../store/OrderSlice";
import { ProductObject } from "../../store/ProductSlice";
import {
  orderSelector,
} from "../../store/OrderSlice";

const AddToCart: React.FC<{
  product: ProductObject;
  variationID: number;
  amount: number;
  subtotal: number;
  shop: string;
}> = (props) => {

  const { theOrder } = useSelector(orderSelector)
 
  const dispatch = useDispatch();


  const prodArray : {}[] = []
  

  const addToCartHandler = () => {

     prodArray.push(props)

     for (let i = 0; i < prodArray.length; i++) {
        if (prodArray[i] === (theOrder.products[i])) {
          }
          else if (theOrder.products[i] === props){
          }
          else if (!(theOrder.products.includes(props))) {
            dispatch(addProductToOrder(props));
          }
     }
   
  };

  return (
    <IonButton
      expand="block"
      fill="outline"
      color="tertiary"
      size="default"
      onClick={addToCartHandler}
    >
      Agregar a carrito
    </IonButton>
  );
};

export default AddToCart;
    