
import { useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch } from "react-redux";


import MyCambaceoProducts from "../../components/cambaceo/MyCambaceoProducts";
import { getAllCambaceo } from "../../store/CambaceoSlice";

const MyCambaceoProductsPage: React.FC = () => {
	const dispatch = useDispatch();


	useIonViewWillEnter(() => {
		
		dispatch(getAllCambaceo({}));
	});
	
	return <MyCambaceoProducts />;
};

export default MyCambaceoProductsPage;
