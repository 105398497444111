
import {
    IonButton,
        IonGrid,
        IonSelect,
        IonSelectOption,
      } from "@ionic/react";
      import React, { useEffect, useState } from "react";
      import { useDispatch, useSelector} from "react-redux";
  import { useLocation } from "react-router";
import { CatalogObject, editCatalog } from "../../store/CatalogSlice";
  import { flagSelector } from "../../store/FlagSlice";
  import UserFeedback from "../global/general/UserFeedback";
      
      
      const ImagesAdminStatusCatalogs: React.FC<{
        setCoverStatus: React.Dispatch<
          React.SetStateAction<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined
          >
        >;
        status: "PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined;
        catalog: CatalogObject
      }> = (props) => {
      
       
        const [coverStatus, setCoverStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | string
      >(props.catalog.coverStatus)
       const dispatch = useDispatch(); 
       const [text, setText] = useState("")  
       const location = useLocation()
       const {loading} = useSelector(flagSelector)
       const [toastMessage, setToastMessage] = useState("")
      
      const changeStatus = (statusChanged: any) => {
      
        let inputObject = {
          id: props.catalog.id,
          coverStatus: statusChanged,
        };
        dispatch(editCatalog(inputObject))
        setCoverStatus(statusChanged)
       
      }
  
      const colorByStatus = () => {
        let color = [];
        switch (coverStatus) {
          case "ACTIVO":
            return "success";
          case "PENDIENTE DE APROBACIÓN":
            return "warning";
            case "NO APROBADO":
            return "tertiary";
        }
      };
 
  
      useEffect(() => {}, [colorByStatus(), props.catalog.coverStatus, setCoverStatus, coverStatus])
      
        return (<>
          <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        />
          <IonGrid>
        <IonButton className="ion-text-center" expand="block" color={colorByStatus()!}>
            <IonSelect 
              value={coverStatus}
              onIonChange={(e) => {
                changeStatus(e.detail.value); setCoverStatus(e.detail.value);
              }}
            >
              <IonSelectOption value="PENDIENTE DE APROBACIÓN">PENDIENTE DE APROBACIÓN</IonSelectOption>
              <IonSelectOption value="ACTIVO">
             APROBADO
              </IonSelectOption>
              <IonSelectOption value="NO APROBADO">
             NO APROBADO
              </IonSelectOption>
            </IonSelect>
            </IonButton>
           
        </IonGrid>
        </>);
      };
      
      export default ImagesAdminStatusCatalogs;