import { getProductInfo } from './ProductSlice';
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropertyFeatureThunk } from "../index";
import {
	setError,
	setLoading,
	setWasCreated,
	setWasDeleted,
	setWasUpdated,
} from "./FlagSlice";
import { createPropertyFeature, deletePropertyFeature, updatePropertyFeature } from "../graphql/mutations";
import { getPropertyFeature, listPropertyFeatures } from "../graphql/queries";
import { getPropertyInfo } from './PropertySlice';


//Interfaces
export interface PropertyFeatureState {
	propertyFeature: PropertyFeatureObject[];
	actualPropertyFeature: PropertyFeatureObject;
}

export interface PropertyFeatureObject {
	
	id: string;
	name: string,
  condition: string,
}

//Initial State
const initialState: PropertyFeatureState = {
	propertyFeature: [],
	actualPropertyFeature: { 
		id: "",
		name: "",
		condition: "",
	}
};

//Reducers
const propertyFeature = createSlice({
	name: "propertyFeature",
	initialState,
	reducers: {
		setPropertyFeature(state, { payload }: PayloadAction<PropertyFeatureObject[]>) {
			state.propertyFeature = payload;
		},
		setActualPropertyFeature(state, { payload }: PayloadAction<PropertyFeatureObject>){
			state.actualPropertyFeature = payload;
		},
		cleanPropertyFeature(state) {
			state.propertyFeature = [];
		},
	},
});

//Exports
export const { setPropertyFeature, cleanPropertyFeature, setActualPropertyFeature } = propertyFeature.actions;

export default propertyFeature.reducer;

export const PropertyFeatureSelector = (state: { propertyFeatureStore: PropertyFeatureState }) =>
	state.propertyFeatureStore;

//Middleware
export const postPropertyFeature = (input: any): PropertyFeatureThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createPropertyFeature, { input }));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error.errors[0].message));
		}
	};
};

export const getAllPropertyFeature = (): PropertyFeatureThunk => async (dispatch: any) => {
	try {
		dispatch(setLoading(true));
		const res: any = await API.graphql(graphqlOperation(listPropertyFeatures));
		dispatch(setPropertyFeature(res.data.listPropertyFeatures.items));
		dispatch(setLoading(false));
	} catch (error:any) {
		dispatch(setLoading(false));
		dispatch(setError(error));
	}
};
export const readPropertyFeature =
	(input: any): PropertyFeatureThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(
				graphqlOperation(getPropertyFeature, { input })
			);
			dispatch(setPropertyFeature(res.data.listPropertyFeatures));
			dispatch(setLoading(false));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editPropertyFeature = (input: any): PropertyFeatureThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(updatePropertyFeature, { input }));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error:any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removePropertyFeature = (input: any): PropertyFeatureThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deletePropertyFeature, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error:any) {
			dispatch(setLoading(false));
		}
	};
};
