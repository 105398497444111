import {
    IonItem,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonButton,
    IonList,
    IonText,
    IonRouterLink,
  } from "@ionic/react";
import {
    logoFacebook,
    logoInstagram,
    logoLinkedin,
    logoTwitter,
} from "ionicons/icons";

import { ROUTE_ABOUT_US, ROUTE_CONTACT, ROUTE_FAQ, ROUTE_PRIVACITY, ROUTE_PROMOTE, ROUTE_TERMS } from "../../../utils/routes";

import React from "react";

const FooterComponent: React.FC = () => {

return (
    <IonGrid className=" ion-align-self-end ion-margin-top ion-no-padding">
          <IonRow className="footer-css-2">
            <IonCol>
              <IonGrid fixed className="">
                <IonRow>
                  <IonCol
                    sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" 
                    className="ion-align-self-center ion-text-center "
                  >
                    <IonList className="footer-css-2 ion-text-center">
                      <IonItem color="dark-tint " lines="none">
                        <IonRouterLink routerLink={ROUTE_ABOUT_US}>
                          <IonText color="secondary"> Acerca de Grupo Novocorpo</IonText>
                        </IonRouterLink>
                      </IonItem>

                      <IonItem color="dark-tint" lines="none">
                        <IonRouterLink routerLink={ROUTE_FAQ}>
                          <IonText color="secondary">Preguntas Frecuentes</IonText>
                        </IonRouterLink>
                      </IonItem>

                      <IonItem color="dark-tint" lines="none">
                        <IonRouterLink routerLink={ROUTE_PROMOTE}>
                          <IonText color="secondary">¿Cómo promocionarte con nosotros?</IonText>
                        </IonRouterLink>
                      </IonItem>
                    </IonList>
                  </IonCol>
                  <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12"  className=" ion-align-self-center">
                    <IonRow>
                      <IonCol size="12" className="ion-align-self-center ion-text-center">
                        <IonButton fill="clear">
                          <IonIcon
                            color="secondary"
                            slot="icon-only"
                            icon={logoFacebook}
                          ></IonIcon>
                        </IonButton>

                        <IonButton fill="clear">
                          <IonIcon
                            color="secondary"
                            slot="icon-only"
                            icon={logoTwitter}
                          ></IonIcon>
                        </IonButton>

                        <IonButton fill="clear">
                          <IonIcon
                            color="secondary"
                            slot="icon-only"
                            icon={logoLinkedin}
                          ></IonIcon>
                        </IonButton>

                        <IonButton fill="clear">
                          <IonIcon
                            color="secondary"
                            slot="icon-only"
                            icon={logoInstagram}
                          ></IonIcon>
                        </IonButton>
                      </IonCol>
                      <IonCol size="12" className="ion-text-center ion-margin">
                        <IonRouterLink routerLink={ROUTE_CONTACT}>
                            <IonText color="secondary">Contacto</IonText>
                        </IonRouterLink>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>

          <IonRow className="footer-css ion-text-center ion-padding">
            <IonCol className="footer-text-style">
              <IonText color="medium">2021 Novocorpo, Inc. | </IonText>
              <IonRouterLink color="medium" routerLink={ROUTE_TERMS}>Términos y Condiciones | </IonRouterLink>
              <IonRouterLink color="medium" routerLink={ROUTE_PRIVACITY}>Aviso Legal</IonRouterLink>
            </IonCol>
          </IonRow>
    </IonGrid>
)};

export default FooterComponent;