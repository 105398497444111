import React, { useState } from "react";
import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useSelector } from "react-redux";
import { editShop, listShopsInfo, postShop, shopSelector } from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
import { flagSelector } from "../../store/FlagSlice";
import ShopAlert from "../alerts/ShopAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Toolbar from "../global/general/Toolbar";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";

export const SHOP_LANDING = "shopLanding";
export const SHOP_PLAN = "shopPlan";
export const SHOP_FORM = "shopForm";
export const SHOP_PLAN_ONE = "planOne";
export const SHOP_PLAN_TWO = "planTwo";
export const SHOP_PLAN_THREE = "planThree";

const MyShopEditContent: React.FC = () => {
	const history = useHistory();
	const { theShop } = useSelector(shopSelector);
	const {  loading } = useSelector(flagSelector);
	const [toastMessage, setToastMessage] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	
	const goBackHandler = () => {
		history.replace("/mi-tienda");
	};

	const fieldArray = [
		{
			name: "name",
			label: "Nombre tienda",
			type: "text" as FieldTypes,
			value: useState(theShop.name),
			placeholder: "Ej: Venta de Cochera Mary",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
		},
		{
			name: "description",
			label: "Descripción tienda",
			type: "textarea" as FieldTypes,
			value: useState(theShop.description ? theShop.description : ""),
			placeholder: "Ej: Muebles antiguos ...",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
		},
		{
      name: "logo",
      label: "Logo",
      type: "images" as FieldTypes,
      value: useState(theShop.logo ? JSON.stringify(theShop.logo) : ""),
      imageArray: useState([""]),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
      showPreview: true,
      max: 1,
      unavailableImageURL: UNAVAILABLE_IMAGE,
      bucketURL: BUCKET_URL,
    },
	];
	const actionObject = {
		add: {
			inputObject: {},
			dispatchFunction: postShop,
			pushRoute: "/mi-tienda",
		},
		edit: {
			inputObject: {},
			dispatchFunction: editShop,
			pushRoute: "/mi-tienda",
		},
		delete: {
			inputObject: {},
			dispatchFunction: listShopsInfo,
			pushRoute: "/mi-tienda",
		},
	};
	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<ShopAlert showAlert={showAlert} setShowAlert={setShowAlert} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<ErrorAlert />

			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size-xs="12" size-sm="10" offset-sm="1" size-md="8" offset-md="2" size-lg="8" offset-lg="2" size-xl="8" offset-xl="2">
							<AmonytForm
								title="Editar Información"
								fieldArray={fieldArray}
								actionObject={actionObject}
								add={false}
                disableDelete={true}
								hiddenFields={[
									{ name: "shopID", value: theShop.shopID },
								]}
							/>
							
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default MyShopEditContent;
