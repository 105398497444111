import { IonButton, IonCard, IonCol, IonContent,IonButtons, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonPage, IonRow, IonSearchbar, IonText, IonTitle } from "@ionic/react";
import { layers, homeOutline, layersOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import { catalogSelector, setActualCatalog } from "../../store/CatalogSlice";
import FooterComponent from "../global/general/FooterComponent";
import { ROUTE_CATALOG } from "../../utils/routes";
import './Catalogs.css';

const Catalogs: React.FC = () => {
	const history = useHistory();
  	const dispatch = useDispatch();
	const { catalogs } = useSelector(catalogSelector);
	const [searchText, setSearchText] = useState("");
	const catType = useContext(CatalogTypeContext);
	const lista = catalogs.filter(l=>l.type === `${catType.getCatalogType}`);

	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent>
				<IonGrid fixed>
					<>
					<IonRow>
						<IonCol>
							<IonButtons>
								<IonButton color="secondary" href="/">
								<IonIcon slot="start" icon={homeOutline}></IonIcon>
								<IonText className="ion-hide-sm-down">Inicio</IonText>
								</IonButton>
								<IonText color="secondary">/</IonText>
								<IonButton color="secondary" href="/catalogs">
								<IonIcon slot="start" icon={layersOutline}></IonIcon>
								<IonText className="ion-hide-sm-down">{(catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")}</IonText>
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
						<IonRow>
							<IonCol>
								<IonItem>
									<IonIcon slot="end" icon={layers} />
									<IonTitle color="tertiary"><h1>{(catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")}</h1></IonTitle>
								</IonItem>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonSearchbar
									value={searchText}
									onIonChange={(e) => setSearchText(e.detail.value!)}
									placeholder={"Nombre " + (catType.getCatalogType === "catalogo" ? "del catálogo" : "de la colección")}
									class="search-bar-color"
								/>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol className="catalogGrid">	
								{lista
									.map((catalog) => (
										<><IonRow className='ion-text-center'>
											<IonCol>
												
											<IonCard className="catalog-card" onClick={async(e) => {
											e.preventDefault();   
											await dispatch(setActualCatalog(catalog));
											history.push("/catalogo/" + catalog.id);
										  }}>
											<IonText><h5>{catalog.name}</h5></IonText>
											
                    						<img max-height="240" max-width="345" className='ion-no-margin' src={catalog.cover}/>
										</IonCard>
										{catalog.url? <IonButton className='' fill="outline" onClick={()=>{window.open(catalog.url)}}>Ver completo</IonButton>:''}
										
											</IonCol>
											</IonRow></>
									))}
							</IonCol>
						</IonRow>
						{lista.length<=0 && <IonGrid className="empty ion-text-center"><IonText color="primary">Sin {`${catType.getCatalogType}s`}</IonText></IonGrid>}
					</>
				</IonGrid>
				<FooterComponent/>
			</IonContent>
		</IonPage>
	);
};

export default Catalogs;
