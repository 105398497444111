import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonThumbnail,
  IonTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  arrowBackOutline,
  arrowForward,
  cash,
  pricetagOutline,
  receiptOutline,
  saveOutline,
  square,
} from "ionicons/icons";
import { useHistory } from "react-router";
import MyShopToolbar from "../shop/MyShopToolbar";
import Toolbar from "../../components/global/general/Toolbar";
import {
  editOrder,
  getOrderInfo,
  getOrdersByDate,
  OrderObject,
  orderSelector,
  setactualPastOrder,
} from "../../store/OrderSlice";
import { UNAVAILABLE_IMAGE } from "../../utils/constants";
import "./MyPurchaseOrders.css";
import { shopSelector } from "../../store/ShopSlice";
import { ProductObject } from "../../store/ProductSlice";
import OrderState from "./OrderState";
import { ROUTE_SHOP_SELLED_PRODUCTS } from "../../utils/routes";

export const ORDERS = "orders";
export const ORDER = "order";

const MyPurchaseOrders: React.FC = () => {
  const history = useHistory();
  const [myOrder, setMyOrder] = useState(ORDERS);
  const [text, setText] = useState("")
  const { pastOrder } = useSelector(orderSelector);
  const dispatch = useDispatch();
  const o = useSelector(orderSelector);
  const orders = o.orders;
  const { theShop } = useSelector(shopSelector);
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const [status, setStatus] = useState<
  "PENDIENTE DE SURTIR" | "SURTIDO" | "PENDIENTE DE ENTREGA" | "ENTREGADO" | undefined
>()
const [paymentStatus, setPaymentStatus] = useState<"PENDIENTE" | "RECIBIDO" | undefined
>()

  const myOrders: OrderObject[] = []; //ORDENES CON PRODUCTOS DE MI TIENDA
  let myProducts: ProductObject[] = [];
  let mySubtotal: number = 0;

  for (let i = 0; i < orders.length; i++) {
    if (orders[i].shops.includes(theShop.shopID)) {
      myOrders.push(orders[i]);
    }
  }
  if (
    myOrders.length > 0 &&
    theShop.shopID !== "novocorpo" &&
    myOrder === ORDER
  ) {
    const myProducts = JSON.parse(pastOrder.products as any).filter(
      (productsList: any) => productsList.product.shopID === theShop.shopID
    );
    for (let i = 0; i < myProducts.length; i++) {
      mySubtotal = mySubtotal + myProducts[i].subtotal;
    }
  };

  
  useEffect(() => {  
    if (myOrder == ORDER) {
      setactualPastOrder(pastOrder);
       dispatch(getOrderInfo({pastOrder}));
      }
       else if (myOrder == ORDERS ) {
        dispatch(getOrdersByDate({typeName: "Order", sortDirection: "DESC"}));
       }
      }, 
  [pastOrder, myOrder]
  )

  const generatePdfOrder = () => {
    setMyOrder(ORDER);
    history.push("/mis-ordenes/generate-order");
  };
  
const goBackHandler = () => {
    if (myOrder === ORDERS) {
      history.push("/mi-tienda");
    } else if (myOrder === ORDER) {
      setMyOrder(ORDERS)
    } 
    
  };

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar
        iconName={cash}
        title={myOrder === ORDER ? "Orden de Compra" : "Ordenes de Compra"}
      />

      <IonContent>
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonRow>
            <IonCol sizeMd="9.73" sizeXs="5">
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
            {theShop.shopID === 'novocorpo' && myOrder === ORDERS && <>
            <IonCol>
              <IonButton onClick={() => history.push(ROUTE_SHOP_SELLED_PRODUCTS)}>
               <IonIcon
               slot="start"
                 icon={pricetagOutline}
               />
                Productos vendidos
              </IonButton>
            </IonCol>

         
            <IonCol offsetXs="3" sizeXs="12" sizeMd="4" offsetMd="3">
              <IonItem lines="none">
                <IonIcon color="secondary" icon={square} slot="start" />
                <IonText>Pago recibido</IonText>
              </IonItem>
            </IonCol>
            <IonCol offsetXs="3" sizeXs="12" sizeMd="3" offsetMd="0">
              <IonItem lines="none">
                <IonIcon color="primary" icon={square} slot="start" />
                <IonText>Pago pendiente</IonText>
              </IonItem>
            </IonCol>
          </>}

            {theShop.shopID === 'novocorpo' && myOrder === ORDER &&
            
             <IonCol className="ion-text-center">
               <IonButton fill="outline" onClick={() => {generatePdfOrder()}}>
                 <IonIcon slot="start" icon={receiptOutline} />
                 Generar etiqueta
               </IonButton>
             </IonCol>
           }
          </IonRow>
          <IonRow>
            <IonCol>
              {myOrder === ORDERS &&
                myOrders.length > 0 &&
                Object.entries(myOrders).map(([key, order]) => (
                  <IonCard key={key}>
                    <IonItem
                      className="show-click"
                      color={
                        order.payment === "PENDIENTE" ? "primary" : "secondary"
                      }
                      onClick={() => {
                        dispatch(setactualPastOrder(order));
                        setMyOrder(ORDER);
                      }}
                    >
                      <IonIcon
                        color={
                          order.payment === "PENDIENTE" ? "light" : "primary"
                        }
                        icon={cash}
                      />
                      <IonGrid>
                        <IonRow>
                          <IonCol sizeMd="2" sizeSm="5">
                            <IonLabel
                              color={
                                order.payment === "PENDIENTE"
                                  ? "light"
                                  : "primary"
                              }
                            >
                              {new Date(order.createdAt).toLocaleDateString(
                                "es-MX"
                              )}
                            </IonLabel>
                          </IonCol>
                          <IonCol
                            sizeMd="8"
                            sizeSm="1"
                            className="ion-hide-sm-down"
                          >
                            <IonLabel
                              color={
                                order.payment === "PENDIENTE"
                                  ? "light"
                                  : "primary"
                              }
                            >
                              {order.userProfileEmail}
                            </IonLabel>
                          </IonCol>
                          <IonCol sizeMd="2" sizeSm="5">
                            <IonLabel
                              color={
                                order.payment === "PENDIENTE"
                                  ? "light"
                                  : "primary"
                              }
                            >
                              {numberFormat.format(order.total)}
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <IonIcon icon={arrowForward}></IonIcon>
                    </IonItem>
                  </IonCard>
                ))}

              {myOrder === ORDER && (
                <>
                  <IonTitle color="primary ion-text-center">
                    Pedido de: <strong>{pastOrder.userProfileEmail}</strong>
                  </IonTitle>

                  <IonGrid className="orderCard">
                    <IonRow>
                      {theShop.shopID === "novocorpo" && (
                        <>
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating" color="tertiary">
                                Información de contacto:
                              </IonLabel>
                              <IonInput
                                color="primary"
                                value={`${
                                  JSON.parse(pastOrder.contactInfo).name
                                } ${
                                  JSON.parse(pastOrder.contactInfo).lastName
                                }`}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating" color="tertiary">
                                Teléfono:
                              </IonLabel>
                              <IonInput
                                color="primary"
                                value={`${
                                  JSON.parse(pastOrder.contactInfo).phoneNumber
                                }`}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating" color="tertiary">
                                Correo Electrónico:
                              </IonLabel>
                              <IonInput
                                color="primary"
                                value={`${
                                  JSON.parse(pastOrder.contactInfo).email
                                }`}
                              />
                            </IonItem>
                          </IonCol>
                        </>
                      )}{" "}
                    </IonRow>

                    {theShop.shopID === "novocorpo" && (
                      <>
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel position="floating" color="tertiary">
                                Dirección de envio:
                              </IonLabel>
                              <IonTextarea
                                color="primary"
                                value={`Calle: ${
                                  JSON.parse(pastOrder.addressData).street
                                } ${JSON.parse(pastOrder.addressData).extNum} ${
                                  JSON.parse(pastOrder.addressData).intNum
                                }. Colonia: ${
                                  JSON.parse(pastOrder.addressData).neighbour
                                }. CP: ${
                                  JSON.parse(pastOrder.addressData).cp
                                }. Municipio: ${
                                  JSON.parse(pastOrder.addressData).municipio
                                }. Estado: ${
                                  JSON.parse(pastOrder.addressData).state
                                }. Fachada: ${
                                  JSON.parse(pastOrder.addressData).extInfo
                                }`}
                              />
                            </IonItem>
                          </IonCol>
                          {JSON.parse(pastOrder.billingData).rfccompany !==
                            "" && (
                            <IonCol>
                              <IonItem>
                                <IonLabel position="floating" color="tertiary">
                                  Datos de facturación:
                                </IonLabel>
                                <IonTextarea
                                  color="primary"
                                  value={`Compañia: ${
                                    JSON.parse(pastOrder.billingData).rfccompany
                                  }. RFC: ${
                                    JSON.parse(pastOrder.billingData).rfc
                                  }. Email: ${
                                    JSON.parse(pastOrder.billingData).rfcmail
                                  }. Dirección: ${
                                    JSON.parse(pastOrder.billingData).rfcstreet
                                  } ${
                                    JSON.parse(pastOrder.billingData).rfcextNum
                                  } ${
                                    JSON.parse(pastOrder.billingData).rfcintNum
                                  }. Colonia: ${
                                    JSON.parse(pastOrder.billingData)
                                      .rfcneighbour
                                  }. CP: ${
                                    JSON.parse(pastOrder.billingData).rfccp
                                  }. Estado: ${
                                    JSON.parse(pastOrder.billingData).rfcstate
                                  }. Municipio: ${
                                    JSON.parse(pastOrder.billingData)
                                      .rfcmunicipio
                                  }`}
                                />
                              </IonItem>
                            </IonCol>
                          )}
                        </IonRow>
                      </>
                    )}

                    <IonRow>
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
                     {theShop.shopID === 'novocorpo' ? 
                         <OrderState paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} status={status} setStatus={setStatus} />
                         
                         :
                         <IonRow>
                         <IonText className="ion-margin" color="primary">{'Estado de la orden: ' + pastOrder.status}</IonText>
                         </IonRow>
                         
                         }
                       
                                   
                      </IonCol>
                      <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
                        <IonItem>
                          <IonLabel position="floating" color="tertiary">
                            Fecha creación:
                          </IonLabel>
                          <IonInput
                            color="primary"
                            value={new Date(
                              pastOrder.createdAt
                            ).toLocaleDateString("es-MX")}
                          />
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  {theShop.shopID === "novocorpo" && (
                    <IonGrid>
                      <IonItem className="ion-margin-bottom ion-text-left">
                       
                       <IonTextarea
                           rows={4}
                           cols={20}
                           placeholder="Añadir comentarios..."
                           value={text}
                           onIonChange={(e) => setText(e.detail.value!)}>

                           </IonTextarea>
                           </IonItem>
                           <IonRow>
                             <IonCol className="ion-text-center">
                             <IonButton onClick={() => dispatch(editOrder({
      id: pastOrder.id,
      comments: text
    }))}>
                               <IonIcon slot="start" icon={saveOutline}/>
                               Guardar comentarios
                             </IonButton>
                           </IonCol></IonRow>
                     
                    </IonGrid>
                  )}

                  <IonGrid>
                    <IonRow className="ion-margin">
                      <IonTitle className="ion-text-center" color="tertiary">
                        PRODUCTOS
                      </IonTitle>
                    </IonRow>
                    {pastOrder.products !== undefined && (
                      <>
                        {theShop.shopID !== "novocorpo"
                          ? JSON.parse(pastOrder.products as any)
                              .filter(
                                (productsList: any) =>
                                  productsList.product.shopID === theShop.shopID
                              )
                              .map((productsList: any, index: number) => (
                                <>
                                  <IonItem key={index}>
                                    <IonGrid>
                                    
                                      <IonRow className="ion-hide-sm-down">
                                        <IonCol size="5">
                                          <IonLabel color="tertiary">
                                            Producto
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          size="2"
                                          className="ion-text-center"
                                        >
                                          <IonLabel color="tertiary">
                                            Cantidad
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          size="2"
                                          className="ion-text-center"
                                        >
                                          <IonLabel color="tertiary">
                                            Precio Unitario
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          size="2"
                                          className="ion-text-right"
                                        >
                                          <IonLabel color="tertiary">
                                            Total
                                          </IonLabel>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol sizeMd="1" sizeSm="2">
                                          <IonThumbnail>
                                            <IonImg src={UNAVAILABLE_IMAGE} />
                                          </IonThumbnail>
                                        </IonCol>
                                        <IonCol sizeMd="4" sizeSm="8">
                                          <IonLabel color="primary">
                                            {productsList.product.name}
                                          </IonLabel>
                                          <br></br>
                                          <IonLabel color="medium">
                                            <i>
                                              <small>
                                                {
                                                  productsList.product
                                                    .productVariations.items[0]
                                                    .sku
                                                }
                                              </small>
                                            </i>
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          className="ion-hide-sm-down ion-text-center"
                                          size="2"
                                        >
                                          <IonLabel color="primary">
                                            {productsList.amount}
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          className="ion-hide-sm-down ion-text-center"
                                          size="2"
                                        >
                                          <IonLabel color="primary">
                                            {"$" +
                                              productsList.product
                                                .productVariations.items[0]
                                                .cost}
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          sizeMd="2"
                                          sizeSm="2"
                                          className="ion-text-right"
                                        >
                                          <IonLabel color="primary">
                                            {"$" +
                                              productsList.product
                                                .productVariations.items[0]
                                                .cost *
                                                productsList.amount}
                                          </IonLabel>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonItem>
                                </>
                              ))
                          : JSON.parse(pastOrder.products as any).map(
                              (productsList: any, index: number) => (
                                <IonItem key={index}>
                                  <IonGrid>
                                    <IonRow className="ion-hide-sm-down">
                                      <IonCol size="5">
                                        <IonLabel color="tertiary">
                                          Producto
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        size="2"
                                        className="ion-text-center"
                                      >
                                        <IonLabel color="tertiary">
                                          Cantidad
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        size="2"
                                        className="ion-text-center"
                                      >
                                        <IonLabel color="tertiary">
                                          Precio Unitario
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        size="2"
                                        className="ion-text-right"
                                      >
                                        <IonLabel color="tertiary">
                                          Total
                                        </IonLabel>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol sizeMd="1" sizeSm="2">
                                        <IonThumbnail>
                                          <IonImg src={UNAVAILABLE_IMAGE} />
                                        </IonThumbnail>
                                      </IonCol>
                                      <IonCol sizeMd="4" sizeSm="8">
                                        <IonLabel color="primary">
                                          {productsList.product.name}
                                        </IonLabel>
                                        <br></br>
                                        <IonLabel color="medium">
                                          <i>
                                            <small>
                                              {
                                                productsList.product
                                                  .productVariations.items[0]
                                                  .sku
                                              }
                                            </small>
                                          </i>
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        className="ion-hide-sm-down ion-text-center"
                                        size="2"
                                      >
                                        <IonLabel color="primary">
                                          {
                                            productsList.product
                                              .productVariations.items[0].amount
                                          }
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        className="ion-hide-sm-down ion-text-center"
                                        size="2"
                                      >
                                        <IonLabel color="primary">
                                          {"$" +
                                            productsList.product
                                              .productVariations.items[0].cost}
                                        </IonLabel>
                                      </IonCol>
                                      <IonCol
                                        sizeMd="2"
                                        sizeSm="2"
                                        className="ion-text-right"
                                      >
                                        <IonLabel color="primary">
                                          {"$" +
                                            productsList.product
                                              .productVariations.items[0]
                                              .cost *
                                              productsList.product
                                                .productVariations.items[0]
                                                .amount}
                                        </IonLabel>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonItem>
                              )
                            )}
                      </>
                    )}
                  </IonGrid>

                  {pastOrder.cambaceoProducts !== undefined &&
                    theShop.shopID === "novocorpo" && (
                      <>
                        {JSON.parse(pastOrder.cambaceoProducts as any).map(
                          (cambaceoProductsList: any, index: number) => (
                            <IonItem key={index}>
                              <IonGrid>
                                <IonRow className="ion-hide-sm-down">
                                  <IonCol size="5">
                                    <IonLabel color="tertiary">
                                      Producto
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol size="2" className="ion-text-center">
                                    <IonLabel color="tertiary">
                                      Cantidad
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol size="2" className="ion-text-center">
                                    <IonLabel color="tertiary">
                                      Precio Unitario
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol size="2" className="ion-text-right">
                                    <IonLabel color="tertiary">Total</IonLabel>
                                  </IonCol>
                                </IonRow>
                                <IonRow>
                                  <IonCol sizeMd="1" sizeSm="2">
                                    <IonThumbnail>
                                      <IonImg src={UNAVAILABLE_IMAGE} />
                                    </IonThumbnail>
                                  </IonCol>
                                  <IonCol sizeMd="4" sizeSm="8">
                                    <IonLabel color="primary">
                                      {cambaceoProductsList.product.name}
                                    </IonLabel>
                                    <br></br>
                                    <IonLabel color="medium">
                                      <i>
                                        <small>
                                          {cambaceoProductsList.product.sku}
                                        </small>
                                      </i>
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol
                                    className="ion-hide-sm-down ion-text-center"
                                    size="2"
                                  >
                                    <IonLabel color="primary">
                                      {cambaceoProductsList.amount}
                                    </IonLabel>
                                    
                                  </IonCol>
                                  <IonCol
                                    className="ion-hide-sm-down ion-text-center"
                                    size="2"
                                  >
                                    <IonLabel color="primary">
                                      {"$" + cambaceoProductsList.product.price}
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol
                                    sizeMd="2"
                                    sizeSm="2"
                                    className="ion-text-right"
                                  >
                                    <IonLabel color="primary">
                                      {"$" +
                                        cambaceoProductsList.product.price *
                                          cambaceoProductsList.amount}
                                    </IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                          )
                        )}
                      </>
                    )}

                  <IonRow className="">
                    <IonCol
                      offsetMd="7"
                      offsetLg="8"
                      sizeMd="5"
                      sizeLg="4"
                      sizeXs="12"
                    >
                      <IonCard>
                        <IonItem className="ion-padding-horizontal">
                          <IonCol>
                            <IonLabel color="primary">
                              <strong>Costo de Envío </strong>
                            </IonLabel>
                          </IonCol>
                          <IonText color="primary">
                            {numberFormat.format(pastOrder.shipping)}
                          </IonText>
                        </IonItem>
                        <IonItem className="ion-padding-horizontal">
                          <IonCol>
                            <IonLabel color="primary">
                              <strong>Subtotal </strong>
                            </IonLabel>
                          </IonCol>
                          <IonText color="primary">
                            {theShop.shopID === "novocorpo"
                              ? numberFormat.format(pastOrder.subTotal)
                              : numberFormat.format(mySubtotal)}
                          </IonText>
                        </IonItem>
                        <IonItem className="ion-margin-bottom ion-padding-horizontal">
                          <IonCol>
                            <IonLabel color="primary">
                              <strong>IVA </strong>
                            </IonLabel>
                          </IonCol>
                          <IonText color="primary">
                            {theShop.shopID === "novocorpo"
                              ? numberFormat.format(pastOrder.subTotal * 0.16)
                              : numberFormat.format(mySubtotal * 0.16)}
                          </IonText>
                        </IonItem>
                        <IonItem className="ion-margin-bottom ion-padding-horizontal">
                          <IonCol>
                            <IonLabel color="tertiary">
                              <strong>TOTAL</strong>
                            </IonLabel>
                          </IonCol>
                          <IonText color="tertiary">
                            <strong>
                              {theShop.shopID === "novocorpo"
                                ? numberFormat.format(pastOrder.total)
                                : numberFormat.format(mySubtotal * 1.16)}
                            </strong>
                          </IonText>
                        </IonItem>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default MyPurchaseOrders;