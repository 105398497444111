import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
  IonThumbnail,
  IonTitle,
} from "@ionic/react";
import {
  addCircleOutline,
  cartOutline,
  removeCircleOutline,
  trashOutline,
} from "ionicons/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Toolbar from "../../components/global/general/Toolbar";
import {
  deleteProductOfOrder,
  orderSelector,
  updateProductAmount,
  deleteProductOfOrder2,
  updateProductAmount2,
} from "../../store/OrderSlice";
import FooterComponent from "../global/general/FooterComponent";
import "./Cart.css";
import {
  catalogSelector,
} from "../../store/CatalogSlice";

const MyCartContent: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const { theOrder } = useSelector(orderSelector);
  useEffect(() => {
  }, [theOrder]);

  const disableButton = () => {
    if (theOrder.products.length === 0 && theOrder.cambaceoProducts.length === 0) {
      return true
    } else {
      return false
    }
  }
  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonIcon slot="end" icon={cartOutline} />
                <IonText color="tertiary">
                  <h1>Mi carrito</h1>
                </IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonGrid className="productosList">
                <IonRow>
                  <IonCol>
                  {theOrder.products.length !== 0 && 
  <IonTitle color="primary" className="ion-margin-top ion-margin-bottom">Mis productos de tiendas</IonTitle> }
                    <IonList>
                      {theOrder.products.map(
                        (productsList: any, index: number) => (
                          <IonItem key={index}>
                           <IonGrid>
                              <IonRow>
                                <IonCol
                                  className="ion-align-self-center"
                                  size-xs="2"
                                  size-sm="2"
                                  size-md="1"
                                  size-lg="1"
                                >
                                  <IonThumbnail className="thumbnail">
                                    <IonImg
                                      src={
                                        productsList.product.productVariations
                                          .items[productsList.variationID]
                                          .pictures[0]
                                      }
                                    />
                                  </IonThumbnail>
                                </IonCol>
                                <IonCol
                                  className="ion-align-self-center"
                                  size-xs="10"
                                  size-sm="10"
                                  size-md="4"
                                  size-lg="4"
                                >
                                  <IonText color="primary">
                                    <strong>{productsList.product.name}</strong>
                                  </IonText>
                                  <br></br>
                                  <IonText>
                                    <small>
                                      <i>
                                        {
                                          productsList.product.productVariations
                                            .items[productsList.variationID].sku
                                        }
                                      </i>
                                    </small>
                                  </IonText>
                                  <br></br>
                                 
                        <IonText>
                          <small>
                            <i>{productsList.shop}</i>
                          </small>
                            </IonText>
                            <br></br>
                                  <IonText color="primary">
                                    <small>
                                      {numberFormat.format(
                                        productsList.product.productVariations
                                          .items[productsList.variationID].cost
                                      )}
                                    </small>
                                  </IonText>
                                  <IonText color="medium">
                                    <small> - P. Unitario</small>
                                  </IonText>
                                </IonCol>
                                <IonCol
                                  className="ion-align-self-center"
                                  size-xs="12"
                                  size-sm="12"
                                  size-md="3"
                                  size-lg="3"
                                >
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol
                                        size="5"
                                        className="ion-text-center"
                                      >
                                        <IonButton
                                          fill="clear"
                                          size="small"
                                          onClick={() => {
                                            let newAmount =
                                              parseInt(productsList.amount) - 1;
                                            if (newAmount <= 1) {
                                              newAmount = 1;
                                            }
                                            dispatch(
                                              updateProductAmount({
                                                id: index,
                                                amount: newAmount,
                                              })
                                            );
                                          }}
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={removeCircleOutline}
                                          />
                                        </IonButton>
                                      </IonCol>
                                      <IonCol
                                        size="2"
                                        className="ion-text-center"
                                      >
                                        <IonText>
                                          {parseInt(productsList.amount)}
                                        </IonText>
                                      </IonCol>
                                      <IonCol
                                        size="5"
                                        className="ion-text-center ion-align-self-center"
                                      >
                                        <IonButton
                                          fill="clear"
                                          size="small"
                                          onClick={() => {
                                            const newAmount =
                                              parseInt(productsList.amount) + 1;
                                            dispatch(
                                              updateProductAmount({
                                                id: index,
                                                amount: newAmount,
                                              })
                                            );
                                          }}
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={addCircleOutline}
                                          />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCol>
                                <IonCol
                                  className="ion-align-self-center ion-text-center"
                                  size-xs="6"
                                  size-sm="6"
                                  size-md="2"
                                  size-lg="2"
                                >
                                  <IonText color="primary">
                                    {numberFormat.format(
                                      productsList.product.productVariations
                                        .items[productsList.variationID].cost *
                                        parseInt(productsList.amount)
                                    )}
                                  </IonText>
                                </IonCol>

                                <IonCol
                                  className="ion-align-self-center ion-text-end"
                                  size-xs="6"
                                  size-sm="6"
                                  size-md="2"
                                  size-lg="2"
                                >
                                  <IonButton
                                    fill="clear"
                                    size="small"
                                    onClick={() => {
                                      dispatch(
                                        deleteProductOfOrder({ id: index })
                                      );
                                    }}
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      color="danger"
                                      icon={trashOutline}
                                    />
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonItem>
                        )
                      )}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
{theOrder.cambaceoProducts.length !== 0 && 
  <IonTitle color="primary" className="ion-margin">Mis productos de catálogos</IonTitle> }
          <IonList>
            {theOrder.cambaceoProducts.map(
              (cambaceoProductsList: any, index: number) => (
                <IonItem key={index}>
                  <IonGrid>
                    <IonRow>
                      <IonCol className="ion-margin">
                        <IonThumbnail className="thumbnail">
                                    <IonImg 
                                      src={cambaceoProductsList.catalogCover}
                                    />
                                  </IonThumbnail>
                                  </IonCol>
                                  <IonCol
                                  className="ion-align-self-center"
                                  size-xs="10"
                                  size-sm="10"
                                  size-md="4"
                                  size-lg="4"
                                >
                        <IonText color="primary">
                          <strong>{cambaceoProductsList.product.name}</strong>
                        </IonText>
                        <br></br>
                        <IonText>
                          <small>
                            <i>{cambaceoProductsList.product.sku}</i>
                          </small>
                        </IonText>
                        <br></br>
                        <IonText>
                          <small>
                            <i>
                              {cambaceoProductsList.catalogName}
                            </i>
                          </small>
                        </IonText>
                        <br></br>
                        <IonText color="primary">
                          <small>
                            {numberFormat.format(
                              cambaceoProductsList.product.price
                            )}
                          </small>
                        </IonText>
                        
                        <IonText color="medium">
                          <small> - P. Unitario</small>
                        </IonText>
                      </IonCol>
                      <IonCol
                        className="ion-align-self-center"
                        size-xs="12"
                        size-sm="12"
                        size-md="3"
                        size-lg="3"
                      >
                        <IonGrid>
                          <IonRow>
                            <IonCol size="5" className="ion-text-center">
                              <IonButton
                                fill="clear"
                                size="small"
                                onClick={() => {
                                  let newAmount =
                                    parseInt(cambaceoProductsList.amount) - 1;
                                  if (newAmount <= 1) {
                                    newAmount = 1;
                                  }
                                  dispatch(
                                    updateProductAmount2({
                                      id: index,
                                      amount: newAmount,
                                    })
                                  );
                                }}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={removeCircleOutline}
                                />
                              </IonButton>
                            </IonCol>
                            <IonCol size="2" className="ion-text-center">
                              <IonText>
                                {parseInt(cambaceoProductsList.amount)}
                              </IonText>
                            </IonCol>
                            <IonCol
                              size="5"
                              className="ion-text-center ion-align-self-center"
                            >
                              <IonButton
                                fill="clear"
                                size="small"
                                onClick={() => {
                                  const newAmount =
                                    parseInt(cambaceoProductsList.amount) + 1;
                                  dispatch(
                                    updateProductAmount2({
                                      id: index,
                                      amount: newAmount,
                                    })
                                  );
                                }}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={addCircleOutline}
                                />
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol
                        className="ion-align-self-center ion-text-center"
                        size-xs="6"
                        size-sm="6"
                        size-md="2"
                        size-lg="2"
                      >
                        <IonText color="primary">
                          {numberFormat.format(
                            cambaceoProductsList.product.price *
                              parseInt(cambaceoProductsList.amount)
                          )}
                        </IonText>
                      </IonCol>

                      <IonCol
                        className="ion-align-self-center ion-text-end"
                        size-xs="6"
                        size-sm="6"
                        size-md="2"
                        size-lg="2"
                      >
                        <IonButton
                          fill="clear"
                          size="small"
                          onClick={() => {
                            dispatch(deleteProductOfOrder2({ id: index }));
                          }}
                        >
                          <IonIcon
                            slot="icon-only"
                            color="danger"
                            icon={trashOutline}
                          />
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              )
            )}
          </IonList>

          <IonRow className="totalBox, ion-margin-top">
            <IonCol offsetMd="7" offsetLg="8" sizeMd="5" sizeLg="4" sizeXs="12">
              <IonCard>
                <IonItem className="ion-padding-horizontal">
                  <IonCol>
                    <IonLabel color="primary">
                      <strong>Subtotal </strong>
                    </IonLabel>
                  </IonCol>
                  <IonText color="primary">
                    {numberFormat.format(theOrder.subTotal)}
                  </IonText>
                </IonItem>

                <IonItem className="ion-margin-bottom ion-padding-horizontal">
                  <IonCol>
                    <IonLabel color="primary">
                      <strong>IVA </strong>
                    </IonLabel>
                  </IonCol>
                  <IonText color="primary">
                    {numberFormat.format(theOrder.subTotal * 0.16)}
                  </IonText>
                </IonItem>

                <IonItem className="ion-margin-bottom ion-padding-horizontal">
                  <IonCol>
                    <IonLabel color="tertiary">
                      <strong>TOTAL</strong>
                    </IonLabel>
                  </IonCol>
                  <IonText color="tertiary">
                    <strong>
                      {numberFormat.format(theOrder.subTotal * 1.16)}
                    </strong>
                  </IonText>
                </IonItem>

                <IonButton
                  className="ion-margin-bottom ion-padding-horizontal"
                  expand="block"
                  color="tertiary"
                  onClick={() => {
                    history.push("/checkout");
                  }}
                  disabled={disableButton()}
                >
                  Realizar Compra
                </IonButton>
                <IonRow>
            <IonCol className="ion-text-center">
              <IonButton fill="clear" routerLink="/home">
                <u>{"Seguir comprando >>"}</u>
              </IonButton>
            </IonCol>
          </IonRow>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterComponent></FooterComponent>
      </IonContent>
    </IonPage>
  );
};

export default MyCartContent;
