import {
  IonPage,
  IonHeader,
  IonContent,
  IonGrid,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AmonytField, {
  FieldFields,
  FieldTypes,
} from "../../amonyt/components/AmonytField";
import {
  cleanOrder,
  orderSelector,
  postOrder,
} from "../../store/OrderSlice";
import Toolbar from "../../components/global/general/Toolbar";
import { fieldValidations } from "../../amonyt/validations/FieldValidations";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import UserFeedback from "../../components/global/general/UserFeedback";
import CreateOrderAlert from "../../components/alerts/CreateOrderAlert";
import { cartOutline } from "ionicons/icons";
import { authSelector } from "../../store/AuthSlice";
import '../../components/cart/Cart.css';
import {
  catalogSelector,
} from "../../store/CatalogSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";

const Checkout: React.FC = () => {
  const dispatch = useDispatch();
  const [whatsAppMssg, setWhatsAppMssg] = useState("");
  const currencyFormat = { style: "currency", currency: "MXN" };
  const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);
  const { theOrder } = useSelector(orderSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [billChecked, setBillChecked] = useState(false);
  const { wasCreated, loading } = useSelector(flagSelector);
  const {userProfile} = useSelector(authSelector)
  const [showAlert, setShowAlert] = useState(false);
  let { actualCatalog } = useSelector(catalogSelector);

  useEffect(() => {
    if (wasCreated) {
      setToastMessage("¡Orden enviada satisfactoriamente!");
      dispatch(cleanOrder())
      dispatch(setWasCreated(false))
      setShowAlert(true);
    }
  }, [wasCreated, loading]);

  const name = {
    name: "name",
    label: "Nombre(s)",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: Luis Alejandro",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const lastname = {
    name: "lastname",
    label: "Apellido(s)",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: Mendoza Guevara",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const cel = {
    name: "cel",
    label: "Teléfono celular",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: 0123456789",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const mail = {
    name: "mail",
    label: "Correo electrónico",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: nombre@gmail.com",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "email", //"email" | "password" | "text" | "tel"
  };

  const street = {
    name: "mail",
    label: "Calle",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const extnum = {
    name: "mail",
    label: "Número Exterior",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const intnum = {
    name: "mail",
    label: "Número Interior",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: false,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const neighbour = {
    name: "mail",
    label: "Colonia",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const cp = {
    name: "mail",
    label: "C.P.",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const municipio = {
    name: "mail",
    label: "Alcadía / Municipio",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const state = {
    name: "mail",
    label: "Estado",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const extinfo = {
    name: "mail",
    label: "Instrucciones adicionales para encontrar tu dirección",
    type: "textarea" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Color de la casa, puntos de referencia, entre calles, etc.",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: true,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfccompany = {
    name: "company",
    label: "Nombre de Razón Social",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: Luis Mendoza, Epsilon",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfc = {
    name: "rfc",
    label: "RFC",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: DEVA880815EI2",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcmail = {
    name: "rfcmail",
    label: "Correo electrónico",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "Ej: nombre@gmail.com",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: billChecked ? "email" : "text", //"email" | "password" | "text" | "tel"
  };

  const rfcstreet = {
    name: "mail",
    label: "Calle",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcextnum = {
    name: "mail",
    label: "Número Exterior",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcintnum = {
    name: "mail",
    label: "Número Interior",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: false,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcneighbour = {
    name: "mail",
    label: "Colonia",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfccp = {
    name: "mail",
    label: "C.P.",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcmunicipio = {
    name: "mail",
    label: "Alcadía / Municipio",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfcstate = {
    name: "mail",
    label: "Estado",
    type: "text" as FieldTypes, //"text" | "password" | "email" | "number" | "search" | "tel" | "url"
    value: useState(""),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: billChecked,
    validationType: "text", //"email" | "password" | "text" | "tel"
  };

  const rfccsf = {
    name: "pdf",
    label: "Constancia de Situación Fiscal",
    type: "pdf" as FieldTypes,
    value: useState(""),
    pdfArray: useState([""]),
    placeholder: "",
    error: useState(false),
    errorMessage: useState(""),
    isRequired: false,
    validationType: "text",
    showPreview: true,
    max: 1,
    unavailableImageURL: UNAVAILABLE_IMAGE,
    bucketURL: BUCKET_URL,
  };

  const generateOrder = () => {
    if (
      fieldValidations(name) ||
      fieldValidations(lastname) ||
      fieldValidations(cel) ||
      fieldValidations(mail) ||
      fieldValidations(street) ||
      fieldValidations(extnum) ||
      fieldValidations(intnum) ||
      fieldValidations(municipio) ||
      fieldValidations(neighbour) ||
      fieldValidations(cp) ||
      fieldValidations(state) ||
      fieldValidations(extinfo) ||
      fieldValidations(rfccompany) ||
      fieldValidations(rfc) ||
      fieldValidations(rfcmail) ||
      fieldValidations(rfcstreet) ||
      fieldValidations(rfcextnum) ||
      fieldValidations(rfcintnum) ||
      fieldValidations(rfcneighbour) ||
      fieldValidations(rfccp) ||
      fieldValidations(rfcmunicipio) ||
      fieldValidations(rfcstate)
    ) {
      setToastMessage("Por favor llena los campos indicados");
    } else {
      const contactInfo = {
        name: name.value[0],
        lastName: lastname.value[0],
        phoneNumber: cel.value[0],
        email: mail.value[0],
      };
      const addressData = {
        street: street.value[0],
        extNum: extnum.value[0],
        intNum: intnum.value[0],
        neighbour: neighbour.value[0],
        cp: cp.value[0],
        municipio: municipio.value[0],
        state: state.value[0],
        extInfo: extinfo.value[0],
      };
      const billingData = {
        rfccompany: rfccompany.value[0],
        rfc: rfc.value[0],
        rfcmail: rfcmail.value[0],
        rfcstreet: rfcstreet.value[0],
        rfcextNum: rfcextnum.value[0],
        rfcintNum: rfcintnum.value[0],
        rfcneighbour: rfcneighbour.value[0],
        rfccp: rfccp.value[0],
        rfcmunicipio: rfcmunicipio.value[0],
        rfcstate: rfcstate.value[0],
        rfccsf: rfc.value[0]
      };

      const total = theOrder.subTotal*1.16;
      const status = "PENDIENTE DE SURTIR";
      const payment = "PENDIENTE";
      const userProfileEmail = userProfile.email?userProfile.email:"anonimo";
      const iva = theOrder.subTotal;
      const shipping = 0;
      const typeName = "Order"
      

      const postCartOrder = {
        input: {
          products: JSON.stringify(theOrder.products),
          cambaceoProducts: JSON.stringify(theOrder.cambaceoProducts),
          shops: JSON.stringify(theOrder.shops),
          catalogs: JSON.stringify(theOrder.catalogs),
          contactInfo: JSON.stringify(contactInfo),
          billingData: JSON.stringify(billingData),
          addressData: JSON.stringify(addressData),
          subTotal: theOrder.subTotal,
          iva: iva,
          shipping: shipping,
          total: total,
          payment: payment,
          status: status,
          userProfileEmail: userProfileEmail,
          typeName: typeName,
        },
      };

      
     for (let i = 0; i++; theOrder.cambaceoProducts.length) {
        theOrder.cambaceoProducts[i].catalogName = actualCatalog.name
        theOrder.cambaceoProducts[i].catalogCover = actualCatalog.cover
         }

      

      let productsList = "";
      theOrder.products.forEach((product) => {
        productsList =
          productsList +
          // " sku: " +
          // product.product.productVariations.items[0] +
          " producto: " +
          product.product.name +
          " cantidad: " +
          product.amount +
          " subtotal: " +
          product.subtotal +
          "%0a";
      });
      const wa =
        "https://wa.me/5212225636193?text=*Órden%20de%20compra%20de%20usuario:*%0a*" +
        theOrder.userProfileEmail +
        "*%0aInformación de contacto:%0a" +
        contactInfo.name +
        "%20" +
        contactInfo.lastName +
        "%0aNúmero de teléfono:%20" +
        contactInfo.phoneNumber +
        "%0aEmail:%20" +
        contactInfo.email +
        "*%0aInformación de envío:%0a" +
        addressData.street +
        "%20" +
        addressData.extNum +
        "%20" +
        addressData.intNum +
        "%20" +
        addressData.neighbour +
        "%20" +
        addressData.cp +
        "%20" +
        addressData.municipio +
        "%20" +
        addressData.state +
        "%0aReferencias:%20" +
        addressData.extInfo +
        "*%0aDatos de facturación:%0a" +
        billingData.rfccompany +
        "%20" +
        billingData.rfc +
        "%20" +
        billingData.rfcmail +
        "%20" +
        billingData.rfcstreet +
        "%20" +
        billingData.rfcextNum +
        "%20" +
        billingData.rfcintNum + 
        "%20" +
        billingData.rfcneighbour +
        "%20" +
        billingData.rfccp +
        "%20" +
        billingData.rfcmunicipio +
        "%20" +
        billingData.rfcstate +
        "%0aPedido:%20" +
        productsList +
        "%0aSubtotal:%20*" +
        numberFormat.format(theOrder.subTotal) +
        "%0aIVA:%20*" +
        numberFormat.format(theOrder.subTotal * 0.16) +
        "%0a*TOTAL*:%20*" +
        numberFormat.format(theOrder.subTotal * 1.16) +
        "*";
      setWhatsAppMssg(wa);
      dispatch(postOrder(postCartOrder));
    }
  };



  return (
    <IonPage>
      <IonHeader><Toolbar /></IonHeader>
      <CreateOrderAlert showAlert={showAlert} setShowAlert={setShowAlert} link={whatsAppMssg}/>
      <UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]}/>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonIcon slot="end" icon={cartOutline} />
                <IonText color="tertiary"><h1>Enviar Pedido</h1></IonText>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonGrid fixed>
              <IonRow>
                <IonCol sizeXs="12" sizeMd="8">
                  <IonRow className="formsList">
                    <IonCol sizeSm="12" sizeXs="12" sizeMd="12">
                      <IonCard>
                        <IonCardHeader color="primary">
                          <IonCardTitle>Persona que recibe</IonCardTitle>
                        </IonCardHeader>
                        <IonGrid fixed>
                          <IonRow>
                            <IonCol sizeXs="12"
                              sizeMd="10"
                              offsetMd="1"
                              sizeLg="10"
                              offsetLg="1"
                            >
                              <AmonytField dataField={name as FieldFields} />
                              <br />
                              <AmonytField   dataField={lastname as FieldFields} />
                              <br />
                              <AmonytField  dataField={cel as FieldFields} />
                              <br />
                              <AmonytField   dataField={mail as FieldFields} />
                              <br />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeXs="12" sizeMd="12">
                      <IonCard>
                        <IonCardHeader color="primary">
                          <IonCardTitle>Dirección de envío</IonCardTitle>
                        </IonCardHeader>
                        <IonGrid fixed>
                          <IonRow>
                            <IonCol
                              sizeXs="12"
                              sizeMd="10"
                              offsetMd="1"
                              sizeLg="10"
                              offsetLg="1"
                            >
                              <AmonytField   dataField={street as FieldFields} />
                              <br />
                              <AmonytField   dataField={extnum as FieldFields} />
                              <br />
                              <AmonytField   dataField={intnum as FieldFields} />
                              <br />
                              <AmonytField  dataField={neighbour as FieldFields} />
                              <br />
                              <AmonytField  dataField={cp as FieldFields} />
                              <br />
                              <AmonytField  dataField={municipio as FieldFields} />
                              <br />
                              <AmonytField  dataField={state as FieldFields} />
                              <br />
                              <AmonytField  dataField={extinfo as FieldFields} />
                              <br />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCard>
                    </IonCol>
                    <IonCol sizeSm="12" sizeXs="12" sizeMd="12">
                        <IonItem>
                          <IonLabel>Deseo facturar</IonLabel>
                          <IonCheckbox
                            checked={billChecked}
                            onIonChange={(e) => setBillChecked(e.detail.checked)}
                          />
                        </IonItem>
                    </IonCol>
                    <IonCol sizeSm="12" sizeXs="12" sizeMd="12">
                        {billChecked && (
                          <IonCard>
                            <IonCardHeader color="primary">
                              <IonCardTitle>Datos de facturación</IonCardTitle>
                            </IonCardHeader>
                            <IonGrid fixed>
                              <IonRow>
                                <IonCol
                                  sizeXs="12"
                                  sizeMd="10"
                                  offsetMd="1"
                                  sizeLg="10"
                                  offsetLg="1"
                                >
                                  <AmonytField  dataField={rfccompany as FieldFields} />
                                  <br />
                                  <AmonytField dataField={rfc as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcmail as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcstreet as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcextnum as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcintnum as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcneighbour as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfccp as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcmunicipio as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfcstate as FieldFields}  />
                                  <br />
                                  <AmonytField dataField={rfccsf as FieldFields}  />
                                  <br />
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCard>
                        )}
                    </IonCol>
                  </IonRow>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4">
                  <IonRow className="checkoutBox">
                    <IonCol sizeXs="12" sizeMd="3" offsetMd="8">
                      <IonCard>
                        <IonItem className="ion-padding-horizontal">
                          <IonLabel color="primary"><strong>Subtotal </strong></IonLabel>
                          <IonText color="primary">{numberFormat.format(theOrder.subTotal)}</IonText>
                        </IonItem>
                        <IonItem className="ion-padding-horizontal">
                          <IonLabel color="primary"><strong>Envío </strong></IonLabel>
                          <IonText color="primary">{numberFormat.format(80)}</IonText>
                        </IonItem>
                        <IonItem className="ion-padding-horizontal">
                          <IonLabel color="primary"><strong>IVA </strong></IonLabel>
                          <IonText color="primary">{numberFormat.format(theOrder.subTotal * 0.16)}</IonText>
                        </IonItem>
                        <IonItem className="ion-margin-bottom ion-padding-horizontal">
                          <IonLabel color="tertiary"><strong>TOTAL </strong></IonLabel>
                          <IonText color="tertiary"><strong>{numberFormat.format((theOrder.subTotal * 1.16)+80)}</strong></IonText>
                        </IonItem>
                        <IonButton
                          className="ion-margin-bottom ion-padding-horizontal"
                          expand="block"
                          color="tertiary"
                          onClick={generateOrder}
                          // onClick={() => {
                          //   history.push("/checkout");
                          // }}
                        >Comprar</IonButton>
                        {/* <IonButton
                          className="ion-margin-bottom ion-padding-horizontal"
                          shape="round"
                          expand="block"
                          color="success"
                          href={whatsAppMssg}
                          target="_blank"
                          //  onClick={() => {
                          //    history.push("/checkout");
                          //  }}
                          //onClick={upload}
                        >
                          Enviar orden por Whatsapp
                        </IonButton> */}
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Checkout;
