import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useDispatch, useSelector } from "react-redux";
import { authSelector, setIsAuthed, setUserProfile } from "./store/AuthSlice";
import { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";

/* Pages */
import SignUpPage from "./pages/auth/SignUpPage";
import HomePage from "./pages/home/HomePage";
import SignInPage from "./pages/auth/SignInPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import MyShopPage from "./pages/shop/MyShopPage";
import MyProductsPage from "./pages/Products/MyProductsPage";
import Checkout from "./pages/Cart/Checkout";
import ProductDetailPage from "./pages/Products/ProductDetailPage";
import MyShopsPage from "./pages/shop/MyShopsPage";
import MyCartPage from "./pages/Cart/MyCartPage";
import MyCatalogsPage from "./pages/catalogs/MyCatalogsPage";
import ProfileClient from "./pages/profile/ProfileClient";
import ProfileOrders from "./pages/profile/ProfileOrders";
import MyShopEditPage from "./pages/shop/MyShopEditPage";
import { 
  ROUTE_PRIVACITY, 
  ROUTE_TERMS,
  ROUTE_SHOP_EDIT,
  ROUTE_SHOP_SLIDER, 
  ROUTE_SHOP_CATALOGS,
  ROUTE_SHOP,
  ROUTE_SHOP_CATALOGS_NEW,
  ROUTE_SHOP_CATALOGS_PAGES,
  ROUTE_SHOP_CATALOGS_PAGES_NEW,
  ROUTE_SHOP_CATALOGS_PAGES_EDIT,
  ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS,
  ROUTE_CATALOG,
  ROUTE_CATALOGS,
  ROUTE_ABOUT_US,
  ROUTE_CONTACT,
  ROUTE_FAQ,
  ROUTE_SHOP_CATALOGS_EDIT,
  ROUTE_SHOP_PRODUCTS,
  ROUTE_PROMOTE,
  ROUTE_PROFILE_CLIENT,
  ROUTE_PROFILE_ORDERS,
  ROUTE_PROFILE_MONEDERO,
  ROUTE_ADMIN_SHOPS,
  ROUTE_ADMIN_PRODUCTS,
  ROUTE_SHOP_SELLED_PRODUCTS,
  ROUTE_ADMIN_USERS,
  ROUTE_SHOP_CAMBACEO,
  ROUTE_SHOP_CAMBACEO_NEW,
  ROUTE_SHOP_CAMBACEO_EDIT,
  ROUTE_SHOP_PROPERTIES,
  ROUTE_SHOP_PROPERTIES_ADD,
ROUTE_SHOP_PROPERTYFEATURE_ADD,
ROUTE_ADMIN_CATEGORIES,
ROUTE_ADMIN_IMAGES} from "./utils/routes";
import MyShopSliderPage from "./pages/shop/MyShopSliderPage";
import MyPlan from "./components/shop/MyPlan";
import PrivacyAndPolicyPage from "./pages/info/PrivacyAndPolicyPage";
import TermsAndConditionsPage from "./pages/info/TermsAndConditionsPage";
import MyCatalogsNewPage from "./pages/catalogs/MyCatalogsNewPage";
import MyCatalogsPagesPage from "./pages/catalogs/MyCatalogsPagesPage";
import MyCatalogsPagesNewPage from "./pages/catalogs/MyCatalogsPagesNewPage";
import MyCatalogsPagesEditPage from "./pages/catalogs/MyCatalogsPagesEditPage";
import MyCatalogsPagesEditProductsPage from "./pages/catalogs/MyCatalogsPagesEditProductsPage";
import PromotePage from "./pages/info/PromotePage";
import AboutUsPage from "./pages/info/AboutUsPage";
import FaqPage from "./pages/info/FaqPage";
import ContactPage from "./pages/info/ContactPage";
import MyCatalogsEditPage from "./pages/catalogs/MyCatalogsEditPage";
import Menu from "./components/global/menu/Menu";
import CatalogPage from "./pages/catalogs/CatalogPage";
import CatalogsPage from "./pages/catalogs/CatalogsPage";
import AllProductsPage from "./pages/Products/AllProductsPage";
import AllShopsPage from "./pages/shop/AllShopsPage";
import ShopProdroductsPage from "./pages/ShopProducts/ShopProductsPage";
import MyPurchaseOrdersPage from "./pages/Orders/MyPurchaseOrdersPage";
import ProfileMonedero from "./pages/profile/ProfileMonedero";
import AllUsersPage from "./pages/User/AllUsersPage";
import PdfOrderView from "./components/Orders/PdfOrderView";
import MyCambaceoProductsPage from "./pages/cambaceo/MyCambaceoProductsPage";
import MyCambaceoFormPage from "./pages/cambaceo/MyCambaceoFormPage";
import MyCambaceoFormEditPage from "./pages/cambaceo/MyCambaceoFormEditPage";
import SelledProductsPage from "./pages/Products/SelledProductsPage";
import MyPropertiesPage from "./pages/properties/MyPropertiesPage";
import MyPropertiesFormPage from "./pages/properties/MyPropertiesFormPage";
import PropertiesViewPage from "./pages/properties/PropertiesViewPage";
import PropertyDetailPage from "./pages/properties/PropertyDetailPage";
import PropertyFeaturesListPage from "./pages/propertyFeatures/PropertyFeaturesListPage";
import { AuthContext } from "./context/AuthContext";
import CategoryListPage from "./pages/category/CategoryListPage";
import SelectedCategoryPage from "./pages/category/SelectedCategoryPage";
import ImagesAdminPage from "./pages/imgAdmin/ImagesAdminPage";


const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthed } = useSelector(authSelector);
  const [auth2, setAuth2] = useState(false);
  const isAuthCntxt = useContext(AuthContext);
  useEffect(() => {
    getUser().then(async (userData) => {
      if (userData) {
        const userProfile = {
          name: userData.attributes.name,
          lastname: userData.attributes.family_name,
          email: userData.attributes.email,
        };
        dispatch(setUserProfile(userProfile));
        dispatch(setIsAuthed(true));
        isAuthCntxt.setAuthStateTo(true)
      }
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser().then((userData) => userData);
  }

  
  return (
    <IonApp>
      <Menu />
           <IonReactRouter> 
           
        <IonRouterOutlet>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/"><Redirect to="/home" /></Route>
          <Route exact path="/shops/:shopSlug" component={MyShopsPage} />
          <Route exact path="/producto/:productID" render={() => { return <ProductDetailPage />; }} />
          <Route exact path="/catalogo/:catalogID" render={() => { return <CatalogPage />; }} />
          <Route exact path="/productos/:categoryID" render={() => { return <SelectedCategoryPage />; }} />
          <Route exact path="/inmueble/:propertyID" render={() => { return <PropertyDetailPage />; }} />
          <Route exact path="/mi-carrito" render={() => { return <MyCartPage />; }} />
          <Route path="/checkout" component={Checkout} exact={true} />
          <Route exact path="/inmuebles" render={() => { return <PropertiesViewPage /> }} />
          <Route exact path={ROUTE_SHOP_PRODUCTS} render={() => { return <ShopProdroductsPage /> }} />
          {/* <Route exact path={ROUTE_CATALOG} render={() => { return <CatalogPage /> }} /> */}
          <Route exact path={ROUTE_CATALOGS} render={() => { return <CatalogsPage /> }} />
          <Route exact path={ROUTE_PROMOTE} component={PromotePage} />
          <Route exact path={ROUTE_ABOUT_US} component={AboutUsPage} />
          <Route exact path={ROUTE_FAQ} component={FaqPage} />
          <Route exact path={ROUTE_CONTACT} component={ContactPage} />
          <Route exact path={ROUTE_PRIVACITY} component={PrivacyAndPolicyPage} />
          <Route exact path={ROUTE_TERMS} component={TermsAndConditionsPage} />
          

          <Route exact path="/signup" render={() => { return !isAuthed ? <SignUpPage /> : <Redirect to="/" />; }} />
          <Route exact path="/signin" render={() => { return !isAuthed ? <SignInPage /> : <Redirect to="/" />; }} />
          <Route exact path="/forgotpassword" render={() => { return !isAuthed ? <ForgotPasswordPage /> : <Redirect to="/" />; }} />      
          {/* ONLY CLIENT */}
          <Route exact path={ROUTE_PROFILE_CLIENT} render={() => { return isAuthed ? <ProfileClient /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_PROFILE_ORDERS} render={() => { return isAuthed ? <ProfileOrders/> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_PROFILE_MONEDERO} render={() => { return isAuthed ? <ProfileMonedero/> : <Redirect to="/" />; }} />
          <Route exact path="/mis-productos" render={() => { return isAuthed ? <MyProductsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_PROPERTIES} render={() => { return isAuthed ? <MyPropertiesPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_PROPERTIES_ADD} render={() => { return isAuthed ? <MyPropertiesFormPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_PROPERTYFEATURE_ADD} render={() => { return isAuthed ? <PropertyFeaturesListPage /> : <Redirect to="/" />; }} />
          <Route exact path="/mis-ordenes" render={() => { return isAuthed ? <MyPurchaseOrdersPage /> : <Redirect to="/" />; }} />
          <Route exact path="/mis-ordenes/generate-order" render={() => { return isAuthed ? <PdfOrderView /> : <Redirect to="/" />; }} />
          <Route exact path="/mi-plan" render={() => { return isAuthed ? <MyPlan /> : <Redirect to="/" />; }} />

          <Route exact path={ROUTE_SHOP} render={() => { return isAuthed ? <MyShopPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_EDIT} render={() => { return isAuthed ? <MyShopEditPage></MyShopEditPage> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_SLIDER} render={() => { return isAuthed ? <MyShopSliderPage /> : <Redirect to="/" />; }} />

          <Route exact path={ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS} render={() => { return isAuthed ? <MyCatalogsPagesEditProductsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS_PAGES_EDIT} render={() => { return isAuthed ? <MyCatalogsPagesEditPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS_PAGES_NEW} render={() => { return isAuthed ? <MyCatalogsPagesNewPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CAMBACEO} render={() => { return isAuthed ? <MyCambaceoProductsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CAMBACEO_NEW} render={() => { return isAuthed ? <MyCambaceoFormPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CAMBACEO_EDIT} render={() => { return isAuthed ? <MyCambaceoFormEditPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_SELLED_PRODUCTS} render={() => { return isAuthed ? <SelledProductsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS_PAGES} render={() => { return isAuthed ? <MyCatalogsPagesPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS_EDIT} render={() => { return isAuthed ? <MyCatalogsEditPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS_NEW} render={() => { return isAuthed ? <MyCatalogsNewPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_SHOP_CATALOGS} render={() => { return isAuthed ? <MyCatalogsPage /> : <Redirect to="/" />; }} />
          
          {/* only admin */}
          <Route exact path={ROUTE_ADMIN_SHOPS} render={() => { return isAuthed ? <AllShopsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_ADMIN_PRODUCTS} render={() => { return isAuthed ? <AllProductsPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_ADMIN_CATEGORIES} render={() => { return isAuthed ? <CategoryListPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_ADMIN_USERS} render={() => { return isAuthed ? <AllUsersPage /> : <Redirect to="/" />; }} />
          <Route exact path={ROUTE_ADMIN_IMAGES} render={() => { return isAuthed ? <ImagesAdminPage /> : <Redirect to="/" />; }} />

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};
export default App;
