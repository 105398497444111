import {
    IonButton,
    IonButtons,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonText,
    IonTextarea,
    IonTitle,
  } from "@ionic/react";
  import { arrowBackOutline, caretDownOutline, caretUpOutline, closeCircleOutline, pricetags, square } from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { flagSelector, setWasCreated, setWasUpdated } from "../../store/FlagSlice";
  import { editProduct, productSelector } from "../../store/ProductSlice";
  import UserFeedback from "../global/general/UserFeedback";
  import MyShopToolbar from "../shop/MyShopToolbar";
  import MyProductsForm from "./MyProductsForm";
  import ProductVariationForm from "./ProductVariationForm";
  import MyProductsCardVariation from "./MyProductsCardVariation";
  import ProductVariationsCard from "./ProductVariationsCard";
  import { ProductVariationObject } from "../../store/ProductVariationSlice";
  import { shopSelector } from "../../store/ShopSlice";
  import { useHistory } from "react-router";
  import Toolbar from "../global/general/Toolbar";
import CategoryList from "../category/CategoryList";
import AllProductsCard from "./AllProductsCard";
import BrandList from "../brands/BrandList";
import { getAllBrand } from "../../store/BrandSlice";
import { getAllCategory } from "../../store/CategorySlice";
import { profile } from "console";
import AllProductsCardVariation from "./AllProductsCardVariation";
  
  export const PRODUCTS_LANDING = "productsLanding";
  export const PRODUCTS_ADD = "productsAdd";
  export const PRODUCTS_VARIATIONS = "productsVariations";
  export const PRODUCTS_ADD_VARIATIONS = "productsAddVariations";
  export const CATEGORIES = "categories";
  export const BRANDS = "brands";

  
  const AllProductsContent: React.FC = () => {
    const { theShop } = useSelector(shopSelector);
    const [myProducts, setProducts] = useState(PRODUCTS_LANDING);
    const { wasCreated, loading, wasUpdated, wasDeleted } = useSelector(flagSelector);
    const { products } = useSelector(productSelector);
    const [toastMessage, setToastMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [text, setText] = useState<string>();
    const [featured, setFeatured] = useState(false)
    const dispatch = useDispatch();
    const { actualProduct } = useSelector(productSelector);
    const history = useHistory();
    const [active, setActive] = useState("")


    useEffect(() => {
      if (wasCreated && myProducts === PRODUCTS_ADD) {
        setProducts(PRODUCTS_VARIATIONS);
        setToastMessage("¡Producto creado satisfactoriamente!");
        dispatch(setWasCreated(false));
      } else if (wasCreated && myProducts === PRODUCTS_ADD_VARIATIONS) {
        setProducts(PRODUCTS_VARIATIONS);
        setToastMessage("¡Variación creada satisfactoriamente!");
        dispatch(setWasCreated(false));
      }
      else if (wasCreated && myProducts === CATEGORIES) {
        setToastMessage("¡Categoría creada satisfactoriamente!");
        dispatch(setWasCreated(false));
      } 
      // else if (wasUpdated && myProducts === PRODUCTS_VARIATIONS) {
        
      //   setToastMessage("¡Los cambios han sido guardados!");
      //   dispatch(setWasUpdated(false));
      // }
    }, [wasCreated, toastMessage, actualProduct, wasUpdated]);
  
    const goBackHandler = () => {
      if (myProducts === PRODUCTS_LANDING) {
        history.push("/mi-tienda");
      } else if (myProducts === PRODUCTS_ADD) {
        setProducts(PRODUCTS_LANDING);
      } else if (myProducts === PRODUCTS_VARIATIONS) {
        setProducts(PRODUCTS_LANDING);
      } else if (myProducts === PRODUCTS_ADD_VARIATIONS) {
        setProducts(PRODUCTS_VARIATIONS);
      }
     else if (myProducts === CATEGORIES || myProducts === BRANDS) {
        setProducts(PRODUCTS_LANDING);
      }
    };

    

    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <MyShopToolbar iconName={pricetags} title="Productos" />
        <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        />
        <IonContent>
 
          <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
            <IonRow>
              <IonCol>
                <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />Volver
                </IonButton>
              </IonCol>
      
              { (myProducts === PRODUCTS_LANDING && theShop.shopID === "novocorpo") && (<><IonCol sizeXs="9.1" sizeMd="3" className='ion-text-end'>
                <IonButtons>
                              <IonButton color='primary' fill="solid" onClick={ ()=>{setProducts(CATEGORIES); dispatch(getAllCategory())}}>
                                  Agregar categoría
                              </IonButton>
                          <IonButton color='primary' fill="solid" onClick={ ()=>{setProducts(BRANDS); dispatch(getAllBrand())}}>
                              Agregar marca
                          </IonButton></IonButtons>
                      </IonCol>
</>
              )}
               
       
            </IonRow>
            {myProducts === PRODUCTS_LANDING && (
              <>

           
                <IonRow>
                  <IonCol>
                    <IonSearchbar
                      value={searchText}
                      onIonChange={(e) => setSearchText(e.detail.value!)}
                      placeholder="Nombre del producto"
                      class="search-bar-color"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
              <IonCol className="ion-text-right">
                
              <IonChip onClick={() => {featured ? setFeatured(false): setFeatured(true)}} color={featured ? "secondary":"tertiary" }>
                <small>{featured?"VER TODOS":"VER PRODUCTOS DESTACADOS"}    <IonIcon icon={ featured ? caretUpOutline  : caretDownOutline } slot="end" /></small>
                
              </IonChip></IonCol>
            </IonRow> 
            {!featured &&
                <IonRow>
                  <IonCol>
                    {products.filter((prod) => prod.productVariations.items[0] !== undefined )
                      .filter((product) =>
                        product.name
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                      )
                      .map((product) => (
                        <AllProductsCard
                          key={product.id}
                          product={product}
                          setProducts={setProducts}
                        />
                      ))}
                  </IonCol>
                </IonRow>
                 }
                {featured && <>
                <IonRow>
                  <IonCol>
                    {products.filter((prod) => prod.productVariations.items[0] !== undefined && prod.featured == "DESTACADO")
                      .map((product) => (
                        <AllProductsCard
                          key={product.id}
                          product={product}
                          setProducts={setProducts}
                        />
                      ))}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonText color="tertiary"><small>*Recuerda que solo se verán en Home los primeros 9 productos que destaques.</small></IonText></IonRow></> }
              </>
            )}
  
  { myProducts === CATEGORIES && <CategoryList/>}
  { myProducts === BRANDS && <BrandList/>}

            {myProducts === PRODUCTS_VARIATIONS && (
              <>
              
                <IonRow>
                  <IonCol>
                    <AllProductsCardVariation
                      key={actualProduct.id}
                      product={actualProduct}
                      status={active}                 />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonText>
                      <h3>
                        {actualProduct.productVariations.items[0]
                          ? "Variaciones del producto"
                          : "Este producto aún no posee variaciones."}
                      </h3>
                    </IonText>
                  </IonCol>
                </IonRow>
                {!actualProduct.productVariations.items[0] && (
                  <IonRow>
                    <IonCol className="ion-text-center">
                      <IonText>
                        Una VARIACIÓN DE PRODUCTO te permite agregar el SKU,
                        precio y fotografías de tu producto.
                      </IonText>
                    </IonCol>
                  </IonRow>
                )}
  
                <IonRow>
                  <IonCol>
                    {actualProduct.productVariations.items.map(
                      (pv: ProductVariationObject) => {
                        return (
                          <ProductVariationsCard
                            setProducts={setProducts}
                            key={pv.id}
                            productVariation={pv}
                          />
                        );
                      }
                    )}
                  </IonCol>
                </IonRow>
              </>
            )}
  
            {myProducts === PRODUCTS_ADD_VARIATIONS && (
              <ProductVariationForm myProducts={setProducts} />
            )}
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default AllProductsContent;
  