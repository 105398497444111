import React from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonCard,
  IonImg,
  IonTitle,
  IonText,
} from "@ionic/react";
import { pricetags, arrowBackOutline } from "ionicons/icons";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { catalogSelector } from "../../store/CatalogSlice";
import { shopSelector } from "../../store/ShopSlice";
import Toolbar from "../global/general/Toolbar";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import ImageAdminStatus from "./ImagesAdminStatus";
import { useHistory } from "react-router";
import ImagesAdminSliderCard from "./ImagesAdminSliderCard";
import ImagesAdminStatusCatalogs from "./ImagesAdminStatusCatalogs";

const ImagesAdminContent: React.FC = () => {
  const { shops } = useSelector(shopSelector);
  const { catalogs } = useSelector(catalogSelector);
  const dispatch = useDispatch();
const history = useHistory()
  // const [imageStatus, setImageStatus] = useState<
  //   "PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined
  // >();
  const [coverStatus, setCoverStatus] = useState<
  "PENDIENTE DE APROBACIÓN" | "ACTIVO" | "NO APROBADO" | undefined
>();

  const shopsWithSlider = shops
  .filter(
    (shop) => shop.slider !== null && shop.shopID !== "novocorpo"
  )

  const titleCase = (text: string) => {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
  }

  const goBackhandler = () => {
    history.push('/mi-tienda')
  }

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar iconName={pricetags} title="Productos" />
      {/* <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
        /> */}
      <IonContent>
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackhandler}>
                <IonIcon slot="start" icon={arrowBackOutline}  />
                Volver
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="ion-text-center ion-margin" color="primary">
                <h1>Aprobación de imágenes</h1>
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <IonTitle className="ion-text-center" color="tertiary">
                Carruseles
              </IonTitle>
            </IonCol>
            <IonCol size="6">
              <IonTitle className="ion-text-center" color="tertiary">
                Portadas
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeMd="6" sizeXs="12">
              {shops
                .filter(
                  (shop) => shop.slider !== null && shop.shopID !== "novocorpo"
                )
                .map((shop) => (<>
                  <ImagesAdminSliderCard slider={shop.slider!} shop={shop}
                   />
                </>))}
            </IonCol>
            <IonCol size="6">
              {catalogs
                .filter((catalog) => catalog.cover !== null)
                .map((catalog) => (
                  <IonCard>
                    <IonGrid>
                   
                    <IonRow>
                        <IonCol className="ion-text-center">
                          <IonText color="tertiary"><b>
                          {titleCase(catalog.shopID)}</b></IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <IonText color="primary">
                          {catalog.name}</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <img src={catalog.cover} />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="ion-text-center">
                          <ImagesAdminStatusCatalogs
                            setCoverStatus={setCoverStatus}
                            status={coverStatus}
                            catalog={catalog}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ImagesAdminContent;
