import { IonCard, IonInput,  IonItem, IonLabel, IonText, IonTitle, IonGrid, IonRow, IonCol, IonTextarea, IonBadge, IonButton, IonModal, IonIcon, IonCheckbox, IonToggle } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editProduct,
  ProductObject,
} from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";

const AllProductsCardVariation: React.FC<{
  product: ProductObject;
  status: string;
}> = (props) => {
  const [productStatus, setProductStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | undefined
  >()
  const {theShop} = useSelector(shopSelector)
  const [checked, setChecked] = useState(false)
const dispatch = useDispatch()

const featuredProduct = () => {
  if (!checked && props.product.featured == undefined) {
    dispatch(editProduct({id: props.product.id, featured: "DESTACADO"}))
  } else if (checked) {
    dispatch(editProduct({id: props.product.id, featured: null}))
  }
}


useEffect(() => {
 if (props.product.featured === "DESTACADO") {
  setChecked(true)
 }
}, [props.product.status, props.product.featured, checked])

  return (<>
        
    <IonCard className="ion-padding ion-margin-bottom">
      <IonRow>
        <IonCol  sizeMd="6" sizeXs="12">
      <IonItem lines="none">
        <IonTitle  color="primary"><p className="ion-text-wrap">{props.product.name}</p></IonTitle>
      </IonItem></IonCol>
      {theShop.shopID == "novocorpo" && 
      <IonCol sizeMd="3" offsetMd="3" className="ion-align-self-right">
        <IonItem lines="none">
        <IonCheckbox checked={checked} onIonChange={(e) => {setChecked(e.detail.checked); featuredProduct()}} slot="start" />
        <IonLabel color="primary"><small>PRODUCTO DESTACADO</small></IonLabel></IonItem>
      </IonCol>}
    
      </IonRow>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Marca:</IonLabel>
              <IonInput readonly={true} color="primary" value={props.product.brandName}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonText  color="tertiary">Categorías:</IonText>
         <IonRow>
              {JSON.parse(JSON.stringify(props.product.category)).map((category: any) => (<IonCol><IonBadge color="primary">{category}</IonBadge></IonCol>))
              }
              </IonRow>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Descripción:</IonLabel>
              <IonTextarea readonly={true} color="primary" value={props.product.description}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Especificaciones:</IonLabel>
              <IonTextarea readonly={true} color="primary" value={props.product.specifications}/>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
 </> );
};

export default AllProductsCardVariation;
