import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
  IonLabel
} from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
  SHOP_FORM,
  SHOP_PLAN_ONE,
  SHOP_PLAN_THREE,
  SHOP_PLAN_TWO,
} from "../../pages/MyShop";

const MyShopPlans: React.FC<{
  myShop: Dispatch<SetStateAction<string>>;
  myShopPlan: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const [continueDisabled, setContinueDisabled] = useState(true);

  const selectPlan = (planName: string) => {
    props.myShopPlan(planName);

    let thePlan = document.querySelector(".planSelector." + planName);
    let allPlans = document.querySelectorAll(".planSelector");
    allPlans.forEach((elm) => {
      elm.classList.remove("selected");
    });
    thePlan?.classList.add("selected");
    setContinueDisabled(false);
  };

  return (
    <IonGrid fixed>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonText color="primary"><p>Paso 2</p></IonText>
          <IonText color="primary"><h1>Crear mi Tienda</h1></IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonText className="ion-margin-bottom" color="secondary"><h4>Selecciona un tipo de cuenta:</h4></IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard
            onClick={() => {
              selectPlan(SHOP_PLAN_ONE);
            }}
            className={"planSelector " + SHOP_PLAN_ONE}
          >
            <IonCardHeader>
              <IonCardTitle color="light"><strong>Cuenta gratis</strong></IonCardTitle>
              <IonCardSubtitle color="light"><strong>Sin mensualidad</strong></IonCardSubtitle>
              <IonLabel color="light"><i>Comisión por venta por producto $25</i></IonLabel>
            </IonCardHeader>
            <IonCardContent>
              <IonText color="light"><ul><li>Visible en plataforma</li></ul></IonText>
              <IonText color="light"><ul><li>Soporte Técnico en plataforma</li></ul></IonText>
              <IonText color="light"><ul><li>Máximo de productos publicados = 10</li></ul></IonText>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard
            onClick={() => {
              selectPlan(SHOP_PLAN_TWO);
            }}
            className={"planSelector " + SHOP_PLAN_TWO}
          >
            <IonCardHeader>
              <IonCardTitle color="light"><strong>Cuenta Clásica</strong></IonCardTitle>
              <IonCardSubtitle color="light"><strong>Mensualidad de $150</strong></IonCardSubtitle>
              <IonText color="light"><i>Comisión por venta por producto $15</i></IonText>
            </IonCardHeader>
            <IonCardContent>
              <IonLabel color="light"><ul><li>Publicidad personalizada</li></ul></IonLabel>
              <IonLabel color="light"><ul><li>Soporte Técnico en plataforma</li></ul></IonLabel>
              <IonLabel color="light"><ul><li>Visible en plataforma</li></ul></IonLabel>
              <IonLabel color="light"><ul><li>Landing page: Logotipo y nombre de la empresa</li></ul></IonLabel>
              <IonLabel color="light"><ul><li>Máximo de productos publicados = 50</li></ul></IonLabel>
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard
            onClick={() => {
              selectPlan(SHOP_PLAN_THREE);
            }}
            className={"planSelector " + SHOP_PLAN_THREE}
          >
            <IonCardHeader>
              <IonCardTitle color="primary"><strong>Cuenta Premium</strong></IonCardTitle>
              <IonCardSubtitle color="primary"><strong>Mensualidad de $200</strong></IonCardSubtitle>
              <IonText color="primary"><i>Comisión por venta por producto $10</i></IonText>
            </IonCardHeader>
            <IonCardContent>
              <IonText color="primary"><ul><li>Publicidad personalizada</li></ul></IonText>
              <IonText color="primary"><ul><li>Formato para control de inventarios</li></ul></IonText>
              <IonText color="primary"><ul><li>Formato para calcular ganancia</li></ul></IonText>
              <IonText color="primary"><ul><li>Soporte Técnico en plataforma</li></ul></IonText>
              <IonText color="primary"><ul><li>Asesorías, ventas y administración</li></ul></IonText>
              <IonText color="primary"><ul><li>Visible en plataforma</li></ul></IonText>
              <IonText color="primary"><ul><li>Landing page: Logotipo, nombre y descripción de la empresa</li></ul></IonText>
              <IonText color="primary"><ul><li>Redes sociales, 2 banners, promoción en la página principal</li></ul></IonText>
              <IonText color="primary"><ul><li>Catálogo PDF</li></ul></IonText>
              <IonText color="primary"><ul><li>Carrusel página principal</li></ul></IonText>
              <IonText color="primary"><ul><li>Máximo de productos publicados = 100</li></ul></IonText>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow className="ion-text-center">
        <IonCol>
          <IonButton
            color="tertiary"
            disabled={continueDisabled}
            onClick={() => {
              props.myShop(SHOP_FORM);
            }}
          >
            Continuar
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MyShopPlans;
