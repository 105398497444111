import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonLabel,
	IonRow,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { camera, cloudUploadOutline, documentAttachOutline } from "ionicons/icons";
import React, { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";
import {
	Plugins,
	CameraResultType,
	CameraSource,
	Capacitor,
} from "@capacitor/core";
import "./AmonytFilePicker.css";
const { Camera } = Plugins;

const AmonytFilePicker: React.FC<{
	type: string;
	noPhotoText: string;
	isLoading?: boolean;
	actionButtonText: string;
	// takePhotoButtonText: string;
	selectFileButtonText: string;
	width?: number | undefined;
	height?: number | undefined;
	setWidth?: Dispatch<SetStateAction<number | undefined>>;
	setHeight?: Dispatch<SetStateAction<number | undefined>>;
	onFileAction: () => void;
	requiredDimensions?: number[] | undefined;
	checkImgMsg?: string | null;
	onFilePick: (file: { preview: string, fileType:string }) => void;
}> = (props) => {
	const [takenPhoto, setTakenPhoto] = useState<{
		preview: string;
	}>();
	const [availablePhoto, setAvailablePhoto] = useState(false);
	const [checkSize, setCheckSize] = useState(false)
	const [checkWidth, setCheckWidth] = useState<number>()
	const [checkHeight, setCheckHeight] = useState<number>()

	const imgRef = useRef<HTMLImageElement>(null)
	const filePickerRef = useRef<HTMLInputElement>(null);
	const filePickerExtensions =
		props.type === "photo"
			? "image/x-png,image/gif,image/jpeg"
			: "application/pdf";
	const openFilePicker = () => {
		filePickerRef.current!.click();
	};
	const pickFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target!.files![0];
		const fr = new FileReader();
		fr.onload = () => {
			const pickedFile = {
				preview: fr.result!.toString(),
				fileType: props.type==="photo" ? "jpeg" : "pdf",
			};
			setTakenPhoto(pickedFile);
			setAvailablePhoto(true);
			props.onFilePick(pickedFile);
		};
		fr.readAsDataURL(file);
	};
	const takePhotoHandler = async () => {
		if (!Capacitor.isPluginAvailable("Camera")) {
			openFilePicker();
			return;
		}
		try {
			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				source: CameraSource.Camera,
				quality: 80,
				width: 500,
			});

			if (!photo || !photo.webPath) {
				return;
			}

			const pickedPhoto = {
				//path: photo.path,
				preview: photo.webPath,
				fileType: "jpeg",
			};
			setTakenPhoto(pickedPhoto);
			setAvailablePhoto(true);
			props.onFilePick(pickedPhoto);
			
		} catch (error) {
			openFilePicker();
		}
	};

	const takeFileHandler = async () => {
		openFilePicker();
	};

	const getImgDimensions = () => {
		const height = imgRef.current?.naturalHeight
		const width = imgRef.current?.naturalWidth
		
		
		setCheckHeight(height!)
		setCheckWidth(width!)
		  
	}
	return (
		<React.Fragment>
			<IonGrid>
				<IonRow className="ion-text-center ion-margin-top">
					<IonCol>
						{props.type === "photo" && (
							<div className="image-preview">
								{!takenPhoto && <div>{props.noPhotoText}</div>}
								{takenPhoto && (
									<img src={takenPhoto.preview} ref={imgRef} onLoad={getImgDimensions} alt="Preview"></img>
								)}
							</div>
						)}
					</IonCol>
				</IonRow>
				
				<IonRow>
					<IonCol>
						<input
							type="file"
							hidden
							accept={filePickerExtensions}
							ref={filePickerRef}
							onChange={pickFileHandler}
						/>
					</IonCol>
				</IonRow>
				<IonRow className="ion-text-center">
					<IonCol>
						{props.type === "photo" && (
							<IonButton onClick={takeFileHandler}>
								<IonIcon slot="start" icon={camera}></IonIcon>
								<IonLabel>
									{props.selectFileButtonText || "Take picture"}
								</IonLabel>
							</IonButton>
						)}
						{props.type === "file" && (
							<IonButton onClick={takeFileHandler}>
								<IonIcon slot="start" icon={documentAttachOutline}></IonIcon>
								<IonLabel>
									{props.selectFileButtonText || "Pick a file"}
								</IonLabel>
							</IonButton>
						)}
					</IonCol>
				</IonRow>
				<IonRow className="ion-text-center">
					<IonCol>

						{/* {(props.requiredDimensions![0] !== checkWidth || props.requiredDimensions![1] !== checkHeight) && 
						<IonText color="tertiary"><small>{`*Ingrese una imagen de ${props.requiredDimensions![0]}x ${props.requiredDimensions![1]}`}</small></IonText>} */}
		

						
						<IonButton onClick={props.onFileAction} disabled={!availablePhoto && !props.isLoading}>
					<IonIcon slot="start" icon={cloudUploadOutline}></IonIcon>
							<IonLabel>{props.actionButtonText || "Send"}</IonLabel>
								{props.isLoading && <IonSpinner />}
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</React.Fragment>
	);
};

export default AmonytFilePicker;
