import React, { useEffect, useState } from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import MyShopContentCards from "./MyShopContentCards";
import { useDispatch, useSelector } from "react-redux";
import { getShopInfo, shopSelector } from "../../store/ShopSlice";
import MyShopIntro from "./MyShopIntro";
import MyShopPlans from "./MyShopPlans";
import MyShopForm from "./MyShopForm";
import UserFeedback from "../global/general/UserFeedback";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import ShopAlert from "../alerts/ShopAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import Toolbar from "../global/general/Toolbar";
import { getShop } from "../../graphql/queries";
import { API, graphqlOperation, input } from "aws-amplify";
import { authSelector } from "../../store/AuthSlice";
import { userSelector } from "../../store/UserSlice";
import { ConsoleLogger } from "@aws-amplify/core";


export const SHOP_LANDING = "shopLanding";
export const SHOP_INTRO = "shopIntro";
export const SHOP_PLAN = "shopPlan";
export const SHOP_FORM = "shopForm";
export const SHOP_PLAN_ONE = "planOne";
export const SHOP_PLAN_TWO = "planTwo";
export const SHOP_PLAN_THREE = "planThree";

const MyShopContent: React.FC = () => {
  const { theShop } = useSelector(shopSelector);
  const [myShop, setMyShop] = useState("");
  const [myShopPlan, setMyShopPlan] = useState("");
  const {userProfile} = useSelector(authSelector)
  const { wasCreated, loading } = useSelector(flagSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false)
  
const changeState = () => {
  if (theShop.name) {
     setMyShop(SHOP_LANDING)
    } else {
      setCheck(true)
    }
  if (myShop === SHOP_PLAN || myShop === SHOP_FORM) {
    setCheck(false)
  }
  }
  useEffect(() => {
    setTimeout(changeState, 1000)
  })
  useEffect(() => {
    if (wasCreated && myShop === SHOP_FORM) {
      setMyShop(SHOP_LANDING);
      setToastMessage("¡Tienda creada satisfactoriamente!");
      setShowAlert(true);
      dispatch(setWasCreated(false));
    }
    
  }, [wasCreated, toastMessage, myShop, dispatch]);
  


  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <ShopAlert showAlert={showAlert} setShowAlert={setShowAlert} />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <ErrorAlert />

      <IonContent>
        {theShop.name && myShop === SHOP_LANDING && <MyShopContentCards />}
        {!theShop.name && check && (
          <MyShopIntro myShop={setMyShop} />
        )}
        {!theShop.name && myShop === SHOP_PLAN && (
          <MyShopPlans myShop={setMyShop} myShopPlan={setMyShopPlan} />
        )}
        {!theShop.name && myShop === SHOP_FORM && (
          <MyShopForm myShop={setMyShop} myShopPlan={myShopPlan} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyShopContent;
