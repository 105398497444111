import { IonButton, IonCard, IonInput, IonIcon, IonItem, IonLabel, IonText, IonTitle, IonGrid, IonRow, IonCol, IonTextarea, IonImg, IonBadge } from "@ionic/react";
import {
  checkboxOutline,
  checkbox,
  saveOutline,
  documentTextOutline,
  logoYoutube,
  shareSocial,
} from "ionicons/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProperty, PropertyObject, propertySelector } from "../../store/PropertySlice";
import { shopSelector } from "../../store/ShopSlice";
import PropertyState from "./PropertyState";

export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

const MyPropertiesDetailsCard: React.FC<{
  property: PropertyObject;
}> = () => {
  const [propertyStatus, setPropertyStatus] = useState< "ALTA" | "BAJA" | "RENTADO" | "COMPRADO" | "PROMOCIÓN" | "SUSPENSIÓN" | undefined>()
  const {theShop} = useSelector(shopSelector)


  
const {actualProperty} = useSelector(propertySelector)
const [text, setText] = useState(actualProperty.comments)
  const dispatch = useDispatch();
  const transformVideoUrl = () => {
    let str = actualProperty.videoUrl;
     let res = str.split("=");
     let embeddedUrl = "https://www.youtube.com/embed/"+ res[1].substring(0,11)
   
     return embeddedUrl
   }


  return (
    <IonCard className="ion-padding ion-margin-bottom">
      <IonGrid>
        <IonRow>
          <IonCol>
        <IonTitle color="primary">{actualProperty.name + "  "}<IonIcon color="tertiary" onClick={() => {
																										window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20${actualProperty.name}:%20https://www.novocorpo.com.mx/inmueble/+ ${actualProperty.id}`);

												}} icon={shareSocial}/></IonTitle>
     </IonCol>
     </IonRow>
      <IonRow>
          <IonCol>
            <IonItem lines="none">
              <IonBadge color="success">
              <small>{actualProperty.typeOfOperation.toUpperCase()}</small>
            </IonBadge>
            </IonItem>
          </IonCol>
          {theShop.shopID === "novocorpo" && <IonCol size="3">
           <PropertyState propertyStatus={propertyStatus} setPropertyStatus={setPropertyStatus} />
          </IonCol>}
        </IonRow>
        <IonRow>
          
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Ubicación:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.location}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Dirección:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.address}/>
            </IonItem>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Coordenadas geográficas:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.gpsPosition}/>
            </IonItem>
          </IonCol>
        </IonRow> 
        <IonRow>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Terreno:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.landSize}/>
            </IonItem>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="6">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Construcción:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.propertySize}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Plantas:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.floors}/>
            </IonItem>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Recámaras:</IonLabel>
             <IonInput readonly={true} color="primary" value={actualProperty.bedrooms}/>
            </IonItem>
          </IonCol>
          <IonCol sizeXs="12" sizeSm="12" sizeMd="4">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Baños:</IonLabel>
              <IonInput readonly={true} color="primary" value={actualProperty.bathrooms}/>
            </IonItem>
          </IonCol>
        </IonRow>
       
        <IonRow>
          <IonCol className="ion-margin-bottom">
            <IonItem>
              <IonLabel position="floating" color="tertiary">Descripción:</IonLabel>
              <IonTextarea color="primary" readonly={true} value={actualProperty.description}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
          <IonLabel position="floating" color="tertiary">Características públicas:</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          {Object.entries(actualProperty.publicFeatures).map((val: any) => (
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4">
        
            <IonItem lines="none">
             <IonIcon color="tertiary" icon={checkboxOutline} slot="start"/> <IonLabel color="primary">{capitalizeFirstLetter(val[1].toString())}</IonLabel>
              
            </IonItem></IonCol>
          ))}
          
        </IonRow>
        <IonRow>
          <IonCol>
          <IonLabel position="floating" color="tertiary">Características privadas:</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          {Object.entries(actualProperty.privateFeatures).map((val: any) => (
        <IonCol sizeXs="12" sizeSm="12" sizeMd="4">
        
            <IonItem lines="none">
             <IonIcon color="tertiary" icon={checkbox} slot="start"/> <IonLabel color="primary">{capitalizeFirstLetter(val[1].toString())}</IonLabel>
              
            </IonItem></IonCol>
          ))}
          
        </IonRow>
        {theShop.shopID === "novocorpo" && (
                    <IonGrid>
                      <IonItem className="ion-margin-bottom ion-text-left">
                       
                       <IonTextarea
                           rows={4}
                           cols={20}
                           placeholder="Añadir notas internas..."
                           value={text}
                           onIonChange={(e) => setText(e.detail.value!)}>

                           </IonTextarea>
                           </IonItem>
                           <IonRow>
                             <IonCol className="ion-text-center">
                             <IonButton onClick={() => {dispatch(editProperty({
      id: actualProperty.id,
      comments: text
    }))}}>
                               <IonIcon slot="start" icon={saveOutline}/>
                               Guardar notas
                             </IonButton>
                           </IonCol></IonRow>
                     
                    </IonGrid>
                  )}
        <IonRow>
          <IonCol>
            <IonItem lines="none">
              <IonLabel position="floating" color="tertiary">Imágenes:</IonLabel>
              
              <IonRow>
                   {actualProperty.pictures.map((picture: any) =>
                  <IonCol sizeMd="5.5" sizeXs="12" className="ion-margin ion-padding">
              <IonCard>
              <IonImg src={picture}></IonImg>
              </IonCard>
              </IonCol>
           )} </IonRow>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonItem lines="none">
          <IonRow>
          <IonCol>
            
              <IonLabel position="floating" color="tertiary">Video:</IonLabel>
              
           
                   {actualProperty.videoUrl !== null ? <>                   
<IonRow>
<IonCol sizeXs="12" className="ion-margin ion-padding ion-text-center ion-hide-sm-up">
  <IonItem lines="none" >
    <IonIcon color="danger" icon={logoYoutube} slot="start"/>
    <IonText onClick={() =>  window.open(actualProperty.videoUrl, '_blank')} className="show-click">Ver en YouTube »</IonText>
  </IonItem>
                   </IonCol>
                  <IonCol offsetMd="7" sizeMd="12" className="ion-margin ion-padding ion-text-center ion-hide-sm-down">
<iframe width="426" height="240" src={transformVideoUrl()} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                   </IonCol></IonRow> 
            
         </> :  <IonRow><IonCol className="ion-margin"><small>Aún no se ha cargado el video de este inmueble.</small></IonCol></IonRow>} 
     
          
          </IonCol>
         
         </IonRow> </IonItem> 
         <IonItem lines="none"> 
         <IonRow>
         <IonCol>
           
              <IonLabel position="floating" color="tertiary">Documento PDF:</IonLabel>
              {actualProperty.pdf[0] !== null ? <>                   
                <IonRow>
                                <IonCol sizeMd="5.5" sizeXs="12" className="ion-text-center ion-margin ion-padding ion-hide-sm-up">
                              <IonButton target="_blank" onClick={() =>  window.open(actualProperty.pdf[0], '_blank')}>
                                <IonIcon icon={documentTextOutline} slot="start" />
                                Descargar PDF
                              </IonButton>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol sizeMd="12" sizeXs="12" className="ion-text-center ion-margin ion-padding ion-hide-sm-down">
                            
                                <object width="600" height="900" data={actualProperty.pdf[0]} type="application/pdf">
        <div>No online PDF viewer installed</div>
    </object>
                                </IonCol>
                              </IonRow>
            
         </> :  <small>Aún no se ha cargado el documento PDF de este inmueble.</small>}
             
       
          </IonCol></IonRow>
          </IonItem> 
      </IonGrid>
    </IonCard>
  );
};

export default MyPropertiesDetailsCard;
