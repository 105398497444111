
import { createNovocorpo, updateNovocorpo } from "./../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NovocorpoThunk } from "../index";
import { setError, setErrorMessage, setLoading, setWasCreated } from "../store/FlagSlice";
import { getNovocorpo, listNovocorpos } from "../graphql/queries";

export interface NovocorpoState {
	novocorpo: NovocorpoObject[],
  theNovocorpo: NovocorpoObject;
}
export interface NovocorpoObject {
  novocorpoID: string;
  logo?: string;
  description?: string;
  slider?: string[];
}

const initialState: NovocorpoState = {
novocorpo: [],
  theNovocorpo: {
    novocorpoID: "",
	logo: "",
    description: "",
    slider: [],
  }
};

const novocorpo = createSlice({
  name: "novocorpo",
  initialState,
  reducers: {
    setActualNovocorpo(state, { payload }: PayloadAction<NovocorpoObject>) {
      state.theNovocorpo = payload;
    },
    // setPremiumNovocorpos(state, { payload }:PayloadAction<NovocorpoObject[]>){
    //   state.NovocorposPremium = payload;
    // },
    setAllNovocorpo(state, { payload }: PayloadAction<NovocorpoObject[]>) {
      state.novocorpo = payload;
    },
    cleanNovocorpo(state) {
      state.theNovocorpo = {
        novocorpoID: "",
        logo: "",
        description: "",
        slider: [],
      };
    //   state.Novocorpos = [];
    },
  },
});

//Exports
export const { setActualNovocorpo, cleanNovocorpo, setAllNovocorpo } = novocorpo.actions;

export default novocorpo.reducer;

export const novocorpoSelector = (state: { novocorpoStore: NovocorpoState }) =>
  state.novocorpoStore;

//Middleware


export const postNovocorpo = (input: any): NovocorpoThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(createNovocorpo, { input })
      );
      dispatch(setActualNovocorpo(res.data.createNovocorpo));
      dispatch(setWasCreated(true));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };
};

export const getNovocorpoInfoFilter = (input:any): NovocorpoThunk => async (dispatch: any) => {
	try {
	  dispatch(setLoading(true));
	  const res: any = await API.graphql(graphqlOperation(listNovocorpos, input));
	  dispatch(setActualNovocorpo(res.data.listNovocorpos.items[0]));
	  dispatch(setLoading(false));
	} catch (error: any) {
	  dispatch(setLoading(false));
	  dispatch(setErrorMessage(error.errors[0].message.toString()));
	}
  };

export const editNovocorpo = (input: any): NovocorpoThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(updateNovocorpo, { input }));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      dispatch(setErrorMessage(error.errors[0].message.toString()));
    }
  };
};



 