import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { CategoryObject, categorySelector } from "../../store/CategorySlice";
import { flagSelector } from "../../store/FlagSlice";
import {
  editProduct,
  postProduct,
  ProductObject,
  productSelector,
  removeProduct,
} from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
import {
  BRAND_OPTIONS,
} from "./MyProductsConstants";

let categoryOptions: { label: string; value: string }[] = [];

const MyProductsEditForm: React.FC<{
  product: ProductObject
}> = () => {
  const history = useHistory();
  const { theShop } = useSelector(shopSelector);
  const { category } = useSelector(categorySelector);
  const {actualProduct} = useSelector(productSelector);
  const [toastMessage, setToastMessage] = useState("");
  const { loading } = useSelector(flagSelector);

  let brandOptions = [{ label: theShop.shopID, value: theShop.shopID }];
  if (theShop.shopID === "novocorpo") {
    brandOptions = BRAND_OPTIONS;
  }

 categoryOptions = [];

  if (category.length > 0) {
    category.forEach((cat: CategoryObject) =>
        categoryOptions.push({ label: cat.name, value: cat.name })
      );
   
  }
  


  const fieldArray = [
    {
      name: "name",
      label: "Nombre del producto",
      type: "text" as FieldTypes,
      value: useState(actualProduct.name),
      placeholder: "Ej: Playera amarilla",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "description",
      label: "Descripción",
      type: "textarea" as FieldTypes,
      value: useState(actualProduct.description),
      placeholder:
        "Ej: Elige palabras clave que describan claramente tu producto.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "specifications",
      label: "Especificaciones",
      type: "textarea" as FieldTypes,
      value: useState(actualProduct.specifications),
      placeholder: "Ej: Dimensiones, material, garantía, etc.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "category",
      label: "Categorías",
      type: "select" as FieldTypes,
      options: categoryOptions,
      multiple: true,
      value: useState(actualProduct.category),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "brandName",
      label: "Marca",
      type: "select" as FieldTypes,
      options: brandOptions,
      multiple: false,
      value: useState(actualProduct.brandName),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProduct,
      pushRoute: "/mis-productos",
    },
    edit: {
      inputObject: {id: actualProduct.id},
      dispatchFunction: editProduct,
      pushRoute: "/mis-productos",
    },
    delete: {
      inputObject: {id: actualProduct.id},
      dispatchFunction: removeProduct,
      pushRoute: "/mis-productos",
    },
  };

  return (<>
   
    <UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
   
      <IonGrid fixed>
    
        <IonRow>
          <IonCol>
          <AmonytForm
            title="Editar producto"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={false}
            hiddenFields={[
              {
                name: "shopID",
                value: theShop.shopID,
              },
            ]}
          />
        </IonCol>
					</IonRow>
				</IonGrid>
	</>);
}
export default MyProductsEditForm;

