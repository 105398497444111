import { Dispatch, RefObject, SetStateAction } from "react";
import { isPropertySignature } from "typescript";
import {FieldTypes} from "../amonyt/components/AmonytField";

export function fieldValidations(
  dataField: {
    label: string;
    type: FieldTypes;
    value: [string, Dispatch<SetStateAction<string>>];
    placeholder: string;
    error: [boolean, Dispatch<SetStateAction<boolean>>];
    errorMessage: [string, Dispatch<SetStateAction<string>>];
    isRequired: boolean;
    validationType: string;
  }
): boolean {

  //IS REQUIRED - VALIDATION
  if (dataField.isRequired) {
    if (!dataField.value[0]) {      
      dataField.error[1](true);
      dataField.errorMessage[1]("Favor de llenar este campo");
      return true;
    }
  }
  //--------------------------

  // EMAIL - VALIDATION
  if(dataField.validationType === 'email'){
    const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailFormat.test(dataField.value[0]!.toString())) {
      dataField.error[1](true);
      dataField.errorMessage[1]("Por favor introduzca un correo electrónico válido");
      return true;
    }
  }

  // PASSWORD - VALIDATION
  if(dataField.validationType === 'password'){
    // Size validation
    const passLenght = 6;
    if(dataField.value[0].length < passLenght){
      dataField.error[1](true);
      dataField.errorMessage[1]("La contraseña debe ser igual o mayor a "+ passLenght);
      return true;
    }
  }

    // PHONE - VALIDATION
    if(dataField.validationType === 'phone'){
      // Size validation
      const passLenght = 10;
      if(dataField.value[0].length < passLenght){
        dataField.error[1](true);
        dataField.errorMessage[1]("El número de ver ser a " + passLenght + " dígitos");
        return true;
      }
    }

  dataField.error[1](false);
  dataField.errorMessage[1]('');
  return false;

}


export function fieldValidationsCompare(
  dataField1: {
    label: string;
    type: FieldTypes;
    value: [string, Dispatch<SetStateAction<string>>];
    placeholder: string;
    error: [boolean, Dispatch<SetStateAction<boolean>>];
    errorMessage: [string, Dispatch<SetStateAction<string>>];
    isRequired: boolean;
    validationType: string;
  },
  dataField2: {
    label: string;
    type: FieldTypes;
    value: [string, Dispatch<SetStateAction<string>>];
    placeholder: string;
    error: [boolean, Dispatch<SetStateAction<boolean>>];
    errorMessage: [string, Dispatch<SetStateAction<string>>];
    isRequired: boolean;
    validationType: string;
  },
  errorMessage: string,

): boolean {

  if(dataField1.value[0] !== dataField2.value[0]){
    dataField1.error[1](true);
    dataField1.errorMessage[1](errorMessage);
    dataField2.error[1](true);
    dataField2.errorMessage[1](errorMessage);
    return true;
  }

  dataField1.error[1](false);
  dataField1.errorMessage[1]('');
  dataField2.error[1](false);
  dataField2.errorMessage[1]('');
  return false;

}