/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateShop = /* GraphQL */ `
  subscription OnCreateShop {
    onCreateShop {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShop = /* GraphQL */ `
  subscription OnUpdateShop {
    onUpdateShop {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShop = /* GraphQL */ `
  subscription OnDeleteShop {
    onDeleteShop {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNovocorpo = /* GraphQL */ `
  subscription OnCreateNovocorpo {
    onCreateNovocorpo {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNovocorpo = /* GraphQL */ `
  subscription OnUpdateNovocorpo {
    onUpdateNovocorpo {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNovocorpo = /* GraphQL */ `
  subscription OnDeleteNovocorpo {
    onDeleteNovocorpo {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProperty = /* GraphQL */ `
  subscription OnCreateProperty {
    onCreateProperty {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProperty = /* GraphQL */ `
  subscription OnUpdateProperty {
    onUpdateProperty {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProperty = /* GraphQL */ `
  subscription OnDeleteProperty {
    onDeleteProperty {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePropertyFeature = /* GraphQL */ `
  subscription OnCreatePropertyFeature {
    onCreatePropertyFeature {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePropertyFeature = /* GraphQL */ `
  subscription OnUpdatePropertyFeature {
    onUpdatePropertyFeature {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePropertyFeature = /* GraphQL */ `
  subscription OnDeletePropertyFeature {
    onDeletePropertyFeature {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand {
    onCreateBrand {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand {
    onUpdateBrand {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBrand = /* GraphQL */ `
  subscription OnDeleteBrand {
    onDeleteBrand {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCambaceo = /* GraphQL */ `
  subscription OnCreateCambaceo {
    onCreateCambaceo {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCambaceo = /* GraphQL */ `
  subscription OnUpdateCambaceo {
    onUpdateCambaceo {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCambaceo = /* GraphQL */ `
  subscription OnDeleteCambaceo {
    onDeleteCambaceo {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductVariation = /* GraphQL */ `
  subscription OnCreateProductVariation {
    onCreateProductVariation {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductVariation = /* GraphQL */ `
  subscription OnUpdateProductVariation {
    onUpdateProductVariation {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductVariation = /* GraphQL */ `
  subscription OnDeleteProductVariation {
    onDeleteProductVariation {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCatalog = /* GraphQL */ `
  subscription OnCreateCatalog {
    onCreateCatalog {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCatalog = /* GraphQL */ `
  subscription OnUpdateCatalog {
    onUpdateCatalog {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCatalog = /* GraphQL */ `
  subscription OnDeleteCatalog {
    onDeleteCatalog {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCatalogPages = /* GraphQL */ `
  subscription OnCreateCatalogPages {
    onCreateCatalogPages {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCatalogPages = /* GraphQL */ `
  subscription OnUpdateCatalogPages {
    onUpdateCatalogPages {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCatalogPages = /* GraphQL */ `
  subscription OnDeleteCatalogPages {
    onDeleteCatalogPages {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMonederoLog = /* GraphQL */ `
  subscription OnCreateMonederoLog {
    onCreateMonederoLog {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMonederoLog = /* GraphQL */ `
  subscription OnUpdateMonederoLog {
    onUpdateMonederoLog {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMonederoLog = /* GraphQL */ `
  subscription OnDeleteMonederoLog {
    onDeleteMonederoLog {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
