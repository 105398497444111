import React from "react";
import { IonList, IonItem } from "@ionic/react";
import { ROUTE_SHOP } from "../../../utils/routes";

const ToolbarPopover: React.FC<{
  signOut: () => void;
}> = ({ signOut }) => {
  return (
    <IonList mode="ios">
      <IonItem button routerLink="/profile">Mi Perfil</IonItem>
      <IonItem button routerLink={ROUTE_SHOP}>Mi tienda</IonItem>
      <IonItem lines="none" detail={false} button onClick={signOut}>
        Salir
      </IonItem>
    </IonList>
  );
};

export default ToolbarPopover;
