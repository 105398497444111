
import React, { useState } from "react";
import {ROUTE_SHOP_CAMBACEO } from "../../utils/routes";
import MyCambaceoForm from "../../components/cambaceo/MyCambaceoForm";
import { PRODUCTS_ADD} from "../../components/cambaceo/MyCambaceoProducts";

const MyCambaceoFormPage: React.FC = () => {

    const [myCambaceo, setCambaceo] = useState(PRODUCTS_ADD);



	return <MyCambaceoForm previousPage={ROUTE_SHOP_CAMBACEO} myCambaceo={setCambaceo} />;
};

export default MyCambaceoFormPage;
