import React from "react";
import { IonButton, IonImg } from "@ionic/react";
import { useSelector } from "react-redux";
import { novocorpoSelector } from "../../../store/NovocorpoSlice";
// import "./Toolbar.css";

const HomeButton: React.FC = () => {
  const {theNovocorpo} = useSelector(novocorpoSelector)
  return (
    <>
      {window.innerWidth < 300 ?<IonButton fill="clear" className="ion-text-left" routerLink="/">
        <div max-height="2%" max-width="2%" className="ion-text-left">
      <IonImg 
      className="custom-logo ion-margin-vertical ion-padding"
      src={theNovocorpo.logo}
    /></div></IonButton> :
    <IonButton fill="clear" className=" " routerLink="/"><IonImg 
        className="custom-logo ion-margin-vertical ion-padding"
        src="https://novocorpodiseno.s3.amazonaws.com/logo/Novo+logo.png"
      /></IonButton>}</>
    
  );
};

export default HomeButton;
