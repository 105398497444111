import { IonRow, IonCol, IonItem, IonLabel, IonCheckbox } from "@ionic/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { editProductVariation, ProductVariationObject } from "../../store/ProductVariationSlice";


const InactiveCheckbox: React.FC<{variation: ProductVariationObject}> = (props) => {
    const defineVarStatus = props.variation.status !== "INACTIVO" ? "INACTIVO" : "PENDIENTE DE APROBACIÓN"
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch()
    const editProdVarStatus = (varId: string) => {
  
      if (!checked) {
        dispatch(editProductVariation({ id: varId, status: defineVarStatus }));
      } else if (checked) {
        dispatch(editProductVariation({ id: varId, status: props.variation.status }));
      }
    };
    return   <IonRow>
    <IonCol>
    <IonItem key={props.variation.id}>
            <IonLabel color="primary">
              <small>{defineVarStatus}</small>
            </IonLabel>
            <IonCheckbox
              checked={checked}
              onIonChange={(e) => {
                setChecked(e.detail.checked);
                editProdVarStatus(props.variation.id);
              }}
              slot="start"
            ></IonCheckbox>
          </IonItem>
    </IonCol>
  </IonRow>
}

export default InactiveCheckbox;