import {  IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { flagSelector } from "../../store/FlagSlice";
import { PropertyFeatureSelector } from "../../store/PropertyFeatureSlice";
import { editProperty, postProperty, propertySelector, removeProperty } from "../../store/PropertySlice";
import { shopSelector } from "../../store/ShopSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";
import UserFeedback from "../global/general/UserFeedback";
let publicFeaturesOptions: {label: string, value: string}[] = []
let privateFeaturesOptions: {label: string, value: string}[] = []

const MyPropertiesEditForm: React.FC<{
  myProperties: Dispatch<SetStateAction<string>>;
}> = () => {
  let operationOptions = [
    { label: "Venderla", value: "venta" },
    { label: "Rentarla", value: "renta" },
  ];
  const { theShop } = useSelector(shopSelector);
  const {actualProperty} = useSelector(propertySelector)
  const {propertyFeature} = useSelector(PropertyFeatureSelector)
  const [toastMessage, setToastMessage] = useState("");
	const { loading } = useSelector(flagSelector);
  

 

  publicFeaturesOptions = []
  privateFeaturesOptions =[]
  
  
  if (propertyFeature.length > 0) {
    propertyFeature.filter((feat) => feat.condition === "PÚBLICO").forEach((feat) => publicFeaturesOptions.push({label: feat.name, value: feat.name}))
    
     propertyFeature.filter((feat) => feat.condition === "PRIVADO").forEach((feat) => privateFeaturesOptions.push({label: feat.name, value: feat.name}))
    
   }
  

 
  const fieldArray = [
    {
      name: "typeOfOperation",
      label: "¿Qué desea hacer con esta propiedad?",
      type: "select" as FieldTypes,
      options: operationOptions,
      value: useState(actualProperty.typeOfOperation),
      placeholder: "¿Qué desea hacer con esta propiedad?",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "name",
      label: "Nombre del inmueble",
      type: "text" as FieldTypes,
      value: useState(actualProperty.name),
      placeholder: "Ej: Departamento a estrenar",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "description",
      label: "Descripción",
      type: "textarea" as FieldTypes,
      value: useState(actualProperty.description),
      placeholder:
        "Elige palabras clave que describan claramente tu inmueble.",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "address",
      label: "Dirección",
      type: "textarea" as FieldTypes,
      value: useState(actualProperty.address),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "location",
      label: "Ubicación",
      type: "text" as FieldTypes,
      value: useState(actualProperty.location),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "gpsPosition",
      label: "Coordenadas GPS",
      type: "text" as FieldTypes,
      value: useState(actualProperty.gpsPosition),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "floors",
      label: "Cantidad de plantas",
      type: "number" as FieldTypes,
      value: useState(actualProperty.floors as unknown as string),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "bedrooms",
      label: "Cantidad de recámaras",
      type: "number" as FieldTypes,
      value: useState(actualProperty.bedrooms as unknown as string),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "bathrooms",
      label: "Cantidad de baños",
      type: "number" as FieldTypes,
      value: useState(actualProperty.bathrooms as unknown as string),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "publicFeatures",
      label: "Características públicas",
      type: "select" as FieldTypes,
      options: publicFeaturesOptions,
      multiple: true,
      value: useState(actualProperty.publicFeatures),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "privateFeatures",
      label: "Características privadas",
      type: "select" as FieldTypes,
      options: privateFeaturesOptions,
      multiple: true,
      value: useState(actualProperty.privateFeatures),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
        name: "propertySize",
        label: "Superficie construida",
        type: "text" as FieldTypes,
        value: useState(actualProperty.propertySize),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
      },
      {
        name: "landSize",
        label: "Superficie del terreno",
        type: "text" as FieldTypes,
        value: useState(actualProperty.landSize),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
      },
      {
        name: "price",
        label: "Precio",
        type: "text" as FieldTypes,
        value: useState(JSON.stringify(actualProperty.price)),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
      },
      {
        name: "videoUrl",
        label: "Liga de video",
        type: "url" as FieldTypes,
        value: useState(actualProperty.videoUrl),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
      },
      {
        name: "pictures",
        label: "Imágenes",
        type: "images" as FieldTypes,
        value: useState(JSON.stringify(actualProperty.pictures) || ""),
        imageArray: useState([""]),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: true,
        validationType: "text",
        showPreview: true,
        max: 5,
        unavailableImageURL: UNAVAILABLE_IMAGE,
        bucketURL: BUCKET_URL,
        requiredWidth: 450,
				requiredHeight: 600
      },
      {
        name: "pdf",
        label: "Documento PDF",
        type: "pdf" as FieldTypes,
        value: useState(JSON.stringify(actualProperty.pdf) || ""),
        pdfArray: useState([""]),
        placeholder: "",
        error: useState(false),
        errorMessage: useState(""),
        isRequired: false,
        validationType: "text",
        showPreview: true,
        max: 1,
        unavailableImageURL: UNAVAILABLE_IMAGE,
        bucketURL: BUCKET_URL,
      },
  ];

  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProperty,
      pushRoute: "/mis-inmuebles",
    },
    edit: {
      inputObject: {id: actualProperty.id},
      dispatchFunction: editProperty,
      pushRoute: "/mis-inmuebles",
      
    },
    delete: {
      inputObject: {id: actualProperty.id},
      dispatchFunction: removeProperty,
      pushRoute: "/mis-inmuebles",
    },
  };

  return (<>
  <UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
   
      <IonGrid fixed>
      
        <IonRow>
          <IonCol>
          <AmonytForm
            title="Editar inmueble"
            fieldArray={fieldArray}
            actionObject={actionObject}
            add={false}
            hiddenFields={[
              {
                name: "shopID",
                value: theShop.shopID}, {
                name: "status",
                value: "ALTA"
              },
            ]}
          />
          
          </IonCol>
					</IonRow>
				</IonGrid>
	
	</>);
}

export default MyPropertiesEditForm;
