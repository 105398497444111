import {
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProductDetailContent from "../../components/products/ProductDetailContent";
import { getProductInfo } from "../../store/ProductSlice";

const ProductDetailPage: React.FC = () => {
  const selectedProductID = useParams<{ productID: string }>().productID;
  const dispatch = useDispatch();

  useEffect(()=>
  {
    const productID = {
      id: selectedProductID
    }
    dispatch(getProductInfo(productID))
  },[])

  useIonViewWillEnter(() => {
    const productID = {
      id: selectedProductID
    }
    dispatch(getProductInfo(productID))
  });

  return <ProductDetailContent />;
};

export default ProductDetailPage;
