import {
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useEffect} from "react";
import { useDispatch, } from "react-redux";
import { getAllProduct,getAllProducts } from "../../store/ProductSlice";
import { getShopsByStatus} from "../../store/ShopSlice";
import {  useParams } from "react-router";
import { getAllCategory, getCategoryInfo } from "../../store/CategorySlice";
import SelectedCategoryContent from "../../components/category/SelectedCategoryContent";

const SelectedCategoryPage: React.FC = () => {

    const dispatch = useDispatch();

  const selectedCategoryID = useParams<{ categoryID: string }>().categoryID;

  useEffect(()=>
  {
    const categoryID = {
      id: selectedCategoryID
    }
    dispatch(getCategoryInfo(categoryID))

  },[selectedCategoryID])


  useIonViewWillEnter(() => {
    dispatch(getAllProduct({}));
    dispatch(getShopsByStatus("ACTIVO"));
    dispatch(getAllProducts());
    dispatch(getAllCategory());

    const categoryID = {
        id: selectedCategoryID
      }
    dispatch(getCategoryInfo(categoryID))

}

  );
 


  return   ( <SelectedCategoryContent cat={selectedCategoryID} />
);
};

export default SelectedCategoryPage;
