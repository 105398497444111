import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import Catalogs from "../../components/catalogs/Catalogs";
import { getAllCatalogPages } from "../../store/CatalogPageSlice";
import { catalogSelector } from "../../store/CatalogSlice";

const CatalogsPage: React.FC = () => {
	const dispatch = useDispatch();
	const {actualCatalog} = useSelector(catalogSelector);
	useIonViewWillEnter(()=>dispatch(getAllCatalogPages({catalogId:actualCatalog.id})));
	
	return <Catalogs />;
};

export default CatalogsPage;