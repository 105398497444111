import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    IonLabel,
    IonTitle
  } from "@ionic/react";
  import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
  import {
    SHOP_PLAN_ONE,
    SHOP_PLAN_THREE,
    SHOP_PLAN_TWO,
  } from "../../pages/MyShop";
import { ShopObject, shopSelector } from "../../store/ShopSlice";
  
  const MyPlanCard: React.FC = () => {
    const [continueDisabled, setContinueDisabled] = useState(true);
  const {theShop} = useSelector(shopSelector)

    useEffect(() => {
        let allPlans = document.querySelectorAll(".planSelector");
        allPlans.forEach((elm) => {
          elm.classList.add("selected");
        });
    })
  
    return (
      <IonGrid fixed>
      <IonRow><IonCol className="ion-text-center ion-margin"><IonTitle color="primary">
        Características de tu plan
        </IonTitle></IonCol></IonRow>
        <IonRow>
            

         {theShop.plan == SHOP_PLAN_ONE && <IonCol>
            <IonCard
             
              className={"planSelector " + SHOP_PLAN_ONE}
            >
              <IonCardHeader>
                <IonCardTitle color="light"><strong>Cuenta gratis</strong></IonCardTitle>
                <IonCardSubtitle color="light"><strong>Sin mensualidad</strong></IonCardSubtitle>
                <IonLabel color="light"><i>Comisión por venta por producto $25</i></IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonText color="light"><ul><li>Visible en plataforma</li></ul></IonText>
                <IonText color="light"><ul><li>Soporte Técnico en plataforma</li></ul></IonText>
                <IonText color="light"><ul><li>Máximo de productos publicados = 10</li></ul></IonText>
              </IonCardContent>
            </IonCard></IonCol>}
          
         

           {theShop.plan == SHOP_PLAN_TWO && <IonCol>
            <IonCard
             
              className={"planSelector " + SHOP_PLAN_TWO}
            >
              <IonCardHeader>
                <IonCardTitle color="light"><strong>Cuenta Clásica</strong></IonCardTitle>
                <IonCardSubtitle color="light"><strong>Mensualidad de $150</strong></IonCardSubtitle>
                <IonText color="light"><i>Comisión por venta por producto $15</i></IonText>
              </IonCardHeader>
              <IonCardContent>
                <IonLabel color="light"><ul><li>Publicidad personalizada</li></ul></IonLabel>
                <IonLabel color="light"><ul><li>Soporte Técnico en plataforma</li></ul></IonLabel>
                <IonLabel color="light"><ul><li>Visible en plataforma</li></ul></IonLabel>
                <IonLabel color="light"><ul><li>Landing page: Logotipo y nombre de la empresa</li></ul></IonLabel>
                <IonLabel color="light"><ul><li>Máximo de productos publicados = 50</li></ul></IonLabel>
              </IonCardContent>
            </IonCard></IonCol>}
          
         {theShop.plan == SHOP_PLAN_THREE && <IonCol>
            <IonCard
              
              className={"planSelector " + SHOP_PLAN_THREE}
            >
              <IonCardHeader>
                <IonCardTitle color="primary"><strong>Cuenta Premium</strong></IonCardTitle>
                <IonCardSubtitle color="primary"><strong>Mensualidad de $200</strong></IonCardSubtitle>
                <IonText color="primary"><i>Comisión por venta por producto $10</i></IonText>
              </IonCardHeader>
              <IonCardContent>
                <IonText color="primary"><ul><li>Publicidad personalizada</li></ul></IonText>
                <IonText color="primary"><ul><li>Formato para control de inventarios</li></ul></IonText>
                <IonText color="primary"><ul><li>Formato para calcular ganancia</li></ul></IonText>
                <IonText color="primary"><ul><li>Soporte Técnico en plataforma</li></ul></IonText>
                <IonText color="primary"><ul><li>Asesorías, ventas y administración</li></ul></IonText>
                <IonText color="primary"><ul><li>Visible en plataforma</li></ul></IonText>
                <IonText color="primary"><ul><li>Landing page: Logotipo, nombre y descripción de la empresa</li></ul></IonText>
                <IonText color="primary"><ul><li>Redes sociales, 2 banners, promoción en la página principal</li></ul></IonText>
                <IonText color="primary"><ul><li>Catálogo PDF</li></ul></IonText>
                <IonText color="primary"><ul><li>Carrusel página principal</li></ul></IonText>
                <IonText color="primary"><ul><li>Máximo de productos publicados = 100</li></ul></IonText>
              </IonCardContent>
            </IonCard></IonCol>}
          
        </IonRow>
       
      </IonGrid>
    );
  };
  
  export default MyPlanCard;