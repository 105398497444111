import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonText,
  IonThumbnail,
} from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector } from "../../store/FlagSlice";
import {
  editProduct,
  getProductInfo,
  ProductObject,
  productSelector,
  setActualProduct,
} from "../../store/ProductSlice";
import { getAllProductVariation, readProductVariation } from "../../store/ProductVariationSlice";
import { PRODUCTS_VARIATIONS } from "./MyProductsContent";
import ProductVariationState from "./ProductVariationStatus";

const AllProductsCard: React.FC<{
  product: ProductObject;
  setProducts: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [indexValue, setIndexValue] = useState(0)
  const productVariationsHandler = () => {
    const productID = {
      id: props.product.id,
    };
    dispatch(getProductInfo(productID));
    props.setProducts(PRODUCTS_VARIATIONS);
  };
  const [prodVar, setProdVar] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | undefined
  >()


  const [checked, setChecked] = useState(false);
  const featuredProduct = () => {
    if (!checked && props.product.featured == undefined) {
      dispatch(editProduct({ id: props.product.id, featured: "DESTACADO" }));
    } else if (checked) {
      dispatch(editProduct({ id: props.product.id, featured: null }));
    }
  };

  const evaluateColSize = () => {
    if (window.innerWidth > 400) {
      switch (Number(props.product.productVariations.items[indexValue].pictures.length)) {
        case 6:
        return ["3", "0.7"] //first number is for size, second one is for offset
        case 5:
        return ["3", "0.7"]
        case 4:
          return ["3", "0"]
        case 3:
          return ["4", "0"]
        case 2:
          return ["6", "0"]
        case 1:
          return ["6", "3"]
      }} else {
        return (Number(props.product.productVariations.items[indexValue].pictures.length)) == 1 ? ["12", "0"] :  ["6", "0"]}
  }



  useEffect(() => {

    if (props.product.featured === "DESTACADO") {
     setChecked(true)
    }
    dispatch(getAllProductVariation)
    dispatch(readProductVariation(props.product.productVariations.items[0].id))

  },[props.product.productVariations.items[0].status, props.setProducts, props.product.productVariations])
  return (
    <IonCard className="ion-margin-bottom">
      <IonRow>
        <IonCol>
          <IonItem className="show-click">
              <IonThumbnail>
                <IonImg
                  src={props.product.productVariations.items[0].pictures[0]}
                ></IonImg>
              </IonThumbnail>
              <IonText
                onClick={() => {
                  dispatch(setActualProduct(props.product));
                  productVariationsHandler();
                }}
                color="dark"
                className="ion-margin-start"
              >
                {props.product.name}
              </IonText>
           
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
        <IonItem >
                <IonLabel color="primary">
                  <small>DESTACADO</small>
                </IonLabel>
                <IonCheckbox
                  checked={checked}
                  onIonChange={(e) => {
                    setChecked(e.detail.checked);
                    featuredProduct();
                  }}
                  slot="start"
                ></IonCheckbox>
              </IonItem>
        </IonCol>
      </IonRow><IonContent>
      <IonModal isOpen={showModal}>
        <IonGrid fixed>
      <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol></IonRow>
          <IonRow>
         <IonCol className="ion-text-center ion-margin">
          <b>
            <IonText color="tertiary" >
          {props.product.productVariations.items[indexValue].variationName} 
        </IonText></b></IonCol>
        </IonRow>
        
        <IonRow>
          
        {props.product.productVariations.items[indexValue].pictures.map((pic) =>
        <IonCol size={evaluateColSize()![0]} offset={evaluateColSize()![1]}><IonCard><IonImg src={pic}/></IonCard></IonCol>
        )}</IonRow>
         
      </IonGrid>
      </IonModal></IonContent>
      <IonRow className="ion-margin-start">
        <IonCol>
          <IonText>Variaciones</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        {props.product.productVariations.items.map((variation, index) => (
          <IonCol>
            <IonCard>
              <IonGrid>
                <IonRow className="ion-text-center">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonCardHeader>SKU: {variation.sku}</IonCardHeader>
                      </IonCol>
                      <IonCol>
                        <IonThumbnail onClick={()=> {setIndexValue(index); setShowModal(true); setActualProduct(props.product)}}>
                          <IonImg src={variation.pictures[0]}></IonImg>
                        </IonThumbnail>
                      </IonCol>
                    </IonRow>
                    <ProductVariationState setProductVarStatus={setProdVar} status={prodVar} prodVar={variation} />
                   
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonCol>
        ))}
        
      </IonRow>
    </IonCard>
  );
};

export default AllProductsCard;
