import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { shopSelector } from "../../store/ShopSlice";
import { Observable } from "zen-observable-ts";
import { onCreateCatalogPages, onDeleteCatalogPages, onUpdateCatalogPages } from "../../graphql/subscriptions";
import { useHistory } from "react-router";
import { catalogSelector } from "../../store/CatalogSlice";
import { ROUTE_SHOP, ROUTE_SHOP_CATALOGS } from "../../utils/routes";
import { getAllCatalogPages } from "../../store/CatalogPageSlice";
import CatalogPageFormEditProducts from "../../components/catalogs/CatalogPageFormEditProducts";
import { getAllCambaceo } from "../../store/CambaceoSlice";

const MyCatalogsPagesEditProductsPage: React.FC = () => {
	const dispatch = useDispatch();
	const { theShop } = useSelector(shopSelector);
	const { actualCatalog } = useSelector(catalogSelector);
	const history = useHistory();

	let createCatalogPageListener: any;
	let updateCatalogPageListener: any;
	let deleteCatalogPageListener: any;

	useIonViewWillEnter(() => {
		if (!theShop.shopID) {
			history.replace(ROUTE_SHOP);
		}
		if (!actualCatalog.id) {
			history.replace(ROUTE_SHOP_CATALOGS);
		}
		let filterCatalogID = {
			filter: { catalogID: { eq: actualCatalog.id } },
		};
		dispatch(getAllCatalogPages(filterCatalogID));
		dispatch(getAllCambaceo({}));
		
	});
	useIonViewDidEnter(() => {
		const onCreateSubClient = API.graphql(graphqlOperation(onCreateCatalogPages)) as Observable<object>;

		createCatalogPageListener = onCreateSubClient.subscribe({
			next: () => {
				let filterCatalogID = {
					filter: { catalogID: { eq: actualCatalog.id } },
				};
				dispatch(getAllCatalogPages(filterCatalogID));
			},
			error: (error) => console.warn(error),
		});

		const onUpdateSubClient = API.graphql(graphqlOperation(onUpdateCatalogPages)) as Observable<object>;

		updateCatalogPageListener = onUpdateSubClient.subscribe({
			next: () => {
				let filterCatalogID = {
					filter: { catalogID: { eq: actualCatalog.id } },
				};
				dispatch(getAllCatalogPages(filterCatalogID));
			},
		});

		const onDeleteSubClient = API.graphql(graphqlOperation(onDeleteCatalogPages)) as Observable<object>;

		deleteCatalogPageListener = onDeleteSubClient.subscribe({
			next: () => {
				let filterCatalogID = {
					filter: { catalogID: { eq: actualCatalog.id } },
				};
				dispatch(getAllCatalogPages(filterCatalogID));
			},
		});
	});

	useIonViewDidLeave(() => {
		//createCatalogPageListener.unsubscribe();
		//updateCatalogPageListener.unsubscribe();
		//deleteCatalogPageListener.unsubscribe();
	});
	return <CatalogPageFormEditProducts />;
};

export default MyCatalogsPagesEditProductsPage;
