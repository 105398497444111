import React from "react";
import { IonItem, IonText } from "@ionic/react";

const HelpPopover: React.FC<{
  text: string;
}> = ({ text }) => (
  <IonItem lines="none">
    <IonText className="ion-margin-vertical">{text}</IonText>
  </IonItem>
);

export default HelpPopover;
