import { useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MyShopContent from "../../components/shop/MyShopContent";
import { authSelector } from "../../store/AuthSlice";
import { getShopInfo } from "../../store/ShopSlice";

const MyShopPage: React.FC = () => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector(authSelector);

  useIonViewWillEnter(() => {
    let userProfileEmail = {
      userProfileEmail: userProfile.email,
    };
    dispatch(getShopInfo(userProfileEmail));
    
  });
  return <MyShopContent />;
};

export default MyShopPage;
