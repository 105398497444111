import { IonCard, IonInput,  IonItem, IonLabel, IonText, IonTitle, IonGrid, IonRow, IonCol, IonTextarea, IonBadge, IonButton, IonModal, IonIcon, IonCheckbox, IonToggle } from "@ionic/react";
import { arrowBackOutline, logoFacebook, shareSocial } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { flagSelector, setWasDeleted, setWasUpdated } from "../../store/FlagSlice";
import {
  editProduct,
  getProductInfo,
  ProductObject,
  productSelector,
  setActualProduct,
} from "../../store/ProductSlice";
import { shopSelector } from "../../store/ShopSlice";
import UserFeedback from "../global/general/UserFeedback";
import { PRODUCTS_ADD_VARIATIONS, PRODUCTS_VARIATIONS } from "./MyProductsContent";
import MyProductsEditForm from "./MyProductsEditForm";
import { PRODUCTS_EDIT, PRODUCTS_LANDING } from "./SelledProducts";
import { FacebookIcon, FacebookShareButton} from 'react-share'




const MyProductsCardVariation: React.FC<{
  product: ProductObject;
  status: string;
  setProducts:  React.Dispatch<React.SetStateAction<string>>;
  setEditProps:  React.Dispatch<React.SetStateAction<string>>;
}> = (props) => {
  const [productStatus, setProductStatus] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | undefined
  >()
  const {wasUpdated, loading, wasDeleted} = useSelector(flagSelector)
  const {actualProduct} = useSelector(productSelector)
  const [myProducts, setProducts] = useState(PRODUCTS_ADD_VARIATIONS)
  const {theShop} = useSelector(shopSelector)
  const [checked, setChecked] = useState(false)
  const [toastMessage, setToastMessage] = useState("");


const location = useLocation()
const dispatch = useDispatch()
const history = useHistory()

const featuredProduct = () => {
  if (!checked && props.product.featured == undefined) {
    dispatch(editProduct({id: props.product.id, featured: "DESTACADO"}))
  } else if (checked) {
    dispatch(editProduct({id: props.product.id, featured: null}))
  }
}
const productEditHandler = () => {
  const productID = {
    id: props.product.id,
  };
  dispatch(getProductInfo(productID));
  setProducts(PRODUCTS_EDIT);
  props.setEditProps("hide")
};
const goBackHandler = () => {
  if (myProducts === PRODUCTS_EDIT) {
    setProducts(PRODUCTS_ADD_VARIATIONS);
  props.setEditProps("")}
    else if (myProducts == PRODUCTS_VARIATIONS) {
    props.setProducts(PRODUCTS_LANDING)}
}

useEffect(() => {
 if (props.product.featured === "DESTACADO") {
  setChecked(true)
 }
if (wasUpdated) {
  setProducts(PRODUCTS_ADD_VARIATIONS)
  dispatch(setWasUpdated(false))
  setToastMessage("¡Producto actualizado!")
  dispatch(getProductInfo(actualProduct))
} else if (wasDeleted) {
setProducts(PRODUCTS_LANDING)
  dispatch(setWasDeleted(false))
  setToastMessage("¡Producto eliminado!")}
}, [props.product.status, props.product.featured, checked, wasUpdated, wasDeleted])

  return (<>                        
   <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
       {myProducts === PRODUCTS_EDIT && (<>
         <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
              <IonIcon slot="start" icon={arrowBackOutline} />Volver
              </IonButton>
            </IonCol>
            </IonRow>
            <MyProductsEditForm product={actualProduct}  /></>
          )}
          {myProducts === PRODUCTS_ADD_VARIATIONS &&
    <IonCard className="ion-padding ion-margin-bottom">
      <IonRow>
        <IonCol  sizeMd="6" sizeXs="12">
      <IonItem lines="none">
        <IonTitle  color="primary"><p className="ion-text-wrap">{props.product.name + "  "}<IonIcon color="tertiary" onClick={() => {
													window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20${actualProduct.name}:%20https://www.novocorpo.com.mx/producto/${actualProduct.id}`);
												}} icon={shareSocial}/> <FacebookShareButton quote={props.product.name}  url={`https://www.novocorpo.com.mx/producto/${actualProduct.id}`}><IonIcon icon={logoFacebook}/></FacebookShareButton></p>
                       
                        </IonTitle>  
      </IonItem></IonCol>  
      {location.pathname === "/mis-productos" && 
    <IonCol sizeMd="6" sizeXs="7.5" className="ion-text-right">
      <IonButton onClick={() => {
            productEditHandler();  
            }} fill="clear"><u>
        Editar</u>
      </IonButton></IonCol>
      }</IonRow>
   
     
      
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Marca:</IonLabel>
              <IonInput color="primary" readonly={true} value={props.product.brandName}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>

          <IonCol>
            <IonItem>
              <IonText  color="tertiary">Categorías:</IonText>
         <IonRow>
              {JSON.parse(JSON.stringify(props.product.category)).map((category: any) => (<IonCol><IonBadge color="primary">{category}</IonBadge></IonCol>))
              }
              </IonRow>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Descripción:</IonLabel>
              <IonTextarea readonly={true} color="primary" value={props.product.description}/>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating" color="tertiary">Especificaciones:</IonLabel>
              <IonTextarea readonly={true} color="primary" value={props.product.specifications}/>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>}
 </> );
};

export default MyProductsCardVariation;
