
import { useDispatch} from "react-redux";
import { getShopsByStatus } from "../../store/ShopSlice";
import { useIonViewWillEnter } from "@ionic/react";
import { getAllCatalog } from "../../store/CatalogSlice";
import ImagesAdminContent from "../../components/imgAdmin/ImagesAdminContent";
  
  const ImagesAdminPage: React.FC = () => {
    const dispatch = useDispatch();
  
    useIonViewWillEnter(() => {
    dispatch(getAllCatalog({}))
    dispatch(getShopsByStatus("ACTIVO"));
    })
  
  
    return <ImagesAdminContent/>
  };
  
  export default ImagesAdminPage;
  