import { IonCol, IonRow, IonText } from "@ionic/react";
import React from "react";

const ForgotPasswordInstructions: React.FC = () => {
  return (
    <IonRow>
      <IonCol className="ion-text-left">
        <IonText color="medium">
          ¡No te preocupes! Solo escribe tu correo electrónico y nosotros te enviaremos un código de verificación para cambiarla.
        </IonText>
      </IonCol>
    </IonRow>
  );
};

export default ForgotPasswordInstructions;
