
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import HomeContent from "../../components/home/HomeContent";
import { getAllProduct } from "../../store/ProductSlice";
import { getShopInfoFilter, getShopsByPlan } from "../../store/ShopSlice";
import { getAllCatalog } from "../../store/CatalogSlice";
import { getAllProperty } from "../../store/PropertySlice";
import { getAllCategory } from "../../store/CategorySlice";
import { getNovocorpoInfoFilter } from "../../store/NovocorpoSlice";

const HomePage: React.FC = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllProduct({}));
    dispatch(getAllCatalog({}));
    dispatch(getShopsByPlan("planThree"));
    dispatch(getAllProperty({}));
    dispatch(getShopInfoFilter({ filter: { shopID: { eq: "novocorpo" } } }));
    dispatch(getAllCategory())
    dispatch(getNovocorpoInfoFilter({novocorpoID: "novocorpo"}))

  });

  return <HomeContent />;
};

export default HomePage;
