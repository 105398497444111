import {
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { productSelector } from "../../store/ProductSlice";
import {
  editProductVariation,
  postProductVariation,
  removeProductVariation,
} from "../../store/ProductVariationSlice";
import { shopSelector } from "../../store/ShopSlice";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";

const ProductVariationForm: React.FC<{
  myProducts: Dispatch<SetStateAction<string>>;
}> = () => {
  const { actualProduct, products } = useSelector(productSelector);
  const {theShop} = useSelector(shopSelector)
  const [theProduct, setTheProducts] = useState<any>([]);
  let availabilityOptions = [
    { label: "Disponible", value: "in stock" },
    { label: "Agotado", value: "out of stock" },
    { label: "Descontinuado", value: "discontinued" },
  ];
  let conditionOptions = [
    { label: "Nuevo", value: "new" },
    { label: "Segunda Mano", value: "used" },
    { label: "Reacondicionado", value: "refurbished" },
  ];

  let counter: number = theShop.productsVarCounter ? theShop.productsVarCounter : 0
  const filteredProducts = products.filter((prod) => prod.shopID === theShop.shopID)
  const filteredProductsVar = filteredProducts.map((prod) => prod.productVariations.items.length)

  for (let i = 0; i < filteredProductsVar.length; i++) {
    counter = counter + filteredProductsVar[i]
  }

  const suggestedSKU = () => {
   return theShop.shopID === "novocorpo" ? "Novo-" + (counter+1) : theShop.shopID + "-" + (counter+1)
  }



  const fieldArray = [
    {
      name: "sku",
      label: "SKU del producto",
      type: "text" as FieldTypes,
      value: useState(suggestedSKU()),
      placeholder: "Ej: Novo101",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "variationName",
      label: "Nombre de la variación",
      type: "text" as FieldTypes,
      value: useState(theProduct.variationName),
      placeholder: "Ej: Talla XL color Rojo",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "amount",
      label: "Cantidad en inventario",
      type: "text" as FieldTypes,
      value: useState(theProduct.amount),
      placeholder: "Ej: 100",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "cost",
      label: "Precio",
      type: "text" as FieldTypes,
      value: useState(theProduct.cost),
      placeholder: "Ej: 100",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "price",
      label: "Precio en promoción",
      type: "text" as FieldTypes,
      value: useState(theProduct.price),
      placeholder: "Ej: 90",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: false,
      validationType: "text",
    },
    {
      name: "availability",
      label: "Disponibilidad",
      type: "select" as FieldTypes,
      options: availabilityOptions,
      multiple: false,
      value: useState('in stock'),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "condition",
      label: "Condición",
      type: "select" as FieldTypes,
      options: conditionOptions,
      multiple: false,
      value: useState('new'),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
    },
    {
      name: "pictures",
      label: "Imágenes",
      type: "images" as FieldTypes,
      value: useState(""),
      imageArray: useState([""]),
      placeholder: "",
      error: useState(false),
      errorMessage: useState(""),
      isRequired: true,
      validationType: "text",
      showPreview: true,
      max: 6,
      unavailableImageURL: UNAVAILABLE_IMAGE,
      bucketURL: BUCKET_URL,
      requiredWidth: 450,
      requiredHeight: 600
    },
  ];


  const actionObject = {
    add: {
      inputObject: {},
      dispatchFunction: postProductVariation,
      pushRoute: "/mis-productos",
    },
    edit: {
      inputObject: {},
      dispatchFunction: editProductVariation,
      pushRoute: "/mis-productos",
    },
    delete: {
      inputObject: {},
      dispatchFunction: removeProductVariation,
      pushRoute: "/mis-productos",
    },
  };

  

  return (
    <IonGrid>
      <IonRow>
        <IonCol> 
          <IonRow><IonCol className="ion-text-center"><IonText color="tertiary"><b>
            {"Estás a punto de crear una variación para "+ actualProduct.name}
            </b></IonText></IonCol></IonRow>
          <AmonytForm
            title="Crear variación de producto"
            fieldArray={fieldArray}
            add={true}
            actionObject={actionObject}
            hiddenFields={[
              { name: "status", value: "PENDIENTE DE APROBACIÓN" },
              { name: "productID", value: actualProduct.id },
              // { name: "amount", value: fieldArray[2].value[0] !== "" ? fieldArray[2].value[0] : "0"},
              // { name: "price", value: fieldArray[4].value[0] !== "" ? fieldArray[4].value[0] : "0"},
            ]}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProductVariationForm;
