import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import ShopProdroducts from "../../components/ShopProducts/ShopProducts";
import { getAllCategory } from "../../store/CategorySlice";
import { getAllProduct } from "../../store/ProductSlice";
 import {getShopsByStatus} from "../../store/ShopSlice";

const ShopProdroductsPage: React.FC = () => {
  const dispatch = useDispatch();

  useIonViewWillEnter(() => {
    dispatch(getAllProduct({}));
    dispatch(getShopsByStatus("ACTIVO"));
    dispatch(getAllCategory())
  });



  return <ShopProdroducts/>;
};

export default ShopProdroductsPage;
