import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonSearchbar,
  } from "@ionic/react";
  import { arrowBackOutline, pricetags} from "ionicons/icons";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { flagSelector, setWasCreated, setWasDeleted, setWasUpdated } from "../../store/FlagSlice";
  import { getAllProperty, getPropertyInfo, propertySelector } from "../../store/PropertySlice";
  import UserFeedback from "../global/general/UserFeedback";
  import MyShopToolbar from "../shop/MyShopToolbar";
  import MyPropertiesCard from "./MyPropertiesCard";
  import { useHistory } from "react-router";
  import Toolbar from "../global/general/Toolbar";
import MyPropertiesDetailsCard from "./MyPropertiesDetailsCard";
import MyPropertiesEditForm from "./MyPropertiesEditForm";
import { ROUTE_SHOP_PROPERTIES_ADD, ROUTE_SHOP_PROPERTYFEATURE_ADD } from "../../utils/routes";
import { getAllPropertyFeature } from "../../store/PropertyFeatureSlice";
import MyPropertiesFormPage from "../../pages/properties/MyPropertiesFormPage";
  
  export const PROPERTIES_LANDING = "propertiesLanding";
  export const PROPERTIES_ADD = "propertiesAdd";
  export const PROPERTIES_DETAILS = "propertiesDetails";
  export const PROPERTIES_EDIT = "propertiesEdit";
  export const PROPERTYFEATURES_ADD = "propertyFeaturesAdd"
  
  const MyPropertiesContent: React.FC = () => {
    const [MyProperties, setProperties] = useState(PROPERTIES_LANDING);
    const { wasCreated,loading, wasUpdated, wasDeleted } = useSelector(flagSelector);
    const { properties, actualProperty } = useSelector(propertySelector);
    const [toastMessage, setToastMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
      if (wasCreated && MyProperties === PROPERTIES_ADD) {
        setProperties(PROPERTIES_LANDING);
        setToastMessage("¡Inmueble creado satisfactoriamente!");
        dispatch(setWasCreated(false));
        dispatch(getAllProperty({}))
      } if (wasUpdated) {
        setProperties(PROPERTIES_LANDING);
        setToastMessage("¡Inmueble actualizado!");
        dispatch(setWasUpdated(false));
        dispatch(getAllProperty({}))
      } else if (wasDeleted) {
        setProperties(PROPERTIES_LANDING);
        setToastMessage("¡Inmueble eliminado!");
        dispatch(setWasDeleted(false));
        dispatch(getAllProperty({}))
      }
      
    }, [wasCreated, toastMessage, setProperties, wasDeleted, wasUpdated]);
  
    const goBackHandler = () => {
      if (MyProperties === PROPERTIES_LANDING) {
        history.push("/mi-tienda");
      } else if (MyProperties === PROPERTIES_ADD) {
        history.push("/mis-inmuebles");
        setProperties(PROPERTIES_LANDING);
      } else if (MyProperties === PROPERTIES_DETAILS) {
        dispatch(getAllProperty({}));
        setProperties(PROPERTIES_LANDING);
      } 
      else if (MyProperties === PROPERTIES_EDIT) {
         setProperties(PROPERTIES_LANDING);
        history.push("/mis-inmuebles");
       
      } 

      
      
    };
    return (
      <IonPage>
        <IonHeader>
          <Toolbar />
        </IonHeader>
        <MyShopToolbar iconName={pricetags} title="Mis Inmuebles" />
        <UserFeedback
          loading={loading}
          toastMessage={[toastMessage, setToastMessage]}
          
        />
        <IonContent>
         

          <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
            <IonRow>
              <IonCol>
                <IonButton fill="clear" onClick={goBackHandler}>
                  <IonIcon slot="start" icon={arrowBackOutline} />
                  Volver
                </IonButton>
              </IonCol>
              
              {MyProperties === PROPERTIES_LANDING && (<>
                <IonCol offsetMd="5" offsetXs="0.5" className="ion-text-end">
                  <IonButtons>
                  <IonButton
                    color="primary"
                    fill="solid"
                    onClick={() => {
                      setProperties(PROPERTIES_ADD);
                      history.push(ROUTE_SHOP_PROPERTIES_ADD);
                      dispatch(getAllPropertyFeature())
                    }}
                  >
                    Agregar inmueble
                  </IonButton>
                
                    <IonButton onClick={() => {history.push(ROUTE_SHOP_PROPERTYFEATURE_ADD)}} color="primary" fill="solid">
                      Agregar características
                    </IonButton></IonButtons>
                  </IonCol>
             </> )}
            </IonRow>
            {MyProperties === PROPERTIES_LANDING && (
              <>
                <IonRow>
                  <IonCol>
                    <IonSearchbar
                      value={searchText}
                      onIonChange={(e) => setSearchText(e.detail.value!)}
                      placeholder="Nombre del inmueble"
                      class="search-bar-color"
                    />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {properties.length > 0 &&
                      properties
                        .filter((property: any) =>
                          property.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((property: any) => (
                          <MyPropertiesCard
                            key={property.id}
                            property={property}
                            setProperties={setProperties}
                          />
                        ))}
                  </IonCol>
                </IonRow>
              </>
            )}
            {MyProperties === PROPERTIES_ADD && (
              <>
                <MyPropertiesFormPage />
              </>
            )}

            {MyProperties === PROPERTIES_EDIT && (<>
              <MyPropertiesEditForm myProperties={setProperties} />
            </>)}

            {MyProperties === PROPERTIES_DETAILS && (
              <>
                <IonRow>
                  <IonCol>
                    <MyPropertiesDetailsCard
                      key={actualProperty.id}
                      property={actualProperty}
                      
                    />
                  </IonCol>
                </IonRow>
              </>
            )}
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default MyPropertiesContent;
  