import { IonButton, IonContent, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonTitle, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";
import { arrowBackOutline, arrowUndo } from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";

const TermsAndConditions: React.FC<{}> = (props) => {
	const history = useHistory();
	const goBackHandler = () => {
		history.replace("/home");
	};
  	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="page-content">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol className="ion-text-center"><IonTitle color="primary"><h1>Términos y Condiciones</h1></IonTitle></IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>Actualizado en enero de 2021</strong></p></IonText>
							<IonLabel color="primary">
								<p>Le remitimos esta Declaración de Privacidad o usted accede a la misma porque está visitando una página web o está utilizando una aplicación móvil («aplicación») de la empresa Novocorpo S.A., (en adelante también como: “Novocorpo”). Por consiguiente, esta empresa está tratando información sobre usted que constituye «datos personales» y Novocorpo considera que la protección de sus datos personales y su privacidad es un asunto de la máxima importancia.<br></br>
								Novocorpo , S.A., con domicilio en WTC Almeda Park, Plaça de la Pau s/n, Edificio 6, planta 3. 08940 Cornellà de Llobregat (Barcelona), es responsable del tratamiento de sus datos personales, ya que decide por qué y cómo se tratan, por lo que actúa en calidad de «Responsable del tratamiento». En esta Declaración de Privacidad, «nosotros» se refiere a Novocorpo.<br></br>
								Esta Declaración de Privacidad se divide en dos partes. La Parte I contiene información práctica sobre los datos personales específicos que tratamos cuando usted visita nuestra página web o utiliza nuestra aplicación, por qué tratamos estos datos y cómo lo hacemos. La Parte II contiene más información general sobre los datos personales técnicos o transaccionales estándar que estamos tratando sobre los visitantes de nuestras páginas web y los usuarios de nuestras apps, la base legal para utilizar sus datos personales, y sus derechos en relación con todos los datos personales recopilados sobre usted.<br></br>
								Le invitamos a leer detenidamente esta Declaración de Privacidad y, si tiene alguna otra duda relativa al tratamiento de sus datos personales, le invitamos a ponerse en contacto con el Data protection officer (DPO) en privacy@Novocorpo.com.<br></br>
								</p>
							</IonLabel>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>Parte I – Información importante</strong></p></IonText>
							<IonLabel color="primary">
								<p>Novocorpo está tratando datos personales sobre usted cuando visita y o utiliza nuestra aplicación.
									Datos personales específicos que se recopilarán 
									A tales efectos, recopilaremos los siguientes datos personales específicos sobre usted: aquellos datos que nos facilite en caso de existir formularios de recogida de datos, los datos que nos pueda usted remitir al utilizar los apartados de contacto que puedan existir, aquellos datos relativos a la aplicación que se descargue y utilice.
										Esta información puede ser proporcionada directamente por usted (p. ej. al rellenar un formulario web o interactuar con una página web o aplicación), proporcionada por terceros u obtenida a través de fuentes públicas de confianza, tras obtener su consentimiento para proporcionarnos estos datos personales cuando sea necesario conforme a la legislación aplicable.
									Propósitos específicos para los que necesitamos sus datos personales
									Utilizaremos la información recopilada con los siguientes fines específicos:
									Gestionar nuestros usuarios;
									Gestionar y mejorar nuestras páginas web y aplicaciones;
									Medir el uso de nuestras páginas web y aplicaciones;
									Mejorar y personalizar su experiencia y ajustar mejor el contenido a usted;
									Enviarle servicios y contenidos personalizados basados en su ubicación;
									Mejorar la calidad de nuestros productos y servicios y ampliar nuestras actividades comerciales;
									Controlar y evitar fraudes, infracciones y otros posibles usos indebidos de nuestras páginas web y aplicaciones;
									Carrera o buscador de empleo: en caso que esté activada dicha funcionalidad sus datos serán utilizados con la finalidad principal de gestión y selección de personal.
									En caso que nos lo solicite, y sea permitido por la normativa legal vigente, le remitiremos comunicaciones comerciales por vía electrónica productos y/o servicios propios o de terceros.
									Redes sociales: trataremos sus datos con las finalidades de administrar correctamente su presencia en la red social que corresponda, informarlo de nuestras actividades productos y/o servicios, o de terceros que puedan estar relacionados con nuestra actividad, así como para cualquier otra finalidad que las normativas de las Redes Sociales puedan permitir.
									Responder a un requerimiento oficial de una autoridad pública o judicial que cuente con la autorización necesaria;
									Gestionar nuestros recursos de Tecnologías de la información (TI), incluyendo la gestión de infraestructuras y la continuidad del negocio;
									Preservar los intereses económicos de la empresa y garantizar el cumplimiento y la generación de informes;
									Archivado y conservación de registros; y
									Cualquier otra finalidad que impongan la ley y las autoridades.
									Tenga en cuenta que también podemos utilizar los datos recopilados para algunos otros propósitos habituales, y que las anteriores finalidades están más detalladas en la Parte II a continuación:

									Terceros específicos con los que compartiremos sus datos personales

									Compartiremos sus datos personales con los terceros que nos puedan prestar servicios siempre con las garantías jurídicas que correspondan en cada caso.

									Tenga en cuenta que también podríamos tener que compartir sus datos con otros varios destinatarios (p. ej. otra entidad del Grupo Novocorpo si la entidad que recopila datos no es la misma que la que los utiliza) pero siempre en condiciones estrictas, como se explica en mayor detalle en la Parte II.

									Duración del almacenamiento

									Solo almacenaremos los datos personales anteriores y los datos personales indicados en la Parte II durante un periodo que comprende hasta la duración del uso de la web o aplicación específica y con un máximo de tres años desde su última conexión.
									Cookies y otras tecnologías similares
									Se utilizan los tipos específicos de cookies y otras tecnologías de seguimiento explicadas en la Parte II. En caso que la web tenga otra política de cookies específica, prevalecerá lo establecido en dicha política.
									Tenga en cuenta que también utilizamos las cookies y otras tecnologías habituales para los propósitos estándar indicados en la Parte II a continuación (p. ej. para garantizar el correcto funcionamiento de nuestra página web o aplicación).
									Punto de contacto específico. Si tiene alguna duda relativa al tratamiento de sus datos personales en el contexto anterior, póngase en contacto con el DPO enviando un correo electrónico a <a href="mailto:privacy@Novocorpo.com">privacy@Novocorpo.com</a></p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><p><strong>Parte II – Información general</strong></p></IonText>
							<IonLabel color="primary">
								<p>
									La segunda parte de esta Declaración de Privacidad indica en mayor detalle en qué contexto estamos tratando sus datos personales y explica sus derechos y nuestras obligaciones durante el proceso.
									¿En qué supuestos utilizaremos sus datos personales?
									No trataremos sus datos personales si no tenemos una justificación apropiada contemplada en la legislación a tales efectos. Por lo tanto, solo trataremos sus datos personales:
									Si hemos obtenido su consentimiento previamente;
									Si el tratamiento es necesario para cumplir con las obligaciones contractuales que hemos adquirido con usted o para adoptar medidas precontractuales si así lo solicita;
									Si el tratamiento es necesario para cumplir con nuestras obligaciones legales o reglamentarias; o
									Si el tratamiento es necesario para nuestros intereses legítimos y no afecta de forma indebida a sus intereses o derechos y libertades fundamentales.
									Tenga en cuenta que, al tratar sus datos personales basándonos en este último supuesto, siempre intentamos mantener un equilibrio entre nuestros intereses legítimos y su privacidad. Ejemplos de estos «intereses legítimos» son las actividades de tratamiento de datos realizadas:
									para beneficiarnos de servicios rentables (p. ej. podemos optar por utilizar ciertas plataformas ofrecidas por proveedores para tratar datos);
									para ofrecer nuestros productos y servicios a nuestros clientes;
									para evitar fraudes o actividades delictivas, usos indebidos de nuestros productos y servicios, así como la seguridad de nuestras redes, arquitectura y sistemas de TI;
									para vender cualquier parte de nuestro negocio o sus activos o para permitir la adquisición de la totalidad o una parte de nuestro negocio o activos por un tercero; y
									para cumplir nuestros objetivos de responsabilidad corporativa y social.
									¿Quién tiene acceso a sus datos personales y a quién se transfieren?
									Nos comprometemos a no vender, difundir ni transmitir de ningún otro modo sus datos personales a terceros, excepto en los casos indicados en la presente Declaración de Privacidad.
									Durante el transcurso de nuestras actividades y para los mismos propósitos que los descritos en esta Declaración de Privacidad, sus datos personales pueden ser consultados por los terceros específicos identificados en la Parte I de esta Política de Privacidad, o transferidos a ellas y a las siguientes categorías de destinatarios, en caso de que tengan que conocerlos, para cumplir dichos propósitos:
									nuestro personal (incluyendo personal, departamentos u otras empresas del grupo Novocorpo);
									nuestros otros proveedores y proveedores de servicios que nos proporcionan productos y servicios;
									nuestros proveedores de sistemas informáticos, proveedores de servicios en la nube, proveedores de bases de datos y consultores;
									nuestros socios comerciales que ofrecen productos o servicios conjuntamente con nosotros;
									cualquier tercero al que cedamos o novemos cualquiera de nuestros derechos y obligaciones;
									nuestros asesores y abogados externos en el contexto de la venta o transferencia de cualquier parte de nuestro negocio o sus activos.
									Los terceros anteriormente indicados están obligados por contrato a proteger la confidencialidad y la seguridad de sus datos personales, en cumplimiento de la legislación aplicable.
									Sus datos personales también pueden ser consultados por organismos reguladores, policiales, públicos o tribunales nacionales e internacionales, o transferidos a ellos, bien cuando tengamos la obligación de hacerlo en cumplimiento de la legislación o normativa aplicable o cuando lo soliciten.
									Los datos personales que recopilemos de usted también pueden ser objeto de tratamiento, acceso o almacenamiento en un país distinto de aquel en que se encuentra Novocorpo, que quizá no ofrezca el mismo nivel de protección de los datos personales.
									Si transferimos sus datos personales a empresas externas en otras jurisdicciones, garantizaremos la protección de sus datos personales (i) aplicando el nivel de protección requerido conforme a la legislación local en materia de protección de datos/privacidad aplicable a Novocorpo, (ii) actuando de conformidad con nuestras normas y políticas, y (iii) para Novocorpo ubicada en el Espacio Económico Europeo (es decir, los estados miembros de la UE más Islandia, Liechtenstein y Noruega, el «EEE»), a menos que se disponga lo contrario, transfiriendo exclusivamente sus datos personales conforme a cláusulas contractuales estándar aprobadas por la Comisión Europea. Puede solicitar información adicional en relación con transferencias internacionales de datos personales y obtener una copia de las medidas de protección pertinentes implementadas ejerciendo sus derechos como se detalla a continuación en el apartado 6.
									¿Cómo protegemos sus datos personales?
									Hemos implementado medidas técnicas y organizativas apropiadas para proporcionar un nivel de seguridad y confidencialidad a sus datos personales.
									Estas medidas tienen en cuenta:
									los avances más recientes de la tecnología los costes de su implementación;
									la naturaleza de los datos; y
									el riesgo del tratamiento.
									Su propósito consiste en protegerlos frente a la destrucción o alteración accidental o indebida, la pérdida accidental, la divulgación o acceso no autorizados y cualquier otra forma indebida de tratamiento.
									Además, al tratar sus datos personales, cumpliremos con las siguientes obligaciones:
									solo recopilamos y tratamos datos personales que sean adecuados, relevantes y no excesivos, según se requiera para cumplir los propósitos anteriores;
									garantizamos que sus datos personales estén actualizados y sean exactos (para este último propósito, podríamos pedirle que confirme los datos personales que tenemos sobre usted y también le animamos a informarnos espontáneamente si se produce un cambio en sus circunstancias personales para que podamos garantizar que sus datos están actualizados); y
									podemos tratar los datos confidenciales sobre usted que proporcione voluntariamente en cumplimiento de las normas de protección de datos aplicables y según sea estrictamente necesario para los propósitos relevantes indicados anteriormente, y solo el personal pertinente accede a los datos y los trata, bajo la responsabilidad de uno de nuestros representantes que tiene la obligación de mantener el secreto profesional o la confidencialidad.
									¿Durante cuánto tiempo conservaremos sus datos personales?

									Solo conservaremos sus datos personales durante el tiempo necesario para cumplir con el propósito para el que se han recopilado o para cumplir con requisitos reglamentarios o legales.
									A menos que se indique lo contrario en la Parte I de esta Declaración de Privacidad, el periodo de retención es de 36 meses desde su último uso de/acceso a la página web o aplicación pertinente. Cuando finalice este periodo, sus datos personales se eliminarán de nuestros sistemas activos.
									¿Cómo utilizamos cookies y otras tecnologías similares en nuestras páginas web y aplicaciones?
									5.1 Cookies

									Las cookies son pequeños archivos de texto que se envían a su ordenador cuando visita una página web. Utilizamos cookies con los propósitos anteriormente indicados y de conformidad con esta Declaración de Privacidad.

									No utilizamos cookies para controlar a visitantes individuales o identificarle, sino para obtener un conocimiento práctico sobre el modo en el que se utilizan nuestras páginas web y aplicaciones que nos permita mejorarlas para los usuarios. Los datos personales generados a través de cookies se recogen en un formato seudoanonimizado y están sujetos a su derecho a presentar objeciones a este tratamiento de los datos, como se detalla a continuación.

									En particular, podemos utilizar los siguientes tipos de cookies habituales:

									cookies de personalización de interfaz de usuario (es decir, cookies que recuerdan sus preferencias);
									cookies de autenticación (es decir, cookies que le permiten abandonar nuestras páginas y volver sin tener que autenticarse otra vez);
									cookies de reproductor de vídeo (es decir, cookies que almacenan datos necesarios para reproducir contenido de audio o vídeo y guardar sus preferencias);
									cookies analíticas de primeras partes (es decir, cookies que memorizan las páginas que ha visitado y proporcionan información sobre su interacción con estas páginas); y
									cookies analíticas de terceras partes (es decir, cookies de terceros proveedores que controlan las estadísticas de nuestra página web y viceversa).
									Tenga en cuenta que puede modificar su navegador para que le notifique el envío de cookies. Si no desea recibir cookies, también puede rechazarlas definiendo los ajustes apropiados en su navegador. Finalmente, también puede eliminar las cookies que ya se le hayan enviado.

									Para obtener más información sobre cómo gestionar cookies en su dispositivo, consulte la función de Ayuda de su navegador o visite www.aboutcookies.org, que contiene información exhaustiva sobre cómo hacerlo en una gran variedad de navegadores (el enlace es externo).

									5.2 Otras tecnologías

									También podemos utilizar otras tecnologías en nuestras páginas web y aplicaciones para recopilar y tratar sus datos personales con los mismos propósitos que los anteriormente indicados, incluyendo:

									Etiquetas de Internet (como etiquetas de acción, GIF de un píxel, GIF evidentes, GIF invisibles y GIF 1 por 1, que son tecnologías que nos permiten seguir resultados de usuarios); y
									tecnología Adobe Flash (incluyendo objetos locales compartidos Flash, a menos que modifique el ajuste).
									¿Cuáles son sus derechos y cómo puede ejercerlos?


									Puede ejercer los derechos siguientes en las condiciones y los límites estipulados por ley:

									el derecho de acceso a sus datos personales tal como los tratamos y, si cree que alguna información relativa a usted es incorrecta, obsoleta o incompleta, a solicitar su corrección o actualización;
									el derecho a solicitar la eliminación de sus datos personales o su restricción a categorías específicas de tratamiento;
									el derecho a retirar su consentimiento en cualquier momento, sin que ello afecte a la validez del tratamiento antes de la retirada;
									el derecho a presentar objeciones, en su totalidad o en parte, al tratamiento de sus datos personales;
									el derecho a presentar objeciones a comunicaciones de marketing directo; y
									el derecho a solicitar su portabilidad, es decir, que los datos personales que nos haya proporcionado se le devuelvan o se transmitan a la persona que elija, en un formato estructurado, utilizado habitualmente y legible por máquina, sin ningún impedimento por nuestra parte y con arreglo a sus obligaciones de confidencialidad.
									No obstante, tenga en cuenta que, en determinadas circunstancias, el hecho de no aceptar cookies o la configuración de su navegador pueden afectar a su experiencia de navegación e impedir que utilice ciertas funciones en nuestras páginas web o aplicaciones.

									Si tiene una pregunta o desea ejercer los derechos anteriores, puede enviar un correo electrónico al DPO privacy@Novocorpo.com junto con una imagen escaneada de su documento nacional de identidad a efectos identificativos.

									Si no le satisface el modo en que tratamos sus datos personales, diríjase a nuestro responsable de protección de datos privacy@Novocorpo.com, que analizará su reclamación.

									En cualquier caso, también tiene derecho a presentar una reclamación a las autoridades de protección de datos competentes, además de sus derechos anteriores.

									¿Qué datos técnicos y transaccionales podemos recoger sobre usted?


									7.1 Categorías de datos técnicos y transaccionales

									Además de la información recopilada sobre usted conforme a la Parte I de esta Declaración de Privacidad, podemos recopilar varios tipos de datos personales técnicos y transaccionales habituales sobre usted durante el uso de nuestras páginas web y aplicaciones que se requieren para el correcto funcionamiento de nuestras páginas web y aplicaciones, incluyendo:

									información sobre su navegador y dispositivo (p. ej. dominio del proveedor de servicios de Internet, tipo y versión del navegador, sistema operativo y plataforma, resolución de pantalla, fabricante y modelo del dispositivo); 
									estadísticas relativas a su uso de nuestras páginas web y aplicaciones (p. ej. información sobre las páginas visitadas, información buscada, duración de la visita a nuestra página web); 
									datos de uso (es decir, fecha y hora de acceso a nuestra página web y aplicación, archivos descargados); 
									la ubicación de su dispositivo al utilizar nuestra aplicación (a menos que desactivara esta función en la configuración de su dispositivo); y 
									a nivel más general, cualquier información que nos proporcione al utilizar nuestras páginas web y aplicaciones.
									Tenga en cuenta que no recopilaremos, utilizaremos ni divulgaremos de forma intencionada datos personales de menores de 18 años sin obtener el consentimiento previo de un progenitor o tutor legal.

									7.2 ¿Por qué recogemos datos técnicos y transaccionales?

									Siempre tratamos sus datos personales con un objetivo específico y solo tratamos datos personales que sean pertinentes para cumplir ese objetivo. Además de los propósitos que ya se le han comunicado en la Parte I de esta Declaración de Privacidad, también tratamos sus datos personales recogidos durante el uso de una de nuestras páginas web o aplicaciones para los siguientes propósitos habituales:

									gestionar nuestros usuarios (p. ej. registro, gestión de cuentas, responder a preguntas y ofrecer asistencia técnica); 
									gestionar y mejorar nuestras páginas web y aplicaciones (p. ej. diagnosticar problemas de servidores, optimizar el tráfico, integrar y optimizar páginas web cuando proceda);
									medir el uso de nuestras páginas web y aplicaciones (p. ej. generando estadísticas de tráfico, recopilando información sobre el comportamiento de los usuarios y las páginas que visitan);
									mejorar y personalizar su experiencia y ajustar mejor el contenido a usted (p. ej. recordando sus selecciones y preferencias, mediante el uso de cookies); 
									enviarle servicios y contenidos personalizados basados en su ubicación;
									mejorar la calidad de nuestros productos y servicios y ampliar nuestras actividades comerciales; 
									controlar y evitar fraudes, infracciones y otros posibles usos indebidos de nuestras páginas web y aplicaciones; 
									carrera o buscador de empleo: en caso que esté activada dicha funcionalidad sus datos serán utilizados con la finalidad principal de gestión y selección de personal. Este apartado, una vez lo señale, verá que está dentro de la web de Novocorpo, por lo que se regirá por las condiciones legales y de privacidad que allí se establezcan. En cualquier caso, consiente que los datos facilitados por usted en dicha web nos sean comunicados para las finalidades acabadas de exponer.
									en caso que nos lo solicite, y sea permitido por la normativa legal vigente, le remitiremos comunicaciones comerciales por vía electrónica, productos y/o servicios propios o de terceros relacionados con los sectores de fabricación y comercialización de productos, para el cuidado de la visión, así como de fabricación y comercialización de equipos y dispositivos quirúrgicos.
									redes sociales: ponemos en su conocimiento que estamos presentes en Redes Sociales. El tratamiento de los datos que se realice de las personas que se hagan seguidoras (y/o realicen cualquier vínculo o acción de conexión a través de las Redes Sociales) de las páginas oficiales del Responsable del Tratamiento, en las redes sociales, se regirá por este apartado, el resto de la presente Política de privacidad y las Condiciones de Uso de la Web, así como por aquellas Condiciones de uso, políticas de privacidad y demás normativas de acceso, uso y similares que pertenezcan a la red social que corresponda. Trataremos sus datos con las finalidades de administrar correctamente su presencia en la red social que corresponda, informarlo de nuestras actividades productos y/o servicios, o de terceros que puedan estar relacionados con nuestra actividad (relativa a los sectores de fabricación y comercialización de productos y/o servicios para el cuidado de la visión, así como de fabricación y comercialización de equipos y dispositivos quirúrgicos), así como para cualquier otra finalidad que las normativas de las Redes Sociales puedan permitir.
									responder a un requerimiento oficial de una autoridad pública o judicial que cuente con la autorización necesaria;
									gestionar nuestros recursos de TI, incluyendo la gestión de infraestructuras y la continuidad del negocio;
									preservar los intereses económicos de la empresa y garantizar el cumplimiento y la generación de informes (como el cumplimiento de nuestras políticas y los requisitos legales locales, la fiscalidad y las deducciones, la gestión de supuestos casos de mala conducta o fraude, la realización de auditorías y la defensa en litigios);
									archivado y conservación de registros; y
									cualquier otra finalidad que impongan la ley y las autoridades.
									¿Cómo se le informará de los cambios en nuestra Declaración de Privacidad?

									Cualquier futuro cambio o adición al tratamiento de sus datos personales como se describe en esta Declaración de Privacidad se le comunicará de antemano a través de una notificación individual mediante nuestros canales de comunicación habituales (p. ej. por correo electrónico), así como a través de nuestras páginas web o aplicaciones (a través de banners, mensajes emergentes u otros mecanismos de notificación). 
								</p>
							</IonLabel>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonText color="tertiary"><h1>Aviso Legal</h1></IonText>
							<IonLabel color="primary">
								<p>
									Actualizado en enero de 2021<br></br>
									El acceso a la presente página Web y el uso de la misma están supeditados a las condiciones que se expresan a continuación, y a la legislación vigente. Al acceder a esta página Web y explorar el contenido de la misma, Ud. acepta, sin reserva ni limitación, las presentes condiciones, y reconoce que cualquier otro acuerdo entre Ud. y Novocorpo , S.A. (en lo sucesivo denominada como "Novocorpo") queda anulado y sin vigencia ni efecto.<br></br>
									Los datos de Novocorpo son los siguientes:
								</p>
									<ul>
										<li><p>Denominación: Novocorpo , S.A.</p></li>
										<li><p>NIF A-08015836</p></li>
										<li><p>Domicilio: WTC Almeda Park, Plaça de la Pau s/n, Edificio 6, planta 3. 08940 Cornellà de Llobregat (Barcelona)</p></li>
										<li><p>Correo electrónico: operaciones@Novocorpo.com</p></li>
										<li><p>Inscrita en el Registro Mercantil de Barcelona, Tomo 47293, Folio 12, Hoja B-34424, Inscripción 359</p></li>
									</ul>
							</IonLabel>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default TermsAndConditions;
