import React from "react";
import { IonAlert } from "@ionic/react";

const ShopAlert: React.FC<{
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  return (
    <IonAlert
      mode="ios"
      isOpen={props.showAlert}
      header={"Bienvenido"}
      message={
        "Tu tienda pasará por un proceso de validación. Tan luego quede lista te avisaremos por correo electrónico."
      }
      backdropDismiss={false}
      buttons={[
        {
          text: "Aceptar",
          handler: () => {
            props.setShowAlert(false);
          },
        },
      ]}
    />
  );
};

export default ShopAlert;
