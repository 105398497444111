import { IonBadge, IonButton, IonCard, IonCardHeader, IonCheckbox, IonChip, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonItem, IonLabel, IonModal, IonRow, IonText, IonThumbnail } from "@ionic/react";
import {
  closeCircleOutline,
  createOutline,
  pricetagOutline,
  shareSocial,
 
} from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllCategory } from "../../store/CategorySlice";
import {
  editProduct,
  getProductInfo,
  ProductObject,
  setActualProduct,
} from "../../store/ProductSlice";
import { editProductVariation, getAllProductVariation, ProductVariationObject, readProductVariation } from "../../store/ProductVariationSlice";
import { UNAVAILABLE_IMAGE } from "../../utils/constants";
import InactiveCheckbox from "./InactiveCheckbox";
import { PRODUCTS_VARIATIONS } from "./MyProductsContent";
import ProductVariationState from "./ProductVariationStatus";
import { PRODUCTS_EDIT } from "./SelledProducts";

const MyProductsCard: React.FC<{
  product: ProductObject;
  setProducts: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [indexValue, setIndexValue] = useState(0)
  const productVariationsHandler = () => {
    const productID = {
      id: props.product.id,
    };
    dispatch(getProductInfo(productID));
    props.setProducts(PRODUCTS_VARIATIONS);
  };

  
  const [checked, setChecked] = useState(false);

  const evaluateColSize = () => {
    if (window.innerWidth > 400) {
      switch (Number(props.product.productVariations.items[indexValue].pictures.length)) {
        case 6:
        return ["3", "0.7"] //first number is for size, second one is for offset
        case 5:
        return ["3", "0.7"]
        case 4:
          return ["3", "0"]
        case 3:
          return ["4", "0"]
        case 2:
          return ["6", "0"]
        case 1:
          return ["6", "3"]
      }} else {
        return (Number(props.product.productVariations.items[indexValue].pictures.length)) == 1 ? ["12", "0"] :  ["6", "0"]}
  }
  const colorByStatus = (varStat: string) => {
    switch (varStat) {
      case "ACTIVO":
        return "success";
      case "INACTIVO":
        return "medium";
      case "PENDIENTE DE APROBACIÓN":
        return "warning";
        case "NO APROBADO":
        return "tertiary";
    }
  };




  
  return (

<IonCard className="ion-margin-bottom">
      <IonRow>
        <IonCol>
          <IonItem className="show-click">
              <IonThumbnail>
                <IonImg
                  src={props.product.productVariations.items[0] !== undefined? props.product.productVariations.items[0].pictures[0] : UNAVAILABLE_IMAGE}
                ></IonImg>
              </IonThumbnail>
              <IonText
                onClick={() => {
                  dispatch(setActualProduct(props.product));
                  productVariationsHandler();
                }}
                color="dark"
                className="ion-margin-start"
              >
                {props.product.name}
              </IonText>
              {props.product.productVariations.items.filter((pv) => pv.status == "ACTIVO").length > 0 && 
                  <IonButton
												fill="clear"
												onClick={() => {
													window.open(`https://wa.me/?text=Echa%20un%20vistazo%20a%20${props.product.name}:%20https://www.novocorpo.com.mx/producto/${props.product.id}`);
												}}
											> 
												<IonIcon color="tertiary" slot="icon-only" icon={shareSocial} />
											</IonButton>}
          </IonItem>
        </IonCol>
      </IonRow>
      {props.product.productVariations.items[0] !== undefined && <>
     
      <IonModal isOpen={showModal}><IonContent>
        <IonGrid fixed>
      <IonRow>
          <IonCol className="ion-text-right">
            <IonButton onClick={() => setShowModal(false)} fill="clear">
              <IonIcon icon={closeCircleOutline} />
            </IonButton>
          </IonCol></IonRow>
          <IonRow>
         <IonCol className="ion-text-center ion-margin">
          <b>
            <IonText color="tertiary" >
          {props.product.productVariations.items[indexValue].variationName} 
        </IonText></b></IonCol>
        </IonRow>
        
        <IonRow>
          
        {props.product.productVariations.items[indexValue].pictures.map((pic) =>
        <IonCol size={evaluateColSize()![0]} offset={evaluateColSize()![1]}><IonCard><IonImg src={pic}/></IonCard></IonCol>
        )}</IonRow>
         
      </IonGrid></IonContent>
      </IonModal></>}
     
      <IonRow className="ion-margin-start">
        <IonCol> 
          <IonText>{props.product.productVariations.items[0] !== undefined ? "Variaciones" : "Este producto aún no posee variaciones."}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>

        {props.product.productVariations.items[0] !== undefined  && props.product.productVariations.items.map((variation, index) => (
          <IonCol key={variation.id}>
            <IonCard>
              <IonGrid>
                <IonRow className="ion-text-center">
                  <IonCol>
                    <IonRow>
                      <IonCol>
                        <IonCardHeader>SKU: {variation.sku}</IonCardHeader>
                      </IonCol>
                      <IonCol>
                        <IonThumbnail onClick={()=> {setIndexValue(index); setShowModal(true); setActualProduct(props.product)}}>
                          <IonImg src={variation.pictures[0]}></IonImg>
                        </IonThumbnail>
                      </IonCol>
                    </IonRow>
                    {!checked && 
                    <IonRow >
                      <IonCol className="ion-text-center">
                       
                     <IonBadge color={colorByStatus(variation.status)}>{variation.status}</IonBadge>
                      </IonCol>
                    </IonRow>}
                    {variation.status !== "INACTIVO" &&
                   <InactiveCheckbox variation={variation} />}
                     {variation.status == "INACTIVO" &&
                   <InactiveCheckbox variation={variation} />}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          </IonCol>
        ))}
        
      </IonRow>
    </IonCard>

  );
};

export default MyProductsCard;
