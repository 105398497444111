import React from "react";
import { IonBadge, IonButton, IonIcon } from "@ionic/react";
import { cart } from "ionicons/icons";
import { useSelector } from "react-redux";
import { orderSelector } from "../../../store/OrderSlice";

const CartButton: React.FC = () => {
  const { theOrder } = useSelector(orderSelector);

  return (
    <IonButton fill="clear" color="warning" routerLink="/mi-carrito">
      <IonIcon slot="icon-only" icon={cart} size="large" />
       {(theOrder.products === null ? 0 : theOrder.products.length ) + (theOrder.cambaceoProducts === null ? 0 : theOrder.cambaceoProducts.length ) > 0  && (
        <IonBadge slot="end">{(theOrder.products === null ? 0 : theOrder.products.length ) + (theOrder.cambaceoProducts === null ? 0 : theOrder.cambaceoProducts.length)}</IonBadge>
      )}
    </IonButton>
  );
};

export default CartButton;
