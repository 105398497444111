import { IonContent, IonPage,IonHeader, IonTitle, IonGrid, IonRow, IonCol, IonList, IonItem, IonIcon, IonLabel, IonCard, IonText, IonButton } from "@ionic/react";
import React, { useState } from "react";
import Toolbar from "../global/general/Toolbar";
import { arrowBackOutline, bagHandleOutline, logoUsd, personOutline, storefrontOutline  } from "ionicons/icons";
import './Profile.css';
import { ROUTE_PROFILE_CLIENT, ROUTE_PROFILE_MONEDERO, ROUTE_PROFILE_ORDERS } from "../../utils/routes";
import { ORDER, ORDERS } from "../Orders/MyPurchaseOrders";
import { useDispatch, useSelector } from "react-redux";
import { OrderObject, orderSelector, setactualPastOrder } from "../../store/OrderSlice";
import { authSelector } from "../../store/AuthSlice";

const Orders: React.FC<{}> = () => {
    const [myOrder, setMyOrder] = useState(ORDERS);
    const { userProfile } = useSelector(authSelector);
    const { pastOrder } = useSelector(orderSelector);
    const dispatch = useDispatch();
    const o = useSelector(orderSelector);
    const orders = o.orders;
    const currencyFormat = { style: "currency", currency: "MXN" };
    const numberFormat = new Intl.NumberFormat("es-MX", currencyFormat);

    const myOrders: OrderObject[] = []; 
 
    const goBackHandler = () => {
        if (myOrder === ORDER) {
          setMyOrder(ORDERS)
        } }
  
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].userProfileEmail.includes(userProfile.email)) {
        myOrders.push(orders[i]);
      }
    };
    
 


    return (
        <IonPage>
        <IonHeader>
       
            <Toolbar />
        </IonHeader>
        <IonContent 
        // className="container"
        >
      
            <IonGrid fixed>
                <IonRow>
                    <IonCol className="ion-margin">
                     <IonTitle color="primary" className="sign">MIS COMPRAS</IonTitle>
                    </IonCol>
                </IonRow>
                <IonRow className="mainProfile">
                    <IonCol>
                        <IonList className="ionList no-padding">
                            <IonItem className="listItem" href={ROUTE_PROFILE_CLIENT}>
                                <IonIcon color="primary" icon={personOutline} slot="start"/>
                                <IonLabel color="primary">Perfil</IonLabel>
                            </IonItem>
                            <IonItem color="tertiary" className="listItem" href={ROUTE_PROFILE_ORDERS}>
                                <IonIcon   icon={bagHandleOutline} slot="start"></IonIcon>
                                <IonLabel >Mis Compras</IonLabel>
                            </IonItem>
                            <IonItem no-lines className="listItem" href={ROUTE_PROFILE_MONEDERO}>
									<IonIcon color="primary" icon={logoUsd} slot="start"></IonIcon>
									<IonLabel color="primary">Mi Monedero</IonLabel>
								</IonItem>
                            <IonItem className="listItem" href="/mi-tienda">
                                <IonIcon color="primary" icon={storefrontOutline} slot="start"></IonIcon>
                                <IonLabel color="primary">Mi Tienda</IonLabel>
                            </IonItem>
                        </IonList>
                    </IonCol>
                    <IonCol>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                <IonTitle color="primary">
											<strong>INFORMACIÓN DE MIS COMPRAS</strong>
										</IonTitle>
                                    <div className="lineaTertiary"></div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className="gridMosaic">
                                    <IonGrid className="area">
                                        <IonRow>
                                            <IonCol>
                                                <IonGrid className="gridOrders">  
                                                {myOrder === ORDER && <>
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
          </IonRow></>}

{myOrder === ORDERS &&
<>
                                                    <IonRow>
                                                        
                                                        <IonCol><IonText color="primary">#</IonText></IonCol>
                                                        <IonCol className="ion-hide-sm-down"><IonText color="primary">Fecha</IonText></IonCol>
                                                        <IonCol><IonText color="primary">Total</IonText></IonCol>
                                                        <IonCol className="ion-hide-sm-down"><IonText color="primary">Status</IonText></IonCol>
                                                        <IonCol className="ion-text-right"><IonText color="primary"></IonText></IonCol>
                                                    </IonRow>
                                                    <div className="lineaTertiary"></div>
                                                     {myOrders.length > 0 && Object.entries(myOrders).map(([key, order]) =>
                                                    <IonRow key={key}>
                                                        <IonCol><IonText color="primary">{(myOrders.indexOf(order))+1}</IonText></IonCol>
                                                        <IonCol className="ion-hide-sm-down"><IonText color="primary"> {new Date(order.createdAt).toLocaleDateString(
                                "es-MX"
                              )}</IonText></IonCol>
                                                        <IonCol><IonText color="tertiary"><b>{'$' + Number.parseFloat(order.total.toFixed(2))}</b></IonText></IonCol>
                                                        <IonCol className="ion-hide-sm-down"><IonText color="primary">{order.status}</IonText></IonCol>
                                                        <IonCol className="ion-text-right"><IonButton  onClick={() => {
                        dispatch(setactualPastOrder(order));
                        setMyOrder(ORDER);
                      }} color="tertiary" fill="outline">Ver Orden</IonButton></IonCol>
                                                    </IonRow>)}
    </>}
</IonGrid>
                                         
                               
             


                {myOrder === ORDER && (
                <> 
                
                
                <IonRow className="ion-margin">
                  <IonTitle className="ion-text-center" color="tertiary">
                    PRODUCTOS
                  </IonTitle>
                </IonRow>
                {pastOrder.products !== undefined && (
                  <>
                    {JSON.parse(pastOrder.products as any)
                          .map((productsList: any, index: number) => (
                            <>
                              <IonItem key={index}>
                                <IonGrid>
                                  <IonRow className="ion-hide-sm-down">
                                    <IonCol sizeMd="5" >
                                      <IonLabel color="tertiary">
                                        Producto
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      sizeMd="3"
                                      className="ion-text-center"
                                    >
                                      <IonLabel color="tertiary">
                                        Precio Unitario
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      sizeMd="3"
                                      className="ion-text-right"
                                    >
                                      <IonLabel color="tertiary">
                                        Total
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                  <IonRow>
                                
                                    <IonCol sizeMd="5" sizeXs="6">
                                      <IonLabel color="primary">
                                         {productsList.amount + "x " + productsList.product.name}
                                      </IonLabel>
                                      <br></br>
                                      <IonLabel color="medium">
                                        <i>
                                          <small>
                                            {
                                              productsList.product
                                                .productVariations.items[0]
                                                .sku
                                            }
                                          </small>
                                        </i>
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      className="ion-hide-sm-down ion-text-center"
                                      sizeMd="3"
                                    >
                                      <IonLabel color="primary">
                                        {"$" +
                                          productsList.product
                                            .productVariations.items[0]
                                            .cost}
                                      </IonLabel>
                                    </IonCol>
                                    <IonCol
                                      sizeMd="2.5"
                                      offsetMd="0.5"
                                      sizeXs="2.5"
                                      className="ion-text-right"
                                    >
                                      <IonLabel color="primary">
                                        {"$" +
                                          productsList.product
                                            .productVariations.items[0]
                                            .cost *
                                            productsList.amount}
                                      </IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonItem>
                            </>
                          ))}
                  </>
                )}
          

              {pastOrder.cambaceoProducts !== undefined && (
                  <>
                    {JSON.parse(pastOrder.cambaceoProducts as any).map(
                      (cambaceoProductsList: any, index: number) => (
                        <IonItem key={index}>
                          <IonGrid>
                            <IonRow className="ion-hide-sm-down">
                              <IonCol sizeMd="5">
                                <IonLabel color="tertiary">
                                  Producto
                                </IonLabel>
                              </IonCol>
                              <IonCol sizeMd="3" className="ion-text-center">
                                <IonLabel color="tertiary">
                                  Precio Unitario
                                </IonLabel>
                              </IonCol>
                              <IonCol sizeMd="3" className="ion-text-right">
                                <IonLabel color="tertiary">Total</IonLabel>
                              </IonCol>
                            </IonRow>
                            <IonRow>
                             
                              <IonCol sizeMd="5" sizeXs="8">
                                <IonLabel color="primary">
                                {cambaceoProductsList.amount + "x " + cambaceoProductsList.product.name}
                                </IonLabel>
                                <br></br>
                                <IonLabel color="medium">
                                  <i>
                                    <small>
                                      {cambaceoProductsList.product.sku}
                                    </small>
                                  </i>
                                </IonLabel>
                              </IonCol>
                             
                              <IonCol
                                className="ion-hide-sm-down ion-text-center"
                                sizeMd="3"
                              >
                                <IonLabel color="primary">
                                  {"$" + cambaceoProductsList.product.price}
                                </IonLabel>
                              </IonCol>
                              <IonCol
                                sizeMd="3"
                                sizeXs="4"
                                className="ion-text-right"
                              >
                                <IonLabel color="primary">
                                  {"$" +
                                    cambaceoProductsList.product.price *
                                      cambaceoProductsList.amount}
                                </IonLabel>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      )
                    )}
                  </>
                )}

            </>
          )}
                   </IonCol>
                                        </IonRow>
                                    </IonGrid></IonCol>
                            </IonRow>
                        </IonGrid>
              </IonCol>
                </IonRow>
            </IonGrid>  
            {myOrder === ORDER &&
            <IonGrid>
              <IonRow className="ion-margin">
                <IonCol offsetMd="3"
                      offsetLg="5"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXs="12">
                  <IonCard>
                    <IonItem className="ion-padding-horizontal">
                      <IonCol>
                        <IonLabel color="primary">
                          <strong>Costo de Envío </strong>
                        </IonLabel>
                      </IonCol>
                      <IonText color="primary">
                        {numberFormat.format(pastOrder.shipping)}
                      </IonText>
                    </IonItem>
                    <IonItem className="ion-padding-horizontal">
                      <IonCol>
                        <IonLabel color="primary">
                          <strong>Subtotal </strong>
                        </IonLabel>
                      </IonCol>
                      <IonText color="primary">
                        {numberFormat.format(pastOrder.subTotal)}
                      </IonText>
                    </IonItem>
                    <IonItem className="ion-margin-bottom ion-padding-horizontal">
                      <IonCol>
                        <IonLabel color="primary">
                          <strong>IVA </strong>
                        </IonLabel>
                      </IonCol>
                      <IonText color="primary">
                        {numberFormat.format(pastOrder.subTotal * 0.16)}
                      </IonText>
                    </IonItem>
                    <IonItem className="ion-margin-bottom ion-padding-horizontal">
                      <IonCol>
                        <IonLabel color="tertiary">
                          <strong>TOTAL</strong>
                        </IonLabel>
                      </IonCol>
                      <IonText color="tertiary">
                        <strong>
                          {numberFormat.format(pastOrder.total)}
                        </strong>
                      </IonText>
                    </IonItem>
                  </IonCard>
                </IonCol>
              </IonRow></IonGrid>   }                               
        </IonContent>
        </IonPage>
    );
};

export default Orders;