import {
  useIonViewWillEnter,
} from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../store/CategorySlice";
import CategoryList from "../../components/category/CategoryList";

const CategoryListPage: React.FC = () => {
  const dispatch = useDispatch();
  useIonViewWillEnter(() => {
   
    dispatch(getAllCategory());
    
  });

  return <CategoryList />;
};

export default CategoryListPage;
