import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow } from "@ionic/react";
import { arrowBackOutline, cube } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FieldTypes } from "../../amonyt/components/AmonytField";
import AmonytForm from "../../amonyt/components/AmonytForm";
import { editCatalog, postCatalog, removeCatalog } from "../../store/CatalogSlice";
import { flagSelector } from "../../store/FlagSlice";
import { shopSelector } from "../../store/ShopSlice";
import { ROUTE_SHOP_CATALOGS, ROUTE_SHOP_CATALOGS_PAGES } from "../../utils/routes";
import Toolbar from "../global/general/Toolbar";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import { BRAND_OPTIONS } from "./MyProductsConstants";
import { CatalogTypeContext } from "../../context/CatalogTypeContext";
import { BUCKET_URL, UNAVAILABLE_IMAGE } from "../../utils/constants";

const MyCatalogsForm: React.FC = () => {
	const history = useHistory();
	const { theShop } = useSelector(shopSelector);
	const [toastMessage, setToastMessage] = useState("");
	const { loading } = useSelector(flagSelector);
	const catType = useContext(CatalogTypeContext);

	let brandOptions = [{ label: theShop.shopID, value: theShop.shopID }];
	if (theShop.shopID === "novocorpo") {
		brandOptions = BRAND_OPTIONS;
	}
	const fieldArray = [
		{
			name: "name",
			label: "Nombre " + (catType.getCatalogType === "catalogo" ? "del catálogo" : "de la colección"),
			type: "text" as FieldTypes,
			value: useState(""),
			placeholder: "Ej: Productos Primavera",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
		},
		{
			name: "cover",
			label: "Portada",
			type: "images" as FieldTypes,
			value: useState(""),
			imageArray: useState([""]),
			placeholder: "",
			error: useState(false),
			errorMessage: useState(""),
			isRequired: true,
			validationType: "text",
			showPreview: true,
			max: 1,
			unavailableImageURL: UNAVAILABLE_IMAGE,
			bucketURL: BUCKET_URL,
			requiredWidth: 450,
			requiredHeight: 790
		},
		// {
		// 	name: "url",
		// 	label: "URL del catálogo",
		// 	type: "text" as FieldTypes,
		// 	value: useState(""),
		// 	placeholder: "",
		// 	error: useState(false),
		// 	errorMessage: useState(""),
		// 	isRequired: false,
		// 	validationType: "text",
		// },
	];
	const actionObject = {
		add: {
			inputObject: { type: catType.getCatalogType },
			dispatchFunction: postCatalog,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
		edit: {
			inputObject: {},
			dispatchFunction: editCatalog,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
		delete: {
			inputObject: {},
			dispatchFunction: removeCatalog,
			pushRoute: ROUTE_SHOP_CATALOGS_PAGES,
		},
	};
	const goBackHandler = () => {
		history.replace(ROUTE_SHOP_CATALOGS);
	};
	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<MyShopToolbar iconName={cube} title={"Mis " + (catType.getCatalogType === "catalogo" ? "Catálogos" : "Colecciones")} />
			<UserFeedback loading={loading} toastMessage={[toastMessage, setToastMessage]} />
			<IonContent>
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButton fill="clear" onClick={goBackHandler}>
							<IonIcon slot="start" icon={arrowBackOutline} />Volver
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<AmonytForm
								title={"Crear " + (catType.getCatalogType === "catalogo" ? "Catálogo" : "Colección")}
								fieldArray={fieldArray}
								actionObject={actionObject}
								add={true}
								hiddenFields={[
									{
										name: "shopID",
										value: theShop.shopID},
										{name: "coverStatus", value: "PENDIENTE DE APROBACIÓN"}
								]}
							/>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default MyCatalogsForm;
