import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatalogPageThunk } from "../index";
import {
  setError,
  setLoading,
  setWasCreated,
  setWasDeleted,
  setWasUpdated,
} from "./FlagSlice";
import {
  createCatalogPages,
  deleteCatalogPages,
  updateCatalogPages,
} from "../graphql/mutations";
import { getCatalogPages, listCatalogPagess } from "../graphql/queries";
import { ProductObject } from "./ProductSlice";
import { CambaceoObject } from "./CambaceoSlice";

//Interfaces
export interface CatalogPageState {
  catalogPages: CatalogPageObject[];
  actualCatalogPage: CatalogPageObject;
}

export interface CatalogPageObject {
	id: string;
	catalogID: string;
	pageNumber: number;
	pageImage: string[];
	products?: [string];
	cambaceoProductsArray: CambaceoObject[];
	productsString?: string;
	productsArray?: ProductObject[];
}

//Initial State
const initialState: CatalogPageState = {
	catalogPages: [],
	actualCatalogPage: {
		id: "",
		catalogID: "",
		pageNumber: 0,
		pageImage: [],
		products: [""],
		cambaceoProductsArray: [],
		productsString: "",
		productsArray: [],
	},
};

//Reducers
const catalogpage = createSlice({
  name: "catalogpage",
  initialState,
  reducers: {
    setCatalogPages(state, { payload }: PayloadAction<CatalogPageObject[]>) {
      state.catalogPages = payload;
    },
    setActualCatalogPage(state, { payload }: PayloadAction<CatalogPageObject>) {
		const catalogPageArray = {
			id: payload.id,
		catalogID: payload.catalogID,
		pageNumber: payload.pageNumber,
		pageImage: [payload.pageImage],
		products: [payload.products],
		cambaceoProductsArray: [payload.cambaceoProductsArray],
		productsString: JSON.parse(payload.productsString!),
		productsArray: [payload.productsArray],
		}
		state.actualCatalogPage = payload
    },
    cleanCatalog(state) {
      state.catalogPages = [];
    },
  },
});

//Exports
export const { setCatalogPages, setActualCatalogPage, cleanCatalog } = catalogpage.actions;

export default catalogpage.reducer;

export const catalogPageSelector = (state: { catalogPageStore: CatalogPageState }) =>
  state.catalogPageStore;

//Middleware
export const postCatalogPage = (input: any): CatalogPageThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(createCatalogPages, { input }));
			dispatch(setActualCatalogPage(res.data.createCatalogPages));
			dispatch(setLoading(false));
			dispatch(setWasCreated(true));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const getAllCatalogPages =
	(input: any): CatalogPageThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(listCatalogPagess, input));
			dispatch(setCatalogPages(res.data.listCatalogPagess.items));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
export const getCatalogPageInfo =
	(input: any): CatalogPageThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(getCatalogPages, input));
			if (typeof res.data.getCatalog !== "undefined") {
				dispatch(setActualCatalogPage(res.data.getCatalog));
			}
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const readCatalogPage =
	(input: any): CatalogPageThunk =>
	async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res: any = await API.graphql(graphqlOperation(getCatalogPages, { input }));
			dispatch(setCatalogPages(res.data.listCatalogPages.items));
			dispatch(setLoading(false));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};

export const editCatalogPage = (input: any): CatalogPageThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			const res:any = await API.graphql(graphqlOperation(updateCatalogPages,  input ));
			dispatch(setLoading(false));
			dispatch(setWasUpdated(true));
		} catch (error: any) {
			dispatch(setLoading(false));
			dispatch(setError(error));
		}
	};
};

export const removeCatalogPage = (input: any): CatalogPageThunk => {
	return async (dispatch: any) => {
		try {
			dispatch(setLoading(true));
			await API.graphql(graphqlOperation(deleteCatalogPages, { input }));
			dispatch(setLoading(false));
			dispatch(setWasDeleted(true));
		} catch (error) {
			dispatch(setLoading(false));
		}
	};
};
