import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonItem, IonReorder, IonReorderGroup, IonRow, IonText, IonThumbnail } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setLoading } from "../../store/FlagSlice";
import { Storage } from "aws-amplify";
import AmonytFilePicker from "./AmonytFilePicker";
import { closeCircleOutline, pizza } from "ionicons/icons";
import { ItemReorderEventDetail } from "@ionic/core";


export const immutableMove = (arr:any[], from:number, to:number) => {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, []);
}

interface imageField {
	imageArray: string[];
	value: [string, Dispatch<SetStateAction<string>>];
	unavailableImageURL?: string;
	max?: number;
	showPreview?: boolean;
	bucketURL?: string;
	requiredWidth: number;
	requiredHeight: number;
}
const AmonytPhotoField: React.FC<imageField> = (props) => {
	const dispatch = useDispatch();

	//----------------- fotos -----------------------
	const { loading } = useSelector(flagSelector);
	const [pickerType, setPickerType] = useState<"photo" | "file">("file");
	const [takenPhoto, setTakenPhoto] = useState<{
		preview: string;
		fileType: string;
	}>();
	const [downloadedPhoto, setDownloadedPhoto] = useState<string>("");
	const [getIsLoading, setIsLoading] = useState(false);
	const [width, setWidth] = useState<number>()
	const [height, setHeight] = useState<number>()


	const [imagesArray, setImagesArray] = useState<string[]>(props.value[0].startsWith('[') ? JSON.parse(props.value[0]||'""') : "");

	const photoPickHandler = (photo: { preview: string; fileType: string }) => {
		setTakenPhoto(photo);
	};
	const addImageHandler = async () => {
		
		//TODO: Diferenciar Archivo de foto
		const extension = "." + takenPhoto?.fileType;
		const fileName = new Date().getTime() + extension;
		try {
			const response = await fetch(takenPhoto!.preview);
			const blob = await response.blob();
			let cType: string = "";
			switch (takenPhoto?.fileType) {
				case "pdf":
					cType = "application/pdf";
					break;
				case "jpg":
				default:
					cType = "image/jpeg";
					break;
			}
			dispatch(setLoading(true));
			setIsLoading(true);
			Storage.put(fileName, blob, {
				level: "public",
				acl: "public-read",
				contentType: cType,
			}).then((result) => {
				getImageHandler(fileName, cType);
			});
			dispatch(setLoading(false));
			setIsLoading(false);

		} catch (err) {
			setIsLoading(false);
			dispatch(setLoading(false));
		}
	};
	const getImageHandler = async (fileName: string, fileType: string) => {
		dispatch(setLoading(true));
		setIsLoading(true);
		Storage.get(fileName, { download: false, contentType: fileType })
			.then((downloadedFile) => {
				setDownloadedPhoto(downloadedFile.toString());
				if (imagesArray.length === 0) {
					setImagesArray([props.bucketURL + fileName]);
					props.value[1](JSON.stringify([props.bucketURL + fileName]));
				} else {
					setImagesArray([...imagesArray, props.bucketURL + fileName]);
					props.value[1](JSON.stringify([...imagesArray, props.bucketURL + fileName]));
				}
				props.imageArray = imagesArray;
				dispatch(setLoading(false));
				setIsLoading(false);
			})
			
			.catch((err) => {
				dispatch(setLoading(false));
				setIsLoading(false);
			});
	};
	const removeImageHandler = (index: number) => {
		let localImagesArray: string[] = JSON.parse(JSON.stringify(imagesArray));
		localImagesArray.splice(index, 1);
		setImagesArray(localImagesArray);
		props.value[1](JSON.stringify(localImagesArray));
	};
	function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
		event.detail.complete();
		let localImagesArray: string[] = JSON.parse(JSON.stringify(imagesArray));
		localImagesArray = immutableMove(localImagesArray,event.detail.from,event.detail.to);
		setImagesArray(localImagesArray);
		props.value[1](JSON.stringify(localImagesArray));
		
	}
	//----------- fotos ------------------


	return (
		<IonGrid>
			{props.showPreview && (
				<IonRow>
					<IonCol>
						<IonGrid>
							<IonRow>
								{imagesArray.length === 0 && (
									<IonCol className="ion-text-center">
										<IonThumbnail>
											<IonImg src={props.unavailableImageURL}></IonImg>
										</IonThumbnail>
									</IonCol>
								)}
						
								<IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
									{imagesArray.length > 0 &&
										imagesArray.map((image, index) => (
											<IonItem key={image}>
												<IonThumbnail>
													<IonImg src={image}></IonImg>
												</IonThumbnail>
												<IonButton
													slot="end"
													onClick={() => {
														removeImageHandler(index);
													}}
													color="danger"
												>
													<IonIcon icon={closeCircleOutline} slot="icon-only" />
												</IonButton>

												<IonReorder slot="start">
												</IonReorder>
											</IonItem>
										))}
								</IonReorderGroup>
							</IonRow>
						</IonGrid>
					</IonCol>
				</IonRow>
			)}
			<IonRow>
				<IonCol>
					{imagesArray.length < props.max! ? (<>
						{/* {props.requiredWidth && props.requiredHeight &&
          <IonText color="tertiary"><small>{`*Asegúrese de que sus imágenes tengan una medida de ${props.requiredWidth}x${props.requiredHeight} píxeles aproximadamente.`}</small></IonText>} */}
						<AmonytFilePicker
							type="photo"
							onFilePick={photoPickHandler}
							noPhotoText="No se ha capturado una imagen"
							selectFileButtonText="Selecciona una imagen"
							actionButtonText="Cargar archivo"
							onFileAction={addImageHandler}
							isLoading={getIsLoading}
							requiredDimensions={[props.requiredWidth, props.requiredHeight]}
							
						/>
					</>) : (
						<IonText>Has alcanzado el máximo de imágenes permitidas.</IonText>
					)}
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
AmonytPhotoField.defaultProps = {
	imageArray: [""],
	unavailableImageURL: "",
	max: 1,
	showPreview: true,
	bucketURL: "",
};

export default AmonytPhotoField;
