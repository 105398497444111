import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonSplitPane,
} from "@ionic/react";
import "./MyShop.css";
import ProductsLanding from "./Products/ProductsLanding";

import MyShopIntro from "../components/shop/MyShopIntro";
import MyShopPreview from "./MyShopPreview";
import MyShopPreview2 from "./MyShopPreview2";
import MyShopPreview3 from "./MyShopPreview3";
import {
	ShopObject,
	shopSelector,
} from "../store/ShopSlice";
import { useSelector } from "react-redux";
import { flagSelector } from "../store/FlagSlice";
import { RouteComponentProps } from "react-router-dom";

export const SHOP_FORM = "shopForm";
export const SHOP_LANDING = "shopLanding";
export const SHOP_TERMS = "shopTerms";
export const SHOP_PLAN = "shopPlan";
export const SHOP_LANDING_M = "shopMedium";
export const SHOP_LANDING_L = "shopLarge";
export const SHOP_PLAN_ONE = "planOne";
export const SHOP_PLAN_TWO = "planTwo";
export const SHOP_PLAN_THREE = "planThree";
export const SHOP_PLAN_NOVO = "planNovocorpo";

interface myShopProps
	extends RouteComponentProps<{
		shopselection: string;
		productselection: string;
	}> {}

const MyShop: React.FC<myShopProps> = ({ match }) => {
	const { theShop } = useSelector(shopSelector);
	const { wasCreated, loading } = useSelector(flagSelector);
	useEffect(() => {

	}, [wasCreated]);
	let shopArray = theShop;
	const [products, setProducts] = useState(0);
	const [myShop, setMyShop] = useState(SHOP_LANDING);
	const [productId, setProductID] = useState("");
	const myShopData: ShopObject = {
		name: "",
		shopID: "",
		logo: "",
		description: "",
		plan: "",
		status: "",
		userProfileEmail: "",
	};
	const [shopData, setShopData] = useState<ShopObject>({...myShopData, ...theShop});

	const renderInfo = (
		shopSelection: string,
		productSelection: number,
		shopDataSelection: ShopObject
	) => {
		switch (productSelection) {
			case 0:
				switch (shopSelection) {
					case SHOP_LANDING_M:
						if (shopArray.name !== "") {
							return (
								<MyShopPreview2
									myShop={setMyShop}
									shopData={shopDataSelection}
									setShopData={setShopData}
								/>
							); // Landing page
						} else {
							return <MyShopIntro myShop={setMyShop} />; // Términos y condiciones
						}
					case SHOP_LANDING_L:
						if (shopArray.name !== "") {
							return (
								<MyShopPreview3
									myShop={setMyShop}
									shopData={shopDataSelection}
									setShopData={setShopData}
								/>
							); // Landing page
						} else {
							return <MyShopIntro myShop={setMyShop} />; // Términos y condiciones
						}
					// case SHOP_PLAN:
					// 	return (
					// 		// <MyShopPlans
					// 		// 	myShop={setMyShop}
					// 		// 	shopData={shopDataSelection}
					// 		// 	setShopData={setShopData}
					// 		// />}
							
					// 	);
					case SHOP_TERMS:
						return <MyShopIntro myShop={setMyShop} />; // Términos y condiciones
					case SHOP_LANDING:
					default:
						if (shopArray.name !== "") {
							return (
								<MyShopPreview
									myShop={setMyShop}
									shopData={shopDataSelection}
									setShopData={setShopData}
								/>
							); // Landing page
						} else {
							return <MyShopIntro myShop={setMyShop} />; // Términos y condiciones
						}
				}
				break;
			case 1:
			case 2:
			case 3:
			case 4:
				break;
		}
	};
	return (
		<IonPage>
			<IonHeader>
				{/* <Toolbar /> */}
			</IonHeader>
			<IonContent>
				<IonSplitPane contentId="myshop">

					<IonPage id="myshop">
						{!loading ? (
							<>
								{renderInfo(match.params.shopselection, products, shopData)}
								<hr></hr>
								{products === 1 && myShop === SHOP_LANDING && (
									<ProductsLanding products={setProducts} />
								)}
								{products === 2 && myShop === SHOP_LANDING && (
									{/* <MyProducts
										products={setProducts}
										setProductId={setProductID}
									/> */}
								)}
								{products === 3 && myShop === SHOP_LANDING && (
									{/* <ProductForm /> */}
								)}
								{products === 4 && myShop === SHOP_LANDING }{" "}
							</>
						) : (
							<></>
						)}
					</IonPage>
				</IonSplitPane>
			</IonContent>
		</IonPage>
	);
};

export default MyShop;
