import {
	IonContent,
	IonPage,
	IonHeader,
	IonTitle,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonIcon,
	IonLabel,
	IonInput,
	IonButton,
	useIonViewDidEnter,
	useIonViewDidLeave,
	useIonViewWillEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../store/AuthSlice";
import Toolbar from "../global/general/Toolbar";
import { bagHandleOutline, logoUsd, person, storefrontOutline } from "ionicons/icons";
import "./Profile.css";
import { editUser, postUser, readUser, setActualUser, userSelector } from "../../store/UserSlice";
import { API, graphqlOperation } from "aws-amplify";
import { Observable } from "zen-observable-ts";
import { onCreateUser, onDeleteUser, onUpdateUser } from "../../graphql/subscriptions";
import { ROUTE_PROFILE_CLIENT, ROUTE_PROFILE_MONEDERO, ROUTE_PROFILE_ORDERS } from "../../utils/routes";
import { AuthContext } from "../../context/AuthContext";
import { getUser } from "../../graphql/queries";

const Profile: React.FC<{}> = () => {
	const isAuthCntxt = useContext(AuthContext)

	const dispatch = useDispatch();
	const { userProfile } = useSelector(authSelector);
	const { actualUser } = useSelector(userSelector);
	/* Personal Info */
	const [getPhoneNumber, setPhoneNumber] = useState("");
	/* Address */
	const [getAddressStreet, setAddressStreet] = useState("");
	const [getAddressExterior, setAddressExterior] = useState("");
	const [getAddressInterior, setAddressInterior] = useState("");
	const [getAddressNeighborhood, setAddressNeighborhood] = useState("");
	const [getAddressZipcode, setAddressZipcode] = useState("");
	const [getAddressState, setAddressState] = useState("");
	const [getAddressCity, setAddressCity] = useState("");
	const [getAddressNotes, setAddressNotes] = useState("");
	/* Invoice */
	const [getInvoiceName, setInvoiceName] = useState("");
	const [getInvoiceRfc, setInvoiceRfc] = useState("");
	const [getInvoiceStreet, setInvoiceStreet] = useState("");
	const [getInvoiceExterior, setInvoiceExterior] = useState("");
	const [getInvoiceInterior, setInvoiceInterior] = useState("");
	const [getInvoiceNeighborhood, setInvoiceNeighborhood] = useState("");
	const [getInvoiceZipcode, setInvoiceZipcode] = useState("");
	const [getInvoiceState, setInvoiceState] = useState("");
	const [getInvoiceCity, setInvoiceCity] = useState("");



	const savePersonalInfo = (e: any) => {
		if (actualUser.userID) {
			const inputObject = {
				userID: actualUser.userID,
				userProfileEmail: userProfile.email,
				phoneNumber: getPhoneNumber,
			};
			dispatch(editUser(inputObject));
			
		} else {
			const inputObject = {
				userID: userProfile.email,
				userProfileEmail: userProfile.email,
				phoneNumber: getPhoneNumber,
			};
			dispatch(postUser(inputObject));
		}
	};
	const saveAddress = (e: any) => {
		if (actualUser.userID) {
			const inputObject = {
				userID: actualUser.userID,
				userProfileEmail: userProfile.email,
				addressStreet: getAddressStreet,
				addressExterior: getAddressExterior,
				addressInterior: getAddressInterior,
				addressNeighborhood: getAddressNeighborhood,
				addressZipcode: getAddressZipcode,
				addressState: getAddressState,
				addressCity: getAddressCity,
				addressNotes: getAddressNotes,
			};
			dispatch(editUser(inputObject));
		} else {
			const inputObject = {
				userID: userProfile.email,
				userProfileEmail: userProfile.email,
				addressStreet: getAddressStreet,
				addressExterior: getAddressExterior,
				addressInterior: getAddressInterior,
				addressNeighborhood: getAddressNeighborhood,
				addressZipcode: getAddressZipcode,
				addressState: getAddressState,
				addressCity: getAddressCity,
				addressNotes: getAddressNotes,
			};
			dispatch(postUser(inputObject));
		}
	};
	const saveInvoice = (e: any) => {
		if (actualUser.userID) {
			const inputObject = {
				userID: actualUser.userID,
				userProfileEmail: userProfile.email,
				invoiceName: getInvoiceName,
				invoiceRfc: getInvoiceRfc,
				invoiceStreet: getInvoiceStreet,
				invoiceExterior: getInvoiceExterior,
				invoiceInterior: getInvoiceInterior,
				invoiceNeighborhood: getInvoiceNeighborhood,
				invoiceZipcode: getInvoiceZipcode,
				invoiceState: getInvoiceState,
			};
			dispatch(editUser(inputObject));
		} else {
			const inputObject = {
				userID: userProfile.email,
				userProfileEmail: userProfile.email,
				invoiceName: getInvoiceName,
				invoiceRfc: getInvoiceRfc,
				invoiceStreet: getInvoiceStreet,
				invoiceExterior: getInvoiceExterior,
				invoiceInterior: getInvoiceInterior,
				invoiceNeighborhood: getInvoiceNeighborhood,
				invoiceZipcode: getInvoiceZipcode,
				invoiceState: getInvoiceState,
			};
			dispatch(postUser(inputObject));
		}
	};
	useEffect(() => {
		dispatch(readUser(userProfile.email))
		if (actualUser) {
			/* Personal Info */
			setPhoneNumber(actualUser.phoneNumber||"");
			/* Address */
			setAddressStreet(actualUser.addressStreet || "");
			setAddressExterior(actualUser.addressExterior || "");
			setAddressInterior(actualUser.addressInterior || "");
			setAddressNeighborhood(actualUser.addressNeighborhood || "");
			setAddressZipcode(actualUser.addressZipcode || "");
			setAddressState(actualUser.addressState || "");
			setAddressCity(actualUser.addressCity || "");
			setAddressNotes(actualUser.addressNotes || "");
			/* Invoice */
			setInvoiceName(actualUser.invoiceName || "");
			setInvoiceRfc(actualUser.invoiceRfc || "");
			setInvoiceStreet(actualUser.invoiceStreet || "");
			setInvoiceExterior(actualUser.invoiceExterior || "");
			setInvoiceInterior(actualUser.invoiceInterior || "");
			setInvoiceNeighborhood(actualUser.invoiceNeighborhood || "");
			setInvoiceZipcode(actualUser.invoiceZipcode || "");
			setInvoiceState(actualUser.invoiceState || "");
			setInvoiceCity(actualUser.invoiceCity || "");
		}
	}, [actualUser]);

	let createUserListener: any;
	let updateUserListener: any;
	let deleteUserListener: any;
	useIonViewDidEnter(() => {
		const onCreateSubClient = API.graphql(graphqlOperation(onCreateUser)) as Observable<object>;

		createUserListener = onCreateSubClient.subscribe({
			next: () => {
				let userProfileEmail = {
					userProfileEmail: userProfile.email,
				};
				dispatch(readUser(userProfileEmail));
			},
			error: (error) => console.warn(error),
		});

		const onUpdateSubClient = API.graphql(graphqlOperation(onUpdateUser)) as Observable<object>;

		updateUserListener = onUpdateSubClient.subscribe({
			next: () => {
				let userProfileEmail = {
					userProfileEmail: userProfile.email,
				};
				dispatch(readUser(userProfileEmail));
			},
		});

		const onDeleteSubClient = API.graphql(graphqlOperation(onDeleteUser)) as Observable<object>;

		deleteUserListener = onDeleteSubClient.subscribe({
			next: () => {
				let userProfileEmail = {
					userProfileEmail: userProfile.email,
				};
				dispatch(readUser(userProfileEmail));
			},
		});
	});
	useIonViewDidLeave(() => {
		createUserListener.unsubscribe();
		updateUserListener.unsubscribe();
		deleteUserListener.unsubscribe();
	});

	return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent 
			// className="container"
			>
				<IonGrid fixed>
					<IonRow>
						<IonCol className="ion-margin">
							<IonTitle color="primary" className="sign">
								MI PERFIL
							</IonTitle>
						</IonCol>
					</IonRow>
					<IonRow className="mainProfile">
						<IonCol>
							<IonList className="ionList no-padding">
								<IonItem color="tertiary"className="listItem" href={ROUTE_PROFILE_CLIENT}>
									<IonIcon icon={person} slot="start"/>
									<IonLabel>Perfil</IonLabel>
								</IonItem>
								<IonItem className="listItem" href={ROUTE_PROFILE_ORDERS}>
									<IonIcon  color="primary" icon={bagHandleOutline} slot="start"></IonIcon>
									<IonLabel color="primary">Mis Compras</IonLabel>
								</IonItem>
								<IonItem no-lines className="listItem" href={ROUTE_PROFILE_MONEDERO}>
										<IonIcon color="primary" icon={logoUsd} slot="start"></IonIcon>
										<IonLabel color="primary">Mi Monedero</IonLabel>
									</IonItem>
								<IonItem className="listItem" href="/mi-tienda">
									<IonIcon color="primary" icon={storefrontOutline} slot="start"></IonIcon>
									<IonLabel color="primary">Mi Tienda</IonLabel>
								</IonItem>
							</IonList>
						</IonCol>
						<IonCol>
							<IonGrid class="area ion-padding">
								<IonRow>
									<IonCol>
										<IonTitle color="primary">
											<strong>INFORMACIÓN PERSONAL</strong>
										</IonTitle>
										<div className="lineaTertiary"></div>
										<IonItem>
											<IonLabel position="floating">Nombre(s):</IonLabel>
											<IonInput value={`${userProfile.name}`} disabled />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Apellido(s):</IonLabel>
											<IonInput value={`${userProfile.lastname}`} disabled />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Correo Electrónico:</IonLabel>
											<IonInput value={`${userProfile.email}`} disabled />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Teléfono:</IonLabel>
											<IonInput value={getPhoneNumber} onIonChange={(e) => setPhoneNumber(e.detail.value || "")} />
										</IonItem>
										<IonItem lines="none">
											<IonButton color="tertiary" slot="end" fill="solid" expand="full" onClick={savePersonalInfo}>
												Guardar
											</IonButton>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>

							<IonGrid class="area">
								<IonRow>
									<IonCol>
										<IonTitle color="primary">
											<strong>DIRECCIÓN DE ENTREGA</strong>
										</IonTitle>
										<div className="lineaTertiary"></div>
										<IonItem>
											<IonLabel position="floating">Calle:</IonLabel>
											<IonInput placeholder="Reforma 222" value={getAddressStreet} onIonChange={(e) => setAddressStreet(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Número exterior:</IonLabel>
											<IonInput placeholder="222" value={getAddressExterior} onIonChange={(e) => setAddressExterior(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Número interior:</IonLabel>
											<IonInput placeholder="B" value={getAddressInterior} onIonChange={(e) => setAddressInterior(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Colonia:</IonLabel>
											<IonInput
												placeholder="Juárez"
												value={getAddressNeighborhood}
												onIonChange={(e) => setAddressNeighborhood(e.detail.value || "")}
											/>
										</IonItem>
										<IonItem>
											<IonLabel position="floating">CP:</IonLabel>
											<IonInput placeholder="06600" value={getAddressZipcode} onIonChange={(e) => setAddressZipcode(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Alcaldía / Municipio:</IonLabel>
											<IonInput placeholder="Cuauhtémoc" value={getAddressCity} onIonChange={(e) => setAddressCity(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Estado:</IonLabel>
											<IonInput placeholder="CDMX" value={getAddressState} onIonChange={(e) => setAddressState(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Instrucciones adicionales para encontrar tu dirección:</IonLabel>
											<IonInput
												placeholder="Incluye entre qué calles se encuetra el lugar y referencias"
												value={getAddressNotes}
												onIonChange={(e) => setAddressNotes(e.detail.value || "")}
											/>
										</IonItem>
										<IonItem lines="none">
											<IonButton color="tertiary" slot="end" fill="solid" expand="full" onClick={saveAddress}>
												Guardar
											</IonButton>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>

							<IonGrid class="area ion-padding ion-margin-top">
								<IonRow>
									<IonCol>
										<IonTitle color="primary">
											<strong>DATOS DE FACTURACIÓN</strong>
										</IonTitle>
										<div className="lineaTertiary"></div>
										<IonItem>
											<IonLabel position="floating">Nombre de Razón Social:</IonLabel>
											<IonInput placeholder="Hugo Deloya" value={getInvoiceName} onIonChange={(e) => setInvoiceName(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">RFC:</IonLabel>
											<IonInput placeholder="VECJ880326XXX" value={getInvoiceRfc} onIonChange={(e) => setInvoiceRfc(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Email:</IonLabel>
											<IonInput value={userProfile.email} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Calle:</IonLabel>
											<IonInput placeholder="Reforma 222" value={getInvoiceStreet} onIonChange={(e) => setInvoiceStreet(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Número exterior:</IonLabel>
											<IonInput placeholder="222" value={getInvoiceExterior} onIonChange={(e) => setInvoiceExterior(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Número interior:</IonLabel>
											<IonInput placeholder="B" value={getInvoiceInterior} onIonChange={(e) => setInvoiceInterior(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Colonia:</IonLabel>
											<IonInput
												placeholder="Juárez"
												value={getInvoiceNeighborhood}
												onIonChange={(e) => setInvoiceNeighborhood(e.detail.value || "")}
											/>
										</IonItem>
										<IonItem>
											<IonLabel position="floating">CP:</IonLabel>
											<IonInput placeholder="06600" value={getInvoiceZipcode} onIonChange={(e) => setInvoiceZipcode(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Alcaldía / Municipio:</IonLabel>
											<IonInput placeholder="Cuauhtémoc" value={getInvoiceCity} onIonChange={(e) => setInvoiceCity(e.detail.value || "")} />
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Estado:</IonLabel>
											<IonInput placeholder="CDMX" value={getInvoiceState} onIonChange={(e) => setInvoiceState(e.detail.value || "")} />
										</IonItem>
										<IonItem lines="none">
											<IonButton color="tertiary" slot="end" fill="solid" expand="full" onClick={saveInvoice}>
												Guardar
											</IonButton>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default Profile;
