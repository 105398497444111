import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel,  IonRow, IonText } from "@ionic/react";
import { pricetagOutline} from "ionicons/icons";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getAllProductVariation, ProductVariationObject, readProductVariation } from "../../store/ProductVariationSlice";
import { PRODUCTS_EDIT_VARIATIONS } from "./MyProductsContent";
import ProductVariationState from "./ProductVariationStatus";

const ProductVariationsCard: React.FC<{
  productVariation: ProductVariationObject;
  setProducts: Dispatch<SetStateAction<string>>;
}> = (props) => {
  const [viewMore, setViewMore] = useState(false);
  const currencyFormat = { style: 'currency', currency: 'MXN' };
  const numberFormat = new Intl.NumberFormat('es-MX', currencyFormat);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const location = useLocation()
  const dispatch = useDispatch()
  const [prodVar, setProdVar] = useState<"PENDIENTE DE APROBACIÓN" | "ACTIVO" | "INACTIVO" | "NO APROBADO" | undefined
  >()
  
  useEffect(() => { 
    
    dispatch(getAllProductVariation)
    dispatch(readProductVariation(props.productVariation.id))

}, [props.productVariation, props.setProducts, props.productVariation.status])


  const viewMoreHandler = () => {
    if (viewMore) {
      setViewMore(false);
    } else {
      setViewMore(true);
    }
  };
  const availabilityTranslate = () => {
    if (JSON.parse(props.productVariation.availability) === "in stock") {
      return "Disponible";
    } else if (
      JSON.parse(props.productVariation.availability) === "out of stock"
    ) {
      return "Agotado";
    } else {
      return "Descontinuado";
    }
  };
  const conditionTranslate = () => {
    if (props.productVariation.condition === '"new"') {
      return "Nuevo";
    } else if (JSON.stringify(props.productVariation.condition) === '"used"') {
      return "Segunda Mano";
    } else {
      return "Reacondicionado";
    }
  };
  return (<IonCard className="ion-padding">
      <IonItem className="show-click" >
          <IonCol sizeMd="11" sizeXs="9">
        <IonIcon icon={pricetagOutline} slot="start" />
        <IonLabel onClick={() => {viewMoreHandler(); setShowModalEdit(false)}} color="tertiary">SKU: <strong><i>{props.productVariation.sku}</i></strong></IonLabel></IonCol>
        <IonCol>
          {location.pathname === "/mis-productos" &&
          <IonButton fill="clear" 
          onClick={() => props.setProducts(PRODUCTS_EDIT_VARIATIONS)}
          ><u>Editar</u></IonButton>}
        </IonCol>
      
      </IonItem>
    
      {viewMore && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel color="tertiary" position="floating">Nombre</IonLabel>
                <IonInput readonly={true} color="primary" value={props.productVariation.variationName}/>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeSm="12" sizeXs="12" sizeMd="6">
              <IonItem>
                <IonLabel color="tertiary" position="floating">Disponibilidad</IonLabel>
                <IonInput readonly={true} color="primary" value={availabilityTranslate()}/>
              </IonItem>

              <IonItem>
                <IonLabel color="tertiary" position="floating">Condición</IonLabel>
                <IonInput readonly={true} color="primary" value={conditionTranslate()}/>
              </IonItem>
            {location.pathname === "/mis-productos" ? <IonItem>
              <IonLabel position="floating" color="tertiary">Estatus:</IonLabel>
              <IonInput readonly={true} color="primary" value={props.productVariation.status === "" ? props.productVariation.status : props.productVariation.status}/>
            </IonItem> : <ProductVariationState setProductVarStatus={setProdVar} status={prodVar} prodVar={props.productVariation} />}
         
            </IonCol>
            <IonCol sizeSm="12" sizeXs="12" sizeMd="6">
            {props.productVariation.price ? <>
              <IonItem>
                <IonLabel color="tertiary" position="floating">Cantidad</IonLabel>
                <IonInput readonly={true} color="primary" value={props.productVariation.amount}/>
              </IonItem></> : "" }

              <IonItem>
                <IonLabel color="tertiary" position="floating">Precio</IonLabel>
                <IonInput readonly={true} color="primary" value={numberFormat.format(props.productVariation.cost)}/>
              </IonItem>
{props.productVariation.price ? <>
              <IonItem>
                <IonLabel color="tertiary" position="floating">Precio Promoción</IonLabel>
                <IonInput readonly={true} color="primary" value={numberFormat.format(props.productVariation.price)}/>
              </IonItem></> : ""}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonText color="tertiary"><p>Imágenes</p></IonText>
              <div className="variationsImagesContainer">
              {props.productVariation.pictures.map(
                  (pic: string, index: number) => {
                    return (
                      <a key={index} href={pic} target="_blank">
                        <img className="variationImg" src={pic} />
                      </a>
                    );
                  }
                )}
                </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonCard> 
  );
};

export default ProductVariationsCard;
