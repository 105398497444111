/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendEmailToAdmin = /* GraphQL */ `
  mutation SendEmailToAdmin(
    $toemail: String
    $subject: String
    $message: String
  ) {
    sendEmailToAdmin(toemail: $toemail, subject: $subject, message: $message)
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      shopID
      name
      logo
      description
      plan
      status
      slider
      sliderStatus
      userProfileEmail
      productsVarCounter
      createdAt
      updatedAt
    }
  }
`;
export const createNovocorpo = /* GraphQL */ `
  mutation CreateNovocorpo(
    $input: CreateNovocorpoInput!
    $condition: ModelNovocorpoConditionInput
  ) {
    createNovocorpo(input: $input, condition: $condition) {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const updateNovocorpo = /* GraphQL */ `
  mutation UpdateNovocorpo(
    $input: UpdateNovocorpoInput!
    $condition: ModelNovocorpoConditionInput
  ) {
    updateNovocorpo(input: $input, condition: $condition) {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const deleteNovocorpo = /* GraphQL */ `
  mutation DeleteNovocorpo(
    $input: DeleteNovocorpoInput!
    $condition: ModelNovocorpoConditionInput
  ) {
    deleteNovocorpo(input: $input, condition: $condition) {
      novocorpoID
      logo
      description
      slider
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      specifications
      category
      brandName
      shopID
      productVariations {
        items {
          id
          sku
          variationName
          amount
          cost
          price
          availability
          condition
          pictures
          productID
          status
          statusReason
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      featured
      createdAt
      updatedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      name
      description
      address
      location
      typeOfOperation
      gpsPosition
      floors
      bedrooms
      bathrooms
      publicFeatures
      privateFeatures
      landSize
      propertySize
      price
      videoUrl
      pdf
      comments
      pictures
      shopID
      status
      createdAt
      updatedAt
    }
  }
`;
export const createPropertyFeature = /* GraphQL */ `
  mutation CreatePropertyFeature(
    $input: CreatePropertyFeatureInput!
    $condition: ModelPropertyFeatureConditionInput
  ) {
    createPropertyFeature(input: $input, condition: $condition) {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const updatePropertyFeature = /* GraphQL */ `
  mutation UpdatePropertyFeature(
    $input: UpdatePropertyFeatureInput!
    $condition: ModelPropertyFeatureConditionInput
  ) {
    updatePropertyFeature(input: $input, condition: $condition) {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const deletePropertyFeature = /* GraphQL */ `
  mutation DeletePropertyFeature(
    $input: DeletePropertyFeatureInput!
    $condition: ModelPropertyFeatureConditionInput
  ) {
    deletePropertyFeature(input: $input, condition: $condition) {
      id
      name
      condition
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCambaceo = /* GraphQL */ `
  mutation CreateCambaceo(
    $input: CreateCambaceoInput!
    $condition: ModelCambaceoConditionInput
  ) {
    createCambaceo(input: $input, condition: $condition) {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateCambaceo = /* GraphQL */ `
  mutation UpdateCambaceo(
    $input: UpdateCambaceoInput!
    $condition: ModelCambaceoConditionInput
  ) {
    updateCambaceo(input: $input, condition: $condition) {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteCambaceo = /* GraphQL */ `
  mutation DeleteCambaceo(
    $input: DeleteCambaceoInput!
    $condition: ModelCambaceoConditionInput
  ) {
    deleteCambaceo(input: $input, condition: $condition) {
      id
      sku
      name
      price
      promoPrice
      promoType
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createProductVariation = /* GraphQL */ `
  mutation CreateProductVariation(
    $input: CreateProductVariationInput!
    $condition: ModelProductVariationConditionInput
  ) {
    createProductVariation(input: $input, condition: $condition) {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const updateProductVariation = /* GraphQL */ `
  mutation UpdateProductVariation(
    $input: UpdateProductVariationInput!
    $condition: ModelProductVariationConditionInput
  ) {
    updateProductVariation(input: $input, condition: $condition) {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductVariation = /* GraphQL */ `
  mutation DeleteProductVariation(
    $input: DeleteProductVariationInput!
    $condition: ModelProductVariationConditionInput
  ) {
    deleteProductVariation(input: $input, condition: $condition) {
      id
      sku
      variationName
      amount
      cost
      price
      availability
      condition
      pictures
      productID
      status
      statusReason
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      products
      shops
      cambaceoProducts
      catalogs
      contactInfo
      billingData
      addressData
      subTotal
      iva
      shipping
      total
      payment
      status
      comments
      userProfileEmail
      typeName
      createdAt
      updatedAt
    }
  }
`;
export const createCatalog = /* GraphQL */ `
  mutation CreateCatalog(
    $input: CreateCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    createCatalog(input: $input, condition: $condition) {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const updateCatalog = /* GraphQL */ `
  mutation UpdateCatalog(
    $input: UpdateCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    updateCatalog(input: $input, condition: $condition) {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const deleteCatalog = /* GraphQL */ `
  mutation DeleteCatalog(
    $input: DeleteCatalogInput!
    $condition: ModelCatalogConditionInput
  ) {
    deleteCatalog(input: $input, condition: $condition) {
      id
      name
      shopID
      type
      cover
      coverStatus
      url
      createdAt
      updatedAt
    }
  }
`;
export const createCatalogPages = /* GraphQL */ `
  mutation CreateCatalogPages(
    $input: CreateCatalogPagesInput!
    $condition: ModelCatalogPagesConditionInput
  ) {
    createCatalogPages(input: $input, condition: $condition) {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCatalogPages = /* GraphQL */ `
  mutation UpdateCatalogPages(
    $input: UpdateCatalogPagesInput!
    $condition: ModelCatalogPagesConditionInput
  ) {
    updateCatalogPages(input: $input, condition: $condition) {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCatalogPages = /* GraphQL */ `
  mutation DeleteCatalogPages(
    $input: DeleteCatalogPagesInput!
    $condition: ModelCatalogPagesConditionInput
  ) {
    deleteCatalogPages(input: $input, condition: $condition) {
      id
      catalogID
      pageNumber
      pageImage
      productsString
      cambaceoProductsArray {
        id
        sku
        name
        price
        promoPrice
        promoType
        amount
        createdAt
        updatedAt
      }
      productsArray {
        id
        name
        description
        specifications
        category
        brandName
        shopID
        productVariations {
          nextToken
        }
        status
        featured
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      userID
      phoneNumber
      addressStreet
      addressExterior
      addressInterior
      addressNeighborhood
      addressZipcode
      addressState
      addressCity
      addressNotes
      invoiceName
      invoiceRfc
      invoiceStreet
      invoiceExterior
      invoiceInterior
      invoiceNeighborhood
      invoiceZipcode
      invoiceState
      invoiceCity
      userProfileEmail
      monederoPoints
      createdAt
      updatedAt
    }
  }
`;
export const createMonederoLog = /* GraphQL */ `
  mutation CreateMonederoLog(
    $input: CreateMonederoLogInput!
    $condition: ModelMonederoLogConditionInput
  ) {
    createMonederoLog(input: $input, condition: $condition) {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateMonederoLog = /* GraphQL */ `
  mutation UpdateMonederoLog(
    $input: UpdateMonederoLogInput!
    $condition: ModelMonederoLogConditionInput
  ) {
    updateMonederoLog(input: $input, condition: $condition) {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteMonederoLog = /* GraphQL */ `
  mutation DeleteMonederoLog(
    $input: DeleteMonederoLogInput!
    $condition: ModelMonederoLogConditionInput
  ) {
    deleteMonederoLog(input: $input, condition: $condition) {
      id
      userID
      points
      type
      description
      createdAt
      updatedAt
    }
  }
`;
