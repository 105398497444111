
import React, { useState } from "react";
import  {  PRODUCTS_EDIT } from "../../components/cambaceo/MyCambaceoProducts";
import MyCambaceoEditForm from "../../components/cambaceo/MyCambaceoEditForm";

const MyCambaceoFormEditPage: React.FC = () => {

    const [myCambaceo, setCambaceo] = useState(PRODUCTS_EDIT);



	return <MyCambaceoEditForm myCambaceo={setCambaceo} />;
};

export default MyCambaceoFormEditPage;
