import { IonButton, IonButtons,IonContent, IonHeader,IonCol, IonGrid, IonIcon, IonPage, IonRow, IonText, IonImg } from "@ionic/react";
import { useHistory } from "react-router";
import { homeOutline, chatboxOutline } from "ionicons/icons";
import Toolbar from "../global/general/Toolbar";
import FooterComponent from "../global/general/FooterComponent";
import { ROUTE_HOME, ROUTE_PROMOTE } from "../../utils/routes";
import ShopPlansCards from "../shop/ShopPlansCards";
import { useState } from "react";
import ShopPlansCardsPromote from "../shop/ShopPlansCardsPromote";

const Promote: React.FC<{}> = (props) => {
	const history = useHistory();
	const [myShop, setMyShop] = useState("");
	const [myShopPlan, setMyShopPlan] = useState("");

    return (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="page-content info">
				<IonGrid fixed>
					<IonRow>
						<IonCol>
							<IonButtons>
								<IonButton color="secondary" href={ROUTE_HOME}><IonIcon slot="start" icon={homeOutline}></IonIcon><IonText className="ion-hide-sm-down">Inicio</IonText></IonButton>
								<IonText color="secondary">/</IonText>
								<IonButton color="secondary" href={ROUTE_PROMOTE}><IonIcon slot="start" icon={chatboxOutline}></IonIcon><IonText className="ion-hide-sm-down">¿Cómo promocionarte con nosotros?</IonText></IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonImg src="https://novoprodstoragebucket113706-prod.s3.amazonaws.com/public/1645571428124.jpeg"/>
						</IonCol>
					</IonRow>
					<IonRow className="ion-text-center">
						<IonCol>
							<IonButton
							size="large"
							color="tertiary"
							className="ion-margin-bottom"
							onClick={()=>{history.push("/signup")}}>
								¡Crea tu cuenta!
							</IonButton>
						</IonCol>
					</IonRow>
					<ShopPlansCardsPromote/>
                </IonGrid>
				<FooterComponent></FooterComponent>
            </IonContent>
        </IonPage>
    );
}

export default Promote;