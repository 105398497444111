import React from "react";
import Monedero from "../../components/profile/Monedero";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../store/AuthSlice";
import { getAllUser, setActualUser, userSelector } from "../../store/UserSlice";
import { useIonViewWillEnter } from "@ionic/react";
import { getAllMonederoLogsByUserID } from "../../store/MonederoSlice";

const ProfileMonedero: React.FC<{}> = () => {
	const dispatch = useDispatch();
	const { userProfile } = useSelector(authSelector);
	const { users } = useSelector(userSelector);

	for (let i = 0; i < users.length; i++) {
		if (users[i].userID.includes(userProfile.email)) {
			dispatch(setActualUser(users[i]));
		}}

	useIonViewWillEnter(() => {
		dispatch(getAllUser({userID: userProfile.email}))
		dispatch(getAllMonederoLogsByUserID({userID: userProfile.email, sortDirection: "DESC"}))})
	
	return <Monedero />;
};

export default ProfileMonedero;
