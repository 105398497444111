
import { API, graphqlOperation } from "aws-amplify";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CambaceoThunk } from "../index";
import {
  setError,
  setLoading,
  setWasCreated,
  setWasDeleted,
  setWasUpdated,
} from "./FlagSlice";
import { createCambaceo, deleteCambaceo, updateCambaceo } from "../graphql/mutations";
import { getCambaceo, listCambaceos } from "../graphql/queries";

//Interfaces
export interface CambaceoState {
  cambaceo: CambaceoObject[];
  actualCambaceo: CambaceoObject;
}

export interface CambaceoObject {
  id: string
  sku: string;
  name: string;
  price: number;
  promoPrice: number;
  promoType: string;
  amount: number;
}

//Initial State
const initialState: CambaceoState = {
  cambaceo: [],
  actualCambaceo: {
    id: "",
    sku: "",
    name: "",
    price: 0,
    promoPrice: 0,
    promoType: "",
    amount: 0,
  },

};

//Reducers
const cambaceo = createSlice({
  name: "cambaceo",
  initialState,
  reducers: {
    setCambaceo(state, { payload }: PayloadAction<CambaceoObject[]>) {
      state.cambaceo = payload;
    },
    setActualCambaceo(state, { payload }: PayloadAction<CambaceoObject>) {
      state.actualCambaceo = payload;
    },

    cleanCambaceo(state) {
      state.cambaceo = [];
    },
  },
});

//Exports
export const { setCambaceo, setActualCambaceo, cleanCambaceo } = cambaceo.actions;

export default cambaceo.reducer;

export const cambaceoSelector = (state: { cambaceoStore: CambaceoState }) =>
  state.cambaceoStore;



//Middleware
export const postCambaceo = (input: any): CambaceoThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res:any = await API.graphql(graphqlOperation(createCambaceo, { input }));
      dispatch(setActualCambaceo(res.data.createCambaceo))
      dispatch(setLoading(false));
      dispatch(setWasCreated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const getAllCambaceo =
  (input: any): CambaceoThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(listCambaceos, input));
      dispatch(setCambaceo(res.data.listCambaceos.items));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
export const getCambaceoInfo =
  (input: any): CambaceoThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(getCambaceo, input));
      if (typeof res.data.getCambaceo !== "undefined") {
        dispatch(setActualCambaceo(res.data.getCambaceo));
      }
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };


export const customListCamb = (): CambaceoThunk => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    dispatch(setLoading(false));
  } catch (error:any) {
    dispatch(setLoading(false));
    dispatch(setError(error));
  }
};

export const readCambaceo =
  (input: any): CambaceoThunk =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(
        graphqlOperation(getCambaceo, { input })
      );
      dispatch(setCambaceo(res.data.cambaceo));
      dispatch(setLoading(false));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };

export const editCambaceo = (input: any): CambaceoThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res: any = await API.graphql(graphqlOperation(updateCambaceo, { input }));
      dispatch(setLoading(false));
      dispatch(setWasUpdated(true));
    } catch (error:any) {
      dispatch(setLoading(false));
      dispatch(setError(error));
    }
  };
};

export const removeCambaceo = (input: any): CambaceoThunk => {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      await API.graphql(graphqlOperation(deleteCambaceo, { input }));
      dispatch(setLoading(false));
      dispatch(setWasDeleted(true));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};