import { useIonViewWillEnter } from "@ionic/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAllCatalogPages } from "../../store/CatalogPageSlice";
import Catalog from "../../components/catalogs/Catalog";
import { useParams } from "react-router-dom";

const CatalogPage: React.FC = () => {
	const selectedCatalogPageID = useParams<{ catalogPageID: string }>().catalogPageID;
	const dispatch = useDispatch();

	useEffect(()=>
	{
	  const catalogPageID = {
		id: selectedCatalogPageID
	  }
	  dispatch(getAllCatalogPages({catalogId:catalogPageID}))
	} ,[])
  
	
	return <Catalog/>;
};
export default CatalogPage;
