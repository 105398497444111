import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTextarea,
  IonText,
  IonTitle,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonBadge,
} from "@ionic/react";
import {
  arrowBackOutline,
  storefrontOutline,
  closeCircleOutline,
  arrowDown,
  arrowUp,
  arrowForwardOutline,
  wallet,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUser, getAllUser, setActualUser, userSelector } from "../../store/UserSlice";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import MyShopToolbar from "../shop/MyShopToolbar";
import {
  postMonederoLog,
  monederoLogSelector,
  getAllMonederoLogsByUserID,
} from "../../store/MonederoSlice";
import UserFeedback from "../global/general/UserFeedback";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";

export const USERS_LIST = "users_list";
export const USER_MONEDERO = "user_monedero";



const AllUsers: React.FC = () => {
  const [myUser, setUser] = useState(USERS_LIST);
  const { users } = useSelector(userSelector);
  const [showModal, setShowModal] = useState(false);
  const { wasCreated, loading } = useSelector(flagSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [text, setText] = useState<string>();
  const [points, setPoints] = useState<string>();
  const [operation, setOperation] = useState<string>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actualUser } = useSelector(userSelector);
  const { monederoLog } = useSelector(monederoLogSelector);


  const goBackHandler = () => {
    if (myUser === USERS_LIST) {
      history.push("/mi-tienda");
    } else {
      setUser(USERS_LIST);
      dispatch(getAllUser({}))
    }
  };



  useEffect(() => {
    if (myUser === USER_MONEDERO) {
      dispatch(
        getAllMonederoLogsByUserID({
            userID:  actualUser.userID, sortDirection: "DESC" 
    }))
  }
    if (wasCreated) {
      setToastMessage("¡Puntos actualizados satisfactoriamente!");
      dispatch(setWasCreated(false));
      dispatch(getAllUser({}))
    }
  }, [wasCreated, toastMessage, myUser]);

  let result: number = 0;

  const changePointsHandler = () => {
    let initialPoints: number =
      actualUser.monederoPoints === null
        ? 0
        : (actualUser.monederoPoints as number);
  
    if (operation === "sumar") {
      result = Number(initialPoints) + Number(points) ;
    } else if (operation === "restar") {
      result = Number(initialPoints) - Number(points);
    }
    let input = {
      userID: actualUser.userID,
      points: points,
      type: operation,
      description: text,
    };
    dispatch(postMonederoLog(input));
    dispatch(
      editUser({ userID: actualUser.userID, monederoPoints: result as number })
    );
    setPoints("")
    setOperation("")
    setText("")
    result = 0
    initialPoints = 0
  };

  return (
    <IonPage>
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar
        iconName={storefrontOutline}
        title="Usuarios de Novocorpo"
      />
      <IonContent>
        <IonModal isOpen={showModal}>
          <IonRow>
            <IonCol className="ion-text-right">
              <IonButton onClick={() => setShowModal(false)} fill="clear">
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonGrid fixed>
            <IonRow className="ion-text-center">
              <IonTitle color="primary">Operación de Monedero</IonTitle>
            </IonRow>
            <IonGrid className="ion-text-center">
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Cantidad puntos</IonLabel>
                    <IonInput
                      type="number"
                      onIonChange={(e) => setPoints(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>Operación</IonLabel>
                    <IonSelect
                      value={operation}
                      placeholder="Tipo operación"
                      onIonChange={(e) => setOperation(e.detail.value)}
                    >
                      <IonSelectOption value="sumar">Sumar</IonSelectOption>
                      <IonSelectOption value="restar">Restar</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Razón</IonLabel>
                    <IonTextarea
                      onIonChange={(e) => setText(e.detail.value!)}
                    ></IonTextarea>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    onClick={() => {
                      changePointsHandler();
                      setShowModal(false);
                      setUser(USERS_LIST)
                      dispatch(getAllUser({}))
                    }}
                    color="primary"
                  >
                    ENVIAR
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonGrid>
        </IonModal>
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonRow>
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
                <IonIcon slot="start" icon={arrowBackOutline} />
                Volver
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-flex">
            {myUser == USERS_LIST && (
              <>
                <IonGrid>
                  <IonRow>
                    <IonCol sizeXs="12" className="ion-text-center ion-padding">
                      <IonTitle color="tertiary">MONEDEROS</IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-center" sizeXs="12">
                      <IonLabel className="ion-text-wrap">
                        Elija un usuario para realizar operaciones en su monedero.
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  {myUser == USERS_LIST &&
                    users.length > 0 &&
                    users.map((user, key) => {
                      return (
                        <IonRow key={key}>
                          <IonCol sizeXs="12">
                            <IonCard
                              className="show-click"
                              onClick={() => {
                                dispatch(setActualUser(user));
                                setUser(USER_MONEDERO);
                                dispatch(
                                  getAllMonederoLogsByUserID({
                                      userID:  user.userID, sortDirection: "DESC" 
                              })
                                );
                          
                              }}
                            >
                              <IonItem><IonIcon color="tertiary" icon={wallet} slot="start"/>
                                <IonCol size="6">
                                <IonLabel color="primary"><IonText>{user.userID}</IonText></IonLabel> </IonCol>
                                <IonCol className="ion-hide-sm-down"  size="6" offset="5">
                                {user.monederoPoints !== null ? <IonBadge color="success"><IonLabel><small><strong>{user.monederoPoints + " puntos"}</strong></small></IonLabel></IonBadge> : ""}</IonCol>
                                <IonIcon color="primary" className="ion-hide-sm-down" icon={arrowForwardOutline} slot="end"/>
                              </IonItem>
                             
                            </IonCard>
                          </IonCol>
                        </IonRow>
                      );
                    })}
                </IonGrid>
              </>
            )}
            {myUser == USER_MONEDERO && (
              <>
                <IonGrid>
                  <IonRow>
                    <IonCol className="ion-text-right" sizeXs="2" offsetXs="2.5" offsetMd="9.7">
                      <IonButton onClick={() => setShowModal(true)}>
                        AGREGAR OPERACIÓN
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="ion-text-center ion-padding">
                      <IonTitle color="primary">
                        Monedero de: <p><i>{actualUser.userProfileEmail}</i></p>
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonGrid class="area ion-padding">
                        {actualUser.monederoPoints === null && (
                          <IonRow>
                            <IonCol className="ion-text-center ion-padding">
                              <IonLabel color="primary">
                                Monedero Vacío
                              </IonLabel>
                            </IonCol>
                          </IonRow>
                        )}
                        {actualUser.monederoPoints !== null && (
                          <>
                            <IonRow>
                              <IonCol>
                                <IonTitle color="primary">
                                  <strong>HISTORIAL</strong>
                                </IonTitle>
                                <IonText
                                  className="ion-margin ion-padding-top"
                                  color="tertiary"
                                >
                                  <small>
                                    <i>Información de movimientos</i>
                                  </small>
                                </IonText>
                              </IonCol>
                              <IonCol className="ion-text-right">
                                <IonTitle color="primary">
                                  <small>Total: </small>{" "}
                                  <IonText color="tertiary">
                                    <b>{actualUser.monederoPoints}</b>
                                  </IonText>{" "}
                                  <small>puntos</small>
                                </IonTitle>
                              </IonCol>
                              <div className="lineaTertiary"></div>
                            </IonRow>
                            {monederoLog && monederoLog.map((log) =>
                            <IonRow>
                              <IonCol>
                                <IonItem>
                                  <IonLabel
                                    color="tertiary"
                                    position="floating"
                                  >
                                    {log.points} <small>puntos</small>
                                  </IonLabel>
                                  <IonInput
                                    color={log.type == "sumar" ? "primary":"secondary"}
                                    value={new Date(
                                      log.createdAt
                                    ).toLocaleDateString("es-MX")}
                                  ></IonInput>
                                  <IonIcon
                                    color={log.type == "sumar" ? "primary":"secondary"}
                                    icon={log.type === "sumar" ? arrowUp : arrowDown}
                                    slot="start"
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol className="ion-hide-sm-down">
                                <IonItem>
                                  <IonLabel position="floating">Razón</IonLabel>
                                  <IonInput
                                    color="primary"
                                    value={log.description}
                                  ></IonInput>
                                </IonItem>
                              </IonCol>
                            </IonRow>
                            
                            )}
                          </>
                        )}
                      </IonGrid>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AllUsers;
