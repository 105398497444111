import React, { useState } from "react";

export const AuthContext = React.createContext({
	isAuthState: false,
	setAuthStateTo: (state: boolean) => {},
});

const AuthContextProvider = (props: any) => {
	const [isAuthState, setIsAuthState] = useState<boolean>(false);
	const setAuthStateTo = (state: boolean) => {setIsAuthState(state);};

	return (
		<AuthContext.Provider
			value={{
				isAuthState,
				setAuthStateTo
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContextProvider;