import {
    useIonViewWillEnter,
  } from "@ionic/react";
  import React from "react";
  import { useDispatch } from "react-redux";
  import { useParams } from "react-router-dom";
import PropertyDetailContent from "../../components/properties/PropertyDetailContent";
import { getPropertyInfo } from "../../store/PropertySlice";
  
  const PropertyDetailPage: React.FC = () => {
    const selectedPropertyID = useParams<{ propertyID: string }>().propertyID;
    const dispatch = useDispatch();
  
  
  
    useIonViewWillEnter(() => {
   
      const propertyID = {
        id: selectedPropertyID
      }
      dispatch(getPropertyInfo(propertyID))
    });
  
    return <PropertyDetailContent />;
  };
  
  export default PropertyDetailPage;
  