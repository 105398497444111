import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
} from "@ionic/react";

import { arrowBackOutline, pricetags, square } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated, setWasDeleted, setWasUpdated } from "../../store/FlagSlice";
import {productSelector } from "../../store/ProductSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import MyProductsForm from "./MyProductsForm";
import MyProductsCard from "./MyProductsCard";
import ProductVariationForm from "./ProductVariationForm";
import MyProductsCardVariation from "./MyProductsCardVariation";
import ProductVariationsCard from "./ProductVariationsCard";
import { getAllProductVariation, ProductVariationObject, productVariationSelector } from "../../store/ProductVariationSlice";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import { PRODUCTS_EDIT } from "./SelledProducts";
import MyProductsEditForm from "./MyProductsEditForm";
import ProductVariationEditForm from "./ProductVariationEditForm";
import { editShop, shopSelector } from "../../store/ShopSlice";

export const PRODUCTS_LANDING = "productsLanding";
export const PRODUCTS_ADD = "productsAdd";
export const PRODUCTS_VARIATIONS = "productsVariations";
export const PRODUCTS_ADD_VARIATIONS = "productsAddVariations";
export const PRODUCTS_EDIT_VARIATIONS = "productsEditVariations";

const MyProductsContent: React.FC = () => {
  const [myProducts, setProducts] = useState(PRODUCTS_LANDING);
  const [editProps, setEditProps] = useState("")
  const { wasCreated, loading, wasUpdated, wasDeleted } = useSelector(flagSelector);
  const { products } = useSelector(productSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pvIndex, setPvIndex] = useState(0);
  const dispatch = useDispatch();
  const { actualProduct } = useSelector(productSelector);
  const history = useHistory();
  const {productVariation} = useSelector(productVariationSelector)
  const {theShop} = useSelector(shopSelector)
  let counter: number = theShop.productsVarCounter ? theShop.productsVarCounter : 0
  const filteredProducts = products.filter((prod) => prod.shopID === theShop.shopID)
  const filteredProductsVar = filteredProducts.map((prod) => prod.productVariations.items.length)

  for (let i = 0; i < filteredProductsVar.length; i++) {
    counter = counter + filteredProductsVar[i]
  }

  useEffect(() => {
    if (wasCreated && myProducts === PRODUCTS_ADD) {
      setProducts(PRODUCTS_VARIATIONS);
      setToastMessage("¡Producto creado satisfactoriamente!");
      dispatch(setWasCreated(false));
    } else if (wasCreated && myProducts === PRODUCTS_ADD_VARIATIONS) {
      setProducts(PRODUCTS_VARIATIONS);
      dispatch(editShop({shopID: theShop.shopID, productsVarCounter: counter+1}))
      setToastMessage("¡Variación creada satisfactoriamente!");
      dispatch(setWasCreated(false));
    
    }
    else if (wasDeleted && myProducts === PRODUCTS_EDIT_VARIATIONS) {
      setProducts(PRODUCTS_LANDING);
      setToastMessage("¡Variación eliminada!");
      dispatch(setWasDeleted(false));
    
    }
    // else if (wasDeleted && myProducts === PRODUCTS_EDIT) {
    //   setProducts(PRODUCTS_LANDING);
    //   setToastMessage("¡Producto eliminado!");
    //   dispatch(setWasDeleted(false));
    // } 
    else if (wasUpdated && myProducts === PRODUCTS_EDIT_VARIATIONS) {
      setProducts(PRODUCTS_LANDING);
      setToastMessage("¡Variación actualizada!");
      dispatch(setWasUpdated(false));
    
    } else if (myProducts=== PRODUCTS_VARIATIONS) {
      dispatch(getAllProductVariation);
    }
  }, [wasCreated, wasUpdated, wasDeleted, toastMessage, setProducts, productVariation, editProps, myProducts, dispatch, theShop.shopID, counter]);



  const goBackHandler = () => {
    if (myProducts === PRODUCTS_LANDING) {
      history.push("/mi-tienda");
    } else if (myProducts === PRODUCTS_ADD) {
      setProducts(PRODUCTS_LANDING);
    } else if (myProducts === PRODUCTS_VARIATIONS) {
      setProducts(PRODUCTS_LANDING);
    } else if (myProducts === PRODUCTS_ADD_VARIATIONS) {
      setProducts(PRODUCTS_VARIATIONS);
    } else if (myProducts === PRODUCTS_EDIT) {
    setProducts(PRODUCTS_LANDING);
  } else if (myProducts === PRODUCTS_EDIT_VARIATIONS) {
  setProducts(PRODUCTS_VARIATIONS);
}
  };

  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar iconName={pricetags} title="Mis Productos" />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonContent>
      
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonRow>
            {editProps !== "hide" &&
            <IonCol>
              <IonButton fill="clear" onClick={goBackHandler}>
              <IonIcon slot="start" icon={arrowBackOutline} />Volver
              </IonButton>
            </IonCol>}
            { myProducts === PRODUCTS_LANDING && (<IonCol className='ion-text-end'>
							<IonButton color='primary' fill="solid"  onClick={ ()=>{
                setProducts(PRODUCTS_ADD)
              }} >
								Agregar producto
							</IonButton>
						</IonCol>)}
           
          </IonRow>
          {myProducts === PRODUCTS_LANDING && (
            <>
              <IonRow>
                <IonCol>
                  <IonSearchbar
                    value={searchText}
                    onIonChange={(e) => setSearchText(e.detail.value!)}
                    placeholder="Nombre del producto"
                    class="search-bar-color"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {products
                    .filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((product) => (
                      <MyProductsCard
                        key={product.id}
                        product={product}
                        setProducts={setProducts}
                      />
                    ))}
                </IonCol>
              </IonRow>
            </>
          )}
          {myProducts === PRODUCTS_ADD && (
            <MyProductsForm myProducts={setProducts} />
          )}


          {myProducts === PRODUCTS_VARIATIONS && (
            <>
             
              <IonRow>
                <IonCol>
                  <MyProductsCardVariation
                  setEditProps={setEditProps}
                    setProducts={setProducts}
                    key={actualProduct.id}
                    product={actualProduct} status={""}                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonText>
                    <h3>
                      {actualProduct.productVariations.items[0]
                        ? "Variaciones del producto"
                        : "¡Ahora crea variaciones del producto!"}
                    </h3>
                  </IonText>
                </IonCol>
              </IonRow>
              {!actualProduct.productVariations.items[0] && (
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonText>
                      Una VARIACIÓN DE PRODUCTO te permite agregar el SKU,
                      precio y fotografías de tu producto.
                    </IonText>
                  </IonCol>
                </IonRow>
              )}

              <IonRow>
                <IonCol>
                  {actualProduct.productVariations.items.map(
                    (pv: ProductVariationObject, index) => {
                      return (<>
                      <div onClick={() => {setPvIndex(index)}}>
                        <ProductVariationsCard
                          setProducts={setProducts}
                          key={pv.id}
                          productVariation={pv}
                          
                        /></div>
                      </>);
                    }
                  )}
                </IonCol>
              </IonRow>
            </>
          )}
           { myProducts === PRODUCTS_VARIATIONS && (
           <IonRow><IonCol className='ion-text-center'>
							<IonButton color='primary' fill="solid"  onClick={ ()=>{
                setProducts(PRODUCTS_ADD_VARIATIONS)
              }} >
								Agregar variación
							</IonButton>
						</IonCol></IonRow>)}

          {myProducts === PRODUCTS_EDIT_VARIATIONS && actualProduct.productVariations.items[0] !== undefined &&
          <ProductVariationEditForm productVariation={actualProduct.productVariations.items[pvIndex]} myProducts={setProducts}/>}

          {myProducts === PRODUCTS_ADD_VARIATIONS && (
            <ProductVariationForm myProducts={setProducts} />
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyProductsContent;
