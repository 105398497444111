import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonTitle,
} from "@ionic/react";
import {
  arrowBackOutline,
  closeCircleOutline,
  cube,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector} from "../../store/FlagSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyShopToolbar from "../shop/MyShopToolbar";
import { useHistory } from "react-router";
import Toolbar from "../global/general/Toolbar";
import {
  getOrdersInfo,
  OrderObject,
  orderSelector,
} from "../../store/OrderSlice";
import { format, parseISO } from 'date-fns';

export const PRODUCTS_LANDING = "productsLanding";
export const PRODUCTS_ADD = "productsAdd";
export const PRODUCTS_EDIT = "productsEdit";

const SelledProducts: React.FC = () => {
  const {loading } = useSelector(flagSelector);
  const o = useSelector(orderSelector);
  const orders = o.orders;
  const [toastMessage, setToastMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [clean, setClean] = useState(false)
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  

  const goBackHandler = () => {
    history.replace("/mis-ordenes");
  };

  const convertDate = (pickedDate: any) => {
    return new Date(pickedDate).toLocaleDateString("es-MX")
  }
const formatDate = (value: string) => {
  return format(parseISO(value), 'yyyy-MM-dd');
 };


  const compareDate = (orderDate1: OrderObject, orderDate2: OrderObject) => {  
    let oDate1 = new Date(orderDate1.createdAt).getTime();  
    let oDate2 = new Date(orderDate2.createdAt).getTime();  
    return oDate1 > oDate2 ? 1 : -1; }


  useEffect(() => {
    if (selectedDate !== "") {
    let filterOrdersByDate = {
      filter: { createdAt: { contains: formatDate(selectedDate) }}
    }
    dispatch(getOrdersInfo(filterOrdersByDate)) 
    setClean(true)
  }
}, [selectedDate])



 
  return (
    <IonPage>
      <IonHeader>
        <Toolbar />
      </IonHeader>
      <MyShopToolbar iconName={cube} title={"Productos vendidos"} />
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonContent>
        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="8.9">
                <IonButton fill="clear" onClick={goBackHandler}>
                  <IonIcon slot="start" icon={arrowBackOutline} />
                  Volver
                </IonButton>
              </IonCol>
               </IonRow>
<IonGrid>
               <IonRow>
              <IonCol sizeMd="9" sizeXs="12">
                <IonSearchbar
                  value={searchText}
                  onIonChange={(e) => setSearchText(e.detail.value!)}
                  placeholder="Nombre del producto"
                ></IonSearchbar>
              </IonCol>
            
              <IonCol className="ion-padding" sizeMd="3" sizeXs="8" offsetXs="2" offsetMd="0">  
              <IonButtons >
                <IonButton fill="solid" color="primary" onClick={() => setShowModal(true)}>
                  Filtrar por fecha
                </IonButton>
                <IonButton color="primary" fill="outline" onClick={() => {setSelectedDate(""); dispatch(getOrdersInfo({}))}}>
                  Limpiar
                </IonButton>
                </IonButtons>
              </IonCol>
              <IonModal isOpen={showModal} cssClass={window.innerWidth > 576 ? "filtroModal" : ""}>
              <IonRow>
            <IonCol className="ion-text-right">
              <IonButton onClick={() => setShowModal(false)} fill="clear">
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonGrid fixed>
           
              <IonRow>
                <IonCol className="ion-text-center">
                  <IonTitle color="primary">
                    Seleccionar fecha
                  </IonTitle>
                </IonCol>
                
                <IonCol sizeMd="7" offsetMd="2.5" sizeXs="12">
              <IonItem className="ion-text-left">
                <IonLabel>Fecha:</IonLabel>
          <IonDatetime  cancelText="Cancelar" doneText="Seleccionar" displayFormat="DD/M/YYYY" value={selectedDate} onIonChange={e =>  setSelectedDate(e.detail.value!)}></IonDatetime>
         </IonItem></IonCol></IonRow>
         <IonRow>
           <IonCol className="ion-text-center ion-margin">
          <IonButton className="ion-text-center" onClick={() => {setSelectedDate("");  setShowModal(false)}} color="primary">
                      Filtrar
                    </IonButton>
                    </IonCol>
                    </IonRow>
           </IonGrid>
       
              </IonModal>

               </IonRow>
          
          </IonGrid>
</IonGrid>

          {orders.length > 0 &&
            Object.entries(orders).sort((a: any, b: any) => compareDate(a, b)).filter((order: any) => convertDate(selectedDate) == convertDate(order.createdAt)).map(([key, order]) => (
              <>
                <IonGrid >
                  <IonRow key={key}>
                    <IonCol>
                      {order.products !== undefined
                        ? JSON.parse(order.products as any)
                            .filter((prod: any) =>
                              prod.product.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            )
                            .map((productsList: any, index: number) => (
                              <IonCard key={index}>
                                <IonItem>
                                  <IonCol sizeMd="1.5" sizeXs="5">
                                    <IonLabel color="primary">
                                      {convertDate(order.createdAt)}
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol sizeMd="4" sizeXs="8">
                                    <IonLabel color="primary">
                                      {productsList.product.name}
                                    </IonLabel>
                                  </IonCol>
                                </IonItem>
                              </IonCard>
                            ))
                        : null}
                      {order.cambaceoProducts !== undefined
                        ? JSON.parse(order.cambaceoProducts as any)
                            .filter((prod: any) =>
                              prod.product.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                            )
                            .map((productsList: any, index: number) => (
                              <IonCard key={index}>
                                <IonItem>
                                  <IonCol sizeMd="1.5" sizeXs="5">
                                    <IonLabel color="primary">
                                    {convertDate(order.createdAt)}
                                    </IonLabel>
                                  </IonCol>
                                  <IonCol sizeMd="4" sizeXs="8">
                                    <IonLabel color="primary">
                                      {productsList.product.name}
                                    </IonLabel>
                                  </IonCol>
                                </IonItem>
                              </IonCard>
                            ))
                        : null}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </>
            ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SelledProducts;
