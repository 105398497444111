import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
} from "@ionic/react";
import {
  addOutline,
  arrowBackOutline,
  cloudUploadOutline,
  pricetagOutline,
  trashOutline,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { flagSelector, setWasCreated } from "../../store/FlagSlice";
import UserFeedback from "../global/general/UserFeedback";
import MyCambaceoForm from "./MyCambaceoForm";
import { shopSelector } from "../../store/ShopSlice";
import { useHistory } from "react-router";
import {
  CambaceoObject,
  cambaceoSelector,
  getCambaceoInfo,
  setActualCambaceo,
} from "../../store/CambaceoSlice";
import { ROUTE_SHOP_CAMBACEO_NEW, ROUTE_SHOP_CATALOGS_PAGES, ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS } from "../../utils/routes";
import {
  catalogPageSelector,
  editCatalogPage,
} from "../../store/CatalogPageSlice";

export const PRODUCTS_LANDING = "productsLanding";
export const PRODUCTS_ADD = "productsAdd";
export const PRODUCTS_EDIT = "productsEdit";
export let cambaceoArray: CambaceoObject[] = [];

const MyCambaceoContent: React.FC = () => {
  const [myCambaceo, setCambaceo] = useState(PRODUCTS_LANDING);
  const { wasCreated, loading } = useSelector(flagSelector);
  const { cambaceo } = useSelector(cambaceoSelector);
  const [toastMessage, setToastMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [once, setOnce] = useState(true);
  const { actualCambaceo } = useSelector(cambaceoSelector);
  const { actualCatalogPage } = useSelector(catalogPageSelector);
  const history = useHistory();

  let check = actualCatalogPage.productsString
    ? JSON.parse(actualCatalogPage.productsString)
    : false;

  if (check !== false && once == true) {
    cambaceoArray = check;
    setOnce(false);
    {
    }
  }

  const goBackHandler = () => {
    if (myCambaceo === PRODUCTS_LANDING) {
      history.replace(ROUTE_SHOP_CATALOGS_PAGES);
    } else if (myCambaceo === PRODUCTS_ADD || myCambaceo === PRODUCTS_EDIT) {
      setCambaceo(PRODUCTS_LANDING);
      history.replace(ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS);
    }
  };

  const deleteFromList = (selectedCambaceo: CambaceoObject) => {
    if (cambaceoArray.includes(selectedCambaceo)) {
      cambaceoArray = cambaceoArray.filter((camb) => camb !== selectedCambaceo);
    }
  };

 

  const addToPage = (selectedCambaceo: CambaceoObject) => {
   
    if (!(cambaceoArray.includes(selectedCambaceo)) && !(actualCatalogPage.productsString?.includes(JSON.stringify(selectedCambaceo)))) {
       cambaceoArray.push(selectedCambaceo);
    }
    {
    }
  };

  const saveChanges = () => {
    let inputObject = {
      id: actualCatalogPage.id,
      productsString: JSON.stringify(cambaceoArray),
    };
    dispatch(editCatalogPage({ input: inputObject }));
  };


  useEffect(() => {
    if (wasCreated) {
      setToastMessage("¡Producto creado satisfactoriamente!");
      dispatch(setWasCreated(false));
      cambaceoArray.push(actualCambaceo)
      
    }
  }, [wasCreated, toastMessage, cambaceoArray]);

  return (
    <IonPage>
      <UserFeedback
        loading={loading}
        toastMessage={[toastMessage, setToastMessage]}
      />
      <IonContent>
        <IonCol sizeXs="1">
          <IonButton fill="clear" onClick={goBackHandler}>
            <IonIcon slot="start" icon={arrowBackOutline} />
            Volver
          </IonButton>
        </IonCol>

        <IonGrid fixed className="ion-margin-bottom ion-padding-bottom">
          {myCambaceo === PRODUCTS_LANDING && (
            <>
              <IonRow>
                <IonCol
                  sizeMd="5"
                  sizeXs="12"
                  offsetMd="0.5"
                  className="ion-margin-top ion-text-center"
                >
                  <IonCard>
                    <IonImg src={actualCatalogPage.pageImage[0]} />
                    <IonRow className="ion-margin">
                      <IonCol className="ion-margin-top ion-text-center">
                        <IonText color="primary">
                          Lista de productos agregados a la página:
                        </IonText>

                        {cambaceoArray &&
                          cambaceoArray.map((camb) => (
                            <IonCard className="ion-margin-bottom">
                              <IonItem color="primary">
                                <IonIcon
                                  color="light"
                                  icon={pricetagOutline}
                                  slot="start"
                                />
                                <IonLabel color="light">{camb.name}</IonLabel>
                                <IonButtons>
                                  <IonButton
                                    slot="end"
                                    onClick={() => {
                                      dispatch(setActualCambaceo(camb));
                                      deleteFromList(camb);
                                    }}
                                  >
                                    <IonIcon
                                      icon={trashOutline}
                                      slot="icon-only"
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            </IonCard>
                          ))}
                      </IonCol>
                    </IonRow>
                  </IonCard>
                  <IonButton onClick={saveChanges}>
                    <IonIcon slot="start" icon={cloudUploadOutline} />
                    Guardar cambios
                  </IonButton>
                </IonCol>

                <IonCol sizeMd="5" sizeXs="12" offsetMd="1.5" className="ion-text-center">
                  <IonButton
                    color="primary"
                    fill="solid"
                    onClick={() => {
                      setCambaceo(PRODUCTS_ADD);
                      history.push(ROUTE_SHOP_CAMBACEO_NEW)
                    }}
                  >
                    Agregar nuevo producto
                  </IonButton>
                  
                  <IonTitle
                    className="ion-text-center ion-margin "
                    color="primary"
                  >
                    <p className="ion-text-wrap">
                    Si el producto que deseas agregar ya ha
                    aparecido en otras temporadas,
                    ¡búscalo aquí!</p>
                  </IonTitle>

                  <IonRow>
                    <IonSearchbar
                      value={searchText}
                      onIonChange={(e) => setSearchText(e.detail.value!)}
                      placeholder="Nombre del producto"
                      class="search-bar-color"
                    />

                    <IonCol>
                      {cambaceo
                        .filter((product) =>
                          product.name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((product) => (
                          <IonCard className="ion-margin-bottom">
                            <IonItem
                              className="show-click"
                              onClick={() => {
                                dispatch(setActualCambaceo(product));
                                dispatch(getCambaceoInfo(product));
                              }}
                            >
                              <IonIcon
                                color="primary"
                                icon={pricetagOutline}
                                slot="start"
                              />
                              <IonLabel color="primary">
                                {product.name}
                              </IonLabel>
                              <IonButtons>
                              
                                <IonButton
                                  color="primary"
                                  slot="end"
                                  fill="clear"
                                  onClick={() => {
                                    dispatch(setActualCambaceo(product));
                                    addToPage(product);
                                  }}
                                >
                                  <IonIcon icon={addOutline} slot="icon-only" />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonCard>
                        ))}
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </>
          )}

          {myCambaceo === PRODUCTS_ADD && (
            <MyCambaceoForm previousPage={ROUTE_SHOP_CATALOGS_PAGES_PRODUCTS}
              myCambaceo={setCambaceo}
            />
            
          )}

       
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MyCambaceoContent;

