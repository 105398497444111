import { IonCol, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonText, useIonViewWillEnter } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Toolbar from "../../components/global/general/Toolbar";
import { getAllProduct, productSelector } from "../../store/ProductSlice";
import { getShopInfoFilter, shopSelector } from "../../store/ShopSlice";
import "./MyShopsPage.css";
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "./shop.css";

import MyShopsProductView from "../../components/products/MyShopsProductView";

const MyShopsPage: React.FC = () => {
	const selectedShopSlug = useParams<{ shopSlug: string }>().shopSlug;
	const dispatch = useDispatch();
	const { shops } = useSelector(shopSelector);
	const { products } = useSelector(productSelector);

	useIonViewWillEnter(() => {
		dispatch(getShopInfoFilter({ filter: { shopID: { eq: selectedShopSlug } } }));
		dispatch(getAllProduct({ filter: { shopID: { eq: selectedShopSlug } } }));
	});

	return shops[0] ? (
		<IonPage>
			<IonHeader>
				<Toolbar />
			</IonHeader>
			<IonContent className="myShopsPage">
				<IonGrid className="ion-no-margin ion-no-padding">
					<IonRow>
						<IonCol>
							{shops[0].plan === "planThree" ? (
								<div className="premiumContainer">
									<div className="headerPremium">
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonGrid>
														<IonRow>
															<IonCol>
																{shops[0].logo === null ? (
																	<IonImg className="logo" src="https://via.placeholder.com/100x100.png" />
																) : (
																	<IonImg className="logo" src={shops[0].logo} />
																)}
															</IonCol>
														</IonRow>
													</IonGrid>
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<div>
														<IonText color="primary">
															<h1 className="title">{shops[0].name}</h1>
														</IonText>
														<IonText color="primary">
															<p className="subtitle">{shops[0].description}</p>
														</IonText>
														{/* <IonList className="socialMediaList">
															<IonButton color="transparent"><IonIcon color="tertiary"icon={logoWhatsapp}/></IonButton>
															<IonButton color="transparent"><IonIcon color="tertiary" icon={logoFacebook}/></IonButton>
															<IonButton color="transparent"><IonIcon color="tertiary" icon={logoInstagram}/></IonButton>
															<IonButton color="transparent"><IonIcon color="tertiary" icon={logoTwitter}/></IonButton>
															<IonButton color="transparent"><IonIcon color="tertiary" icon={logoYoutube}/></IonButton>
														</IonList> */}
														{/* <IonButton color="tertiary" fill="outline" expand="block">Catálogo</IonButton> */}
													</div>
												</IonCol>
											</IonRow>
										</IonGrid>
									</div>
									<div className="slideShow">
										<Swiper
											modules={[Navigation,Pagination]}
											navigation
											pagination = {true}
											slidesPerView={1}
											onSlideChange={() => console.log("")}
											onSwiper={(swiper) => console.log(swiper)}
											className="shopSlider"
										>
											{shops[0].slider?.map((slide, index) => (
												<SwiperSlide>
													{JSON.parse(shops[0].sliderStatus!)[index] == "ACTIVO" && <img key={index} src={slide} className="slidesTest" style={{ width: "100%", height: "auto", maxHeight:"500px", objectFit:"cover",display: "inlineBlock" }} alt="" />}
												</SwiperSlide>
											))}
										</Swiper>
									</div>
								</div>
							) : (
								<div></div>
							)}
							{shops[0].plan === "planTwo" ? (
								<div className="classicContainer">
									<div className="classicHeader">
										<IonGrid>
											<IonRow>
												<IonCol>
													{shops[0].logo === null ? (
														<IonImg className="logo" src="https://via.placeholder.com/100x100.png" />
													) : (
														<IonImg className="logo" src={shops[0].logo} />
													)}
												</IonCol>
											</IonRow>
											<IonRow>
												<IonCol>
													<IonText color="primary">
														<h1 className="title">{shops[0].name}</h1>
													</IonText>
												</IonCol>
											</IonRow>
										</IonGrid>
									</div>
								</div>
							) : (
								<div></div>
							)}
							{shops[0].plan === "planOne" ? (
								<div className="freeContainer">
									<IonGrid>
										<IonRow>
											<IonCol>
												<IonText color="primary">
													<h1 className="title">{shops[0].name}</h1>
												</IonText>
											</IonCol>
										</IonRow>
									</IonGrid>
								</div>
							) : (
								<div></div>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonGrid fixed className="ion-margin-top ion-margin-bottom ion-padding-top ion-padding-bottom">
					<IonRow>
						<IonCol className="ion-text-center">
							<IonText color="secondary" class="title">
								<b>Galería de Productos</b>
							</IonText>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonGrid fixed className="myshopProductView">
					{products &&
						products.filter((prod) => prod.shopID === selectedShopSlug).map((product) =>
							product.productVariations.items[0] !== undefined  ? <MyShopsProductView key={product.id} {...product}></MyShopsProductView> : ""
						)}
				</IonGrid>
			</IonContent>
		</IonPage>
	) : (
		<IonPage>
			<IonText>
				<p>No se encuentra la tienda señalada</p>
			</IonText>
		</IonPage>
	);
};

export default MyShopsPage;
